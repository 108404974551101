import { SCHEMA } from '@leenda/editor/lib/brand'

import { genFormSchema, FONT_SCHEMA_FIELDS } from 'services/Branding/genFormSchema'
import { t } from 'services/Translation'

export const STYLE_FORM_SCHEMA = genFormSchema(SCHEMA.testInfoEnd.style, {
  circle: {
    META: { label: t('elements.test-info.tags.circle') },
    color: { label: t('input.label.color') },
    borderStyle: {},
    borderColor: {},
    borderWidth: {},
    backgroundColor: {},
  },
  // text: { META: { label: t('elements.test-info.tags.text') } },
})

export const FONT_FORM_SCHEMA = genFormSchema(SCHEMA.testInfoEnd.font, {
  base: {
    ...FONT_SCHEMA_FIELDS.base,
  },
})
