import { DraggableProvided, DraggableStateSnapshot, DraggingStyle } from '@hello-pangea/dnd'
import { GroupingItem } from '@leenda/editor/lib/elements'
import cn from 'classnames'
import { useRef } from 'react'
import { createPortal } from 'react-dom'
import { useContext } from 'use-context-selector'

import GroupingContext from 'components/editor-v2/EditorElements/elements/GroupingElement/GroupingContext'
import {
  borderRadiusContent,
  cardStyles,
  getDragableStyles,
  getStyle,
} from 'components/editor-v2/EditorElements/elements/GroupingElement/helper'
import Icon from 'components/uiKit/Icon'
import { KitSize } from 'components/uiKit/KitTypes'
import { COURSE_TOOLTIP } from 'constants/portals'
import { useProjectT } from 'services/ProjectLabels'
import { useImageWithCrop } from 'utils/files'

import s from './PortalGroupingItem.module.scss'

export type ValidationMap = {
  [key: string]: string
}

type ItemProps = {
  provided: DraggableProvided
  snapshot: DraggableStateSnapshot
  item: GroupingItem
}

const PortalGroupingItem = ({ provided, snapshot, item }: ItemProps) => {
  const pt = useProjectT()
  const { styles, hasAnswerImage, font } = useContext(GroupingContext)
  const ref = useRef<HTMLDivElement>(null)
  const getContainer = () => document.getElementById(COURSE_TOOLTIP) || document.body

  const isPortal: boolean = snapshot.isDragging

  const img = useImageWithCrop(item?.image)
  const backgroundImage = hasAnswerImage && img?.path ? `url(${img.path})` : undefined
  const textStyles = item.label && { ...font?.base }
  const labelClasses = cn(s.label, { [s.placeholder]: !item.label })
  const label =
    item.label || (item?.image?.id && hasAnswerImage)
      ? item.label || ''
      : pt('elements.grouping.answer.placeholder')
  const childStyles = {
    ...cardStyles,
    ...getDragableStyles(snapshot, provided.draggableProps.style as DraggingStyle),
    ...getStyle(snapshot, parseInt(String(styles.group.borderWidth)) || 1, styles.indents.padding),
    ...styles.answer,
    backgroundImage,
  }

  const child = (
    <div
      ref={provided.innerRef}
      {...provided.draggableProps}
      {...provided.dragHandleProps}
      data-grouping-id={item.value}
      style={childStyles}
    >
      <div
        className={s.mask}
        ref={ref}
        style={{
          backgroundColor: hasAnswerImage && img?.path ? item.fillColor : undefined,
          borderRadius: borderRadiusContent(styles.answer) || styles.answer.borderRadius,
        }}
      />
      <div className={labelClasses} style={{ ...textStyles, padding: styles.indents.padding }}>
        {label}
      </div>
      <div className={s.iconWrapper}>
        {styles.icon.backgroundImage ? (
          <div className={s.icon} style={styles.icon} />
        ) : (
          <div className={s.burgerIcon}>
            <Icon name='otherBurger' size={KitSize.S} />
          </div>
        )}
      </div>
    </div>
  )

  if (!isPortal) {
    return child
  }

  return createPortal(child, getContainer())
}

export default PortalGroupingItem
