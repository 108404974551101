import { useHistory } from 'react-router'

import LayoutPage, { LayoutMain } from 'components/LayoutPage'
import { BreadcrumbItem } from 'components/uiKit/Breadcrumbs/Breadcrumbs'
import { FileMetaFor } from 'gql/__generated__/graphql'
import generateLink from 'routes/generateLink'
import { usePathParams } from 'routes/hooks'

import { FinderPage } from '../FinderPage'
import { Paths } from '../types'

interface IFinderTablePageProps {
  createdFor: FileMetaFor.companies | FileMetaFor.projects
  paths: Paths
}

const FinderTablePage: React.FC<IFinderTablePageProps> = ({ createdFor, paths }) => {
  const params = usePathParams(paths.main)
  const history = useHistory()

  const onClickBreadcrumbs = (item: BreadcrumbItem) => {
    const linkToBreadcrumbs = generateLink(paths.main, {
      ...params,
      fileMetaGroupId: item?.id || 'root',
    })
    history.push(linkToBreadcrumbs)
  }
  return (
    <LayoutPage>
      <LayoutMain>
        <FinderPage
          createdFor={createdFor}
          onClickBreadcrumbs={onClickBreadcrumbs}
          params={params}
          paths={paths}
        />
      </LayoutMain>
    </LayoutPage>
  )
}

export default FinderTablePage
