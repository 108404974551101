import { TabsFontSchemaType } from '@leenda/editor/lib/brand'
import cn from 'classnames'
import React, { ReactNode } from 'react'

import { Tooltip } from 'components/uiKit/Dropdown'
import { ElementFontCss } from 'services/Branding/types'
import { testPropsEl } from 'utils/test/qaData'

import s from './TabItem.module.scss'

interface ITabListProps {
  active: boolean
  title: ReactNode | string
  onChangeTab: (tabKey: string) => void
  tabKey: string
  disabled?: boolean
  maxWidth?: number
  font?: ElementFontCss<TabsFontSchemaType>
  mobile: boolean
}

const TabItem = React.forwardRef<HTMLDivElement, ITabListProps>(
  ({ active, title, onChangeTab, tabKey, disabled, maxWidth, font, mobile }, ref) => (
    <div
      {...testPropsEl('tabItem', { value: tabKey })}
      className={cn(s.root, { [s.active]: active, [s.disabled]: disabled })}
      onClick={() => !disabled && onChangeTab(tabKey)}
      ref={ref}
      style={{ maxWidth, ...font?.base }}
    >
      {mobile ? (
        <div className={s.title}>{title || '\u00A0'}</div>
      ) : (
        <Tooltip mouseEnterDelay={1} overlay={title}>
          <div className={s.title}>{title || '\u00A0'}</div>
        </Tooltip>
      )}
    </div>
  ),
)

TabItem.displayName = 'TabItem'

export default TabItem
