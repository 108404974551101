import s from './Modal.module.scss'

interface IModalHeaderProps {
  title?: string | JSX.Element
  description?: string | JSX.Element
  children?: React.ReactNode
}

const ModalHeader: React.FC<IModalHeaderProps> = ({ children, title, description }) => (
  <div className={s.header}>
    {title && <div className={s.title}>{title}</div>}
    {description && <div className={s.description}>{description}</div>}
    {children}
  </div>
)

export default ModalHeader
