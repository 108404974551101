import { FileUsageTypeEnum } from '@leenda/editor/lib/files'
import { saveAs } from 'file-saver'
import numeral from 'numeral'
import React, { useMemo } from 'react'

import Icon from 'components/uiKit/Icon'
import { PreviewMode } from 'services/Store/Project/enums'
import { getFileByUsage } from 'utils/files'
import { testProps } from 'utils/test/qaData'

import { useElementRootStyles } from '../../hooks/useElementRootStyles'
import './DownloaderElement.scss'
import { DownloaderElementType } from './DownloaderElement.types'

const maxLengthLabel = 150

const DownloaderElement: DownloaderElementType = ({ element, styles, mode, font }) => {
  const { file, label } = element.value

  const fileData = getFileByUsage(file, FileUsageTypeEnum.common)

  const size = useMemo(
    () => (fileData?.file?.size && numeral(fileData?.file?.size).format('0.0 b')) || '0.0 MB',
    [fileData?.file?.size],
  )

  const isTypeFile = fileData?.file?.ext
  const isEditor = mode.previewMode === PreviewMode.editor

  const labelValue = label && label?.length >= maxLengthLabel ? `${label}...` : label

  const downloaderLinkClass = `downloader__text${
    isEditor ? ' downloader__text_download-disabled' : ''
  }`
  const onClick = (e: any) => {
    e.stopPropagation()
    e.preventDefault()
    saveAs(fileData?.path || '', `${fileData?.name}`)
  }

  const rootStyles = useElementRootStyles(styles.root, styles.downloader, font?.base, styles.border)
  // todo change icon
  return (
    <div className='downloader' style={rootStyles}>
      <div className='downloader__icon'>
        <Icon name='otherFile' style={styles.icon} />
        {isTypeFile && (
          <div className='downloader__icon-type' style={styles.label}>
            <span style={styles.label}>.{fileData?.file?.ext}</span>
          </div>
        )}
      </div>
      <a
        className={downloaderLinkClass}
        onClick={onClick}
        {...testProps({
          el: 'downloaderElLink',
          name: file,
          type: fileData?.file?.ext,
        })}
      >
        <div>{labelValue || 'Empty'}</div>
      </a>
      <div className='downloader__size'>{size}</div>
    </div>
  )
}

export default DownloaderElement
