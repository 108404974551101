import Form from 'components/controls/Form'
import FormControls from 'components/controls/FormControls'
import FormField from 'components/controls/FormField'
import Modal, { MCWithParams, ModalBody } from 'components/uiKit/Modal'
import { notify } from 'components/uiKit/Notification'
import { NotificationType } from 'components/uiKit/Notification/types'
import { useGetAllBrandSelectItems } from 'gql/brands.apollo'
import { useTemplateGroupUpdate } from 'gql/templates.apollo'
import { t } from 'services/Translation'

import { brand, name, color } from '../fieldsConfig'
import { useGetInitialGroupData } from '../hooks'

interface ICreateTemplateGroupParams {
  companyId: string
  selected?: string
  createdFor?: string
}

const EditCollectionModal: MCWithParams<ICreateTemplateGroupParams> = ({
  onClose,
  params: { companyId, selected, createdFor },
}) => {
  const { data } = useGetAllBrandSelectItems({ companyId })
  const [updateGroupTemplate] = useTemplateGroupUpdate()
  const { title, initialValues, formLabels } = useGetInitialGroupData('update', companyId, selected)

  const brandsOptions = !data?.data?.brands
    ? []
    : data.data.brands.map((brand) => ({
        value: brand.id,
        label: brand.name,
      }))

  const handleSubmit = async (values: typeof initialValues) => {
    if (selected) {
      const { data } = await updateGroupTemplate({
        variables: { id: selected, payload: values },
      })
      if (data?.data) {
        notify({ type: NotificationType.success, message: t('notify.templateGroupEdit') })
        onClose()
      }
    }

    return
  }

  return (
    <Modal name='editCollectionModal' title={title}>
      <ModalBody sizeAutoCapable>
        <Form
          initialValues={initialValues}
          name='editCollectionForm'
          onReset={onClose}
          onSubmit={handleSubmit}
        >
          <FormField config={name(createdFor, true)} />
          <FormField config={color} />
          <FormField config={brand(brandsOptions)} />
          <FormControls justify='right' label={formLabels} />
        </Form>
      </ModalBody>
    </Modal>
  )
}

export default EditCollectionModal
