import { FieldPolicy } from '@apollo/client'
import * as R from 'ramda'

import {
  DashboardCommentsQuery,
  DashboardCommentsQueryVariables,
  DashboardProjectsQuery,
  DashboardProjectsQueryVariables,
  EditorBlocksPatchesQuery,
  EditorBlocksPatchesQueryVariables,
  EmployeesAllQuery,
  TemplatesAllQuery,
  TemplatesAllQueryVariables,
  UnsplashExternalImagesQuery,
  UnsplashExternalImagesQueryVariables,
} from 'gql/__generated__/graphql'

export interface IFileMetaGroupAllResult {
  groups: { __ref: string }[]
  fileMetas: { __ref: string }[]
  total: number
  totalGroup: number
  limit: number
  offset: number
}

export const fileMetaMerge = {
  keyArgs: [
    'search',
    [
      'query',
      'contentType',
      'fileMetaType',
      'isArchived',
      'parentId',
      'ownersIds',
      'createFrom',
      'createTo',
      'sizeFrom',
      'sizeTor',
      'id',
    ],
  ],
  merge(
    existing: IFileMetaGroupAllResult,
    incoming: IFileMetaGroupAllResult,
    args: Record<string, any>,
  ) {
    const isFirstPage = args.args?.search?.pagination?.offset === 0
    const isPaginatedList = args.variables?.isInfiniteLoad === false
    if (isPaginatedList || isFirstPage) {
      return incoming
    }
    if (!incoming) {
      return existing
    }
    if (!existing) {
      return incoming
    }
    const result = {
      groups: existing ? [...existing.groups] : [],
      fileMetas: existing ? [...existing.fileMetas] : [],
      total: incoming.total,
      totalGroup: incoming.totalGroup,
      offset: incoming.offset,
      limit: incoming.limit,
    }

    for (let i = 0; i < incoming.groups.length; i++) {
      const group = incoming.groups[i]
      const existingGroup = existing
        ? existing.groups.find((el: { __ref: string }) => el.__ref === group.__ref)
        : []
      if (!existingGroup) {
        result.groups.push(group)
      } else {
        console.warn('existingGroup', existingGroup)
      }
    }
    for (let i = 0; i < incoming.fileMetas.length; i++) {
      const file = incoming.fileMetas[i]
      const existingFile = existing
        ? existing.fileMetas.find((el: { __ref: string }) => el.__ref === file.__ref)
        : []
      if (!existingFile) {
        result.fileMetas.push(file)
      } else {
        console.warn('existingFile', existingFile)
      }
    }

    return result
  },
}

export const employeesAllMerge = {
  keyArgs: ['search', ['query', 'isArchived', 'createFrom', 'createTo', 'id', 'projectId']],
  merge(
    existing: EmployeesAllQuery['data'],
    incoming: EmployeesAllQuery['data'],
    args: Record<string, any>,
  ) {
    const isFirstPage = args.args?.search?.pagination?.offset === 0
    const isPaginatedList = args.variables?.isInfiniteLoad === false
    if (isPaginatedList || isFirstPage) {
      return incoming
    }
    if (!incoming) {
      return existing
    }
    if (!existing) {
      return incoming
    }
    const result = {
      employees: existing ? [...existing.employees] : [],
      total: incoming.total,
      offset: incoming.offset,
      limit: incoming.limit,
    }

    for (let i = 0; i < incoming.employees.length; i++) {
      const employees = incoming.employees[i]
      const existingEmployees = existing
        ? //@ts-ignore TODO: 1373
          existing.employees.find((el) => el.__ref === employees.__ref)
        : []
      if (!existingEmployees) {
        result.employees.push(employees)
      } else {
        console.warn('existingEmployees', existingEmployees)
      }
    }

    return result
  },
}

export const dashboardCommentsMerge: FieldPolicy<
  DashboardCommentsQuery['data'],
  DashboardCommentsQuery['data'],
  DashboardCommentsQueryVariables
> = {
  keyArgs: [],
  merge(existing, incoming, { args }) {
    const offset = args?.search.pagination.offset || 0
    if (offset === 0) {
      return incoming
    }
    const comments = existing?.comments ? existing.comments.slice() : []
    for (let i = 0; i < incoming.comments.length; ++i) {
      comments[offset + i] = incoming.comments[i]
    }

    return {
      comments,
      pagination: R.mergeDeepRight(existing?.pagination || {}, incoming?.pagination || {}),
      __typename: 'DashboardComments',
    }
  },
}

export const unsplashFilesMerge: FieldPolicy<
  UnsplashExternalImagesQuery['data'],
  UnsplashExternalImagesQuery['data'],
  UnsplashExternalImagesQueryVariables
> = {
  keyArgs: ['payload', ['query', 'id', 'urls', 'raw']],
  merge(existing, incoming, { args }) {
    const offset = args?.payload?.offset || 0
    if (offset === 0) {
      return incoming
    }
    if (!existing) {
      return incoming
    }
    if (!incoming) {
      return existing
    }
    return {
      externalImages: [...existing.externalImages, ...incoming.externalImages],
      offset: incoming.offset || 0,
      limit: incoming.limit || 0,
      __typename: 'UnsplashExternalImages',
      total: incoming.total,
    }
  },
}

export const templatesAllMerge: FieldPolicy<
  TemplatesAllQuery['data'],
  TemplatesAllQuery['data'],
  TemplatesAllQueryVariables
> = {
  keyArgs: ['companyId', 'data', ['tab', 'editorTemplateGroupId']],
  merge(existing, incoming, { args }) {
    const offset = args?.data?.pagination?.offset || 0
    if (offset === 0) {
      return incoming
    }
    const templates = existing?.templates ? existing.templates.slice() : []
    for (let i = 0; i < incoming.templates.length; ++i) {
      templates[offset + i] = incoming.templates[i]
    }
    return {
      templates,
      pagination: R.mergeDeepRight(existing?.pagination || {}, incoming?.pagination || {}),
      __typename: 'EditorTemplateAll',
    }
  },
}

export const dashboardProjectsMerge: FieldPolicy<
  DashboardProjectsQuery['data'],
  DashboardProjectsQuery['data'],
  DashboardProjectsQueryVariables
> = {
  keyArgs: [],
  merge(existing, incoming, { args }) {
    const offset = args?.search.pagination.offset || 0
    if (offset === 0) {
      return incoming
    }
    const projects = existing?.projects ? existing.projects.slice() : []
    for (let i = 0; i < incoming.projects.length; ++i) {
      projects[offset + i] = incoming.projects[i]
    }
    return {
      projects,
      pagination: R.mergeDeepRight(existing?.pagination || {}, incoming?.pagination || {}),
      __typename: 'DashboardProjects',
    }
  },
}

export const editorBlocksPatchesMerge: FieldPolicy<
  EditorBlocksPatchesQuery['data'],
  EditorBlocksPatchesQuery['data'],
  EditorBlocksPatchesQueryVariables
> = {
  keyArgs: ['uuid', 'search', ['order']],
  merge(existing, incoming, { args }) {
    const offset = args?.search?.pagination?.offset || 0
    if (offset === 0) {
      return incoming
    }
    const patches = existing?.patches ? existing.patches.slice() : []
    for (let i = 0; i < incoming.patches.length; ++i) {
      patches[offset + i] = incoming.patches[i]
    }

    return {
      patches,
      pagination: R.mergeDeepRight(existing?.pagination || {}, incoming?.pagination || {}),
      __typename: 'BlockPatches',
    }
  },
}
