import { useState } from 'react'
import { MAX_TABLE_SIZE } from 'utils'

import Button from 'components/uiKit/Button'
import InputNumber from 'components/uiKit/InputNumber'
import { KitSize } from 'components/uiKit/KitTypes'
import { t } from 'services/Translation'

import ColumnsControls from '../ColumnsControls'
import { DEFAULT_PAGE_SIZE } from '../constants'
import { useColumns, usePageSize } from '../table.hooks'
import { ColumnState, ColumnType, ITablesExtendsData } from '../types'
import { getColumnsDefaultState } from '../utils'
import s from './TableOptions.module.scss'

export interface ITableOptionsFormProps<Data, Args extends Record<string, unknown>> {
  tableName: string
  defaultColumns?: ColumnType<Data>[]
  onSubmit?: () => void
  args?: Args
}

const exceptionalSymbolsOfTypeNumber = ['e', 'E', '+', '-', '.', ',', ' ']

const TableOptionsForm = <Data extends ITablesExtendsData>({
  tableName,
  defaultColumns,
  onSubmit,
}: ITableOptionsFormProps<Data, Record<string, unknown>>) => {
  const {
    tableColumnsState,
    setTableColumnsState,
    resetState: resetColumnsState,
  } = useColumns(tableName, defaultColumns)
  const [columnStatesDraft, setColumnStatesDraft] = useState<ColumnState[]>(tableColumnsState)
  const { pageSize, setPageSize } = usePageSize(tableName)
  const [paginationValue, setPaginationValue] = useState<number | undefined>(pageSize)

  const onApply = () => {
    setTableColumnsState(columnStatesDraft)
    setPageSize?.(paginationValue)
    onSubmit?.()
  }

  const onReset = () => {
    if (defaultColumns) {
      resetColumnsState()
      setColumnStatesDraft(getColumnsDefaultState(defaultColumns))
    }

    setPageSize?.(DEFAULT_PAGE_SIZE)
    setPaginationValue(DEFAULT_PAGE_SIZE)
    onSubmit?.()
  }

  const onUserInput = (event: React.KeyboardEvent<HTMLInputElement>) => {
    exceptionalSymbolsOfTypeNumber.includes(event.key) && event.preventDefault()
  }
  const onKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      onApply()
    }
  }

  return (
    <div className={s.modalContainer}>
      {defaultColumns && (
        <ColumnsControls
          columnStates={columnStatesDraft}
          defaultColumns={defaultColumns}
          onChange={setColumnStatesDraft}
        />
      )}
      <div className={s.title}>{t('table.pagination.maxSize', { max: MAX_TABLE_SIZE })}</div>
      <InputNumber
        defaultValue={pageSize}
        max={MAX_TABLE_SIZE}
        min={1}
        name='paginationInput'
        onChange={(value) => setPaginationValue(value)}
        onKeyDown={(event) => onUserInput(event)}
        onKeyPress={onKeyPress}
        placeholder={t('input.placeholder.enterValue')}
        size={KitSize.S}
        value={paginationValue}
      />
      <div className={s.modalContainerBtnsBlock}>
        <span>
          <Button
            name='cancel-save-pagination-value'
            onClick={() => onReset()}
            size={KitSize.S}
            styleType='ghost'
          >
            {t('common.restore')}
          </Button>
        </span>
        <span className={s.modalContainerBtnsBlockApplyBtn}>
          <Button name='save-pagination-btn' onClick={() => onApply()} size={KitSize.S}>
            {t('uiKit.button.apply')}
          </Button>
        </span>
      </div>
    </div>
  )
}

export default TableOptionsForm
