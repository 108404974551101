import { useKeyPress } from 'ahooks'
import cn from 'classnames'
import { useCallback, useMemo, useRef } from 'react'

import InputNumber from 'components/uiKit/InputNumber'
import { testPropsEl } from 'utils/test/qaData'

import { AdditionWrapper } from '../Input'
import { IKitControl, KitSize } from '../KitTypes'
import s from './Slider.module.scss'
import { calculatePercentage, getPosition, getValueWithinMinMax } from './utils'

export interface ISliderProps extends IKitControl<number> {
  /**
   * Determines whether to show an input field for manual value input.
   */
  showInput?: boolean

  /**
   * The placeholder text for the input field.
   */
  placeholder?: string

  /**
   * The postfix text to display after the slider value.
   */
  postfix?: string

  /**
   * The maximum value of the slider.
   */
  max?: number

  /**
   * The minimum value of the slider.
   */
  min?: number

  /**
   * Step of the slider.
   */
  step?: number
}

const Slider: React.FC<ISliderProps> = ({
  name,
  onChange,
  onBlur,
  onFocus,
  value = 0,
  // defaultValue,
  size = KitSize.M,
  disabled,
  readOnly,
  max = 100,
  min = 0,
  step = 1,
  showInput,
  postfix,
  placeholder,
}) => {
  const sliderRef = useRef<HTMLDivElement | null>(null)
  const rootSliderRef = useRef<HTMLDivElement | null>(null)
  const percentage = useMemo(() => calculatePercentage({ value, min, max }), [value, min, max])

  const calculateNewValue = useCallback(
    (clientX: number) => {
      const { left, width } = sliderRef.current?.getBoundingClientRect() || { left: 0, width: 0 }
      const mouseX = clientX - left

      const percentage = (mouseX / width) * 100
      const calcNewValue = Math.round(((percentage / 100) * (max - min)) / step) * step + min

      const newValue = getValueWithinMinMax({ min, max, value: calcNewValue })
      onChange?.(newValue)
    },
    [onChange, min, step, max],
  )

  const handleClick = (e: React.MouseEvent) => {
    if (!disabled && !readOnly) {
      calculateNewValue(e.clientX)
    }
  }

  const handleInputNumberChange = useCallback(
    (value?: number) => onChange?.(value || min),
    [min, onChange],
  )

  const handleMouseDown = useCallback(() => {
    if (disabled || readOnly) {
      return
    }

    const onMouseMove = (e: React.MouseEvent | React.TouchEvent | MouseEvent | TouchEvent) => {
      const { clientX } = getPosition(e)
      calculateNewValue(clientX)
    }

    const onMouseUp = () => {
      document.removeEventListener('mouseup', onMouseUp)
      document.removeEventListener('mousemove', onMouseMove)
      document.removeEventListener('touchend', onMouseUp)
      document.removeEventListener('touchmove', onMouseMove)
    }
    document.addEventListener('mouseup', onMouseUp)
    document.addEventListener('mousemove', onMouseMove)
    document.addEventListener('touchend', onMouseUp)
    document.addEventListener('touchmove', onMouseMove)
  }, [calculateNewValue, disabled, readOnly])

  useKeyPress(
    ['leftarrow', 'rightarrow'],
    (e) => {
      e.preventDefault()
      if (e.key === 'ArrowLeft' && value - step >= min) {
        onChange?.(value - step)
      }
      if (e.key === 'ArrowRight' && value + step <= max) {
        onChange?.(value + step)
      }
    },
    { target: rootSliderRef },
  )

  return (
    <div
      {...testPropsEl('slider', { name, value })}
      className={cn(s.root, s[size], { [s.disabled]: disabled })}
    >
      <div
        className={s.sliderWrapper}
        onBlur={onBlur}
        onClick={handleClick}
        onFocus={onFocus}
        ref={rootSliderRef}
        tabIndex={0}
      >
        <div
          className={cn(s.slider, s[size])}
          onMouseDown={handleMouseDown}
          onTouchStart={handleMouseDown}
          ref={sliderRef}
        >
          <div className={s.sliderRail} />
          <div className={s.sliderTrack} style={{ width: `${percentage}%` }} />
          <div className={s.sliderStep} />
          <div className={s.sliderHandle} style={{ left: `${percentage}%` }} />
        </div>
      </div>
      {showInput && (
        <div className={s.inputWrapper}>
          <InputNumber
            max={max}
            min={min}
            name={name}
            onChange={handleInputNumberChange}
            placeholder={placeholder}
            postfix={postfix ? <AdditionWrapper>{postfix}</AdditionWrapper> : null}
            size={size}
            value={value}
          />
        </div>
      )}
    </div>
  )
}

export default Slider
