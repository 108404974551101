import * as R from 'ramda'
import React, { useContext, useEffect, useMemo } from 'react'
import { ReactEditor, useSlate } from 'slate-react'

import { RichTextContext } from 'components/RichText/context'
import { getLinkElement } from 'components/RichText/inline/withInline'
import { IconButton } from 'components/uiKit/Button'
import Input from 'components/uiKit/Input'
import { KitSize } from 'components/uiKit/KitTypes'
import { validateLinkUrl } from 'utils/websiteValidation'

import s from './LinkForm.module.scss'

const calcTop = (
  rootRef: React.RefObject<HTMLDivElement>,
  formRef: { width: number; height: number },
  _anchor: DOMRect,
  focus: DOMRect,
) => {
  const rootRect = rootRef.current?.getBoundingClientRect()
  const focusTop = focus.top - (rootRect?.top || 0)
  const toolbarHeight = formRef?.height || 0
  const top = focusTop - 8 - toolbarHeight
  return top
}

const calcLeft = (
  rootRef: React.RefObject<HTMLDivElement>,
  formRef: { width: number; height: number },
  anchor: DOMRect,
  focus: DOMRect,
) => {
  const rootRect = rootRef.current?.getBoundingClientRect()
  const focusLeft = focus.left - (rootRect?.left || 0)
  const focusTop = focus.top - (rootRect?.top || 0)
  const anchorLeft = anchor.left - (rootRect?.left || 0)
  const anchorTop = anchor.top - (rootRect?.top || 0)
  const toolbarWidth = formRef?.width || 0

  const left = R.clamp(
    0,
    Math.max(0, (rootRect?.width || 0) - toolbarWidth),
    focusTop < anchorTop
      ? focusLeft
      : focusTop > anchorTop
        ? focusLeft - toolbarWidth
        : focusLeft < anchorLeft || focusTop < anchorTop
          ? focusLeft
          : focusLeft - toolbarWidth,
  )
  return left
}

const calcTopFromElement = (
  rootRect: DOMRect,
  size: { width: number; height: number },
  node: DOMRect,
) => {
  const nodeTop = node.top - (rootRect?.top || 0)
  return nodeTop - 8 - (size.height || 0)
}

const calcLeftFromElement = (
  rootRect: DOMRect,
  size: { width: number; height: number },
  node: DOMRect,
) => {
  const nodeLeft = node.left - (rootRect?.left || 0)
  const nodeRight = node.right - (rootRect?.left || 0)
  return R.clamp(
    0,
    Math.max(rootRect.width - size.width, 1),
    (nodeRight + nodeLeft - (size.width || 0)) / 2,
  )
}

interface ILinkFormProps {
  anchor?: DOMRect
  focus?: DOMRect
  size?: { width: number; height: number }
}

const LinkForm = React.forwardRef<HTMLDivElement, ILinkFormProps>(
  ({ anchor, focus, size }, ref) => {
    const editor = useSlate()
    const { onUpdateFormat, setForm, format, rootRef } = useContext(RichTextContext)
    const [value, setValue] = React.useState<string>(format?.inline?.url || '')
    const isValid = validateLinkUrl(value)
    const isEditing = Boolean(format?.inline?.url)

    const { top, left } = useMemo(() => {
      const element = getLinkElement(editor)
      if (element) {
        const rootRect = rootRef.current?.getBoundingClientRect()
        const rect = element && ReactEditor.toDOMNode(editor, element)?.getBoundingClientRect()
        const top = rootRect && rect && size && calcTopFromElement(rootRect, size, rect)
        const left = rootRect && rect && size && calcLeftFromElement(rootRect, size, rect)
        return { top, left }
      }
      return {
        top: anchor && focus && size && calcTop(rootRef, size, anchor || focus, focus),
        left: anchor && focus && size && calcLeft(rootRef, size, anchor || focus, focus),
      }
    }, [rootRef, editor, anchor, focus, size])

    const onClose = () => setForm('default')

    const onSave = () => {
      onUpdateFormat('link', value)
      onClose()
    }

    const onDelete = () => {
      onUpdateFormat('link', null)
      onClose()
    }

    useEffect(() => {
      setValue(format?.inline?.url || '')
    }, [format?.inline?.url])

    const onKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
      if (e.key === 'Enter') {
        e.stopPropagation()
        e.preventDefault()
        onSave()
      }
      if (e.key === 'Escape') {
        e.stopPropagation()
        e.preventDefault()
        onClose()
      }
    }

    return (
      <div
        className={s.root}
        onKeyDown={onKeyDown}
        ref={ref}
        style={{
          transition: 'transform 0.5s cubic-bezier(.3,1.2,.2,1)',
          transform: `translate(${left}px, ${top}px)`,
        }}
      >
        <div className={s.input}>
          <Input
            autoFocus={!value}
            error={!isValid}
            name='richTextLink'
            onChange={setValue}
            styleType='ghost'
            value={value}
          />
        </div>
        <div className={s.controls}>
          {isEditing && (
            <>
              <IconButton
                icon='otherBrokenLink'
                name='delete'
                onClick={onDelete}
                size={KitSize.S}
                styleType='ghost'
              />
              <IconButton
                icon='checkmark1'
                name='update'
                onClick={onSave}
                size={KitSize.S}
                styleType='ghost'
              />
            </>
          )}
          {!isEditing && value && (
            <IconButton
              icon='checkmark1'
              name='create'
              onClick={onSave}
              size={KitSize.S}
              styleType='ghost'
            />
          )}
          {!isEditing && !value && (
            <IconButton
              icon='otherClose'
              name='cancel'
              onClick={onClose}
              size={KitSize.S}
              styleType='ghost'
            />
          )}
        </div>
      </div>
    )
  },
)
LinkForm.displayName = 'LinkForm'

export default LinkForm
