import cn from 'classnames'

import { completeElement } from 'services/Store/Project/actions'
import { EditorMode, PreviewMode } from 'services/Store/Project/enums'
import { useProjectContext, useProjectDispatch } from 'services/Store/Project/hooks'
import { getIsInteractiveMode } from 'services/Store/Project/selectors'
import { testProps } from 'utils/test/qaData'

import s from './CardElement.module.scss'
import { CardElementType } from './CardElement.types'
import CardSide from './CardSide'

const CARDS_SIDES = ['front', 'back'] as const

const CardElement: CardElementType = ({
  element,
  styles,
  mode,
  block,
  state,
  waiting,
  setState,
  onChange,
  font,
}) => {
  const isBack = Boolean(state?.isBack)
  const dispatch = useProjectDispatch()
  const isInteractive = useProjectContext(getIsInteractiveMode)
  const isFill = mode.previewMode === PreviewMode.editor && mode.editorMode === EditorMode.fill

  const onClick = () => {
    setState?.({ isBack: !isBack })
    if (isInteractive && !isBack) {
      dispatch(
        completeElement({
          elementId: element.id,
          blockId: block?.uuid || '',
          incomplete: false,
        }),
      )
    }
  }

  const cardCn = cn(s.card, { [s.back]: isBack, [s.front]: !isBack })

  const isPdf = mode.previewMode === PreviewMode.pdf
  if (isPdf) {
    return (
      <>
        {CARDS_SIDES.map((item) => (
          <div className={s.root} key={item} style={{ ...styles.card, ...styles.border }}>
            <div className={cn(s.card, s[item])}>
              <CardSide
                font={font}
                id={element.id}
                mode={mode}
                side={item}
                styles={styles}
                value={element.value}
                active
              />
            </div>
          </div>
        ))}
      </>
    )
  }

  return (
    <div
      className={s.root}
      onClick={() => !isFill && onClick()}
      style={{
        width: styles.card.width,
        height: styles.card.height,
      }}
      {...testProps({
        el: 'cardEl',
        side: !isBack ? 'front' : 'back',
        label: !isBack ? element.value.front.label : element.value.back.label,
      })}
    >
      <div
        className={cardCn}
        style={{
          ...styles.border,
          borderRadius: styles.card.borderRadius,
        }}
      >
        <div className={s.fill} style={{ borderRadius: styles.card.borderRadius }} />
        <CardSide
          active={!isBack}
          block={block}
          font={font}
          id={element.id}
          mode={mode}
          onChange={onChange}
          onClick={onClick}
          side='front'
          styles={styles}
          value={element.value}
          waiting={waiting}
        />
        <CardSide
          active={isBack}
          block={block}
          font={font}
          id={element.id}
          mode={mode}
          onChange={onChange}
          onClick={onClick}
          side='back'
          styles={styles}
          value={element.value}
          waiting={waiting}
        />
      </div>
    </div>
  )
}

export default CardElement
