import { SCHEMA } from '@leenda/editor/lib/brand'

import { Tooltip } from 'components/uiKit/Dropdown'
import Icon from 'components/uiKit/Icon'
import { KitSize } from 'components/uiKit/KitTypes'
import { genFormSchema, FONT_SCHEMA_FIELDS } from 'services/Branding/genFormSchema'
import { t } from 'services/Translation'

const labelElement = (title: string, tooltip: string) => (
  <div style={{ display: 'flex', alignItems: 'center' }}>
    <div>{title}</div>
    <Tooltip overlay={tooltip}>
      <Icon name='builderTooltip' size={KitSize.S} />
    </Tooltip>
  </div>
)

export const STYLE_FORM_SCHEMA = genFormSchema(SCHEMA.chart.style, {
  chart: {
    META: { label: t('elements.chart.label') },
    backgroundColor: {
      label: labelElement(t('input.label.color'), t('input.tooltip.primaryColor')),
    },
    color: {
      label: labelElement(t('elements.chart.schema.grid'), t('input.tooltip.chartGrid')),
    },
  },
})

export const FONT_FORM_SCHEMA = genFormSchema(SCHEMA.chart.font, {
  base: {
    ...FONT_SCHEMA_FIELDS.base,
    META: { label: t('elements.chart.tags.fontMain') },
  },
  caption: {
    ...FONT_SCHEMA_FIELDS.caption,
    META: { label: t('elements.chart.tags.fontDescription') },
  },
})
