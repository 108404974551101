import React from 'react'

import AbsolutePortal from 'components/editor-v3/components/AbsolutePortal'
import { useProjectContext } from 'services/Store/Project/hooks'
import { getIsActiveNode, getIsHighlighted } from 'services/Store/Project/selectors'
import { Block } from 'services/Store/Project/types'
import { selectRealtimeUsers } from 'services/Store/Users/selectors'
import { useAppSelector } from 'services/Store/hooks'
import { t } from 'services/Translation'

import ControlSelection from '../ControlSelection'

interface IRowControlsProps {
  nodeId: string
  isActive: boolean
  block: Block
}

const RowControls: React.FC<IRowControlsProps> = ({ nodeId }) => {
  const active = useProjectContext(getIsActiveNode, nodeId)
  const highlighted = useProjectContext(getIsHighlighted, nodeId)
  const users = useAppSelector((state) => selectRealtimeUsers(state, { nodeId }))
  return (
    <AbsolutePortal name='rowSelection'>
      {(active || highlighted || users.length) && (
        <ControlSelection
          active={active}
          highlighted={highlighted}
          label={t('editor.row')}
          users={users}
        />
      )}
    </AbsolutePortal>
  )
}

export default RowControls
