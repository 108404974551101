import { AnyAction, Dispatch, MiddlewareAPI } from '@reduxjs/toolkit'
import { gqlClient } from 'gql'
import { debounce } from 'lodash'

import { updateUserPreferences } from 'gql/kusers/gql/mutations'

import { setUserPreferencesItems } from './reducer'

const updatePreferences = (store: MiddlewareAPI, action: AnyAction) => {
  const state = store.getState()
  const userPreferences = state.users.userPreferences
  const userPreferencesItems = action.payload
  const newUserPreferences = { ...userPreferences, ...userPreferencesItems }

  gqlClient.core.mutate({
    mutation: updateUserPreferences,
    variables: {
      data: JSON.stringify(newUserPreferences),
    },
  })
}

const debouncedUpdate = debounce(updatePreferences, 1000)

const preferencesMiddleware = (store: MiddlewareAPI) => (next: Dispatch) => (action: AnyAction) => {
  if (action.type === setUserPreferencesItems.type) {
    debouncedUpdate(store, action)
  }

  return next(action)
}

export default preferencesMiddleware
