import { RichTextValue } from '@leenda/rich-text'
import { useSize } from 'ahooks'
import { useDebounceFn } from 'ahooks'
import lodash from 'lodash'
import * as R from 'ramda'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'

import RichText from 'components/form/RichText/RichText'
import { useGetRichTextProps } from 'components/form/RichText/useGetRichTextProps'
import Icon from 'components/uiKit/Icon'
import ScrollService from 'services/Scroll/ScrollService'
import { PreviewMode } from 'services/Store/Project/enums'
import { useProjectContext } from 'services/Store/Project/hooks'
import { getIsActiveElement } from 'services/Store/Project/selectors'
import { useImageWithCrop } from 'utils/files'

import { useElementCompleted } from '../../hooks/useElementCompleted'
import { useElementRootStyles } from '../../hooks/useElementRootStyles'
import {
  CIRCLE_WIDTH,
  DEBOUNCE_MOVE_SPLITTER,
  DEFAULT_SPLITTER_POSITION,
  DEFAULT_SPLITTER_WIDTH,
  DEFAULT_WIDTH,
  MAX_SPLITTER_VAlUE,
  MIN_SPLITTER_VAlUE,
} from './SplitterConstants'
import s from './SplitterElement.module.scss'
import { SplitterElementType } from './SplitterElement.types'

const VIEWED_LEFT = 55
const VIEWED_RIGHT = 45

const SplitterElement: SplitterElementType = ({
  element,
  styles,
  mode,
  block,
  state,
  waiting,
  setState,
  onChange,
  font,
}) => {
  const ref = useRef<HTMLDivElement>(null)
  const isPdf = mode.previewMode === PreviewMode.pdf
  const { value, id } = element
  const isActive = useProjectContext(getIsActiveElement, id)
  const splitterWidth = parseInt(String(styles.splitter.width)) || DEFAULT_SPLITTER_WIDTH
  const customIconImage = styles.icon.backgroundImage
  const showIcon = (styles.icon as React.CSSProperties & { inversion: boolean }).inversion
  const { rightBlock, leftBlock } = value
  const textStyleLeft = useElementRootStyles(styles.indents, styles.textLeft)
  const textStyleRight = useElementRootStyles(styles.indents, styles.textRight)
  const stylesWrap = useElementRootStyles(styles.root, styles.base)
  const width =
    state?.fill === 'left'
      ? 100
      : state?.fill === 'right'
        ? 0
        : state?.position ?? element.value.position ?? DEFAULT_SPLITTER_POSITION
  const setWidth = (position: number) => setState?.({ position })
  const [shift, setShift] = useState({ left: false, right: false })
  const allViewed = lodash.every(shift)

  const size = useSize(ref)
  const leftImagePath = useImageWithCrop(leftBlock.image)
  const rightImagePath = useImageWithCrop(rightBlock.image)

  const { iconLeft, left } = useMemo(() => {
    const iconLeft = -Math.round((CIRCLE_WIDTH - splitterWidth) / 2)
    const blockWidth = size?.width || DEFAULT_WIDTH - splitterWidth
    const left = ((blockWidth - splitterWidth) * width) / blockWidth + '%'
    return { iconLeft, left }
  }, [width, splitterWidth, CIRCLE_WIDTH, size?.width])

  const isFullOpen = width === 0 || width === 100

  const setNewPositionElementState = (position: number) => {
    setState?.({ position, fill: null })
    onChange && onChange({ ...value, position })
  }

  const { run: setNewPositionState } = useDebounceFn(
    (position: number) => setNewPositionElementState && setNewPositionElementState(position),
    { wait: DEBOUNCE_MOVE_SPLITTER },
  )

  const setCalcNewWidth = useCallback(
    (moveX: number, startX: number) => {
      const delta = moveX - startX
      const startWidth = width
      const newWidth = Math.round(
        Math.max(
          MIN_SPLITTER_VAlUE,
          Math.min(
            MAX_SPLITTER_VAlUE,
            startWidth + (delta / (size?.width || DEFAULT_WIDTH)) * MAX_SPLITTER_VAlUE,
          ),
        ),
      )
      const viewedSide = newWidth > VIEWED_LEFT ? 'left' : newWidth < VIEWED_RIGHT ? 'right' : ''
      setWidth(newWidth)
      viewedSide && setShift((prev) => (prev[viewedSide] ? prev : { ...prev, [viewedSide]: true }))
      setNewPositionState(newWidth)
    },
    [width, size?.width, setNewPositionState],
  )

  const onMouseDown = useCallback(
    (event: React.MouseEvent<HTMLDivElement, MouseEvent | undefined>) => {
      event.preventDefault()
      const startX = event.clientX
      const onMouseMove = (e: MouseEvent) => setCalcNewWidth(e.clientX, startX)
      const onMouseUp = () => {
        document.removeEventListener('mousemove', onMouseMove)
        document.removeEventListener('mouseup', onMouseUp)
      }
      document.addEventListener('mousemove', onMouseMove)
      document.addEventListener('mouseup', onMouseUp)
    },
    [setCalcNewWidth],
  )

  const onTouchStart = useCallback(
    (event: React.TouchEvent<HTMLDivElement>) => {
      event.preventDefault()
      const startX = event.touches[0].clientX
      if (mode.previewMode !== PreviewMode.editor && ScrollService.disableScroll) {
        ScrollService.disableScroll()
      }
      const onMouseMove = (e: TouchEvent) => {
        e.preventDefault()
        setCalcNewWidth(e.touches[0]?.clientX, startX)
      }
      const onMouseUp = (e: TouchEvent) => {
        e.preventDefault()
        if (mode.previewMode !== PreviewMode.editor && ScrollService?.enableScroll) {
          ScrollService.enableScroll()
        }
        document.removeEventListener('touchmove', onMouseMove)
        document.removeEventListener('touchend', onMouseUp)
      }
      document.addEventListener('touchmove', onMouseMove)
      document.addEventListener('touchend', onMouseUp)
    },
    [setCalcNewWidth],
  )

  useEffect(() => {
    if (!isActive) {
      setState?.({ fill: null })
    }
  }, [isActive])

  useEffect(() => {
    setWidth(value.position)
  }, [value.position])

  useElementCompleted(block?.uuid || '', element.id, allViewed)

  const { isActiveElement, handlePropagationClick } = useGetRichTextProps({
    elementId: id,
    mode,
    block,
  })

  const onChangeLabel = (labelValue: RichTextValue, side: string) => {
    onChange?.(R.assocPath([side, 'text'], labelValue, value))
  }

  const openLeft = () => mode.previewMode === PreviewMode.editor && setState?.({ fill: 'left' })
  const openRight = () => mode.previewMode === PreviewMode.editor && setState?.({ fill: 'right' })

  if (isPdf) {
    return (
      <div className={s.pdf}>
        {[leftImagePath, rightImagePath].map((img) => (
          <img className={s.img} key={img?.path} src={img?.path} style={stylesWrap} />
        ))}
      </div>
    )
  }

  return (
    <div className={s.root}>
      <div className={s.wrap} ref={ref} style={stylesWrap}>
        <div className={s.cropperLeft} onDoubleClick={openLeft} style={{ width: `${width}%` }}>
          <div className={s.left} style={{ width: size?.width }}>
            {leftImagePath && <img alt='' className={s.image} src={leftImagePath.path} />}
            <div
              className={s.text}
              onMouseDown={handlePropagationClick}
              style={{ ...textStyleLeft, background: leftBlock.background }}
            >
              <RichText
                active={isActiveElement && isFullOpen}
                disabled={state?.fill !== 'left'}
                name='splitter.left'
                onChange={(val) => onChangeLabel(val, 'leftBlock')}
                styles={font}
                toolbarContainerId='rich-text-toolbar'
                value={leftBlock.text}
                waiting={waiting}
              />
            </div>
          </div>
        </div>
        <div
          className={s.cropperRight}
          onDoubleClick={openRight}
          style={{ width: `${MAX_SPLITTER_VAlUE - width}%` }}
        >
          <div className={s.right} style={{ ...styles.textRight, width: size?.width }}>
            {rightImagePath && <img alt='' className={s.image} src={rightImagePath.path} />}
            <div
              className={s.text}
              onMouseDown={handlePropagationClick}
              style={{ ...textStyleRight, background: rightBlock.background }}
            >
              <RichText
                active={isActiveElement && isFullOpen}
                disabled={state?.fill !== 'right'}
                name='splitter.right'
                onChange={(val) => onChangeLabel(val, 'rightBlock')}
                styles={font}
                toolbarContainerId='rich-text-toolbar'
                value={rightBlock.text}
                waiting={waiting}
              />
            </div>
          </div>
        </div>
        <div
          className={s.splitter}
          onMouseDown={onMouseDown}
          onTouchStart={onTouchStart}
          style={{ left, background: styles.splitter.borderColor }}
        >
          <div className={s.splitterBody} style={{ width: splitterWidth }} />
          {showIcon && (
            <div className={s.icon} style={{ left: iconLeft }}>
              {customIconImage && (
                <div className={s.customIcon} style={{ backgroundImage: customIconImage }} />
              )}
              {!customIconImage && <Icon name='splitter' />}
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default SplitterElement
