import { SidebarFontSchemaType, SidebarSchemaType } from '@leenda/editor/lib/brand'
import { CourseStructureItem } from '@leenda/editor/lib/elements'
import { useCallback } from 'react'

import { ISectionTreeNode } from 'components/sections/ProjectStructure/utils'
import Collapse from 'components/uiKit/Collapse'
import { ElementFontCss, ElementStyleCss } from 'services/Branding/types'

import { StructureNodeCourse } from '../StructureNodeCourse'

interface IStructureCollapse {
  nodes: ISectionTreeNode<CourseStructureItem>[]
  onSectionPick: (sectionId: string) => void
  styles: ElementStyleCss<SidebarSchemaType>
  font: ElementFontCss<SidebarFontSchemaType>
  sectionId: string
}

const CourseCollapseStructure: React.FC<IStructureCollapse> = ({
  nodes,
  onSectionPick,
  styles,
  sectionId,
  font,
}) => {
  const isActive = useCallback((id: string) => id === sectionId, [sectionId])

  return (
    <>
      {nodes.map((node) =>
        !!node.children?.length ? (
          <Collapse
            header={(props) => (
              <StructureNodeCourse
                {...props}
                active={isActive(node.item.id)}
                font={font}
                node={node}
                styles={styles}
              />
            )}
            initialOpen={styles.value.initialOpen}
            key={node.item.id}
            name={node.item.name || node.item.id}
            styleType='ghost'
          >
            <CourseCollapseStructure
              font={font}
              nodes={node.children}
              onSectionPick={onSectionPick}
              sectionId={sectionId}
              styles={styles}
            />
          </Collapse>
        ) : (
          <StructureNodeCourse
            active={isActive(node.item.id)}
            font={font}
            key={node.item.id}
            node={node}
            onClick={() => onSectionPick(node.item.id)}
            styles={styles}
            open
          />
        ),
      )}
    </>
  )
}

export default CourseCollapseStructure
