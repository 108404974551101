import React from 'react'

import { ColumnActionUiEnum } from 'components/editor-v3/Editor/types'
import { NodeType } from 'components/editor-v3/types/date.enums'
import { scrollProps } from 'services/Scroll/ScrollService'
import { EditorMode } from 'services/Store/Project/enums'
import { useProjectContext } from 'services/Store/Project/hooks'
import { getEditorMode, getIsActiveNode, getIsHighlighted } from 'services/Store/Project/selectors'
import { t } from 'services/Translation'

import { FCNode } from '../../Node/types'
import ColumnControls from '../../controls/ColumnControls'
import s from './EditorAbsolute.module.scss'

const CONTROLS = [
  ColumnActionUiEnum.DELETE,
  ColumnActionUiEnum.SPLIT_HORIZONTAL,
  ColumnActionUiEnum.SPLIT_VERTICAL,
  ColumnActionUiEnum.ADD_SHELL,
]

const EditorAbsolute: FCNode<NodeType.absolute> = React.forwardRef(
  ({ children, node, style, block }, ref) => {
    const isActive = useProjectContext(getIsActiveNode, node.id)
    const isHighlighted = useProjectContext(getIsHighlighted, node.id)
    const isPro = useProjectContext(getEditorMode) === EditorMode.pro

    return (
      <div {...scrollProps(node.id)} className={s.root} ref={ref} style={style}>
        {isActive && isPro && (
          <ColumnControls
            block={block}
            controls={CONTROLS}
            isActive={isActive}
            isHighlighted={isHighlighted}
            label={t('uiKit.tooltip.absolute')}
            nodeId={node.id}
          />
        )}
        {children}
      </div>
    )
  },
)

EditorAbsolute.displayName = 'EditorAbsolute'

export default React.memo(EditorAbsolute)
