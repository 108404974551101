import React from 'react'

import { genDataField } from 'components/controls/Field'
import RealtimeField from 'components/controls/RealtimeField'
import Padding from 'components/editor-v3/Editor/Toolbar/Padding'
import Hint from 'components/uiKit/Hint'
import { Block, MapType } from 'services/Store/Project/types'
import { t } from 'services/Translation'
import { chainName } from 'utils/form'

import { TestSingleChoiceValueFormType } from './TestSingleChoice.types'

const genField = genDataField<MapType<Block>>()

const isRandom = genField({
  name: 'isRandom',
  label: t('input.label.shuffle'),
  type: 'segmented',
  layout: 'horizontal',
})

const TestSingleChoiceForm: TestSingleChoiceValueFormType = ({ name }) => (
  <Padding sides>
    <Hint text={t('elements.testSingleChoice.form.randomHint')} />
    <RealtimeField config={chainName(name, isRandom)} />
  </Padding>
)

export default TestSingleChoiceForm
