import React from 'react'

import {
  ElementErrorPlaceholder,
  RespawnButton,
} from 'components/editor-v2/EditorElements/decorators/ElementErrorPlaceholder'

import s from './RichTextErrorContent.module.scss'

type RichTextErrorPlaceholderProps = {
  onClick: () => void
  message: string
}

const RichTextErrorContent = ({
  onClick,
  message,
}: RichTextErrorPlaceholderProps): React.ReactElement => (
  <div className={s.root}>
    <ElementErrorPlaceholder>{message}</ElementErrorPlaceholder>
    <RespawnButton onClick={onClick} />
  </div>
)

export { RichTextErrorContent }
