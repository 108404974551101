import { SlateElementMark, SlateMark } from '@leenda/rich-text'
import { useContext } from 'react'

import { RichTextContext } from 'components/RichText/context'
import { SlateCommand } from 'components/form/RichText/richText.constants'
import Radio from 'components/uiKit/Radio'
import { stopPropagation } from 'constants/commonConstans'
import { t } from 'services/Translation'

import { ALIGN_OPTIONS, REGISTER_OPTIONS, TRANSFORM_OPTIONS } from '../../toolbarOptions'
import RadioButtonTool from '../RadioButtonTool'
import s from './OtherSettings.module.scss'

const OtherSettings = () => {
  const { onUpdateFormat, format } = useContext(RichTextContext)
  return (
    <div className={s.other} onClick={stopPropagation}>
      <div className={s.list}>
        <div className={s.titleList}>{t('input.label.align')}</div>
        <Radio
          direction='horizontal'
          name='textAlign'
          onChange={(value) => onUpdateFormat(SlateElementMark.textAlign, value)}
          options={ALIGN_OPTIONS}
          value={format[SlateElementMark.textAlign]}
          hideRadio
        />
      </div>

      <div className={s.list}>
        <div className={s.titleList}>{t('elements.richText.settings.register')}</div>
        <Radio
          direction='horizontal'
          fluid={false}
          name='textTransform'
          onChange={(value) => onUpdateFormat(SlateMark.textTransform, value)}
          options={TRANSFORM_OPTIONS}
          value={format[SlateMark.textTransform]}
          hideRadio
        />
      </div>

      <div className={s.list}>
        <div className={s.titleList}>{t('uiKit.tooltip.insertNpbs')}</div>
        <RadioButtonTool name={SlateCommand.insertNpbs} />
      </div>

      <div className={s.list}>
        <div className={s.titleList}>{t('input.label.view')}</div>
        <Radio
          direction='horizontal'
          fluid={false}
          name='register'
          onChange={(value) => onUpdateFormat(SlateMark.register, value)}
          options={REGISTER_OPTIONS}
          value={format[SlateMark.register]}
          hideRadio
        />
      </div>
      <div className={s.delimiter} />
      <div className={s.list}>
        <div className={s.titleList}>{t('elements.richText.settings.reset')}</div>
        <RadioButtonTool name={SlateCommand.clear} />
      </div>
    </div>
  )
}

export default OtherSettings
