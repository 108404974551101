import { EditorElement, ElementValue } from '@leenda/editor/lib/elements'
import React, { useCallback, useEffect, useMemo } from 'react'

import { ELEMENTS_CONFIG } from 'components/editor-v2/EditorElements/elements.config'
import { FCEditorActions } from 'components/editor-v2/EditorElements/elements.types'
import AbsolutePortal from 'components/editor-v3/components/AbsolutePortal'
import { ShellNode } from 'components/editor-v3/types/data.types'
import { setElement, setHighlight } from 'services/Store/Project/actions'
import { useProjectContext, useProjectDispatch } from 'services/Store/Project/hooks'
import { getActiveComment, getElementState } from 'services/Store/Project/selectors'
import { Block, ElementState, IBlockMode } from 'services/Store/Project/types'

import ControlsGroup from '../ControlsGroup/ControlsGroup'

interface IElementControlsProps {
  element: EditorElement
  node: ShellNode
  active: boolean
  setCustomMode: React.Dispatch<React.SetStateAction<IBlockMode | null>>
  customMode: IBlockMode | null
  mode: IBlockMode
  block: Block
  onChange: (value: ElementValue) => void
}

const ElementControls: React.FC<IElementControlsProps> = ({
  element,
  active,
  node,
  setCustomMode,
  block,
  customMode,
  mode,
  onChange,
}) => {
  const dispatch = useProjectDispatch()
  const activeComment = useProjectContext(getActiveComment)
  const isActiveComment = activeComment?.elementId === element.id
  const elementState = useProjectContext(getElementState, element.id)
  const portalDeps = useMemo(() => [elementState], [elementState])

  const handleSetState = useCallback(
    (value: ElementState) => dispatch(setElement({ id: element.id, value })),
    [element.id],
  )

  useEffect(() => {
    if (isActiveComment) {
      dispatch(setHighlight(node.id))
    } else {
      dispatch(setHighlight(null))
    }
  }, [isActiveComment])

  const Actions = ELEMENTS_CONFIG[element.type]?.EditorActions as FCEditorActions | null
  const actionsContent = Actions ? (
    <Actions
      block={block}
      customMode={customMode}
      element={element}
      mode={mode}
      onChange={onChange}
      setCustomMode={setCustomMode}
      setState={handleSetState}
      state={elementState}
    />
  ) : null

  return (
    <AbsolutePortal
      deps={portalDeps}
      name='elementControls'
      placement='bottomLeft'
      zIndex={1}
      intersection
    >
      {active && <ControlsGroup>{actionsContent}</ControlsGroup>}
    </AbsolutePortal>
  )
}

export default React.memo(ElementControls)
