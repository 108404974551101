import { DeviceMode, PreviewMode } from './enums'
import { IBlockMode, IEditorSettings } from './types'

export const INTERACTIVE_MODES = [
  PreviewMode.demo,
  PreviewMode.preview,
  PreviewMode.publicLink,
  PreviewMode.scorm,
]

export const MEDIA_QUERIES_DEFAULT = {
  [DeviceMode.desktop]: `(min-width: 1025px)`,
  [DeviceMode.tablet]: `(max-width: 1024px) and (min-width: 481px)`,
  [DeviceMode.mobile]: `(max-width: 480px)`,
}

export const SKELETON_MODE: IBlockMode = {
  previewMode: PreviewMode.skeleton,
  deviceMode: DeviceMode.desktop,
  editorMode: null,
}

export const PREVIEW_MODE: IBlockMode = {
  previewMode: PreviewMode.preview,
  deviceMode: DeviceMode.desktop,
  editorMode: null,
}

export const PDF_MODE: IBlockMode = {
  deviceMode: DeviceMode.desktop,
  previewMode: PreviewMode.pdf,
  editorMode: null,
}

export const DEMO_MODE: IBlockMode = {
  deviceMode: DeviceMode.desktop,
  previewMode: PreviewMode.demo,
  editorMode: null,
}

export const DEFAULT_SETTINGS: IEditorSettings = {
  accentColor: 'rgba(115, 110, 239, 1)',
  backgroundColor: 'rgba(244, 245, 247, 1)',
  divider: true,
  navWidth: 220,
}
