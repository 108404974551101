import { useContext } from 'react'

import { FORMAT_FIELDS, SlateFormats } from 'components/RichText/constants'
import { RichTextContext } from 'components/RichText/context'
import Checkbox from 'components/uiKit/Checkbox'
import { Tooltip } from 'components/uiKit/Dropdown'
import Icon from 'components/uiKit/Icon'
import { KitSize } from 'components/uiKit/KitTypes'
import { t } from 'services/Translation'

const CheckboxTool: React.FC<{ name: SlateFormats }> = ({ name }) => {
  const { onUpdateFormat, format } = useContext(RichTextContext)
  const iconName = FORMAT_FIELDS[name]?.icon
  return (
    <Tooltip overlay={t(`uiKit.tooltip.${name}`)} placement='top'>
      <Checkbox
        name={name}
        onChange={(value) => onUpdateFormat(name, value)}
        value={format[name]}
        hideCheckbox
      >
        {iconName && <Icon name={iconName} size={KitSize.S} />}
      </Checkbox>
    </Tooltip>
  )
}
export default CheckboxTool
