import { ButtonElementValue } from '@leenda/editor/lib/elements'
import cn from 'classnames'
import { useCallback } from 'react'

import { Tooltip } from 'components/uiKit/Dropdown'
import { useProjectContext } from 'services/Store/Project/hooks'
import { getIsInteractiveMode } from 'services/Store/Project/selectors'
import { testProps, testPropsEl } from 'utils/test/qaData'

import { Properties } from '../../elements.types'
import { useElementRootStyles } from '../../hooks/useElementRootStyles'
import s from './ButtonElement.module.scss'
import { ButtonElementType } from './ButtonElement.types'

interface IBaseButtonElementProps
  extends Pick<Properties<ButtonElementType>, 'font' | 'styles' | 'mode'> {
  label?: string
  disabled?: boolean
  onClick?: () => void
  icon?: React.ReactElement | null
  iconPosition?: 'left' | 'right'
  action?: ButtonElementValue['action']
  tooltip?: string
}

const BaseButtonElement: React.FC<IBaseButtonElementProps> = ({
  label,
  disabled: disabledProp,
  styles,
  onClick,
  icon,
  iconPosition,
  action,
  tooltip,
  font,
}) => {
  const rootStyles = useElementRootStyles(styles.root)
  const isInteractive = useProjectContext(getIsInteractiveMode)
  const disabled = !isInteractive ? false : disabledProp
  const rootCn = cn(s.root, {
    [s.disabled]: disabled,
  })

  const onClickHandler = useCallback(() => {
    if (disabled || !isInteractive) {
      return
    }
    onClick && onClick()
  }, [disabled, isInteractive, onClick])
  return (
    //TODO: think about using tooltip
    <Tooltip overlay={tooltip}>
      <button
        {...testPropsEl('courseButton', { value: action }, { label, disabled })}
        className={rootCn}
        onClick={onClickHandler}
        style={{
          ...rootStyles,
          backgroundImage: disabled
            ? styles.disabled__var?.backgroundImage
            : styles.button__var?.backgroundImage,
          ...styles.button,
          ...font?.base,
        }}
      >
        {icon && iconPosition === 'left' && (
          <div
            className={s.iconLeft}
            {...testProps({
              el: 'iconCourseButton',
              position: 'left',
              value: icon.props?.name,
            })}
          >
            {icon}
          </div>
        )}
        {label}
        {icon && iconPosition === 'right' && (
          <div
            className={s.iconRight}
            {...testProps({
              el: 'iconCourseButton',
              position: 'right',
              value: icon.props?.name,
            })}
          >
            {icon}
          </div>
        )}
      </button>
    </Tooltip>
  )
}

export default BaseButtonElement
