import { generatePath } from 'react-router'

import { GetStrictLinkToType } from './routes.types'
import { isOptions, isRouteParams } from './typeGuards'

const generateLink: GetStrictLinkToType = (...[route, firstArg, secondArg]) => {
  const params = isRouteParams(firstArg) ? firstArg : {}
  const options = isOptions(firstArg) ? firstArg : secondArg

  try {
    const pathname = generatePath(route, params as any)

    return {
      pathname,
      search: options?.search,
      state: options?.state,
    }
  } catch (error) {
    console.warn(error)
    return {
      pathname: '',
    }
  }
}

export default generateLink
