import { RichTextFontSchemaType } from '@leenda/editor/lib/brand'
import { SlateElementType } from '@leenda/rich-text'
import React, { useEffect, useRef } from 'react'

import { IconButton } from 'components/uiKit/Button'
import { KitSize } from 'components/uiKit/KitTypes'
import TextArea from 'components/uiKit/TextArea'
import { ElementFontCss } from 'services/Branding/types'
import { t } from 'services/Translation'

import s from './AnnotationForm.module.scss'

interface IAnnotationFormProps {
  editorValue: any
  onClose: () => void
  onUpdateFormat: (format: string, value: any) => void
  styles?: ElementFontCss<RichTextFontSchemaType>
}
const AnnotationForm: React.FC<IAnnotationFormProps> = ({
  editorValue,
  onClose,
  onUpdateFormat,
  styles,
}) => {
  const [value, setValue] = React.useState<string>(editorValue.inline?.value || '')
  const [hiddenDivRef, setHiddenDivRef] = React.useState<HTMLDivElement | null>(null)
  const textRef = useRef<HTMLTextAreaElement | null>(null)

  const getNumericLineHeight = (element: HTMLElement): number => {
    const lineHeight = getComputedStyle(element).lineHeight
    if (lineHeight === 'normal') {
      const fontSize = parseInt(getComputedStyle(element).fontSize, 10)
      return fontSize * 1.2
    }
    return parseFloat(lineHeight)
  }

  useEffect(() => {
    if (value && hiddenDivRef) {
      hiddenDivRef.textContent = value
    }
  }, [value])

  useEffect(() => {
    setValue(editorValue.inline?.value)
  }, [editorValue.inline?.value])

  const onChange = (value: string) => {
    setValue(value)
  }

  const onSubmit = () => {
    onUpdateFormat(SlateElementType.annotation, value)
    onClose()
  }

  const onDelete = () => {
    onUpdateFormat(SlateElementType.annotation, null)
    onClose()
  }

  return (
    <div className={s.root}>
      <TextArea
        autoSize={{ minRows: 1, maxRows: 16 }}
        lineHeight={hiddenDivRef ? getNumericLineHeight(hiddenDivRef) : undefined}
        name='annotation'
        onChange={onChange}
        placeholder={t('input.placeholder.addAnnotation')}
        postfix={
          <>
            {editorValue.inline?.value && (
              <IconButton
                icon='otherBrokenLink'
                name='delete'
                onClick={onDelete}
                size={KitSize.S}
                styleType='ghost'
              />
            )}
            {value ? (
              <IconButton
                icon='checkmark1'
                name='addAnnotation'
                onClick={onSubmit}
                size={KitSize.S}
                styleType='ghost'
                type='button'
              />
            ) : (
              <IconButton
                icon='otherClose'
                name='cancel'
                onClick={onClose}
                size={KitSize.S}
                styleType='ghost'
              />
            )}
          </>
        }
        ref={textRef}
        size='mini'
        style={{ ...styles?.base, backgroundColor: 'var(--brand-primaryBackgroundColor)' }}
        styleType='ghost'
        value={value}
        autoFocus
        fluid
      />
      <div className={s.hiddenElement} ref={setHiddenDivRef} style={styles?.base} />
    </div>
  )
}

export default AnnotationForm
