import { gql } from 'gql/__generated__'

export const pdfLastBuild = gql(`
  query PdfLastBuild($projectId: ID!) {
    data: pdfLastBuild(projectId: $projectId) {
      ...PdfBuildSchema
    }
  }
`)

export const PdfBuildListFragment = gql(`
  fragment PdfBuildListSchema on PdfBuild {
    pinned
    name
    id
    size
    type
    status
    createdAt
    employeeId
  }
`)

export const ScormBuildListFragment = gql(`
  fragment ScormBuildListSchema on ScormBuild {
    pinned
    name
    id
    size
    status
    version
    createdAt
    employeeId
  }
`)

export const getProjectBuildsAll = gql(`
  query GetProjectBuildsAll(
    $projectId: String!
    $pagination: PaginationType
    $order: [[String!]!]
  ) {
    projectBuildsAll(projectId: $projectId, pagination: $pagination, order: $order) {
      projectBuildsFields {
        ...PdfBuildListSchema
        ...ScormBuildListSchema
      }
      total
      limit
      offset
    }
  }
`)

export const scormBuild = gql(`
  query BuildLastGetByProjectId($projectId: String!) {
    data: buildLastGetByProjectId(projectId: $projectId) {
      ...ScormBuildSchema
    }
  }
`)
