import cn from 'classnames'
import React, { useCallback, useEffect, useMemo, useState } from 'react'

import EditorElementComponent from 'components/editor-v2/EditorElements/EditorElementComponent'
import { NO_PANEL_ELEMENTS } from 'components/editor-v2/EditorElements/elements.config'
import AbsolutePortal from 'components/editor-v3/components/AbsolutePortal'
import { useOnClickBlock } from 'components/editor-v3/context/EditorContext/actions'
import { getElement } from 'components/editor-v3/context/EditorContext/selectors/block'
import { getShellName } from 'components/editor-v3/context/EditorContext/selectors/blockLabels'
import { ELEMENTS_WITH_CHILDREN } from 'components/editor-v3/types/data.constants'
import { NodeType } from 'components/editor-v3/types/date.enums'
import Icon from 'components/uiKit/Icon'
import { KitSize } from 'components/uiKit/KitTypes'
import { scrollProps } from 'services/Scroll/ScrollService'
import {
  setElement,
  setHighlight,
  setNavbar,
  setProjectNavigation,
  setToolbar,
  updateBlock,
} from 'services/Store/Project/actions'
import { CommentsTab, EditorMode, NavigationEnum } from 'services/Store/Project/enums'
import { useProjectContext, useProjectDispatch } from 'services/Store/Project/hooks'
import {
  getElementState,
  getToolbar,
  getIsActiveNode,
  getIsHighlighted,
  getIsCommentedElement,
  getActiveComment,
  getBlockWaiting,
  getNavBar,
} from 'services/Store/Project/selectors'
import { ElementState, IBlockMode } from 'services/Store/Project/types'
import { selectRealtimeUsers } from 'services/Store/Users/selectors'
import { useAppSelector } from 'services/Store/hooks'
import { testProps } from 'utils/test/qaData'

import { FCNode } from '../../Node/types'
import ControlSelection from '../../controls/ControlSelection'
import ElementControls from '../../controls/ElementControls'
import s from './EditorShell.module.scss'

const EditorShell: FCNode<NodeType.shell> = React.forwardRef(
  ({ node, block, style, wrapperStyle, mode }, ref) => {
    const { elementId, type } = node
    const [customMode, setCustomMode] = useState<IBlockMode | null>(null)
    const dispatch = useProjectDispatch()
    const isActive = useProjectContext(getIsActiveNode, node.id)
    const waiting = useProjectContext(getBlockWaiting, block.uuid)
    const toolbar = useProjectContext(getToolbar)
    const navbar = useProjectContext(getNavBar).tab
    const elementState = useProjectContext(getElementState, elementId || '')
    const label = getShellName(block, node.id)
    const users = useAppSelector((state) => selectRealtimeUsers(state, { nodeId: node.id }))
    const portalDeps = useMemo(() => [elementState], [elementState])
    const highlighted = useProjectContext(getIsHighlighted, node.id)
    const element = elementId ? getElement(block, elementId) : null
    const elementWithChildren = element && ELEMENTS_WITH_CHILDREN.includes(element.type)
    const activeComment = useProjectContext(getActiveComment)
    const isActiveComment = activeComment?.elementId === element?.id
    const disableClicks =
      mode.editorMode === EditorMode.pro &&
      customMode?.editorMode !== EditorMode.fill &&
      !elementWithChildren &&
      !toolbar.commentTab

    const firstComment = useProjectContext(getIsCommentedElement, element?.id)
    const onClickComment = useCallback(
      (e: React.MouseEvent) => {
        e.stopPropagation()
        if (firstComment?.id) {
          dispatch(setToolbar({ commentTab: CommentsTab.section }))
          dispatch(setProjectNavigation({ commentId: firstComment.id }))
        }
      },
      [firstComment?.id, dispatch],
    )
    const cnComments = cn(s.comments, { [s.active]: isActiveComment })

    const handleSetState = useCallback(
      (value: ElementState) => element && dispatch(setElement({ id: element.id, value })),
      [element?.id],
    )

    const onClick = useOnClickBlock()

    const onChange = useCallback(
      (value: unknown) =>
        block?.uuid &&
        dispatch(updateBlock({ id: block?.uuid, name: `elements.${element?.id}.value`, value })),
      [dispatch, block?.uuid, element?.id],
    )

    const onMouseDown = (e: React.MouseEvent) => {
      e.preventDefault()
      if (
        mode.editorMode === EditorMode.fill ||
        !isActive ||
        customMode?.editorMode === EditorMode.fill
      ) {
        e.stopPropagation()
      }
      onClick(e, { blockId: block.uuid, nodeId: node.id, previewMode: mode.previewMode })
      if (mode.editorMode === EditorMode.pro && navbar) {
        dispatch(setNavbar({ tab: NavigationEnum.block }))
      }
    }

    const onMouseEnter = () => !isActive && dispatch(setHighlight(node.id))
    const onMouseLeave = () => !isActive && dispatch(setHighlight(null))

    useEffect(() => {
      if (!isActive) {
        setCustomMode(null)
      }
    }, [isActive, mode])

    useEffect(() => {
      setCustomMode((prev) => (prev ? { ...prev, deviceMode: mode.deviceMode } : null))
    }, [mode.deviceMode])

    // potentially we can all shells make zeroLineHeight, and rule lineHeight inside element. Now it is hotfix
    const shellCn = cn(s.shell, [s[type], { [s.zeroLineHeight]: element?.type === 'image' }])

    const openElementPanel = (e: any) => {
      if (element?.type && NO_PANEL_ELEMENTS.includes(element?.type)) {
        return
      }
      e.stopPropagation()
      dispatch(setToolbar({ commentTab: null, taskTab: null, blockSettings: null, hidden: false }))
    }
    return (
      <div
        {...scrollProps(node.id)}
        {...testProps({ el: 'element', name: element?.type, id: element?.id })}
        className={s.wrapper}
        ref={ref}
        style={wrapperStyle}
      >
        <div
          className={shellCn}
          onDoubleClick={openElementPanel}
          onMouseDown={onMouseDown}
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
          style={style}
        >
          {element && (
            <EditorElementComponent
              block={block}
              element={element}
              elementState={elementState}
              mode={customMode || mode}
              onChange={onChange}
              setState={handleSetState}
              shellStyles={style}
              waiting={waiting}
            />
          )}
          <AbsolutePortal
            deps={portalDeps}
            name='elementComment'
            placement='leftTop'
            translateX='100%'
          >
            {firstComment && toolbar.commentTab && (
              <div className={cnComments}>
                <Icon name='messagesChatRegular' onClick={onClickComment} size={KitSize.S} />
              </div>
            )}
          </AbsolutePortal>
          {element && isActive && (
            <ElementControls
              active={isActive}
              block={block}
              customMode={customMode}
              element={element}
              mode={mode}
              node={node}
              onChange={onChange}
              setCustomMode={setCustomMode}
            />
          )}
          {element && Boolean(isActive || highlighted || users.length) && (
            <AbsolutePortal deps={portalDeps} name='shellSelection'>
              <ControlSelection
                active={isActive}
                highlighted={highlighted}
                label={label}
                users={users}
              />
            </AbsolutePortal>
          )}
          {disableClicks && <div className={s.disableClicks} />}
        </div>
      </div>
    )
  },
)

EditorShell.displayName = 'EditorShell'

export default React.memo(EditorShell)
