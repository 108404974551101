import React, { useRef, useState } from 'react'

import s from './AbsolutePortal.module.scss'
import AbsolutePortalContext from './AbsolutePortalContext'
import { BoxType } from './types'

export const CANVAS_ABSOLUTE_PORTAL_CONTAINER_ID = 'canvas-absolute-portal-container'

interface IAbsolutePortalContainerProps {
  containerRef: React.LegacyRef<HTMLDivElement>
}

class AbsolutePortalContainer extends React.Component<IAbsolutePortalContainerProps> {
  shouldComponentUpdate() {
    return false
  }
  render() {
    const { containerRef } = this.props
    return <div className={s.root} id={CANVAS_ABSOLUTE_PORTAL_CONTAINER_ID} ref={containerRef} />
  }
}

interface IAbsolutePortalProviderProps {
  children: React.ReactNode
}

const AbsolutePortalProvider: React.FC<IAbsolutePortalProviderProps> = ({ children }) => {
  const containerRef = useRef<HTMLDivElement>(null)
  const [portals, setPortals] = useState<Record<string, BoxType | null>>({})

  return (
    <AbsolutePortalContext.Provider value={{ portals, setPortals, containerRef }}>
      <AbsolutePortalContainer containerRef={containerRef} />
      {children}
    </AbsolutePortalContext.Provider>
  )
}

export default React.memo(AbsolutePortalProvider)
