import { RichTextFontSchemaType } from '@leenda/editor/lib/brand'
import { createContext } from 'react'

import { NOOP } from 'constants/commonConstans'
import { ElementFontCss } from 'services/Branding/types'

import { ALL_RT_CONTROLS } from './richText.constants'
import { RichTextContextType } from './richText.types'

const RichTextContext = createContext<RichTextContextType>({
  value: {},
  onUpdateFormat: (_format, _value: any) => null,
  controls: ALL_RT_CONTROLS,
  toolbarForm: null,
  setToolbarForm: NOOP,
  styles: {} as ElementFontCss<RichTextFontSchemaType>,
})

export { RichTextContext }
