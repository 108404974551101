import React, { memo, useCallback, useMemo, useState } from 'react'

import Dropdown from 'components/uiKit/Dropdown'
import { FnChild, IDropDownProps } from 'components/uiKit/Dropdown/DropDown'
import { ValueType } from 'components/uiKit/KitTypes'
import Menu, { IMenuOption, IMenuProps } from 'components/uiKit/Menu'

interface IDropdownMenuProps<V extends ValueType>
  extends IMenuProps<V>,
    Pick<IDropDownProps, 'placement' | 'overlay' | 'stopPropagation'> {
  children: React.ReactNode | FnChild
  onMouseLeave?: (e: React.MouseEvent<HTMLDivElement>) => void
  onMouseOver?: (e: React.MouseEvent<HTMLDivElement>) => void
  onVisibleChange?: (visible: boolean) => void
}

const DropdownMenu = <V extends ValueType>({
  children,
  placement = 'bottomRight',
  onClick,
  onMouseOver,
  onMouseLeave,
  onVisibleChange,
  stopPropagation,
  overlay,
  ...menuProps
}: IDropdownMenuProps<V>) => {
  const { name, options } = menuProps
  const [visible, setVisible] = useState(false)
  const ref = React.useRef<HTMLDivElement>(null)
  const showMenu = useMemo(
    () => options.length > 0 && options.some((menuItem) => !menuItem.hidden),
    [options],
  )

  const handleClick = useCallback(
    (option: IMenuOption<V>) => {
      !option.preventClose && setVisible(false)
      onClick?.(option)
    },
    [onClick],
  )

  const handleSetVisible = useCallback(
    (isVisible: boolean) => {
      setVisible(isVisible)
      onVisibleChange?.(isVisible)
    },
    [onVisibleChange],
  )

  if (showMenu) {
    return (
      <Dropdown
        disabled={Boolean(!options.length)}
        name={name}
        onMouseLeave={onMouseLeave}
        onMouseOver={onMouseOver}
        onVisibleChange={handleSetVisible}
        overlay={overlay || <Menu target={ref.current} {...menuProps} onClick={handleClick} />}
        placement={placement}
        rootRef={ref}
        stopPropagation={stopPropagation}
        styleType='clear'
        visible={visible}
      >
        {children}
      </Dropdown>
    )
  }
  return null
}

export default memo(DropdownMenu) as typeof DropdownMenu
