import cn from 'classnames'

import { genDataField } from 'components/controls/Field'
import RealtimeField from 'components/controls/RealtimeField'
import Padding from 'components/editor-v3/Editor/Toolbar/Padding'
import Divider from 'components/uiKit/Divider'
import { Block } from 'services/Store/Project/types'
import { t } from 'services/Translation'
import { chainName } from 'utils/form'

import { CardValueFormType } from './CardElement.types'
import s from './CardForm.module.scss'

const genField = genDataField<Block>()

export const frontImage = genField({
  name: 'front.image',
  label: t('elements.card.form.sideA'),
  type: 'file',
  layout: 'vertical',
  params: {
    fileType: 'image',
    nullable: true,
  },
})

export const frontFill = genField({
  name: 'front.fillColor',
  type: 'color',
  label: t('input.label.blackout'),
  layout: 'horizontal',
  defaultValue: '#FFFFFF1A',
})

export const backImage = genField({
  name: 'back.image',
  type: 'file',
  layout: 'vertical',
  label: t('elements.card.form.sideB'),
  params: {
    fileType: 'image',
    nullable: true,
  },
})

export const backFill = genField({
  name: 'back.fillColor',
  type: 'color',
  label: t('input.label.blackout'),
  layout: 'horizontal',
  defaultValue: '#FFFFFF1A',
})

const CardForm: CardValueFormType = ({ name, state, setState }) => {
  const handleAClick = () => setState({ isBack: false })
  const handleBClick = () => setState({ isBack: true })

  return (
    <>
      <div className={cn({ [s.active]: !state?.isBack })} onClick={handleAClick}>
        <Padding bottom sides top>
          <RealtimeField config={chainName(name, frontImage)} />
          <RealtimeField config={chainName(name, frontFill)} />
        </Padding>
      </div>
      <Divider free />
      <div className={cn({ [s.active]: state?.isBack })} onClick={handleBClick}>
        <Padding bottom sides top>
          <RealtimeField config={chainName(name, backImage)} />
          <RealtimeField config={chainName(name, backFill)} />
        </Padding>
      </div>
    </>
  )
}

export default CardForm
