import React, { FC } from 'react'

import Table from 'components/uiKit/Table'
import { FinderItem } from 'store/models'

import { columnsConfig } from './config'

export const MODAL_REMOVE_FINDER_TABLE_NAME = 'modalRemoveFinderTable'

interface IRemoveModalFileTableProps {
  files: FinderItem[]
}

const FileTable: FC<IRemoveModalFileTableProps> = ({ files }) => (
  <Table
    columns={columnsConfig()}
    dataSource={files}
    defaultSort='name'
    name={MODAL_REMOVE_FINDER_TABLE_NAME}
    pagination={false}
  />
)

export default FileTable
