import { IControlProps } from 'components/controls/Field/Field.types'

import FieldFile from '../FieldFile'

export type FileTypes = 'image' | 'audio' | 'video' | 'document'

interface IFieldFile {
  value?: string
  fileType?: FileTypes
  placeholder?: string
  nullable?: boolean
}

const FieldFileId: React.FC<IControlProps<IFieldFile>> = ({
  value,
  onChange,
  defaultValue,
  ...rest
}) => {
  const onChangeFile = ({ name, value }: { name: string; value?: { id?: string } | null }) => {
    onChange?.({ name, value: value?.id })
  }
  return (
    <FieldFile
      {...rest}
      defaultValue={defaultValue ? { id: defaultValue } : null}
      onChange={onChangeFile}
      value={value ? { id: value } : null}
    />
  )
}

export default FieldFileId
