import { PermissionsKey } from '@leenda/crud'
import cn from 'classnames'
import React, { useCallback } from 'react'
import { useState } from 'react'

import {
  MAX_LENGTH_SECTION_TITLE,
  MIN_LENGTH_SECTION_TITLE,
} from 'components/editor-v3/Editor/Navigation/NavigationProject/components/SectionsTreeEditor/constants'
import { IconButton } from 'components/uiKit/Button'
import Input from 'components/uiKit/Input'
import { KitSize } from 'components/uiKit/KitTypes'
import { useSectionsUpdateById } from 'gql/sections.apollo'
import { usePermissions } from 'services/Permission/PermissionContext'
import { useProjectContext } from 'services/Store/Project/hooks'
import { getProjectId } from 'services/Store/Project/selectors'
import { Section } from 'services/Store/Project/types'
import { getEnumLabel } from 'utils/enum'

import s from '../SectionTreeItemEditor.module.scss'

const SectionInput = React.forwardRef<
  HTMLInputElement,
  {
    setIsEditSectionId: (id: string) => void
    active: boolean
    cover: boolean
    item: Section
  }
>(({ active, cover, setIsEditSectionId, item }, ref) => {
  const { type, name, isDone, isChapter, id } = item

  const [sectionUpdate, { loading }] = useSectionsUpdateById()

  const projectId = useProjectContext(getProjectId)
  const permissions = usePermissions()
  const canUpdateSection = permissions.has(PermissionsKey.project_sections_u)
  const [value, setValue] = useState(name || '')
  const inputStyle = isChapter || cover ? { color: 'var(--color-text-secondary)' } : {}

  const sectionNameUpdate = useCallback(async () => {
    await sectionUpdate({ variables: { data: { name: value }, id, projectId } })
    setIsEditSectionId('')
  }, [id, projectId, sectionUpdate, value, setIsEditSectionId])

  const onBlurSection = () => sectionNameUpdate()

  const onKeyDown = useCallback(
    (e: React.KeyboardEvent<HTMLInputElement>) => {
      if (e.key === 'Enter' && active) {
        e.stopPropagation()
        e.preventDefault()
        sectionNameUpdate()
      }
      if (e.key === 'Escape' && active) {
        e.stopPropagation()
        e.preventDefault()
        setIsEditSectionId('')
      }
    },
    [active, sectionNameUpdate, setIsEditSectionId],
  )

  return (
    <>
      <div className={s.control}>
        <Input
          autoFocus={active}
          maxLength={MAX_LENGTH_SECTION_TITLE}
          min={MIN_LENGTH_SECTION_TITLE}
          name={`${type}.${id}`}
          onBlur={onBlurSection}
          onChange={setValue}
          onKeyDown={onKeyDown}
          placeholder={getEnumLabel('SectionTypeEnumPlaceholder', type)}
          readOnly={isDone || !canUpdateSection || loading}
          ref={ref}
          styleType='ghost'
          value={value}
          // eslint-disable-next-line react/forbid-component-props
          style={inputStyle}
        />
      </div>
      <div className={s.wrapActions}>
        <div className={cn(s.save, s.actions)}>
          <IconButton
            icon='checkmark1'
            loading={loading}
            name='saveSectionName'
            onClick={sectionNameUpdate}
            size={KitSize.S}
            styleType='ghost'
          />
        </div>
      </div>
    </>
  )
})

SectionInput.displayName = 'SectionInput'
export default SectionInput
