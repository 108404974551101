import { LinkFontSchemaType, LinkSchemaType } from '@leenda/editor/lib/brand'
import { FileImageUsage } from '@leenda/editor/lib/files'
import cn from 'classnames'
import lodash from 'lodash'

import { ElementFontCss, ElementStyleCss } from 'services/Branding/types'
import { DeviceMode } from 'services/Store/Project/enums'
import { t } from 'services/Translation'
import { useImageWithCrop } from 'utils/files'

import s from './LinkElement.module.scss'

interface IPreviewCardProps {
  title?: string | null
  description?: string | null
  images?: (string | null)[] | null
  favicons?: (string | null)[] | null
  showCustomImage?: boolean
  styles: ElementStyleCss<LinkSchemaType>
  image: Omit<FileImageUsage, 'type'> | null
  deviceMode: DeviceMode
  font?: ElementFontCss<LinkFontSchemaType>
}

const PreviewCard: React.FC<IPreviewCardProps> = ({
  title,
  description,
  images,
  favicons,
  styles,
  showCustomImage,
  image,
  deviceMode,
  font,
}) => {
  const metaImg = images?.length && images[0]
  const loadedImg = useImageWithCrop(image)
  const imagePath = showCustomImage ? loadedImg?.path : metaImg
  const favicon = favicons?.length && favicons[0]

  const isMobileMode = deviceMode === DeviceMode.mobile
  const previewCn = cn(s.preview, { [s.mobile]: isMobileMode })
  const previewStyles = isMobileMode ? lodash.omit(styles.preview, 'flexDirection') : styles.preview
  const titleCn = cn({ [s.emptyTitle]: !title })
  const descriptionCn = cn(s.description, { [s.placeholder]: !description })

  return (
    <div className={previewCn} style={previewStyles}>
      <div className={s.previewInfo}>
        <span className={titleCn} style={font?.h3}>
          {title || t('elements.link.placeholder.title')}
        </span>
        <p className={descriptionCn} style={font?.base}>
          {description || t('elements.link.placeholder.description')}
        </p>
      </div>
      <div className={s.previewImg} style={styles.image}>
        {!!imagePath && <img alt={title || ''} src={imagePath} />}
        {!imagePath && favicon && !showCustomImage && <img alt={title || ''} src={favicon} />}
      </div>
    </div>
  )
}

export { PreviewCard }
