import * as R from 'ramda'

import { IProjectContext } from './types'

const stateLens = R.lensProp<IProjectContext, 'state'>('state')

export const courseLens = R.compose(stateLens, R.lensProp('course'))

// state blocks
export const blocksLens = R.compose(stateLens, R.lensProp('blocks'))
export const blockLens = (id = '') => R.compose(blocksLens, R.lensProp(id || ''))

// state elements
export const elementsLens = R.compose(stateLens, R.lensProp('elements'))
export const elementLens = (id = '') => R.compose(elementsLens, R.lensProp(id))

// state sections
export const sectionsLens = R.compose(stateLens, R.lensProp('sections'))
export const sectionLens = (id = '') => R.compose(sectionsLens, R.lensProp(id))

// state editor
export const editorLens = R.compose(stateLens, R.lensProp('editor'))
export const settingsLens = R.compose(editorLens, R.lensProp('settings'))
export const toolbarLens = R.compose(editorLens, R.lensProp('toolbar'))
export const navigationLens = R.compose(editorLens, R.lensProp('navBar'))
