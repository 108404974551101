import { Middleware, MiddlewareAPI } from '@reduxjs/toolkit'
import * as R from 'ramda'

import { getLastViewedSection } from 'components/editor-v2/EditorElements/elements/ButtonElement/helper'
import ScrollService, { ScrollContext, ScrollType } from 'services/Scroll/ScrollService'
import { ScrollContainerEnum } from 'services/Scroll/enums'

import { isAction } from '../actionTypeGuard'
import { PreviewMode } from '../enums'
import {
  getBlocksState,
  getContinueBlockId,
  getScrollContext,
  getScrollReady,
  getSections,
} from '../selectors'
import { IProjectContext } from '../types'

const TIMEOUT = 5000
const TICK = 16
const NODE_SCROLL_MARGIN = 50

const waitScroll = (
  store: MiddlewareAPI,
  scroll: ScrollType | ScrollType[],
  scrollContext: ScrollContext,
) => {
  if (Array.isArray(scroll)) {
    scroll.forEach((s) => waitScroll(store, s, scrollContext))
  } else {
    ScrollService.scroll(
      {
        ...scroll,
        complete: () => {
          let time = 0
          const interval = setInterval(() => {
            ScrollService[scroll.container]?.os?.scrollStop()
            ScrollService.scroll({ ...scroll, duration: 0 }, scrollContext)

            time += TICK
            const context: IProjectContext = store.getState().project
            const doScroll = getScrollReady(context)
            if (doScroll || time > TIMEOUT) {
              clearInterval(interval)
            }
          }, TICK)
        },
      },
      scrollContext,
    )
  }
}

export const scroll: Middleware = (store) => (next) => (action) => {
  const prevState = store.getState().project as IProjectContext
  const result = next(action)
  const nextState = store.getState().project as IProjectContext
  const scrollContext = getScrollContext(nextState)

  if (isAction(action, 'scroll')) {
    waitScroll(store, action.payload, scrollContext)
  }

  if (isAction(action, 'setDeviceMode')) {
    if (nextState.mode === PreviewMode.editor) {
      ScrollService.scroll(
        {
          container: ScrollContainerEnum.canvas,
          block: 'begin',
          scroll: ['never', 'always'],
          duration: 0,
          id: nextState.urlParams.blockId,
        },
        scrollContext,
      )
    }
  }

  if (isAction(action, 'setEditorMode')) {
    waitScroll(
      store,
      {
        container: ScrollContainerEnum.canvas,
        id: nextState.urlParams.blockId,
        block: 'begin',
        scroll: ['never', 'ifneeded'],
        intersection: true,
      },
      scrollContext,
    )
  }

  if (isAction(action, 'setProjectNavigation')) {
    const { blockId, nodeId, commentId, taskId, scroll } = action.payload
    if (!R.equals(prevState.urlParams.sectionId, nextState.urlParams.sectionId)) {
      ScrollService.scroll(
        { container: ScrollContainerEnum.canvas, y: 0, duration: 0 },
        scrollContext,
      )
    }
    if (scroll) {
      waitScroll(store, scroll, scrollContext)
    } else {
      waitScroll(
        store,
        {
          container: ScrollContainerEnum.blocksNav,
          id: blockId,
          block: 'nearest',
          scroll: 'ifneeded',
          intersection: true,
        },
        scrollContext,
      )
      waitScroll(
        store,
        {
          container: ScrollContainerEnum.nodesNav,
          id: nodeId,
          block: ['end', 'nearest'],
        },
        scrollContext,
      )

      if (nodeId) {
        waitScroll(
          store,
          {
            container: ScrollContainerEnum.canvas,
            id: nodeId,
            margin: NODE_SCROLL_MARGIN,
          },
          scrollContext,
        )
      } else if (blockId) {
        waitScroll(
          store,
          {
            container: ScrollContainerEnum.canvas,
            id: blockId,
            margin: NODE_SCROLL_MARGIN,
          },
          scrollContext,
        )
      }

      if (commentId) {
        waitScroll(
          store,
          {
            container: ScrollContainerEnum.toolbar,
            id: commentId,
            block: 'nearest',
          },
          scrollContext,
        )
      }
      if (taskId) {
        waitScroll(
          store,
          {
            container: ScrollContainerEnum.toolbar,
            id: taskId,
            block: 'nearest',
          },
          scrollContext,
        )
      }
    }
  }

  if (
    isAction(action, 'setCourseNavigation') ||
    isAction(action, 'courseStart') ||
    isAction(action, 'testStart') ||
    isAction(action, 'testRestart') ||
    isAction(action, 'testEnd')
  ) {
    if (prevState.urlParams.sectionId !== nextState.urlParams.sectionId) {
      ScrollService.scroll(
        { container: ScrollContainerEnum.canvas, y: 0, duration: 0 },
        scrollContext,
      )
    }
    if (isAction(action, 'setCourseNavigation')) {
      waitScroll(
        store,
        {
          container: ScrollContainerEnum.sectionNav,
          id: action.payload.sectionId,
        },
        scrollContext,
      )
    }
    if (isAction(action, 'setCourseNavigation') && action.payload.scrollBlockId) {
      waitScroll(
        store,
        {
          container: ScrollContainerEnum.canvas,
          id: action.payload.scrollBlockId,
          block: 'begin',
          scroll: 'always',
        },
        scrollContext,
      )
    }
  }

  if (isAction(action, 'courseContinue') || isAction(action, 'init')) {
    const sections = getSections(nextState)
    const blocksState = getBlocksState(nextState)
    const lastViewedSection = getLastViewedSection(sections, blocksState)
    const continueBlock = getContinueBlockId(nextState, lastViewedSection?.id)
    continueBlock &&
      waitScroll(
        store,
        {
          container: ScrollContainerEnum.canvas,
          id: continueBlock,
          block: 'begin',
          scroll: 'always',
        },
        scrollContext,
      )
  }
  if (isAction(action, 'init')) {
    const continueBlock = getContinueBlockId(nextState, nextState.urlParams.sectionId)
    continueBlock &&
      waitScroll(
        store,
        {
          container: ScrollContainerEnum.canvas,
          id: continueBlock,
          block: 'begin',
          scroll: 'always',
        },
        scrollContext,
      )
  }
  if (isAction(action, 'continueBlock')) {
    ScrollService.scroll({ container: ScrollContainerEnum.canvas, y: '+=0.5vh' }, scrollContext)
  }

  return result
}
