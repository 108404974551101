import { SCHEMA } from '@leenda/editor/lib/brand'

import { genField } from 'components/controls/Field'
import { genFormSchema, FONT_SCHEMA_FIELDS } from 'services/Branding/genFormSchema'
import { t } from 'services/Translation'

export const STYLE_FORM_SCHEMA = genFormSchema(SCHEMA.list.style, {
  labels: {
    META: { label: t('elements.list.tags.labels') },
    backgroundColor: {},
    backgroundImage: { label: t('input.label.icon') },
    borderStyle: {},
    borderColor: {},
    borderWidth: {},
  },
  animation: {
    META: { label: t('elements.list.tags.animation') },
    fade: genField({
      type: 'segmented',
      layout: 'horizontal',
      label: t('elements.list.schema.fade'),
    }),
  },
  indent: {
    META: { label: t('elements.list.tags.indent') },
    marginRight: {
      label: t('input.label.padding'),
      params: { min: 0 },
    },
    marginTop: {
      label: t('input.label.listGap'),
      params: { min: 0 },
    },
  },
})

export const FONT_FORM_SCHEMA = genFormSchema(SCHEMA.list.font, {
  ...FONT_SCHEMA_FIELDS,
  numbering: {
    ...FONT_SCHEMA_FIELDS.base,
    META: {
      label: t('elements.list.tags.numbering'),
    },
  },
})
