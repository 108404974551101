import { RichTextFontSchemaType } from '@leenda/editor/lib/brand'
import { EditorElementError } from '@leenda/editor/lib/elements'
import {
  RichTextValue,
  SlateElementType,
  SlateMark,
  validateSlate,
  textToRtValue,
} from '@leenda/rich-text'
import cn from 'classnames'
import { isCodeHotkey } from 'is-hotkey'
import React, { useCallback, useEffect, useMemo, useReducer, useRef, useState } from 'react'
import {
  createEditor,
  Descendant,
  Transforms,
  Range as SlateRange,
  Editor,
  Node,
  Point,
  BaseSelection,
} from 'slate'
import { Editable, ReactEditor, Slate, withReact } from 'slate-react'
import { RenderElementProps, RenderLeafProps } from 'slate-react/dist/components/editable'

import { EMPTY_ARRAY } from 'constants/commonConstans'
import { ElementFontCss } from 'services/Branding/types'
import { testProps } from 'utils/test/qaData'

import { RichTextErrorBoundary } from './RichTextErrorBoundary'
import { withFeatureControl } from './featureControl/featureControl'
import {
  clearAllMarks,
  inertSymbol,
  insertBreak,
  insertSymbolByCode,
} from './formatOperations/commands'
import { getCurrentFormat, isFormatActive } from './formatOperations/common'
import { toggleElement, updateElementMark } from './formatOperations/elements'
import { removeSavedSelection, switchToPseudoSelection } from './formatOperations/internal'
import { setMark } from './formatOperations/textMarks'
import { withInlines, wrapAnnotation, wrapCode, wrapCrossLink, wrapLink } from './inline/withInline'
import ElementResolver from './nodes/Elements'
import Text from './nodes/Text'
import { withLists } from './nodes/withLists'
import {
  ALL_RT_CONTROLS,
  EMPTY_RICH_TEXT,
  HOT_KEYS_CONFIG,
  isCommandFormat,
  isElementFormat,
  isElementMarkFormat,
  isMarkFormat,
  LEENDA_SLATE_CLIPBOARD,
  SlateCommand,
  SlateFormats,
  SYMBOLS_CODES,
} from './richText.constants'
import { RichTextContext } from './richText.context'
import { CustomEditor, RichTextControl, ToolbarForm } from './richText.types'
import s from './styles/RichText.module.scss'
import BubbleToolbar from './toolbar/Toolbar/BubbleToolbar'
import { useFontToVars } from './useFontToVars'
import { getCurrentElementTypes } from './utils/common'
import { htmlToSlatePaste } from './utils/htmlToSlate'

type RichTextCoreProps = {
  waiting?: boolean
  placeholder?: string
  initialValue?: RichTextValue
  disabled?: boolean
  handleEditorRespawn: () => void
  styles: ElementFontCss<RichTextFontSchemaType>
  active: boolean
  name: string
  controls?: RichTextControl[]
  verticalAlign?: 'start' | 'center' | 'end'
  cursorPosition?: 'start' | 'end'
} & (
  | {
      onChange?: (
        value: RichTextValue | typeof EMPTY_RICH_TEXT,
        error?: EditorElementError[],
      ) => void
      iterable: true
      onUp?: () => void
      onDown?: () => void
    }
  | {
      onChange?: (value: RichTextValue, error?: EditorElementError[]) => void
      iterable?: never
      onUp?: () => never
      onDown?: () => never
    }
)

// Elements renderers
const renderElement = (props: RenderElementProps) => <ElementResolver isEdit {...props} />
const renderLeaf = (props: RenderLeafProps) => <Text {...props} />

const clearEmpty = (value: any) => {
  if (
    value.length === 1 &&
    value[0].type === SlateElementType.elementDefault &&
    value[0].children.length <= 1 &&
    value[0].children[0]?.text?.trim() === ''
  ) {
    return textToRtValue('')
  }
  return value
}

// const ZERO = { anchor: { path: [0, 0], offset: 0 }, focus: { path: [0, 0], offset: 0 } }
const getSelection = (editor: Editor, history: BaseSelection[]): BaseSelection => {
  const last = history[history.length - 1]
  if (last) {
    try {
      ReactEditor.toDOMPoint(editor, last.anchor)
      ReactEditor.toDOMPoint(editor, last.focus)
      return last
    } catch (error) {
      // return getSelection(editor, history.slice(0, -1))
      return { anchor: Editor.end(editor, []), focus: Editor.end(editor, []) }
    }
  }
  return { anchor: Editor.end(editor, []), focus: Editor.end(editor, []) }
}

const RichTextCore: React.FC<RichTextCoreProps> = ({
  initialValue: value = EMPTY_ARRAY,
  onChange: onChangeOrigin,
  placeholder,
  handleEditorRespawn,
  active,
  styles,
  controls = ALL_RT_CONTROLS,
  name,
  verticalAlign,
  iterable,
  onUp,
  onDown,
  waiting,
  cursorPosition = 'start',
}) => {
  const forceRender = useReducer((s) => !s, false)[1]
  const editor = useMemo(
    () => withFeatureControl(controls)(withLists(withInlines(withReact(createEditor())))),
    [controls],
  )
  const selectionHistory = useRef<BaseSelection[]>([])

  const onChange = useCallback(
    (value: RichTextValue, error?: EditorElementError[]) => {
      onChangeOrigin?.(clearEmpty(value), error)
    },
    [onChangeOrigin],
  )
  const [selectedFormat, setSelectedFormat] = useState({})
  const [isBroken, setIsBroken] = useState<boolean>(false)
  const [toolbarForm, setToolbarForm] = useState<ToolbarForm>(null)
  const isEmpty =
    value.length === 1 &&
    (value[0] as any).children.length === 1 &&
    (value[0] as any).children[0].text === ''
  // END of: Elements renderers

  useEffect(() => {
    if (!active) {
      selectionHistory.current = []
      editor.prevSelection && removeSavedSelection(editor)
    }
  }, [editor, active])

  useEffect(() => {
    if (active) {
      ReactEditor.focus(editor)
      Transforms.select(editor, Editor[cursorPosition](editor, []))
    }
  }, [editor, active, cursorPosition])

  useEffect(() => {
    editor.children = (value || []) as Descendant[]
    editor.selection = getSelection(editor, [...selectionHistory.current, editor.selection])
    selectionHistory.current = [...selectionHistory.current, editor.selection]
    forceRender()
  }, [forceRender, editor, value])

  const onUpdateFormat = useCallback((format: SlateFormats, value: string | number | boolean) => {
    ReactEditor.focus(editor)
    if (format === SlateElementType.link) {
      wrapLink(editor, value as string)
    } else if (format === SlateElementType.crossLink) {
      wrapCrossLink(editor, value)
    } else if (format === SlateElementType.code) {
      wrapCode(editor, value as boolean)
    } else if (format === SlateElementType.annotation) {
      wrapAnnotation(editor, value as string)
    } else if (format === SlateCommand.clear) {
      clearAllMarks(editor)
    } else if (isMarkFormat(format)) {
      setMark(editor, format, value)
    } else if (isElementFormat(format)) {
      toggleElement(editor, format)
    } else if (isCommandFormat(format)) {
      inertSymbol(editor, format)
    } else if (isElementMarkFormat(format)) {
      updateElementMark(editor, format, value)
    }
  }, [])

  const toggleFormat = (
    editor: CustomEditor,
    format: SlateMark | string,
    value: string | number | boolean,
  ): void => {
    const isActive = isFormatActive(editor, format)
    if (isActive) {
      onUpdateFormat(format as SlateFormats, false)
    } else {
      onUpdateFormat(format as SlateFormats, value)
    }
  }

  const handleOnChange = useCallback(
    (changeValue: Descendant[]) => {
      if (isBroken) {
        return
      }

      setSelectedFormat(getCurrentFormat(editor, styles))
      if (changeValue !== value && !editor.prevSelection) {
        onChange && onChange(changeValue)
        const valid = validateSlate(changeValue)
        if (!valid) {
          console.error(changeValue)
        }
      }
    },
    [isBroken, onChange, value],
  )

  const handleOnError = useCallback(() => {
    // FIXME: Move to props
    setIsBroken(true)
    onChange && onChange(value, [{ type: 0, value: null }])
  }, [onChange, value])

  const contextValue = useMemo(
    () => ({
      value: selectedFormat,
      onUpdateFormat,
      controls,
      toolbarForm,
      setToolbarForm,
      styles,
    }),
    [selectedFormat, onUpdateFormat, controls, toolbarForm, styles],
  )

  const onBlur = useCallback(() => {
    if (active) {
      switchToPseudoSelection(editor)
    }
  }, [active])
  const onFocus = useCallback(() => {
    removeSavedSelection(editor)
  }, [active])

  const onKeyDown: React.KeyboardEventHandler<HTMLInputElement> = (event) => {
    // Default left/right behavior is unit:'character'.
    // This fails to distinguish between two cursor positions, such as
    // <inline>foo<cursor/></inline> vs <inline>foo</inline><cursor/>.
    // Here we modify the behavior to unit:'offset'.
    // This lets the user step into and out of the inline without stepping over characters.
    // You may wish to customize this further to only use unit:'offset' in specific cases.
    if (event.key === 'Escape') {
      setToolbarForm(null)
    }
    if (editor.selection && SlateRange.isCollapsed(editor.selection)) {
      const { nativeEvent } = event
      if (isCodeHotkey('left', nativeEvent)) {
        event.preventDefault()
        Transforms.move(editor, { unit: 'offset', reverse: true })
        return
      }
      if (isCodeHotkey('right', nativeEvent)) {
        event.preventDefault()
        Transforms.move(editor, { unit: 'offset' })
        return
      }
    }

    if (iterable) {
      if (isCodeHotkey('tab', event.nativeEvent)) {
        return event.preventDefault()
      }
      if (isCodeHotkey('up', event.nativeEvent)) {
        const start = Editor.start(editor, [])
        const isCursorAtStart =
          editor.selection && Point.compare(start, editor.selection.focus) === 0
        if (isCursorAtStart) {
          event.preventDefault()
          return onUp?.()
        }
      }
      if (isCodeHotkey('down', event.nativeEvent)) {
        const end = Editor.end(editor, [])
        const isCursorAtEnd = editor.selection && Point.compare(end, editor.selection.focus) === 0
        if (isCursorAtEnd) {
          event.preventDefault()
          return onDown?.()
        }
      }
      if (isCodeHotkey('backspace', event.nativeEvent) && Node.string(editor) === '') {
        event.preventDefault()
        return onChangeOrigin?.(EMPTY_RICH_TEXT)
      }
    }

    if (isCodeHotkey('shift+enter', event.nativeEvent)) {
      event.preventDefault()
      insertSymbolByCode(editor, SYMBOLS_CODES.lineBreak)
      return
    }

    if (isCodeHotkey('enter', event.nativeEvent)) {
      event.preventDefault()
      insertBreak(editor)
      return
    }

    const hotkey = HOT_KEYS_CONFIG.find(({ hotkey }) => isCodeHotkey(hotkey, event.nativeEvent))
    if (hotkey) {
      const { name, value } = hotkey

      event.stopPropagation()
      event.preventDefault()

      if (name === SlateElementType.link) {
        setToolbarForm('link')
      } else {
        toggleFormat(editor, name, value)
      }
    }
  }

  const onCopy = (event: React.ClipboardEvent<HTMLDivElement>) => {
    const fragment = editor.selection && Editor.fragment(editor, editor.selection)
    const serializedFragment = JSON.stringify(fragment)
    event.clipboardData.setData(LEENDA_SLATE_CLIPBOARD, serializedFragment)
    // event.preventDefault()
  }

  const onPaste = useCallback((event: React.ClipboardEvent<HTMLDivElement>) => {
    event.preventDefault()
    const clipboardData = event.clipboardData.getData(LEENDA_SLATE_CLIPBOARD)
    if (clipboardData) {
      const parsedFragment = JSON.parse(clipboardData)
      Transforms.insertFragment(editor, parsedFragment)
    } else {
      const type = getCurrentElementTypes(editor)
      const html = event.clipboardData.getData('text/html')
      let nodes = null
      if (html) {
        try {
          nodes = htmlToSlatePaste(html, type)
        } catch (e) {
          console.error('error in parse', e)
        }
      }
      if (nodes) {
        Transforms.insertFragment(editor, htmlToSlatePaste(html, type))
      } else {
        const text = event.clipboardData.getData('text/plain')
        Transforms.insertText(editor, text)
      }
    }
  }, [])

  const handleChangeSelection = useCallback((selection: BaseSelection) => {
    selectionHistory.current = [...selectionHistory.current, selection]
  }, [])

  const style = useFontToVars(styles)

  return (
    <div className={s.container} style={style}>
      <RichTextErrorBoundary onError={handleOnError} onRespawn={handleEditorRespawn}>
        <RichTextContext.Provider value={contextValue}>
          <Slate
            editor={editor}
            initialValue={value}
            onChange={handleOnChange}
            onSelectionChange={handleChangeSelection}
          >
            {active && <BubbleToolbar />}
            <Editable
              className={cn(s.root, { [s.placeholder]: isEmpty })}
              onBlur={onBlur}
              onCopy={onCopy}
              onFocus={onFocus}
              onKeyDown={onKeyDown}
              onPaste={onPaste}
              placeholder={placeholder}
              readOnly={waiting}
              renderElement={renderElement}
              renderLeaf={renderLeaf}
              style={{ justifyContent: verticalAlign }}
              {...testProps({ el: 'rich-text', name, label: (value[0] as any).children[0].text })}
            />
          </Slate>
        </RichTextContext.Provider>
      </RichTextErrorBoundary>
    </div>
  )
}

export default RichTextCore
