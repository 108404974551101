import { useSize } from 'ahooks'
import React, { useContext } from 'react'
import { createPortal } from 'react-dom'

import { useModalContainer } from 'components/uiKit/Modal/ModalContext'

import { FormType, RichTextContext } from '../context'
import DefaultForm from './DefaultForm'
import LinkForm from './LinkForm'
import MentionForm from './MentionForm'
import s from './Toolbar.module.scss'
import { useSelectionRect } from './useSelectionRect'

const FORM_COMPONENTS = {
  link: LinkForm,
  default: DefaultForm,
  mention: MentionForm,
}

interface IProps {
  form: FormType
  full?: boolean
}

const Toolbar = React.forwardRef<HTMLDivElement, IProps>(({ form, full }, ref) => {
  const { rootRef } = useContext(RichTextContext)
  const box = rootRef.current?.getBoundingClientRect()
  const { anchor, focus } = useSelectionRect()
  const size = useSize(ref as React.RefObject<HTMLDivElement>)
  const Form = form && FORM_COMPONENTS[form]
  const getContainer = useModalContainer()

  return (
    Form &&
    createPortal(
      <div className={s.root} style={{ top: box?.top, left: box?.left, width: box?.width }}>
        <Form anchor={anchor} focus={focus} full={full} ref={ref} size={size} />
      </div>,
      getContainer(),
    )
  )
})
Toolbar.displayName = 'Toolbar'

export default React.memo(Toolbar)
