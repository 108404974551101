import React, { useEffect, useState } from 'react'
import { createPortal } from 'react-dom'

import { COURSE_TOOLTIP } from 'constants/portals'
import { IBlockMode } from 'services/Store/Project/types'

import s from './CourseTooltip.module.scss'
import CourseTooltipContent from './CourseTooltipContent'
import { RootBox } from './types'

interface ICourseTooltipProps {
  name: string
  onOpenChange?: (open: boolean) => void
  title: JSX.Element
  open: boolean
  destroyTooltipOnHide: boolean
  mode: IBlockMode
  isAnimated?: boolean
  children?: React.ReactNode
  position?: 'top' | 'bottom'
}

const CourseTooltip: React.FC<ICourseTooltipProps> = ({
  children,
  title,
  mode,
  open,
  onOpenChange,
  name,
  isAnimated,
  position,
}) => {
  const container = document.getElementById(COURSE_TOOLTIP) || document.body
  const [rootBox, setRootBox] = React.useState<RootBox | undefined>()
  const [rootRef, setRootRef] = React.useState<HTMLDivElement | null>(null)
  const [render, setRender] = useState(open)

  useEffect(() => {
    if (open) {
      const rootRect = rootRef?.getBoundingClientRect()
      const containerRect = container.getBoundingClientRect()
      const rootBox = {
        top: (rootRect?.top || 0) - containerRect.top,
        left: (rootRect?.left || 0) - containerRect.left,
        width: rootRect?.width || 0,
        height: rootRect?.height || 0,
        containerWidth: containerRect.width,
        containerHeight: containerRect.height,
      }
      setRootBox(rootBox)
      setRender(true)
    } else {
      if (!isAnimated) {
        setRender(false)
      }
    }
  }, [open, rootRef, container])
  const close = () => {
    onOpenChange && onOpenChange(false)
  }
  const toggle = () => {
    onOpenChange && onOpenChange(!open)
  }

  return (
    <div className={s.inline} ref={setRootRef}>
      <div className={s.inline} onClick={toggle}>
        {children}
      </div>
      {render &&
        createPortal(
          <CourseTooltipContent
            isAnimated={isAnimated}
            mode={mode}
            name={name}
            onClose={close}
            open={open}
            position={position}
            rootBox={rootBox}
            rootRef={rootRef}
            setRender={setRender}
            title={title}
          />,
          container,
        )}
    </div>
  )
}

export default CourseTooltip
