import { ElementsTypes } from '@leenda/editor/lib/elements'
import { useMediaQueries } from '@react-hook/media-query'
import * as R from 'ramda'
import { useEffect, useMemo } from 'react'

import { DEVICE_ASK_ORDER } from 'components/editor-v3/types/data.constants'
import { CommentMock } from 'components/uiKit/Comment'
import { TaskMock } from 'components/uiKit/Task'
import { usePathParams, usePathQuery } from 'routes/hooks'
import { SCORM_API_Wrapper } from 'scormWrapper/vendors/SCORM_API_Wrapper'

import { useAppDispatch } from '../hooks'
import {
  init,
  reset,
  setPath,
  setMode,
  setDataProject,
  setDataSections,
  setDataBlocks,
  setDataComments,
  setDataTasks,
  setScormSettings,
  setUrlParams,
  setSettings,
} from './actions'
import { setDeviceMode } from './actions'
import { MEDIA_QUERIES_DEFAULT } from './constants'
import {
  BlockMode,
  CommentsTab,
  DeviceMode,
  EditorMode,
  ElementTab,
  PreviewMode,
  SectionTypeEnum,
  TasksTab,
} from './enums'
import { shuffleQuestions } from './shuffleQuestions'
import { suspendDataToState } from './suspendData'
import {
  Block,
  ElementState,
  IBlockState,
  IEditorSettings,
  ISectionState,
  MapType,
  Project,
  ProjectPaths,
  ScormSettings,
  Section,
} from './types'

export const makeTestState = (section: Section) => {
  const state: ISectionState = {
    status: 'notStarted',
  }
  if (section.test?.isShuffleQuestions) {
    state.order = shuffleQuestions(section.blocks, section.test.randomCount)
  }
  return state
}
const makeSectionsState = (sections: Section[]) =>
  R.reduce(
    (map, section) =>
      section.type === 'test' ? R.mergeRight(map, { [section.id]: makeTestState(section) }) : map,
    {},
    sections,
  )

const INTERACTIVE_ELEMENTS: ElementsTypes[] = [
  'accordion',
  'card',
  'audio',
  'video',
  'labeledGraphic',
  'slider',
  'splitter',
  'tabs',
  'grouping',
]

const makeBlocksState = (blocks: Block[]) => {
  const blockWithStates = blocks.reduce<MapType<Partial<IBlockState>>>((acc, block) => {
    if (block.mode === BlockMode.questions) {
      acc[block.uuid] = {
        incomplete: true,
        isReady: false,
        currentTry: 0,
      }
      return acc
    }
    const hasInteractiveElement = Object.values(block.elements || {}).some((el) =>
      INTERACTIVE_ELEMENTS.includes(el.type),
    )
    if (hasInteractiveElement) {
      acc[block.uuid] = { incomplete: true }
    }
    return acc
  }, {})

  return blockWithStates
}

const makeElementsState = (blocks: Block[]) => {
  const elements = blocks.flatMap((block) =>
    Object.values(block.elements || {}).map((element) => {
      return {
        ...element,
        blockId: block.uuid,
      }
    }),
  )

  const elementWithStates = elements.reduce<MapType<ElementState>>((acc, element) => {
    if (INTERACTIVE_ELEMENTS.includes(element.type)) {
      acc[element.id] = { incomplete: true }
    }
    return acc
  }, {})

  return elementWithStates
}
const getCurrentMatchedMedia = (
  queriesMatches: { [key in DeviceMode]: boolean },
  controlledMode?: DeviceMode,
): DeviceMode => {
  for (const device of DEVICE_ASK_ORDER) {
    if (queriesMatches[device] || device === controlledMode) {
      return device
    }
  }
  return DeviceMode.desktop
}
export const useReduxUpdate = (
  path: ProjectPaths,
  mode: PreviewMode,
  project: Project,
  sections: MapType<Section>,
  blocks: MapType<Block>,
  comments: CommentMock[],
  tasks: TaskMock[],
  settings: IEditorSettings,
  scormSettings?: ScormSettings,
) => {
  const { matches } = useMediaQueries(MEDIA_QUERIES_DEFAULT)
  const dispatch = useAppDispatch()
  const deviceMode = getCurrentMatchedMedia(matches, DeviceMode.desktop)
  const params = usePathParams(path)
  const query = usePathQuery()
  const urlParams = useMemo(() => ({ ...params, ...query }), [params, query])

  useEffect(() => {
    const {
      course,
      sections: stateSections,
      blocks: stateBlocks,
      elements: stateElements,
    } = suspendDataToState(
      mode === PreviewMode.scorm ? { sections, blocks } : { sections: {}, blocks: {} },
      SCORM_API_Wrapper.getSuspendData(),
    )

    dispatch(
      init({
        urlParams,
        state: {
          deviceMode,
          editor: {
            highlight: null,
            selectedBlocks: urlParams.blockId ? [urlParams.blockId] : [],
            mode: mode === PreviewMode.editor ? EditorMode.fill : null,
            settings,
            navBar: {
              tab: null,
            },
            toolbar: {
              commentTab: urlParams.commentId || urlParams.threadId ? CommentsTab.section : null,
              elementTab: ElementTab.fill,
              taskTab: urlParams.taskId ? TasksTab.current : null,
            },
          },
          course: R.mergeDeepRight(
            {
              status: R.values(sections)?.some(({ type }) => type === SectionTypeEnum.cover)
                ? ('not attempted' as const)
                : ('incomplete' as const),
            },
            course,
          ),
          sections: R.mergeDeepRight(makeSectionsState(R.values(sections)), stateSections),
          blocks: R.mergeDeepRight(makeBlocksState(R.values(blocks)), stateBlocks),
          elements: R.mergeDeepRight(makeElementsState(R.values(blocks)), stateElements),
        },
        data: {
          sections,
          blocks,
          project,
        },
      }),
    )
    return () => {
      dispatch(reset())
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    dispatch(setPath(path))
  }, [dispatch, path])

  useEffect(() => {
    dispatch(setMode(mode))
  }, [dispatch, mode])

  useEffect(() => {
    dispatch(setDataProject(project))
  }, [dispatch, project])

  useEffect(() => {
    dispatch(setDataSections(sections))
  }, [dispatch, sections])

  useEffect(() => {
    dispatch(setDataBlocks(blocks))
  }, [dispatch, blocks])

  useEffect(() => {
    dispatch(setDataComments(comments))
  }, [dispatch, comments])

  useEffect(() => {
    dispatch(setDataTasks(tasks))
  }, [dispatch, tasks])

  useEffect(() => {
    dispatch(setUrlParams(urlParams))
  }, [dispatch, urlParams])

  useEffect(() => {
    dispatch(setSettings(settings))
  }, [dispatch, settings])

  useEffect(() => {
    dispatch(setDeviceMode({ mode: deviceMode }))
  }, [deviceMode, dispatch])

  useEffect(() => {
    dispatch(setScormSettings(scormSettings))
  }, [dispatch, scormSettings])
}
