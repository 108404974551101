import { useMount } from 'ahooks'
import cn from 'classnames'
import * as R from 'ramda'
import React, { useCallback, useImperativeHandle, useMemo, useRef, useState } from 'react'

import { LayoutScroll } from 'components/LayoutPage'
import Divider from 'components/uiKit/Divider'
import { useKeyboardNavigation } from 'components/uiKit/KitHooks'
import { KitDimensions, KitSize, KitIndents, ValueType } from 'components/uiKit/KitTypes'
import { flatOptions } from 'components/uiKit/KitUtils'
import MenuItem, { IMenuOption } from 'components/uiKit/Menu/MenuItem'
import { doScroll } from 'services/Scroll/ScrollService'
import { t } from 'services/Translation'
import { testPropsEl } from 'utils/test/qaData'

import s from './Menu.module.scss'
import MenuContext, { IMenuContext } from './MenuContext'

export interface IMenuProps<V extends ValueType> {
  name: string
  onClick?: (item: IMenuOption<V>) => void
  options: IMenuOption<V>[]
  title?: React.ReactNode
  footer?: React.ReactNode
  value?: V
  empty?: React.ReactNode
  size?: KitSize
  target?: HTMLDivElement | null
  dimensions?: KitDimensions
  indents?: KitIndents
  resettable?: boolean
  horizontal?: boolean
}

export interface IMenuRef<V extends ValueType> {
  setHovered: React.Dispatch<React.SetStateAction<IMenuOption<V> | null>>
}

const Menu = React.forwardRef<IMenuRef<ValueType>, IMenuProps<ValueType>>(
  (
    {
      name,
      onClick,
      options,
      title,
      footer,
      value,
      dimensions,
      indents,
      empty = t('uiKit.menu.empty'),
      target,
      resettable,
      size,
      horizontal,
    },
    menuRef,
  ) => {
    const ref = useRef<HTMLDivElement>(null)
    const scrollRef = useRef(null)
    const [hovered, setHovered] = useState<IMenuOption<ValueType> | null>(null)

    const flattenOptions = useMemo(() => flatOptions(options), [options])

    const height = useMemo(
      () => dimensions && R.pick(['height', 'minHeight', 'maxHeight'], dimensions),
      [dimensions],
    )
    const width = useMemo(
      () => dimensions && R.pick(['width', 'minWidth', 'maxWidth'], dimensions),
      [dimensions],
    )

    const onHover = useCallback(
      (option: IMenuOption<ValueType> | null) => {
        setHovered(option)
        doScroll(scrollRef.current, option?.value, { margin: 4 })
      },
      [scrollRef],
    )

    const onEnter = useCallback(() => hovered && onClick?.(hovered), [onClick, hovered])

    const handleMouseLeave = () => setHovered(null)

    useMount(() => onHover(flattenOptions.find((item) => item.value === value) || null))

    useKeyboardNavigation(flattenOptions, target || ref.current, hovered, onEnter, onHover)

    useImperativeHandle(menuRef, () => ({ setHovered }), [setHovered])

    const contextValue: IMenuContext<ValueType> = useMemo(
      () => ({ value, hovered, setHovered }),
      [value, hovered],
    )

    if (!options?.length || options.every((item) => item.hidden)) {
      const cnEmpty = cn(s.empty, { [s.horizontal]: horizontal })
      return <span className={cnEmpty}>{empty}</span>
    }

    return (
      <MenuContext.Provider value={contextValue}>
        <div
          {...testPropsEl('menu', { name })}
          className={cn(s.root, { [s.horizontal]: horizontal })}
          ref={ref}
          style={width}
        >
          {title && (
            <>
              <div className={s.title}>{title}</div>
              <Divider free />
            </>
          )}
          <LayoutScroll ref={scrollRef} autoUpdate sizeAutoCapable>
            <div
              className={cn(s.items, { [s.horizontal]: horizontal })}
              onMouseLeave={handleMouseLeave}
              style={{ ...height, ...indents }}
            >
              {options.map((item) => (
                <MenuItem
                  horizontal={horizontal}
                  item={item}
                  key={String(item.value)}
                  onClick={onClick}
                  resettable={resettable}
                  size={size}
                />
              ))}
            </div>
          </LayoutScroll>
          {footer && (
            <>
              <Divider free />
              <div className={s.footer}>{footer}</div>
            </>
          )}
        </div>
      </MenuContext.Provider>
    )
  },
)

Menu.displayName = 'Menu'

export default React.memo(Menu) as <V extends ValueType>(
  props: IMenuProps<V> & { ref?: React.RefObject<IMenuRef<V>> },
) => ReturnType<typeof Menu>
