import { SCHEMA } from '@leenda/editor/lib/brand'

import { genFormSchema, FONT_SCHEMA_FIELDS } from 'services/Branding/genFormSchema'
import { t } from 'services/Translation'

export const STYLE_FORM_SCHEMA = genFormSchema(SCHEMA.grouping.style, {
  group: {
    META: { label: t('elements.grouping.tags.group') },
    backgroundColor: {},
    borderRadius: { params: { min: 1, max: 50 } },
    borderColor: {},
    borderWidth: { params: { min: 1, max: 8 } },
  },
  focusColor__var: {
    META: { label: '' },
    borderColor: { label: t('input.label.focus') },
  },
  answer: {
    META: { label: t('elements.grouping.tags.answer') },
    backgroundColor: {},
    borderRadius: { params: { min: 1, max: 50 } },
    borderStyle: {},
    borderColor: {},
    borderWidth: { params: { min: 1, max: 8 } },
  },
  icon: {
    META: { label: t('elements.grouping.tags.icon') },
    backgroundImage: { label: t('elements.grouping.schema.bgImage') },
  },
  progress: {
    META: { label: t('elements.grouping.tags.progress') },
    height: { params: { placeholder: '4px', min: 1 } },
    backgroundColor: {},
  },
  progressResult__var: {
    META: { label: '' },
    backgroundColor: { label: t('input.label.color') },
    borderRadius: { params: { min: 1, max: 50 } },
  },
  indents: {
    META: { label: t('elements.grouping.tags.indents') },
    padding: {
      label: t('input.label.innerIndents'),
      params: { min: 0 },
    },
    marginTop: {
      label: t('input.label.padding'),
      info: t('elements.grouping.placeholder.answerGroupIntents'),
    },
    gap: {
      label: t('input.label.interval'),
      info: t('elements.grouping.placeholder.groupsIndents'),
      params: { width: 64 },
    },
  },
  // result: { META: { label: t('elements.grouping.tags.result') } },
  // text: { META: { label: t('elements.grouping.tags.text') } },
})

export const FONT_FORM_SCHEMA = genFormSchema(SCHEMA.grouping.font, {
  base: {
    ...FONT_SCHEMA_FIELDS.base,
    META: { label: t('elements.grouping.tags.text') },
  },
  h4: {
    ...FONT_SCHEMA_FIELDS.h4,
    META: { label: t('elements.grouping.tags.result') },
  },
})
