import * as R from 'ramda'
import React, { useContext, useMemo, useRef } from 'react'
import { ReactEditor, useSlate } from 'slate-react'

import { LayoutScroll } from 'components/LayoutPage'
import { ScrollRef } from 'components/LayoutPage/LayoutScroll/LayoutScroll'
import { RichTextContext } from 'components/RichText/context'
import { t } from 'services/Translation'

import s from './MentionForm.module.scss'
import MentionItem from './MentionItem'

const calcTop = (
  rootRect?: DOMRect,
  size?: { width: number; height: number },
  mention?: DOMRect,
) => {
  if ((rootRect?.bottom || 0) - (mention?.bottom || 0) >= (size?.height || 0)) {
    const mentionBottom = (mention?.bottom || 0) - (rootRect?.top || 0)
    return mentionBottom + 4
  }
  const mentionTop = (mention?.top || 0) - (rootRect?.top || 0)
  return mentionTop - 4 - (size?.height || 0)
}

const calcLeft = (
  rootRect?: DOMRect,
  size?: { width: number; height: number },
  mention?: DOMRect,
) => {
  const toolbarWidth = size?.width || 0
  const mentionLeft = (mention?.left || 0) - (rootRect?.left || 0)
  return R.clamp(0, Math.max((rootRect?.width || 1) - toolbarWidth, 1), mentionLeft)
}

interface IMentionFormProps {
  anchor?: DOMRect
  focus?: DOMRect
  size?: { width: number; height: number }
}

const MentionForm = React.forwardRef<HTMLDivElement, IMentionFormProps>(({ size }, ref) => {
  const scrollRef = useRef<ScrollRef>(null)
  const editor = useSlate()
  const { employees, mentionIndex, mentionTarget, rootRef } = useContext(RichTextContext)

  const nodeRect = useMemo(() => {
    try {
      const node = mentionTarget && ReactEditor.toDOMRange(editor, mentionTarget)
      return node?.getBoundingClientRect()
    } catch (error) {
      return null
    }
  }, [editor, mentionTarget])

  const rootRect = rootRef.current?.getBoundingClientRect()
  const left = nodeRect && calcLeft(rootRect, size, nodeRect)
  const top = nodeRect && calcTop(rootRect, size, nodeRect)

  return (
    <div
      className={s.root}
      ref={ref}
      style={{
        transition: 'transform 0.2s',
        transform: `translate(${left}px, ${top}px)`,
      }}
    >
      <LayoutScroll ref={scrollRef} sizeAutoCapable>
        {employees.map((employee, index) => (
          <MentionItem
            active={index === mentionIndex}
            employee={employee}
            key={employee.id}
            scrollRef={scrollRef}
          />
        ))}
        {employees.length === 0 && <div className={s.empty}>{t('page.user.notFound')}</div>}
      </LayoutScroll>
    </div>
  )
})
MentionForm.displayName = 'MentionForm'

export default MentionForm
