import React from 'react'
import { chainName } from 'utils'

import { genDataField } from 'components/controls/Field'
import RealtimeField from 'components/controls/RealtimeField'
import Padding from 'components/editor-v3/Editor/Toolbar/Padding'
import Hint from 'components/uiKit/Hint'
import { Block } from 'services/Store/Project/types'
import { t } from 'services/Translation'

import { TestMultipleChoiceValueFormType } from './TestMultipleChoice.types'

const genField = genDataField<Block>()

const isRandom = genField({
  name: 'isRandom',
  label: t('input.label.shuffle'),
  type: 'segmented',
  layout: 'horizontal',
})

const TestMultipleChoiceForm: TestMultipleChoiceValueFormType = ({ name }) => (
  <Padding sides>
    <Hint text={t('elements.testMultipleChoice.form.randomHint')} />
    <RealtimeField config={chainName(name, isRandom)} />
  </Padding>
)

export default TestMultipleChoiceForm
