import { RichTextValue } from '@leenda/rich-text'
import * as R from 'ramda'
import { useState, useRef, useMemo, useEffect, useCallback } from 'react'

import { useElementRootStyles } from 'components/editor-v2/EditorElements/hooks/useElementRootStyles'
import { useGetRichTextProps } from 'components/form/RichText/useGetRichTextProps'
import { setQuestion } from 'services/Store/Project/actions'
import { DeviceMode, PreviewMode, SectionTypeEnum } from 'services/Store/Project/enums'
import { useProjectContext, useProjectDispatch } from 'services/Store/Project/hooks'
import { getBlockState, getSection } from 'services/Store/Project/selectors'

import { TestMatchingElementType } from '../TestMatchingElement.types'
import { useGetLeftRightItems } from '../helper'
import DefaultElement from './DefaultView/DefaultElement'
import MobileElement from './MobileView/MobileElement'
import TestMatchingContext from './TestMatchingContext'

const TestMatchingElement: TestMatchingElementType = ({
  element: { id: elementId, value },
  state,
  mode,
  styles,
  block,
  waiting,
  onChange,
  font,
}) => {
  const isPdf = mode.previewMode === PreviewMode.pdf
  const section = useProjectContext(getSection)
  const blockResult = useProjectContext(getBlockState, block?.uuid)?.result
  const dispatch = useProjectDispatch()

  const wrapperRef = useRef<HTMLDivElement | null>(null)
  const isViewMode = mode.previewMode !== PreviewMode.editor
  const isMobile = mode.deviceMode === DeviceMode.mobile

  const { isActiveElement, activeIndex, onLabelSelect, isFill /* rtStyles */ } =
    useGetRichTextProps({
      block,
      elementId,
      // styles: styles.text,
      mode,
    })

  const onChangeLabel = useCallback(
    (label: RichTextValue, side: string, index: number) => {
      onChange?.(R.assocPath(['items', index, side], label, value))
    },
    [onChange, value],
  )

  const { left, right } = useGetLeftRightItems(isViewMode && !isPdf, value.items, state)

  const originLeft = useMemo(() => left, [])

  const [draggableId, setDraggableId] = useState<string | undefined>()

  const isDisabled = Boolean(!isViewMode || blockResult)
  const isValidated = Boolean(blockResult)
  const isVisible = Boolean(
    isValidated &&
      (section?.test?.isValidationVisible ||
        (section?.type === SectionTypeEnum.landing && block?.test?.isResult)),
  )

  const rootStyles = useElementRootStyles(styles.root)

  // remember first values as right order
  const validOrderMap = useMemo(
    () =>
      value.items.reduce(
        (result, item, index): { [id: string]: number } => {
          result[item.value] = index
          return result
        },
        {} as { [id: string]: number },
      ),
    [value?.items],
  )

  const contextValue = {
    left,
    right,
    font,
    elementId,
    draggableId,
    setDraggableId,
    validOrderMap,
    isDisabled,
    isVisible,
    isMobile,
    isViewMode,
    styles,
    block,
    value,
    wrapperRef,
    mode,
    state,
    isActiveElement,
    activeIndex,
    onChangeLabel,
    onLabelSelect,
    isFill,
    onChange,
    // rtStyles,
    waiting,
  }

  useEffect(() => {
    dispatch(
      setQuestion({
        elementId,
        value: {
          initialItems: left,
        },
        isReady: false,
        blockId: block?.uuid || '',
      }),
    )
  }, [])

  return (
    <div style={rootStyles}>
      <TestMatchingContext.Provider value={contextValue}>
        {isMobile ? <MobileElement originLeft={originLeft} /> : <DefaultElement />}
      </TestMatchingContext.Provider>
    </div>
  )
}

export default TestMatchingElement
