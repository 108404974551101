import { SCHEMA } from '@leenda/editor/lib/brand'

import { genFormSchema, FONT_SCHEMA_FIELDS } from 'services/Branding/genFormSchema'
import { t } from 'services/Translation'

export const STYLE_FORM_SCHEMA = genFormSchema(SCHEMA.testRanking.style, {
  offset__var: {
    META: { label: t('elements.testMatching.tags.spacing') },
    marginTop: {},
    padding: {},
  },
  item__var: {
    META: { label: t('elements.testRanking.tags.item') },
    borderStyle: {},
    borderColor: {},
    borderWidth: {},
    color: { label: t('input.label.iconColor') },
  },
  itemHover__var: {
    META: { label: t('elements.testRanking.tags.itemHover') },
    borderColor: { label: t('elements.testRanking.schema.hoverColor') },
    color: { label: t('input.label.color') },
  },
})

export const FONT_FORM_SCHEMA = genFormSchema(SCHEMA.testRanking.font, FONT_SCHEMA_FIELDS)
