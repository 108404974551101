import { ListFontSchemaType, ListSchemaType } from '@leenda/editor/lib/brand'
import { ListElementValue, ListItem } from '@leenda/editor/lib/elements'
import { RichTextValue } from '@leenda/rich-text'
import * as R from 'ramda'
import React from 'react'

import RichText from 'components/form/RichText/RichText'
import { EMPTY_RICH_TEXT } from 'components/form/RichText/richText.constants'
import { ElementFontCss, ElementStyleCss } from 'services/Branding/types'
import { getImageByUsage } from 'utils/files'

import { DEFAULT_SIZE, POINT_SIZE } from '../../ListConstants'
import ListImage from '../ListImage'
import ListNumber from '../ListNumber/ListNumber'
import ListPoint from '../ListPoint'
import s from './ListMarker.module.scss'

interface IProps {
  styles: ElementStyleCss<ListSchemaType>
  mode: ListElementValue['mode']
  item: ListItem
  onChange: (value: RichTextValue | typeof EMPTY_RICH_TEXT, index: number) => void
  index: number
  active: boolean
  isFill: boolean
  name: string
  onUp?: () => void
  onDown?: () => void
  waiting?: boolean
  cursorPosition: 'start' | 'end'
  font: ElementFontCss<ListFontSchemaType>
}

const labelMap = {
  number: ListNumber,
  images: ListImage,
  point: ListPoint,
}

const ListMarker: React.FC<IProps> = ({
  item,
  styles,
  mode,
  onChange,
  index,
  active,
  isFill,
  name,
  onUp,
  onDown,
  waiting,
  cursorPosition,
  font,
}) => {
  const LabelItemComponent = labelMap[mode]
  const { label, icon, number } = item
  const isPointView = mode === 'point'
  const { labels } = styles
  const innerLayerStyles = R.pick(
    ['backgroundColor', 'borderColor', 'borderWidth', 'borderStyle'],
    labels,
  )

  const path = getImageByUsage(icon)?.path

  const size = isPointView ? POINT_SIZE : DEFAULT_SIZE
  const innerLayerAllStyles = {
    ...innerLayerStyles,
    ...font.numbering,
    width: size,
    height: size,
    backgroundImage: mode === 'images' ? styles.labels.backgroundImage : undefined,
  }

  const onChangeText = (textValue: RichTextValue | typeof EMPTY_RICH_TEXT) => {
    onChange(textValue, index)
  }

  return (
    <>
      <div className={s.root}>
        <LabelItemComponent path={path} styles={innerLayerAllStyles}>
          {mode === 'number' ? number ?? '\u00A0' : '\u00A0'}
        </LabelItemComponent>
        <div className={s.layer}>
          <p className={s.label}>
            <span style={R.pick(['fontSize', 'lineHeight'], font.base)}>{'\u00A0'}</span>
          </p>
        </div>
      </div>
      <span className={s.text}>
        <RichText
          active={active}
          cursorPosition={cursorPosition}
          disabled={!isFill}
          key={name}
          name={name}
          onChange={onChangeText}
          onDown={onDown}
          onUp={onUp}
          styles={font}
          toolbarContainerId='rich-text-toolbar'
          value={label}
          waiting={waiting}
          iterable
        />
      </span>
    </>
  )
}

export default ListMarker
