import React, { useCallback } from 'react'

import { EditorActionUiEnum } from 'components/editor-v3/Editor/types'
import { EditorMode } from 'services/Store/Project/enums'
import { IBlockMode } from 'services/Store/Project/types'
import { getEnumOption } from 'utils/enum'

import ControlItem from '../ControlItem'

interface IEditableProModeProps {
  mode: IBlockMode
  customMode: IBlockMode | null
  setCustomMode: React.Dispatch<React.SetStateAction<IBlockMode | null>>
}

const FILL_MODE_ACTION = getEnumOption('EditorActionUiEnum', EditorActionUiEnum.ELEMENT_FILL_MODE, {
  icon: 'builderContentModeSettings',
})

const EditableProMode: React.FC<IEditableProModeProps> = ({ mode, customMode, setCustomMode }) => {
  const toggledMode = customMode?.editorMode === EditorMode.fill ? EditorMode.pro : EditorMode.fill

  const onChangeEditable = useCallback(
    () => setCustomMode({ ...mode, editorMode: toggledMode }),
    [setCustomMode, toggledMode],
  )

  if (mode.editorMode === EditorMode.pro) {
    return (
      <ControlItem
        active={customMode?.editorMode === EditorMode.fill}
        handler={onChangeEditable}
        option={FILL_MODE_ACTION}
      />
    )
  }
  return null
}

export default EditableProMode
