import { SlateElementType } from '@leenda/rich-text'
import * as R from 'ramda'
import React, { useCallback, useContext, useEffect, useRef } from 'react'
import { createPortal } from 'react-dom'
import { Range as SlateRange, Transforms } from 'slate'
import { useSlate, useSlateSelection } from 'slate-react'

import { useModalContainer } from 'components/uiKit/Modal/ModalContext'
import { useProjectContext } from 'services/Store/Project/hooks'
import { getDeviceMode } from 'services/Store/Project/selectors'

import { removeSavedSelection } from '../../formatOperations/internal'
import { isAnnotationActive, isCrossLinkActive, isLinkActive } from '../../inline/withInline'
import { RichTextContext } from '../../richText.context'
import rs from '../../styles/RichText.module.scss'
import { isInSlate } from '../../utils/common'
import s from './BubbleToolbar.module.scss'
import Toolbar from './Toolbar'

const BubbleToolbar = () => {
  const ref = useRef<HTMLDivElement | null>(null)
  const editor = useSlate()
  const rectVal = useRef<DOMRect | null>(null)
  const getContainer = useModalContainer()
  const { setToolbarForm, toolbarForm } = useContext(RichTextContext)
  const deviceMode = useProjectContext(getDeviceMode)
  const pseudoSelectionElement = useRef<Element | null>(null)

  const slateSelection = useSlateSelection()
  const isLink = isLinkActive(editor)
  const isCrossLink = isCrossLinkActive(editor)
  const isAnnotation = isAnnotationActive(editor)
  const shown =
    editor.selection &&
    (!SlateRange.isCollapsed(editor.selection) || isLink || isCrossLink || isAnnotation)

  const updateStyle = useCallback(() => {
    const el = ref.current

    if (!el || !editor.selection) {
      return
    }

    const domSelection = window.getSelection()
    if (!domSelection || !domSelection.anchorNode) {
      return
    }
    const domRange = domSelection?.getRangeAt(0)
    const rect = domRange?.getBoundingClientRect()

    if (rect.width && rect.height) {
      rectVal.current = rect
    } else {
      rectVal.current = pseudoSelectionElement.current?.getBoundingClientRect() || null
    }

    if (
      !isInSlate(domRange?.commonAncestorContainer as HTMLElement) &&
      !pseudoSelectionElement.current
    ) {
      return
    }
    const shown =
      (editor.selection && !SlateRange.isCollapsed(editor.selection)) ||
      isLink ||
      isCrossLink ||
      isAnnotation ||
      !!rectVal.current

    if (!shown) {
      el.removeAttribute('style')
      return
    }
    if (SlateRange.isCollapsed(editor.selection) && isLink) {
      setToolbarForm(SlateElementType.link)
    } else if (SlateRange.isCollapsed(editor.selection) && isCrossLink) {
      setToolbarForm(SlateElementType.crossLink)
    } else if (SlateRange.isCollapsed(editor.selection) && isAnnotation) {
      setToolbarForm(SlateElementType.annotation)
    } else if (!editor.prevSelection) {
      setToolbarForm(null)
    }
    if (rectVal.current && rectVal.current.width && rectVal.current.height && shown) {
      el.style.display = 'flex'
      const bottom = window.innerHeight - rectVal.current.top + 4
      const left = R.clamp(
        220,
        Math.max(window.innerWidth - el.offsetWidth - 300, 220),
        rectVal.current.left + window.pageXOffset,
      )
      el.style.bottom = `${bottom}px`
      el.style.left = `${left}px`
      el.style.top = 'auto'
    }

    if (rectVal.current && (toolbarForm === SlateElementType.annotation || toolbarForm === 'ai')) {
      const bottom = rectVal.current?.bottom + 4
      const left = R.clamp(
        220,
        Math.max(window.innerWidth - el.offsetWidth - 300, 220),
        rectVal.current.left,
      )

      el.style.top = `${bottom}px`
      el.style.left = `${left}px`
      el.style.bottom = 'auto'
    }
  }, [editor, toolbarForm, setToolbarForm, isLink, isCrossLink, isAnnotation])

  const isActive = !editor.prevSelection

  useEffect(() => {
    pseudoSelectionElement.current = document.getElementsByClassName(rs.pseudoSelection)[0]
    updateStyle()
  }, [isActive, updateStyle])

  useEffect(() => {
    document.addEventListener('scroll', updateStyle)
    document.addEventListener('resize', updateStyle)
    document.addEventListener('mousewheel', updateStyle)
    return () => {
      document.removeEventListener('scroll', updateStyle)
      document.removeEventListener('resize', updateStyle)
      document.removeEventListener('mousewheel', updateStyle)
    }
  }, [editor, toolbarForm, updateStyle])

  useEffect(() => {
    updateStyle()
  }, [slateSelection, toolbarForm, updateStyle, isActive])

  useEffect(() => {
    removeSavedSelection(editor)
    Transforms.deselect(editor)
  }, [deviceMode, editor])

  const onKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Escape') {
      setToolbarForm(null)
    }
  }

  return createPortal(
    <div
      className={s.root}
      onClick={(e) => e.stopPropagation()}
      onKeyDown={onKeyDown}
      onMouseDown={(e) => e.stopPropagation()}
      ref={ref}
    >
      {shown && <Toolbar />}
    </div>,
    getContainer(),
  )
}

export default BubbleToolbar
