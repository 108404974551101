import { useSize } from 'ahooks'
import cn from 'classnames'
import { AnimationDefinition, motion } from 'framer-motion'
import React, { useEffect } from 'react'

import ScrollService from 'services/Scroll/ScrollService'
import { DeviceMode, PreviewMode } from 'services/Store/Project/enums'
import { IBlockMode } from 'services/Store/Project/types'

import { RootBox, Size } from '../types'
import s from './CourseTooltipContent.module.scss'

const ANIMATION_VARIANTS = {
  hidden: { opacity: 0, scale: 0.95 },
  visible: { opacity: 1, scale: 1 },
} as const
interface ICourseTooltipContentProps {
  name: string
  title: JSX.Element
  mode: IBlockMode
  onClose: () => void
  setRender: React.Dispatch<React.SetStateAction<boolean>>
  rootRef: HTMLDivElement | null
  rootBox?: RootBox
  open?: boolean
  isAnimated?: boolean
  position?: string
}
const calculatePosition = (size?: Size, rootBox?: RootBox, position?: string) => {
  if (!rootBox) {
    return {}
  }
  if (!size) {
    return { top: rootBox.top, left: rootBox.left, visibility: 'hidden' as const }
  }
  const { top, left, width, height, containerWidth, containerHeight } = rootBox
  const { width: contentWidth = 100, height: contentHeight = 100 } = size

  let x = left + (width - contentWidth) / 2
  let y = top + height

  if (position === 'bottom') {
    x = left
    y = top + height + 4
  }

  x = Math.max(0, Math.min(x, containerWidth - contentWidth))
  y = Math.max(0, Math.min(y, containerHeight - contentHeight))

  return {
    top: y,
    left: x,
  }
}

const CourseTooltipContent: React.FC<ICourseTooltipContentProps> = ({
  title,
  rootRef,
  rootBox,
  mode,
  onClose,
  name,
  open,
  isAnimated,
  setRender,
  position,
}) => {
  const [contentRef, setContentRef] = React.useState<HTMLDivElement | null>(null)
  const size = useSize(contentRef)

  const style = {
    ...(mode.deviceMode !== DeviceMode.mobile ? calculatePosition(size, rootBox, position) : {}),
  }
  const node: any =
    (mode.previewMode === PreviewMode.editor ? ScrollService.canvas.ref : document) || document

  useEffect(() => {
    const closeOnEsc = (e: KeyboardEvent) => {
      if (e.key === 'Escape') {
        onClose()
      }
    }
    const onScroll = (e: Event) => {
      const target = e.target as HTMLElement
      if (!rootRef?.contains(target) && !contentRef?.contains(target)) {
        onClose()
      }
    }
    const onOutsideClick = (e: MouseEvent) => {
      const target = e.target as HTMLElement
      if (!rootRef?.contains(target) && !contentRef?.contains(target)) {
        e.stopPropagation()
        e.preventDefault()
        onClose()
      }
    }
    document.addEventListener('keydown', closeOnEsc)
    node.addEventListener('scroll', onScroll, true)
    node.addEventListener('mousedown', onOutsideClick)
    return () => {
      document.removeEventListener('keydown', closeOnEsc)
      node.removeEventListener('scroll', onScroll, true)
      node.removeEventListener('mousedown', onOutsideClick)
    }
  }, [rootRef, contentRef, name, node])

  const onAnimationComplete = (definition: AnimationDefinition) => {
    if (definition === 'hidden') {
      setRender(false)
    }
  }

  return isAnimated ? (
    <motion.div
      animate={open ? 'visible' : 'hidden'}
      className={cn(s.root, s[mode.deviceMode])}
      initial='hidden'
      onAnimationComplete={onAnimationComplete}
      ref={setContentRef}
      style={style}
      transition={{ duration: 0.3 }}
      variants={ANIMATION_VARIANTS}
    >
      {title}
    </motion.div>
  ) : (
    <div className={cn(s.root, s[mode.deviceMode])} ref={setContentRef} style={style}>
      {title}
    </div>
  )
}

export default CourseTooltipContent
