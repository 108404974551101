import { SplitterElementValue } from '@leenda/editor/lib/elements'
import { getParent } from 'utils'

import { genDataField } from 'components/controls/Field'
import RealtimeField from 'components/controls/RealtimeField'
import Crop from 'components/controls/customComponents/Crop'
import Padding from 'components/editor-v3/Editor/Toolbar/Padding'
import Divider from 'components/uiKit/Divider'
import Hint from 'components/uiKit/Hint'
import { Block } from 'services/Store/Project/types'
import { t } from 'services/Translation'
import { chainName } from 'utils/form'

import { SplitterValueFormType } from './SplitterElement.types'

const genField = genDataField<Block>()

const position = (name: string) =>
  genField({
    name: `${name}.position`,
    type: 'number',
    label: t('elements.splitter.form.splitter'),
    defaultValue: 50,
    layout: 'horizontal',
    rules: [{ max: 100 }],
    params: { min: 0, max: 100, width: 64 },
  })

const leftBlockImage = genField({
  name: 'leftBlock.image',
  type: 'file',
  layout: 'horizontal',
  params: {
    fileType: 'image',
    nullable: true,
  },
})

const leftBlockImageCrop = genField({
  name: 'leftBlock.image',
  type: 'custom',
  label: t('input.label.crop'),
  component: Crop,
  layout: 'horizontal',
  params: (block, { name }) => {
    const { parent } = getParent<SplitterElementValue['leftBlock']>(name, block)
    return {
      hidden: !parent?.image?.id,
    }
  },
})

export const leftBlockBg = genField({
  name: 'leftBlock.background',
  type: 'color',
  label: t('input.label.blackout'),
  layout: 'horizontal',
})

// --------------------------------

const rightBlockImage = genField({
  name: 'rightBlock.image',
  type: 'file',
  layout: 'horizontal',
  params: {
    fileType: 'image',
    nullable: true,
  },
})

const rightBlockImageCrop = genField({
  name: 'rightBlock.image',
  type: 'custom',
  label: t('input.label.crop'),
  component: Crop,
  layout: 'horizontal',
  params: (block, { name }) => {
    const { parent } = getParent<SplitterElementValue['rightBlock']>(name, block)
    return {
      hidden: !parent?.image?.id,
    }
  },
})

export const rightBlockBg = genField({
  name: 'rightBlock.background',
  type: 'color',
  label: t('input.label.blackout'),
  layout: 'horizontal',
})

const SplitterForm: SplitterValueFormType = ({ name }) => (
  <Padding sides>
    <Hint text={t('elements.splitter.form.info')} />
    <RealtimeField config={position(name)} />
    <RealtimeField config={chainName(name, leftBlockImage)} />
    <RealtimeField config={chainName(name, leftBlockImageCrop)} />
    <RealtimeField config={chainName(name, leftBlockBg)} />
    <Divider />
    <RealtimeField config={chainName(name, rightBlockImage)} />
    <RealtimeField config={chainName(name, rightBlockImageCrop)} />
    <RealtimeField config={chainName(name, rightBlockBg)} />
  </Padding>
)

export default SplitterForm
