import cn from 'classnames'
import * as R from 'ramda'
import React from 'react'

import { testPropsEl } from 'utils/test/qaData'
import { useTextWidth } from 'utils/useTextWidth'

import {
  useSelectActions,
  useSelectHandlers,
  useSelectInputRef,
  useSelectSize,
  useSelectContext,
  useSelectStyleType,
  useSelectListRef,
} from '../SelectContext'
import { splitBy } from '../SelectUtils'
import s from './SelectInput.module.scss'

const parseNumber = (value: string | number) => (isNaN(+value) ? value : +value)
const isNumber = (value: string | number): value is number => typeof parseNumber(value) === 'number'

const SelectInput = () => {
  const ref = useSelectInputRef()
  const listRef = useSelectListRef()
  const { handleChange, handleSeparate } = useSelectHandlers()
  const { setIsOpen, setInputValue } = useSelectActions()
  const styleType = useSelectStyleType()
  const size = useSelectSize()
  const inputValue = useSelectContext((c) => c.inputValue)
  const name = useSelectContext((c) => c.name)
  const value = useSelectContext((c) => c.value)
  const options = useSelectContext((c) => c.listOptions)
  const isSearch = useSelectContext((c) => c.isSearch)
  const disabled = useSelectContext((c) => c.disabled)
  const autoFocus = useSelectContext((c) => c.autoFocus)
  const inputable = useSelectContext((c) => c.inputable)
  const inputType = useSelectContext((c) => c.inputType)
  const isMultiple = useSelectContext((c) => c.isMultiple)
  const separators = useSelectContext((c) => c.separators)
  const focus = useSelectContext((c) => Boolean(c.focus))
  const active = useSelectContext(
    (c) =>
      !c.readOnly &&
      !c.disabled &&
      (inputable || c.forceSearch || (c.isSearch && (!value || isMultiple))),
  )

  const width = useTextWidth(ref?.current, inputValue)

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!active) {
      return
    }
    setIsOpen(true)
    setInputValue(e.target.value)

    if (!isMultiple && inputable && inputType === 'number' && isNumber(e.target.value)) {
      handleChange?.(e.target.value ? +e.target.value : null)
      const exactMatch = options.find((option) => option.value === +e.target.value) || null
      listRef.current?.setHovered(exactMatch)
    }
    if (!isMultiple && inputable && inputType === 'text') {
      handleChange?.(e.target.value)
    }
  }

  const handlePaste = (e: React.ClipboardEvent<HTMLInputElement>) => {
    if (!inputValue && separators) {
      e.preventDefault()
      const text = e.clipboardData.getData('text/plain')
      const parsed = splitBy(text, separators)
      const newValue = R.uniq([...((value || []) as []), ...parsed])
      handleChange?.(isMultiple ? newValue : inputValue.trim())
    }
  }

  const handleKeyDown = (e: React.KeyboardEvent) => {
    if (e.key === 'ArrowDown' || e.key === 'ArrowUp') {
      e.preventDefault()
    }

    if (e.key === 'Enter') {
      e.preventDefault()
      isMultiple && inputable && handleSeparate()
    }

    if (e.key === 'Backspace' && isMultiple && !inputValue) {
      handleChange?.(R.init((value || []) as []))
    }
  }

  const handleKeyUp = (e: React.KeyboardEvent) => {
    if (e.key === 'ArrowDown' || e.key === 'ArrowUp') {
      return
    }
    isSearch && listRef.current?.setHovered(options[0])
  }

  const cnRoot = cn(s.root, s[styleType], s[size], {
    [s.show]: active || disabled,
    [s.disabled]: disabled,
  })

  return (
    <input
      {...testPropsEl('selectInput', {
        name,
        value: inputType === 'number' ? parseNumber(inputValue) : inputValue,
        focus,
      })}
      autoFocus={autoFocus}
      className={cnRoot}
      disabled={disabled}
      onChange={handleInputChange}
      onKeyDown={handleKeyDown}
      onKeyUp={handleKeyUp}
      onPaste={handlePaste}
      ref={ref}
      style={{ width }}
      tabIndex={-1}
      value={inputValue}
    />
  )
}

export default React.memo(SelectInput)
