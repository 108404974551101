import { SlateElementMark, SlateElementType, SlateMark } from '@leenda/rich-text'

import { KeyIconsType } from 'components/uiKit/Icon'
import { t } from 'services/Translation'

import { RichTextControl } from './richText.types'
import s from './styles/RichText.module.scss'

export enum SlateCommand {
  clear = 'clear',
  insertNpbs = 'insertNpbs',
  leftDoubleQuotes = 'leftDoubleQuotes',
  rightDoubleQuotes = 'rightDoubleQuotes',
  squareRoot = 'squareRoot',
  integral = 'integral',
  degree = 'degree',
  greaterThan = 'greaterThan',
  lessThan = 'lessThan',
  dash = 'dash',
}

export enum SlateInternal {
  pseudoSelection = 'pseudoSelection',
}

export const ALL_RT_CONTROLS: RichTextControl[] = [
  'blockElement',
  'color',
  'backgroundColor',
  'bold',
  'italic',
  'underline',
  'strikethrough',
  'link',
  'crossLink',
  'insertSymbol',
  'textTransform',
  'register',
  'textAlign',
  'code',
  'annotation',
  'ai',
]

export type SlateFormats =
  | keyof typeof SlateMark
  | keyof typeof SlateElementType
  | keyof typeof SlateElementMark
  | keyof typeof SlateCommand
  | keyof typeof SlateInternal

export const isMarkFormat = (format: SlateFormats): format is SlateMark => {
  return Object.values<string>(SlateMark).includes(format)
}
export const isElementFormat = (format: SlateFormats): format is SlateElementType => {
  return Object.values<string>(SlateElementType).includes(format)
}
export const isElementMarkFormat = (format: SlateFormats): format is SlateElementMark => {
  return Object.values<string>(SlateElementMark).includes(format)
}
export const isCommandFormat = (format: SlateFormats): format is SlateCommand => {
  return Object.values<string>(SlateCommand).includes(format)
}

type SlateMarkConfig = {
  name: SlateMark
  className?: string
  cssProperty?: 'backgroundColor' | 'color' | 'textTransform'
  defaultValue?: string | number
}
type SlateElementConfig = {
  name: SlateElementType
  className?: string
}
type SlateElementMarkConfig = {
  name: SlateElementMark
  cssProperty?: 'textAlign' | 'lineHeight'
  defaultValue?: string | number
}

export const SlateMarkConfig: { [key in SlateMark]: SlateMarkConfig } = {
  [SlateMark.bold]: {
    name: SlateMark.bold,
    className: s.textBold,
  },
  [SlateMark.italic]: {
    name: SlateMark.italic,
    className: s.textItalic,
  },
  [SlateMark.underline]: {
    name: SlateMark.underline,
    className: s.textUnderline,
  },
  [SlateMark.strikethrough]: {
    name: SlateMark.strikethrough,
    className: s.textStrikethrough,
  },
  [SlateMark.backgroundColor]: {
    name: SlateMark.backgroundColor,
    cssProperty: 'backgroundColor',
  },
  [SlateMark.color]: {
    name: SlateMark.color,
    cssProperty: 'color',
  },

  // [SlateMark.fontSize]: {
  //   name: SlateMark.fontSize,
  //   cssProperty: 'fontSize',
  // },
  [SlateMark.textTransform]: {
    name: SlateMark.textTransform,
    cssProperty: 'textTransform',
    defaultValue: 'none',
  },
  [SlateMark.register]: {
    name: SlateMark.register,
  },
}

export const SlateElementConfig: { [key in SlateElementType]: SlateElementConfig } = {
  [SlateElementType.blockquote]: {
    name: SlateElementType.blockquote,
    className: s.elementBlockquote,
  },
  [SlateElementType.numberedList]: {
    name: SlateElementType.numberedList,
    className: s.numberedList,
  },
  [SlateElementType.bulletedList]: {
    name: SlateElementType.bulletedList,
    className: s.bulletedList,
  },
  [SlateElementType.listItem]: {
    name: SlateElementType.listItem,
    className: s.listItem,
  },
  [SlateElementType.heading1]: {
    name: SlateElementType.heading1,
    className: s.heading1,
  },
  [SlateElementType.heading2]: {
    name: SlateElementType.heading2,
    className: s.heading2,
  },
  [SlateElementType.heading3]: {
    name: SlateElementType.heading3,
    className: s.heading3,
  },
  [SlateElementType.heading4]: {
    name: SlateElementType.heading4,
    className: s.heading4,
  },
  [SlateElementType.elementDefault]: {
    name: SlateElementType.elementDefault,
  },
  [SlateElementType.caption]: {
    name: SlateElementType.caption,
    className: s.caption,
  },
  [SlateElementType.link]: {
    name: SlateElementType.link,
    className: s.link,
  },
  [SlateElementType.crossLink]: {
    name: SlateElementType.crossLink,
    className: s.link,
  },
  // https://docs.slatejs.org/concepts/12-typescript#multiple-document-models
  [SlateElementType.mention]: {
    name: SlateElementType.elementDefault,
  },
  [SlateElementType.code]: {
    name: SlateElementType.code,
    className: s.code,
  },
  [SlateElementType.annotation]: {
    name: SlateElementType.annotation,
    className: s.annotation,
  },
}

export const SlateElementMarkConfig: { [key in SlateElementMark]: SlateElementMarkConfig } = {
  [SlateElementMark.textAlign]: {
    name: SlateElementMark.textAlign,
    cssProperty: 'textAlign',
    defaultValue: 'left',
  },
}

export const MARK_CONFIGS: { name: string; className?: string; defaultValue?: any }[] = [
  ...Object.values(SlateElementMarkConfig),
  ...Object.values(SlateMarkConfig),
]

export const FORMAT_FIELDS: Partial<Record<SlateFormats, { icon?: KeyIconsType }>> = {
  [SlateMark.bold]: {
    icon: 'builderBold',
  },
  [SlateMark.italic]: {
    icon: 'builderItalic',
  },
  [SlateMark.strikethrough]: {
    icon: 'builderUnderlinetextCross',
  },
  [SlateMark.underline]: {
    icon: 'builderUnderline',
  },
  [SlateElementType.bulletedList]: {
    icon: 'builderList',
  },
  [SlateElementType.numberedList]: {
    icon: 'builderListNumbers',
  },
  [SlateElementType.blockquote]: {
    icon: 'builderBlockquote',
  },
  [SlateElementType.link]: {
    icon: 'link2',
  },
  [SlateElementType.crossLink]: {
    icon: 'interfaceEssentialAnchor',
  },
  [SlateCommand.insertNpbs]: {
    icon: 'builderSpace',
  },
  [SlateCommand.clear]: {
    icon: 'otherRefresh',
  },
  [SlateElementType.code]: {
    icon: 'iconsOtherEmbedCode',
  },
}

export const LIST_ELEMENTS = [SlateElementType.numberedList, SlateElementType.bulletedList]

export const SYMBOLS_CODES: { [key: string]: number } = {
  npbs: 160,
  leftDoubleQuotes: 171,
  rightDoubleQuotes: 187,
  squareRoot: 8730,
  integral: 8747,
  degree: 176,
  greaterThan: 62,
  lessThan: 60,
  dash: 8212,
  lineBreak: 10,
}

export const EMPTY_RICH_TEXT = Symbol('EMPTY_RICH_TEXT')

export const RICH_TEXT_INTERNAL_ERROR_MESSAGE = t('input.error.internalError')

export const HOT_KEYS_CONFIG: Array<{ hotkey: string; name: string; value: string | boolean }> = [
  { hotkey: 'mod+b', name: SlateMark.bold, value: true },
  { hotkey: 'mod+i', name: SlateMark.italic, value: true },
  { hotkey: 'mod+u', name: SlateMark.underline, value: true },
  { hotkey: 'mod+shift+x', name: SlateMark.strikethrough, value: true },
  { hotkey: 'alt+shift+5', name: SlateMark.strikethrough, value: true },
  { hotkey: 'mod+.', name: SlateMark.register, value: 'sup' },
  { hotkey: 'mod+,', name: SlateMark.register, value: 'sub' },
  { hotkey: 'mod+\\', name: SlateCommand.clear, value: true },
  { hotkey: 'mod+space', name: SlateCommand.clear, value: true },
  { hotkey: 'mod+shift+l', name: SlateElementMark.textAlign, value: 'left' },
  { hotkey: 'mod+shift+e', name: SlateElementMark.textAlign, value: 'center' },
  { hotkey: 'mod+shift+r', name: SlateElementMark.textAlign, value: 'right' },
  { hotkey: 'mod+shift+j', name: SlateElementMark.textAlign, value: 'justify' },
  { hotkey: 'mod+k', name: SlateElementType.link, value: 'link' },
]

export const LEENDA_SLATE_CLIPBOARD = 'application/x-leenda-slate-fragment'
export const TEXT = 'text/plain'
