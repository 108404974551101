import { LabeledGraphicFontSchemaType } from '@leenda/editor/lib/brand'
import { LabeledGraphicPoint, LabeledGraphicElementValue } from '@leenda/editor/lib/elements'
import { rtValueToText } from '@leenda/rich-text'
import cn from 'classnames'
import * as R from 'ramda'
import React, { useEffect, useRef, useState } from 'react'

import CourseTooltip from 'components/editor-v3/cource/components/CourseTooltip'
import Icon from 'components/uiKit/Icon'
import { ElementFontCss } from 'services/Branding/types'
import { EditorMode, PreviewMode } from 'services/Store/Project/enums'
import { getImageByUsage } from 'utils/files'
import { testProps } from 'utils/test/qaData'

import { useLabeledGraphicContext, useLabeledGraphicImgRef } from '../LabeledGraphicContext'
import s from './Point.module.scss'
import PointTooltip from './PointTooltip'

const toPercents = (full: number) => (value: number) => (value / full) * 100
const setX = (i: number, x: string) =>
  R.assocPath<string, LabeledGraphicElementValue>(['items', i, 'x'], x)
const setY = (i: number, y: string) =>
  R.assocPath<string, LabeledGraphicElementValue>(['items', i, 'y'], y)

interface IPointProps {
  point: LabeledGraphicPoint
  index: number
  isActive: boolean
  value: LabeledGraphicElementValue
  id: string
  isAnimated?: boolean
  font: ElementFontCss<LabeledGraphicFontSchemaType>
}

const Point: React.FC<IPointProps> = ({ point, index, isActive, value, id, isAnimated, font }) => {
  const { type = 'image' } = value
  const styles = useLabeledGraphicContext((c) => c.styles)
  const mode = useLabeledGraphicContext((c) => c.mode)
  const element = useLabeledGraphicContext((c) => c.element)
  const onChange = useLabeledGraphicContext((c) => c.onChange)
  const imgRef = useLabeledGraphicImgRef()
  const setActivePoint = useLabeledGraphicContext((c) => c.setActivePoint)

  const ref = useRef<HTMLDivElement>(null)
  const isEditor = mode.previewMode === PreviewMode.editor
  const isFill = mode.editorMode === EditorMode.fill
  const isSkeleton = mode.previewMode === PreviewMode.skeleton
  const isImage = type === 'image'
  const isMobile = mode.deviceMode === 'mobile'

  const file = getImageByUsage(point.pointIcon)

  const [{ x: left, y: top }, setPosition] = useState({ x: point.x, y: point.y })
  const [isDragging, setIsDragging] = useState(false)

  const cnPoint = cn(s.point, { [s.editor]: isEditor, [s.active]: isActive })
  const iconClasses = cn(s.icon, { [s.drag]: isDragging })
  const dragIconClasses = cn(s.dragIcon, { [s.drag]: isDragging })

  const Component: Record<string, JSX.Element | null> = {
    number: (
      <div className={cn(isDragging && s.hidden)} style={font?.numbering}>
        {index + 1}
      </div>
    ),
    image:
      !styles.point.backgroundImage && !file?.path ? (
        <div className={iconClasses}>
          <Icon name='otherAdd' />
        </div>
      ) : null,
    withoutIcon: null,
  }

  const openTooltip = () => {
    setActivePoint(point.value)
  }
  const closeTooltip = () => {
    setActivePoint(null)
  }

  const onVisibleChange = (val: unknown) => {
    if (val) {
      return isActive ? null : openTooltip()
    } else {
      return closeTooltip()
    }
  }

  const onToggle = () => {
    if (isActive) {
      closeTooltip()
    } else {
      openTooltip()
    }
  }

  const onMouseDown = (e: any) => {
    const { clientX, clientY } = e
    const clickStart = { x: clientX, y: clientY }
    let isDragDistance = false
    let lastPoint = { x: point.x, y: point.y }

    const onMouseMove = (event: MouseEvent) => {
      isDragDistance =
        isDragDistance ||
        Math.abs(event.clientX - clickStart.x) > 5 ||
        Math.abs(event.clientY - clickStart.y) > 5
      if (isDragDistance) {
        closeTooltip()
        setIsDragging(true)
        const { clientX, clientY } = event
        const img = imgRef?.current?.getBoundingClientRect()
        const { width = 900, height = 300, x: imgX = 0, y: imgY = 0 } = img || {}
        const percentsW = toPercents(width)
        const percentsH = toPercents(height)

        const point = ref.current?.getBoundingClientRect()
        const { width: pointWidth = 32, height: pointHeight = 32 } = point || {}

        const minX = 0 + percentsW(pointWidth) / 2
        const maxX = 100 - percentsW(pointWidth) / 2
        const x = R.clamp(minX, maxX, percentsW(clientX - imgX))

        const minY = 0 + percentsH(pointHeight) / 2
        const maxY = 100 - percentsH(pointHeight) / 2
        const y = R.clamp(minY, maxY, percentsH(clientY - imgY))
        lastPoint = { x: `${x}%`, y: `${y}%` }
        setPosition({ x: `${x}%`, y: `${y}%` })
      }
    }

    const onMouseUp = () => {
      if (isDragDistance) {
        setIsDragging(false)
        closeTooltip()
        if (onChange && (point.x !== lastPoint.x || point.y !== lastPoint.y)) {
          onChange && onChange(R.compose(setX(index, lastPoint.x), setY(index, lastPoint.y))(value))
        }
      } else {
        onToggle()
      }

      document.removeEventListener('mousemove', onMouseMove)
      document.removeEventListener('mouseup', onMouseUp)
    }

    document.addEventListener('mousemove', onMouseMove)
    document.addEventListener('mouseup', onMouseUp)
  }

  useEffect(() => {
    setPosition({ x: point.x, y: point.y })
  }, [point.x, point.y])

  useEffect(() => {
    if (!isFill) {
      closeTooltip()
    }
  }, [isFill])

  const stopPropagation = (e: React.MouseEvent) => {
    e.stopPropagation()
    e.preventDefault()
  }

  const ringletWidth =
    (typeof styles.point.width === 'string'
      ? parseInt(styles.point.width)
      : styles.point.width || 0) + 8
  const ringletHeight =
    (typeof styles.point.height === 'string'
      ? parseInt(styles.point.height)
      : styles.point.height || 0) + 8

  const PointInner = (
    <div className={s.pointWrapper} onClick={isFill ? stopPropagation : undefined}>
      <div className={s.rings}>
        {isAnimated && (
          <>
            <div
              className={s.ringlet}
              style={{
                width: ringletWidth,
                height: ringletHeight,
              }}
            />
            <div
              className={s.ringlet}
              style={{
                width: ringletWidth,
                height: ringletHeight,
              }}
            />
          </>
        )}
      </div>
      <div
        className={cnPoint}
        onMouseDown={isFill ? onMouseDown : undefined}
        {...testProps({
          el: 'labeledGraphicElPoint',
          value: point.value,
          label: rtValueToText(point.description),
          index,
        })}
        ref={ref}
        style={{
          ...styles.point,
          backgroundImage: isImage
            ? file?.path
              ? `url(${file?.path})`
              : styles.point.backgroundImage
            : undefined,
        }}
      >
        {type && Component[type]}
        <div className={dragIconClasses}>
          <Icon name='builderDrug' />
        </div>
      </div>
    </div>
  )

  return (
    <div className={s.root} onMouseDown={stopPropagation} style={{ top, left }}>
      {isMobile ? (
        <div>{PointInner}</div>
      ) : (
        <CourseTooltip
          isAnimated={styles.animation.fade && !isEditor}
          key={index}
          mode={mode}
          name={index.toString()}
          onOpenChange={onVisibleChange}
          open={!isSkeleton && isActive}
          title={
            <PointTooltip
              count={value.items.length}
              font={font}
              id={id}
              index={index}
              items={element.value.items}
              point={point}
              value={value}
            />
          }
          destroyTooltipOnHide
        >
          {PointInner}
        </CourseTooltip>
      )}
    </div>
  )
}

export default Point
