import {
  CompanyRole,
  EmployeeStatusEnum,
  PermissionGroup,
  ProjectRole,
  SystemRole,
} from '@leenda/crud'
import { MenuPositionType, SidebarMenuType } from '@leenda/editor/lib/brand/types'
import {
  CollapseIconPosition,
  ProgressIconPosition,
  ProgressType,
  TestFeedbackType,
} from '@leenda/editor/lib/elements'

import { ElementGroup } from 'components/editor-v2/EditorElements/elements.types'
import { VideoPointActionUiEnum } from 'components/editor-v2/EditorElements/elements/Media/types'
import { ElementTableActionUiEnum } from 'components/editor-v2/EditorElements/elements/TableElement/components/TableDragControl/types'
import { UiTemplateTags } from 'components/editor-v3/Editor/Modals/TemplatePickerModal/types'
import {
  BlockActionUiEnum,
  ColumnActionUiEnum,
  EditorActionUiEnum,
  PreviewBlockActionUiEnum,
} from 'components/editor-v3/Editor/types'
import { ExportTypeEnumUi, ManifestIdsTypeEnumUi } from 'components/form/ExportForm/types'
import { PublicLinkEntityEnumUi } from 'components/forms/PublicLinkForm/type'
import { HubSpotOptionsEnumUi } from 'components/integrations/HubSpot/types'
import { UnsplashTableActionUiEnum } from 'components/pages/FinderPage/FinderModalPage/Unsplash/types'
import { FinderPageActionUiEnum, FinderTableActionUiEnum } from 'components/pages/FinderPage/types'
import { ProjectLabelGroups } from 'components/sections/ProjectSettings/Localization/helper'
import { ProjectSettingsTabEnumUi } from 'components/sections/ProjectSettings/type'
import { SectionActionUiEnum } from 'components/sections/ProjectStructure/SectionToolbar/types'
import { CommentActionUiEnum } from 'components/uiKit/Comment/types'
import { ValueType } from 'components/uiKit/KitTypes'
import { IMenuOption } from 'components/uiKit/Menu'
import { SortUiEnum } from 'components/uiKit/SortPopover/types'
import {
  AvailabilityEnum,
  ContractType,
  ImageResolution,
  LicenseStatus,
  Locale,
  NotificationGroupEnum,
  PassOrderEnum,
  PdfBuildType,
  ResourcesLimit,
  ScormScoreType,
  ScormStatusMode,
  ScormVersionEnum,
  SuspendDataType,
  TaskStatus,
  VideoResolution,
  ProjectExportToPdfEvents,
  ProjectExportToScormEvents,
  EmployeePreferencesTab,
  TranslateFormatEnum,
  ActiveAccounts,
  AiTextPromptTogglesEnum,
  AiProvider,
  ProjectAutoTranslateStatus,
} from 'gql/__generated__/graphql'
import { DashboardProjectActionUiEnum } from 'routes/routes/App/routes/Company/pages/Dashboard/types'
import {
  BrandPageActionUiEnum,
  BrandTableActionUiEnum,
} from 'routes/routes/App/routes/Company/routes/Brands/pages/Brands/types'
import {
  CatalogPageActionUiEnum,
  CatalogTableActionUiEnum,
} from 'routes/routes/App/routes/Company/routes/Catalog/pages/Catalog/types'
import { EmployeeActionUi } from 'routes/routes/App/routes/Company/routes/Employees/pages/Employees/type'
import { ProjectBuildActionUiEnum } from 'routes/routes/App/routes/Company/routes/Project/routes/Home/pages/Export/HistoryPage/types'
import { ScormUiEnum } from 'routes/routes/App/routes/Company/routes/Project/routes/Home/pages/Export/type'
import {
  ParticipantsPageActionUiEnum,
  ParticipantsTableActionUiEnum,
} from 'routes/routes/App/routes/Company/routes/Project/routes/Home/pages/Participants/types'
import { TemplateActionsUiEnum } from 'routes/routes/App/routes/Company/routes/Templates/components/enum'
import { CollectionActionUiEnum } from 'routes/routes/App/routes/Company/routes/Templates/types'
import { IUiPermission } from 'services/Permission/types'
import { BlockMode, SectionTypeEnum } from 'services/Store/Project/enums'
import { t } from 'services/Translation'

import { AiConfigActionUiEnum } from '../entities/AiConfig/types'
import { throwLocal } from './dev'

export const TRANSLATE_ENUMS = {
  ActiveAccounts: ActiveAccounts,
  AvailabilityEnum: AvailabilityEnum,
  BlockMode: BlockMode,
  CollapseIconPosition: CollapseIconPosition,
  CompanyRole: CompanyRole,
  ContractType: ContractType,
  ContractExpirationWarning: ContractType,
  ContractExpired: ContractType,
  ElementGroup: ElementGroup,
  EmployeeStatusEnum: EmployeeStatusEnum,
  ImageResolution: ImageResolution,
  Locale: Locale,
  NotificationGroupEnum: NotificationGroupEnum,
  NotificationGroupEnum_info: NotificationGroupEnum,
  PassOrderEnum: PassOrderEnum,
  PdfBuildType: PdfBuildType,
  PermissionGroup: PermissionGroup,
  ProgressIconPosition: ProgressIconPosition,
  ProgressType: ProgressType,
  ProjectLabelGroups: ProjectLabelGroups,
  ProjectRole: ProjectRole,
  ScormScoreType: ScormScoreType,
  ScormStatusMode: ScormStatusMode,
  ScormVersionEnum: ScormVersionEnum,
  SectionTypeEnum: SectionTypeEnum,
  SectionTypeEnumPlaceholder: SectionTypeEnum,
  SuspendDataType: SuspendDataType,
  SystemRole: SystemRole,
  TaskStatus: TaskStatus,
  TestFeedbackType: TestFeedbackType,
  UiTemplateTags: UiTemplateTags,
  VideoResolution: VideoResolution,
  ResourcesLimit: ResourcesLimit,
  LicenseStatus: LicenseStatus,
  ProjectExportToPdfEvents: ProjectExportToPdfEvents,
  ProjectExportToScormEvents: ProjectExportToScormEvents,
  EmployeePreferencesTab: EmployeePreferencesTab,
  ProjectSettingsTabEnumUi: ProjectSettingsTabEnumUi,
  EmployeeActionUi: EmployeeActionUi,
  TranslateFormatEnum: TranslateFormatEnum,
  ExportTypeEnumUi: ExportTypeEnumUi,
  VideoPointActionUiEnum: VideoPointActionUiEnum,
  BrandTableActionUiEnum: BrandTableActionUiEnum,
  BrandPageActionUiEnum: BrandPageActionUiEnum,
  ElementTableActionUiEnum: ElementTableActionUiEnum,
  FinderPageActionUiEnum: FinderPageActionUiEnum,
  FinderTableActionUiEnum: FinderTableActionUiEnum,
  CatalogPageActionUiEnum: CatalogPageActionUiEnum,
  CatalogTableActionUiEnum: CatalogTableActionUiEnum,
  ParticipantsPageActionUiEnum: ParticipantsPageActionUiEnum,
  ParticipantsTableActionUiEnum: ParticipantsTableActionUiEnum,
  UnsplashTableActionUiEnum: UnsplashTableActionUiEnum,
  BlockActionUiEnum: BlockActionUiEnum,
  SectionActionUiEnum: SectionActionUiEnum,
  CommentActionUiEnum: CommentActionUiEnum,
  DashboardProjectActionUiEnum: DashboardProjectActionUiEnum,
  CollectionActionUiEnum: CollectionActionUiEnum,
  SortUiEnum: SortUiEnum,
  HubSpotOptionsEnumUi: HubSpotOptionsEnumUi,
  PublicLinkEntityEnumUi: PublicLinkEntityEnumUi,
  ManifestIdsTypeEnumUi: ManifestIdsTypeEnumUi,
  SidebarMenuType: SidebarMenuType,
  MenuPositionType: MenuPositionType,
  ScormUiEnum: ScormUiEnum,
  AiTextPromptTogglesEnum: AiTextPromptTogglesEnum,
  TemplateActionsUiEnum: TemplateActionsUiEnum,
  ColumnActionUiEnum: ColumnActionUiEnum,
  EditorActionUiEnum: EditorActionUiEnum,
  PreviewBlockActionUiEnum: PreviewBlockActionUiEnum,
  ProjectBuildActionUiEnum: ProjectBuildActionUiEnum,
  AiProvider: AiProvider,
  AiConfigActionUiEnum: AiConfigActionUiEnum,
  ProjectAutoTranslateStatus: ProjectAutoTranslateStatus,
} as const

export type EnumName = keyof typeof TRANSLATE_ENUMS

export const getEnumLabel = <N extends EnumName, V = (typeof TRANSLATE_ENUMS)[N]>(
  enumName: N,
  enumValue: V,
  data?: Record<string, string | number | null | undefined>,
) => {
  const tKey = `enums.${enumName}.${enumValue}`
  const label = t(tKey, data)
  if (label === tKey) {
    throwLocal(`Add label to translate "enums.${enumName}.${enumValue}": "${enumValue}"  not found`)
  }
  return label
}

/**
 * Retrieves the option from an enum based on the enum name and enum value.
 * @example
 * ```typescript
 * enum SortUiEnum {
 *  CREATED_AT_ASC = 'CREATED_AT_ASC',
 *  CREATED_AT_DESC = 'CREATED_AT_DESC'
 * }
 * const OPTIONS = [
 *  getEnumOption('SortUiEnum', SortUiEnum.CREATED_AT_ASC),
 *  getEnumOption('SortUiEnum', SortUiEnum.CREATED_AT_DESC),
 * ]
 * // OPTIONS: [
 * //   { value: SortUiEnum.CREATED_AT_ASC, label: enums.SortUiEnum.CREATED_AT_ASC },
 * //   { value: SortUiEnum.CREATED_AT_DESC, label: enums.SortUiEnum.CREATED_AT_DESC }
 * // ]
 * ```
 */
export function getEnumOption<N extends EnumName, V = (typeof TRANSLATE_ENUMS)[N]>(
  enumName: N,
  enumValue: V,
): { value: V; label: string }
/**
 * Retrieves the option from an enum based on the enum name, enum value, and additional option data.
 * ```typescript
 * enum SortUiEnum {
 *  CREATED_AT_ASC = 'CREATED_AT_ASC',
 *  CREATED_AT_DESC = 'CREATED_AT_DESC'
 * }
 * const OPTIONS = [
 *  getEnumOption('SortUiEnum', SortUiEnum.CREATED_AT_ASC, {
 *    disabled: true,
 *    permission: {
 *      hidden: PermissionsKey.project_resource_d
 *    },
 *  }),
 *  getEnumOption('SortUiEnum', SortUiEnum.CREATED_AT_DESC, {
 *    hidden: true,
 *  }),
 * ]
 * // OPTIONS: [
 * //   {
 * //     value: SortUiEnum.CREATED_AT_ASC,
 * //     label: enums.SortUiEnum.CREATED_AT_ASC,
 * //     disabled: true,
 * //     permission: {
 * //       hidden: PermissionsKey.project_resource_d
 * //     }
 * //   },
 * //   {
 * //     value: SortUiEnum.CREATED_AT_DESC,
 * //     label: enums.SortUiEnum.CREATED_AT_DESC,
 * //     hidden: true
 * //   }
 * // ]
 * ```
 */
export function getEnumOption<
  N extends EnumName,
  O extends
    | Partial<IMenuOption<V extends ValueType ? V : ValueType> & IUiPermission>
    | Partial<IMenuOption<V extends ValueType ? V : ValueType>>,
  V = (typeof TRANSLATE_ENUMS)[N],
>(
  enumName: N,
  enumValue: V,
  option: O,
): O extends IUiPermission
  ? IMenuOption<V extends ValueType ? V : ValueType> & IUiPermission
  : IMenuOption<V extends ValueType ? V : ValueType>
export function getEnumOption<N extends EnumName, V = (typeof TRANSLATE_ENUMS)[N]>(
  enumName: N,
  enumValue: V,
  option?:
    | Partial<IMenuOption<V extends ValueType ? V : ValueType> & IUiPermission>
    | Partial<IMenuOption<V extends ValueType ? V : ValueType>>,
  data?: Record<string, string | number | null | undefined>,
) {
  return { label: getEnumLabel(enumName, enumValue, data), value: enumValue, ...option }
}
