import { SCHEMA } from '@leenda/editor/lib/brand'

import { genFormSchema, FONT_SCHEMA_FIELDS } from 'services/Branding/genFormSchema'
import { t } from 'services/Translation'

export const STYLE_FORM_SCHEMA = genFormSchema(SCHEMA.testMatching.style, {
  pair: {
    META: { label: t('elements.testMatching.tags.pair') },
    backgroundColor: {},
    borderRadius: { params: { min: 1 } },
  },
  selected__var: {
    META: { label: '' },
    backgroundColor: { label: t('elements.testMatching.schema.selected') },
  },
  border: {
    META: { label: t('elements.testMatching.tags.border') },
    borderColor: {},
    borderWidth: { params: { min: 1, max: 8 } },
  },
  focusColor__var: {
    META: { label: '' },
    borderColor: { label: t('input.label.focus') },
  },
  icon: {
    META: { label: t('elements.testMatching.tags.desktopIcon') },
    backgroundImage: { label: t('input.label.icon') },
  },
  mobileIcon: {
    META: { label: t('elements.testMatching.tags.mobileIcon') },
    backgroundImage: { label: t('input.label.icon') },
  },
  indents: {
    META: { label: t('elements.testMatching.tags.indents') },
    padding: { label: t('input.label.innerIndents'), params: { min: 0 } },
    marginBottom: { label: t('elements.testMatching.schema.pairsIndents') },
    marginRight: { label: t('elements.testMatching.schema.iconIndents') },
  },
  // text: { META: { label: t('elements.testMatching.tags.text') } },
})

export const FONT_FORM_SCHEMA = genFormSchema(SCHEMA.testMatching.font, FONT_SCHEMA_FIELDS)
