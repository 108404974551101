import React, { ImgHTMLAttributes, useMemo, useState } from 'react'

import { EMPTY_OBJECT } from 'constants/commonConstans'
import { FileSchemaFragment } from 'gql/__generated__/graphql'

import s from './ResponsiveImage.module.scss'

interface IResponsiveImageProps extends ImgHTMLAttributes<HTMLImageElement> {
  file?: FileSchemaFragment
}

const ResponsiveImage = React.forwardRef<HTMLImageElement, IResponsiveImageProps>(
  ({ file, ...rest }, ref) => {
    const [loading, setLoading] = useState(true)
    const imgWidth = file?.width || 1
    const imgHeight = file?.height || 1
    const canvas = useMemo(() => (loading ? { width: '100%' } : { display: 'none' }), [loading])
    const img = useMemo(
      () =>
        loading ? { ...rest.style, position: 'absolute', inset: 0 } : rest.style || EMPTY_OBJECT,
      [loading, rest.style],
    )

    const handleLoad = () => setLoading(false)

    return (
      <>
        <canvas height={imgHeight} style={canvas} width={imgWidth} />
        <img {...rest} className={s.img} onLoad={handleLoad} ref={ref} style={img} />
      </>
    )
  },
)

ResponsiveImage.displayName = 'ResponsiveImage'

export default ResponsiveImage
