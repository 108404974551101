import { useCallback, useState } from 'react'
import { FileTypeEnum } from 'utils'

import Header from 'components/Header'
import { LayoutContent, LayoutRow } from 'components/LayoutPage'
import StorageLimitAlert from 'components/limits/StorageLimitAlert'
import { KitSize } from 'components/uiKit/KitTypes'
import Link from 'components/uiKit/Link'
import Loader from 'components/uiKit/Loader'
import { notify } from 'components/uiKit/Notification'
import { NotificationType } from 'components/uiKit/Notification/types'
import Search from 'components/uiKit/Search'
import { ExternalImage } from 'gql/__generated__/graphql'
import { useCompanyGetById } from 'gql/companies.apollo'
import { useUnsplashCreateFileMeta } from 'gql/files.apollo'
import { t } from 'services/Translation'
import { getResourcesMeta } from 'utils/resources'
import { testProps } from 'utils/test/qaData'

import ExternalImages from '../ExternalImages'
import s from './Unsplash.module.scss'
import { ReactComponent as EmptyUnsplash } from './empty.svg'
import { useUnsplashFiles } from './hooks'
import { useTableMenu } from './useHandlers'

interface IUnsplashProps {
  defaultContentType?: FileTypeEnum
  onClose: (data?: string | undefined) => void
  params: {
    companyId: string
    projectId?: string
  }
  parentId: string
  handleSetActiveTab: () => void
}

const UNSPLASH_LINK = 'https://unsplash.com'

const getEmptyConfig = (isError: boolean) => ({
  CustomIcon: EmptyUnsplash,
  text: isError ? t('page.finder.tabs.unsplash.errorText') : t('page.finder.tabs.unsplash.empty'),
})

const Unsplash: React.FC<IUnsplashProps> = ({
  defaultContentType,
  onClose,
  params: { companyId, projectId },
  parentId,
  handleSetActiveTab,
}) => {
  const [selected, setSelected] = useState<ExternalImage[]>([])
  const {
    externalImages,
    total,
    fetchMore,
    query,
    setQuery,
    loading: unsplashDataLoading,
    chunkLoading,
    error,
  } = useUnsplashFiles()
  const { data: companyData } = useCompanyGetById(companyId)
  const { disableUpload, showResourcesLimitAlert } = getResourcesMeta(
    companyData?.data?.overallResources.total,
    companyData?.data?.availableStorage,
    companyData?.data?.throwLimit,
  )

  const [unsplashCreateFileMeta, { loading: createFileMetaLoading }] = useUnsplashCreateFileMeta()
  const createFileMetaFromExternalImage = useCallback(
    async (ids: string[]) =>
      await unsplashCreateFileMeta({
        variables: { payload: { ids, companyId, projectId, parentId } },
      }),
    [companyId, parentId, projectId, unsplashCreateFileMeta],
  )
  const loading = unsplashDataLoading || createFileMetaLoading

  const handleClickAddFileMeta = useCallback(
    async (items: ExternalImage[]) => {
      if (disableUpload) {
        return
      }
      const res = await createFileMetaFromExternalImage(items.map(({ id }) => id))
      const fileId = res.data?.data[0]?.id
      if (defaultContentType === FileTypeEnum.IMAGE && fileId) {
        notify({
          type: NotificationType.success,
          message: t('notify.file.unsplash.success', { count: selected.length }),
        })
        if (items.length === 1) {
          onClose(fileId)
        } else {
          setSelected([])
          handleSetActiveTab()
        }
      }
      setSelected([])
    },
    [
      createFileMetaFromExternalImage,
      defaultContentType,
      disableUpload,
      handleSetActiveTab,
      onClose,
      selected.length,
    ],
  )

  const { tableActions, onClickTableMenu } = useTableMenu({
    selected,
    setSelected,
    disabled: loading,
    handleClickAddFileMeta,
    disableUpload,
  })

  const handleSetQuery = useCallback((value?: string) => setQuery(value || ''), [setQuery])

  const onClickItem = useCallback(
    (item: ExternalImage) => handleClickAddFileMeta([item]),
    [handleClickAddFileMeta],
  )

  const clearQuery = useCallback(() => setQuery(''), [setQuery])

  return (
    <>
      <LayoutRow justify='right' horizontalFree>
        <Header
          onClickTableMenu={onClickTableMenu}
          showMenu={!!selected.length}
          tableActions={tableActions}
          contentLeft
        >
          <Search
            name='unsplash'
            onChange={handleSetQuery}
            size={KitSize.S}
            value={query}
            wait={500}
            {...testProps({ el: 'title', name: 'unsplash' })}
          />
        </Header>
      </LayoutRow>
      <LayoutRow styleType='clear' horizontalFree>
        {companyData?.data && showResourcesLimitAlert && (
          <div className={s.alert}>
            <StorageLimitAlert companyData={companyData?.data} />
          </div>
        )}
      </LayoutRow>
      <LayoutContent free>
        <>
          {!createFileMetaLoading && (
            <ExternalImages
              chunkLoading={chunkLoading}
              clearQuery={clearQuery}
              data={externalImages}
              empty={getEmptyConfig(Boolean(error))}
              error={Boolean(error)}
              fetchMore={fetchMore}
              loading={loading}
              onChangeSelection={setSelected}
              onClickItem={onClickItem}
              selectedItems={selected}
              total={total}
            />
          )}
          {loading && <Loader name='unsplashLoading' styleType='absolute' resources />}
        </>
      </LayoutContent>
      <LayoutRow justify='center' horizontalFree>
        <div>
          {t('page.finder.tabs.unsplash.footer.info')}&nbsp;
          <Link name='unsplashLink' rel='noreferrer' target='_blank' to={UNSPLASH_LINK}>
            {t('page.finder.tabs.unsplash.footer.entityLink')}
          </Link>
        </div>
      </LayoutRow>
    </>
  )
}

export default Unsplash
