import { AudioFontSchemaType, VideoFontSchemaType } from '@leenda/editor/lib/brand'
import { TimePoint } from '@leenda/editor/lib/elements'
import { useInViewport } from 'ahooks'
import cn from 'classnames'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { createPortal } from 'react-dom'
import ReactPlayer from 'react-player'
import { FileTypeEnum } from 'utils'

import Icon from 'components/uiKit/Icon'
import { ElementFontCss } from 'services/Branding/types'
import { testProps } from 'utils/test/qaData'
import { useReducerWithMiddleware } from 'utils/useReducerWithMiddleware'

import Controls from './Controls'
import s from './MediaPlayer.module.scss'
import { play, pause, setDuration, setProgress, togglePlay, update, seekTo } from './store/actions'
import { fullscreenMiddleware, reactPlayerMiddleware } from './store/middlewares'
import reducer from './store/reducer'
import { IState } from './store/types'
import { IProgressChange, MediaStyles } from './types'

const IS_IOS = /iPhone/.test(navigator.userAgent)

export interface IMediaPlayerProps {
  url: string | null
  type: FileTypeEnum.VIDEO | FileTypeEnum.AUDIO
  autoPlay?: boolean
  timePoints?: TimePoint[]
  controls?: boolean
  cover?: string | false | null
  volume?: number
  duration?: number
  start?: number
  loop?: boolean
  blackout?: string
  styles?: Partial<Record<MediaStyles, React.CSSProperties>>
  fonts?: ElementFontCss<AudioFontSchemaType | VideoFontSchemaType>
  isEditor?: boolean
  playbackRate?: number
  onDuration?: (duration: number) => void
  volumeLabel: string
  onPlay?: () => void
  onReady?: () => void
}

const MediaPlayer: React.VFC<IMediaPlayerProps> = ({
  url = '',
  type,
  styles,
  cover,
  timePoints,
  isEditor,
  onDuration,
  onPlay,
  onReady,
  fonts,
  start = 0,
  duration = 0,
  autoPlay = false,
  loop = false,
  controls = true,
  playbackRate = 1,
  volume = 1,
  blackout = 'rgba(255, 255, 255, 0.1)',
  volumeLabel,
}) => {
  const player = useRef<ReactPlayer>(null)
  const ref = useRef<HTMLDivElement>(null)
  const [inViewport, ratio] = useInViewport(ref, { threshold: [0, 1] })
  const [iosFullScreen, setIosFullScreen] = useState(false)

  const [state, dispatch] = useReducerWithMiddleware(
    reducer,
    { started: false, playing: false, loop, playbackRate, start, duration, volume } as IState,
    [fullscreenMiddleware(ref), reactPlayerMiddleware(player)],
  )

  const mainStyles = useMemo(
    () => ({ ...styles?.border, ...styles?.player, ...fonts?.player }),
    [fonts?.player, styles?.border, styles?.player],
  )

  const handlePlayPause = () => dispatch(togglePlay())
  const handlePlay = () => {
    onPlay && onPlay()
    dispatch(play())
  }

  const handlePause = () => dispatch(pause())
  const handleDuration = (duration: number) => dispatch(setDuration(duration))
  const handleProgress = (progress: IProgressChange) => dispatch(setProgress(progress))
  const handleError = useCallback(() => {
    onReady?.()
    dispatch(pause())
  }, [dispatch, onReady])

  useEffect(() => {
    if (inViewport && ratio == 1 && autoPlay && !state.started) {
      dispatch(play())
    }
  }, [inViewport, ratio, autoPlay, state.started])

  useEffect(() => {
    if (isEditor) {
      dispatch(update({ volume, playbackRate, start, duration, loop }))
    }
  }, [isEditor, volume, start, duration, loop, playbackRate])

  const handleOnReady = () => {
    // TODO: tmp fix for ios continue video after fullscreen
    IS_IOS && state.playedSeconds && dispatch(seekTo(state.playedSeconds))
    onReady?.()
  }

  const showCover = Boolean(cover && !state.playing && !state.played)
  const showMask = !state.playing

  const innerBorderRadius = parseInt(String(styles?.border?.borderRadius || '6px')) - 2
  const setIosFullScreenMemo = useCallback(() => setIosFullScreen((prev) => !prev), [iosFullScreen])
  const handleFullScreenIos = IS_IOS ? setIosFullScreenMemo : undefined

  const ReactPlayerComponent = (
    <div className={cn(s.root, { [s.fullscreen]: state.fullscreen })} ref={ref} style={mainStyles}>
      <div className={s.fixSwipe}>
        <div
          className={cn(s[type], { [s.fullscreen]: state.fullscreen })}
          onClick={handlePlayPause}
          style={{ borderRadius: isNaN(innerBorderRadius) ? 2 : innerBorderRadius }}
        >
          <ReactPlayer
            config={{
              youtube: {
                // TODO: tmp fix for ios stop player after fullscreen if video is unstarted
                onUnstarted: () => IS_IOS && dispatch(pause()),
              },
            }}
            height='100%'
            onDuration={onDuration || handleDuration}
            onError={handleError}
            onPause={handlePause}
            onPlay={handlePlay}
            onProgress={handleProgress}
            onReady={handleOnReady}
            playbackRate={state.playbackRate}
            playing={state.playing}
            // https://www.notion.so/1179-8434fc9b1d534581819ef9861a34facb?pvs=23
            // eslint-disable-next-line react/forbid-component-props
            progressInterval={100}
            ref={player}
            style={{ position: 'relative' }}
            url={url || ''}
            volume={state.volume}
            width='100%'
            playsinline
          />
          {showCover && <img className={s.cover} src={cover || ''} />}
          {showMask && (
            <div className={s.mask} style={{ backgroundColor: blackout }}>
              <div className={s.play} style={styles?.play} {...testProps({ el: 'playMedia' })}>
                <Icon name='otherPlay' />
              </div>
            </div>
          )}
        </div>
        {controls && (
          <div className={s.controls}>
            <Controls
              dispatch={dispatch}
              fonts={fonts}
              handleFullScreenIos={handleFullScreenIos}
              isEditor={isEditor}
              state={state}
              styles={styles}
              timePoints={timePoints}
              type={type}
              volumeLabel={volumeLabel}
            />
          </div>
        )}
      </div>
    </div>
  )

  return iosFullScreen && IS_IOS
    ? createPortal(<div className={s.wrapIos}>{ReactPlayerComponent}</div>, document.body)
    : ReactPlayerComponent
}
export default MediaPlayer
