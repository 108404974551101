import './FormsDivider.scss'

const FormsDivider = ({ offsets }: { offsets?: [number, number] }) => {
  return (
    <div
      className='editor-forms-layout__line'
      style={offsets ? { marginTop: offsets[0], marginBottom: offsets[1] } : undefined}
    />
  )
}
export { FormsDivider }
