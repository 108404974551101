import React, { useEffect } from 'react'

import { IconButton } from 'components/uiKit/Button'
import Input from 'components/uiKit/Input'
import { KitSize } from 'components/uiKit/KitTypes'
import { validateLinkUrl } from 'utils/websiteValidation'

import s from './LinkForm.module.scss'

interface ILinkFormProps {
  editorValue: any
  onClose: () => void
  onUpdateFormat: (format: string, value: any) => void
}
const LinkForm: React.FC<ILinkFormProps> = ({ editorValue, onClose, onUpdateFormat }) => {
  const [value, setValue] = React.useState<string>(editorValue?.inline?.url || '')
  const isValid = validateLinkUrl(value)
  const isEditing = Boolean(editorValue?.inline?.url)

  const onSave = () => {
    onUpdateFormat('link', value)
    onClose()
  }
  const onDelete = () => {
    onUpdateFormat('link', null)
    onClose()
  }
  useEffect(() => {
    setValue(editorValue?.inline?.url || '')
  }, [editorValue?.inline?.url])

  const onKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      e.stopPropagation()
      e.preventDefault()
      onSave()
    }
    if (e.key === 'Escape') {
      e.stopPropagation()
      e.preventDefault()
      onClose()
    }
  }

  return (
    <div className={s.root} onKeyDown={onKeyDown}>
      <div className={s.input}>
        <Input
          autoFocus={!value}
          error={!isValid}
          name='richTextLink'
          onChange={setValue}
          styleType='ghost'
          value={value}
        />
      </div>
      <div className={s.controls}>
        {isEditing && (
          <>
            <IconButton
              icon='otherBrokenLink'
              name='delete'
              onClick={onDelete}
              size={KitSize.S}
              styleType='ghost'
            />
            <IconButton
              icon='checkmark1'
              name='update'
              onClick={onSave}
              size={KitSize.S}
              styleType='ghost'
            />
          </>
        )}
        {!isEditing && value && (
          <IconButton
            icon='checkmark1'
            name='create'
            onClick={onSave}
            size={KitSize.S}
            styleType='ghost'
          />
        )}
        {!isEditing && !value && (
          <IconButton
            icon='otherClose'
            name='cancel'
            onClick={onClose}
            size={KitSize.S}
            styleType='ghost'
          />
        )}
      </div>
    </div>
  )
}

export default LinkForm
