import { generateId } from '@leenda/editor/lib/utils/id'
import cn from 'classnames'
import React, { useMemo, useRef } from 'react'
import { createPortal } from 'react-dom'
import { useContextSelector } from 'use-context-selector'

import { EMPTY_ARRAY } from 'constants/commonConstans'

import s from './AbsolutePortal.module.scss'
import AbsolutePortalContext from './AbsolutePortalContext'
import { GAP, useCheckIntersection, useElementBox, usePortalBox, useSetBox } from './hooks'
import { PlacementType } from './types'

interface IBlockAbsolutePortalProps {
  zIndex?: number
  deps?: unknown[]
  intersection?: boolean
  translateY?: string
  translateX?: string
  placement?: PlacementType
  name: string
  children: React.ReactNode
}

const AbsolutePortalComponent: React.FC<IBlockAbsolutePortalProps> = ({
  children,
  deps = EMPTY_ARRAY,
  placement = 'filled',
  intersection = false,
  zIndex = 0,
  translateX,
  translateY,
}) => {
  const id = useMemo(() => generateId(), [])
  const rootRef = useContextSelector(AbsolutePortalContext, ({ containerRef }) => containerRef)
  const measureRef = useRef<HTMLDivElement>(null)
  const elementRef = useRef<HTMLDivElement>(null)
  const rootBox = useElementBox(rootRef, [...deps])
  const measureBox = useElementBox(measureRef, deps)
  const elementBox = usePortalBox(
    rootBox,
    measureBox,
    { width: elementRef.current?.scrollWidth || 0, height: elementRef.current?.scrollHeight || 0 },
    { placement, gap: Boolean(translateX || translateY) },
  )

  const intersectionBox = useCheckIntersection(id, intersection, elementBox)

  const portalStyle = useMemo(
    () => ({
      zIndex,
      top: intersectionBox ? intersectionBox.y : elementBox.y,
      left: intersectionBox ? intersectionBox.x + intersectionBox.width + GAP : elementBox.x,
      transform: `translate(${translateX || 0}, ${translateY || 0})`,
      ...(placement === 'filled' && { height: measureBox.height, width: measureBox.width }),
    }),
    [intersectionBox, measureBox, elementBox],
  )

  useSetBox(id, placement, elementBox)

  const cnPortal = cn(s.portal, { [s.interactive]: placement !== 'filled' })

  return (
    <>
      <div className={s.measure} ref={measureRef} />
      {rootRef.current &&
        createPortal(
          <div className={cnPortal} ref={elementRef} style={portalStyle}>
            {children}
          </div>,
          rootRef.current,
        )}
    </>
  )
}

const AbsolutePortal: React.FC<IBlockAbsolutePortalProps> = ({ children, ...rest }) => {
  let isEmptyChildren = true
  React.Children.forEach(children, (child) => {
    if (child) {
      isEmptyChildren = false
    }
  })
  if (isEmptyChildren) {
    return null
  }
  return <AbsolutePortalComponent {...rest}>{children}</AbsolutePortalComponent>
}

export default AbsolutePortal
