import React from 'react'

import ControlItem from 'components/editor-v3/cource/layout/controls/ControlItem'
import EditableProMode from 'components/editor-v3/cource/layout/controls/ElementControls/EditableProMode'
import Separator from 'components/editor-v3/cource/layout/controls/Separator/Separator'
import { EditorMode } from 'services/Store/Project/enums'
import { t } from 'services/Translation'

import { SplitterEditorActionsType } from './SplitterElement.types'

const ACTIONS = {
  SHOW_LEFT: {
    icon: 'builderSwitchRight',
    label: t('uiKit.tooltip.splitterOpenLeft'),
    value: 'SHOW_LEFT',
  },
  SHOW_RIGHT: {
    icon: 'builderSwitchLeft',
    label: t('uiKit.tooltip.splitterOpenRight'),
    value: 'SHOW_RIGHT',
  },
} as const

const SplitterEditorActions: SplitterEditorActionsType = ({
  state,
  mode,
  customMode,
  setState,
  setCustomMode,
}) => {
  const setLeft = () => setState({ fill: state?.fill === 'left' ? null : 'left' })
  const setRight = () => setState({ fill: state?.fill === 'right' ? null : 'right' })

  return (
    <>
      {mode.editorMode === EditorMode.pro && (
        <>
          <EditableProMode customMode={customMode} mode={mode} setCustomMode={setCustomMode} />
          <Separator />
        </>
      )}
      <ControlItem active={state?.fill === 'left'} handler={setLeft} option={ACTIONS.SHOW_LEFT} />
      <ControlItem
        active={state?.fill === 'right'}
        handler={setRight}
        option={ACTIONS.SHOW_RIGHT}
      />
    </>
  )
}

export default SplitterEditorActions
