import { ListSchemaType } from '@leenda/editor/lib/brand'
import { EditorElement, ListElementValue, ListItem } from '@leenda/editor/lib/elements'
import { generateId } from '@leenda/editor/lib/utils/id'
import { textToRtValue } from '@leenda/rich-text'
import lodash from 'lodash'

import { genDataField } from 'components/controls/Field'
import { IOnChangeData } from 'components/controls/Field/Field.types'
import RealtimeField from 'components/controls/RealtimeField'
import Padding from 'components/editor-v3/Editor/Toolbar/Padding'
import { Block } from 'services/Store/Project/types'
import { t } from 'services/Translation'
import { chainName, getParent } from 'utils/form'

import { ListValueFormType } from './ListElement.types'

type ListElement = EditorElement<ListElementValue, ListSchemaType>

const onAddNumberedItem = (changeData: IOnChangeData<unknown>) => {
  const dataValue = lodash.clone(changeData.value) as ListItem[]
  const lastElement = lodash.last(dataValue)
  const newItem = {
    value: generateId(),
    label: textToRtValue(t('input.label.description')),
    number: 1,
    icon: null,
  }
  if (dataValue.length >= 1 && lastElement?.number) {
    newItem.number = lastElement.number + 1
  }
  dataValue.push(newItem)
  return dataValue
}

const onCloneNumberedItem = (changeData: IOnChangeData<unknown>) => {
  const dataValue = lodash.clone(changeData.value) as ListItem[]
  const item = changeData.meta?.item as ListItem
  const newItem = {
    ...item,
    value: generateId(),
  }
  dataValue.push(newItem)
  return dataValue
}

const genField = genDataField<Block>()

const mode = genField({
  name: 'mode',
  type: 'select',
  label: t('elements.list.mode.label'),
  layout: 'horizontal',
  params: {
    options: [
      { value: 'number', label: t('input.option.number') },
      { value: 'point', label: t('input.option.point') },
      { value: 'images', label: t('input.option.images') },
    ],
    fluid: true,
  },
})

const number = genField({
  name: 'number',
  type: 'number',
  rules: [
    { min: 0, max: 99, message: t('input.error.limitNumber', { number: 99 }) },
    {
      validator: (_, value) => {
        if (value === null || value === undefined) {
          return t('input.error.numberRange', { from: 0, to: 99 })
        }
        return false
      },
    },
  ],
  params: (block, { name }) => {
    const { parent } = getParent<ListElement>(name, block, 3)
    return {
      hidden: parent?.value.mode !== 'number',
    }
  },
})

const icon = genField({
  name: 'icon',
  layout: 'horizontal',
  type: 'file',
  params: (block, { name }) => {
    const { parent } = getParent<ListElement>(name, block, 3)
    return { hidden: parent && parent.value.mode !== 'images', fileType: 'image', nullable: true }
  },
})

const items = genField({
  name: 'items',
  type: 'array',
  layout: 'horizontal',
  defaultValue: {
    number: 1,
    icon: null,
  },
  params: {
    styleType: 'clear',
    onAdd: onAddNumberedItem,
    onClone: onCloneNumberedItem,
    ordering: true,
    itemLabel: t('elements.accordion.form.component'),
  },
  fields: [number, icon],
})

const ListElementForm: ListValueFormType = ({ name }) => (
  <Padding sides>
    <RealtimeField config={chainName(name, mode)} />
    <RealtimeField config={chainName(name, items)} />
  </Padding>
)

export default ListElementForm
