import { LinkElementValue } from '@leenda/editor/lib/elements'

import { genDataField } from 'components/controls/Field'
import Crop from 'components/controls/customComponents/Crop'
import { Block } from 'services/Store/Project/types'
import { t } from 'services/Translation'
import { getParent } from 'utils/form'

import { labelFromUrl } from './helper'

const genField = genDataField<Block>()
export const linkUrl = genField({
  name: 'linkURL',
  label: t('input.label.linkAddress'),
  type: 'text',
  params: { placeholder: t('input.placeholder.enterUrl') },
  layout: 'vertical',
  rules: [
    {
      max: 2048,
      message: t('input.error.invalidUrl'),
    },
  ],
})

export const linkLabel = genField({
  name: 'linkLabel',
  label: t('elements.link.form.linkName'),
  type: 'text',
  params: (block, { name }) => {
    const { parent } = getParent<LinkElementValue>(name, block)
    return {
      placeholder: labelFromUrl(parent?.linkURL) || t('elements.link.placeholder.linkText'),
    }
  },
  layout: 'vertical',
  rules: [{ max: 256 }],
})

export const showPreview = genField({
  name: 'showPreview',
  type: 'segmented',
  label: t('elements.link.form.preview'),
  layout: 'horizontal',
})

export const title = genField({
  name: 'title',
  label: t('input.label.title'),
  type: 'text',
  layout: 'vertical',
  rules: [{ max: 256 }],
  params: (block, { name }) => {
    const { parent } = getParent<LinkElementValue>(name, block)
    return {
      hidden: !parent?.showPreview,
      placeholder: t('elements.link.placeholder.title'),
    }
  },
})

export const description = genField({
  name: 'description',
  label: t('input.label.description'),
  type: 'textarea',
  layout: 'vertical',
  rules: [{ max: 1080 }],
  params: (block, { name }) => {
    const { parent } = getParent<LinkElementValue>(name, block)
    return {
      hidden: !parent?.showPreview,
      placeholder: t('elements.link.placeholder.description'),
    }
  },
})

export const showCustomImage = genField({
  name: 'showCustomImage',
  type: 'segmented',
  label: t('elements.link.form.upload'),
  layout: 'horizontal',
  params: (block, { name }) => {
    const { parent } = getParent<LinkElementValue>(name, block)
    return {
      hidden: !parent?.showPreview,
    }
  },
})

export const image = genField({
  name: 'image',
  type: 'file',
  layout: 'horizontal',
  params: (block, { name }) => {
    const { parent: value } = getParent<LinkElementValue>(name, block)
    return {
      hidden: !value?.showCustomImage || !value?.showPreview,
      fileType: 'image',
      nullable: true,
    }
  },
})

export const crop = genField({
  name: 'image',
  type: 'custom',
  label: t('input.label.crop'),
  component: Crop,
  layout: 'horizontal',
  params: (block, { name }) => {
    const { parent } = getParent<LinkElementValue>(name, block)
    return {
      hidden: !parent?.showCustomImage || !parent?.showPreview || !parent?.image,
    }
  },
})
