import { SCHEMA } from '@leenda/editor/lib/brand'

import { genField } from 'components/controls/Field'
import { genFormSchema } from 'services/Branding/genFormSchema'
import { t } from 'services/Translation'

export const STYLE_FORM_SCHEMA = genFormSchema(SCHEMA.testHotspot.style, {
  image: {
    META: { label: t('elements.testHotspot.tags.image') },
    borderRadius: {},
    borderStyle: {
      label: t('input.label.showBorder'),
    },
    borderColor: {
      label: t('input.label.colorBorder'),
    },
    borderWidth: { params: { min: 1, max: 8 }, label: t('input.label.borderThickness') },
  },
  area: {
    META: { label: t('elements.testHotspot.tags.area') },
    backgroundColor: {
      label: t('input.label.colorBackground'),
    },
    borderColor: {
      label: t('input.label.colorBorder'),
    },
  },
  spot: {
    META: { label: t('elements.testHotspot.tags.spot') },
    backgroundImage: { label: t('input.label.spotImage') },
    backgroundColor: {},
    color: { label: t('input.label.hover') },
    height: { params: { min: 0 } },
    width: { params: { min: 0 } },
  },
  correct: {
    META: { label: t('elements.testHotspot.tags.correct') },
    backgroundColor: { label: t('input.label.colorBackground') },
    backgroundImage: { label: t('input.label.correct') },
  },
  incorrect: {
    META: { label: t('elements.testHotspot.tags.incorrect') },
    backgroundColor: { label: t('input.label.colorBackground') },
    backgroundImage: { label: t('input.label.incorrect') },
  },
  animation: {
    pulse: genField({
      type: 'segmented',
      layout: 'horizontal',
      label: t('input.label.spotPulse'),
      params: { fluid: false },
    }),
  },
})
