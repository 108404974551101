import { AudioFontSchemaType, VideoFontSchemaType } from '@leenda/editor/lib/brand'
import { TimePoint } from '@leenda/editor/lib/elements'
import React, { useMemo, useRef } from 'react'

import Icon from 'components/uiKit/Icon'
import { KitSize } from 'components/uiKit/KitTypes'
import { ElementFontCss } from 'services/Branding/types'
import { videoFormat } from 'utils/editorUtils'
import { testProps } from 'utils/test/qaData'

import {
  setVolume,
  togglePlay,
  setPlayed,
  startSeeking,
  seekTo,
  toggleFullScreen,
  setPlaybackRate,
} from '../store/actions'
import { IState, Actions } from '../store/types'
import { MediaStyles } from '../types'
import s from './Controls.module.scss'
import PlaybackRateSelect from './PlaybackRateSelect'
import SeekSlider from './SeekSlider'
import TimePoints from './TimePoints'
import VolumeSlider from './VolumeSlider'

interface IControlsProps {
  state: IState
  dispatch: React.Dispatch<Actions>
  styles?: Partial<Record<MediaStyles, React.CSSProperties>>
  fonts?: ElementFontCss<AudioFontSchemaType | VideoFontSchemaType>
  type: 'audio' | 'video'
  timePoints?: TimePoint[]
  isEditor?: boolean
  volumeLabel: string
  handleFullScreenIos?: () => void
}

const Controls: React.FC<IControlsProps> = ({
  state,
  dispatch,
  styles,
  type,
  timePoints,
  isEditor,
  volumeLabel,
  handleFullScreenIos,
  fonts,
}) => {
  const toolTipRef = useRef<HTMLDivElement>(null)

  const { playing, played, playedSeconds, duration, playbackRate, volume, loaded, fullscreen } =
    state

  const points = useMemo(
    () => timePoints?.filter((item) => item.time <= duration).sort((a, b) => a.time - b.time) || [],
    [duration, timePoints],
  )

  const currentPoint = useMemo(
    () =>
      points
        .slice()
        .reverse()
        .find(({ time }) => time <= (playedSeconds || 0)),
    [points, playedSeconds],
  )

  const container = fullscreen ? () => toolTipRef.current || document.body : () => document.body

  const handlePlayPause = () => dispatch(togglePlay())

  const handleStartSeeking = () => dispatch(startSeeking())
  const handleSetPlayed = (seconds: number) => dispatch(setPlayed(seconds))
  const handleSeekTo = (seconds: number) => dispatch(seekTo(seconds))

  const onVolumeChange = (volume: number) => dispatch(setVolume(volume))
  const onPlaybackRateChange = (playbackRate: number) => dispatch(setPlaybackRate(playbackRate))
  const handleClickFullscreen = () => {
    handleFullScreenIos?.()
    dispatch(toggleFullScreen())
  }

  const testAttributes = isEditor
    ? {
        play: testProps({ el: 'play', playing }),
        pointsControl: testProps({ el: 'pointsControl', value: timePoints }),
      }
    : undefined

  return (
    <div className={s.root}>
      <div
        className={s.tooltip}
        style={fonts?.tooltip}
        {...testProps({ el: 'tooltipControl', value: currentPoint && currentPoint.title })}
      >
        {currentPoint && currentPoint.title}
      </div>
      <div
        className={s.play}
        onClick={handlePlayPause}
        style={styles?.icons}
        {...testAttributes?.play}
      >
        <Icon name={playing ? 'otherPause' : 'otherPlay'} size={KitSize.M} />
      </div>
      <div className={s.start}>{videoFormat(playedSeconds)}</div>
      <div className={s.seek}>
        <SeekSlider
          duration={duration}
          loaded={loaded}
          onSeekTo={handleSeekTo}
          onSetPlayed={handleSetPlayed}
          onStartSeeking={handleStartSeeking}
          played={played}
          points={points}
          styles={styles}
        />
      </div>
      <div className={s.finish}>{videoFormat(duration)}</div>
      {Boolean(points?.length) && (
        <div
          className={s.points}
          key={fullscreen?.toString()}
          style={styles?.icons}
          {...testAttributes?.pointsControl}
        >
          <TimePoints container={container} dispatch={dispatch} timePoints={points} />
        </div>
      )}
      <div className={s.volume}>
        <VolumeSlider
          label={volumeLabel}
          onChange={onVolumeChange}
          styles={styles}
          value={volume}
        />
      </div>
      <div className={s.rate} key={fullscreen?.toString()} style={styles?.icons}>
        <PlaybackRateSelect
          container={container}
          onChange={onPlaybackRateChange}
          value={playbackRate}
        />
      </div>
      {type === 'video' && (
        <div className={s.fullscreen} onClick={handleClickFullscreen} style={styles?.icons}>
          <Icon name={fullscreen ? 'otherSmallScreen' : 'otherFullScreen'} />
        </div>
      )}
      <div ref={toolTipRef} />
    </div>
  )
}

export default Controls
