import cn from 'classnames'
import React, { useCallback, useMemo } from 'react'

import Dropdown, { Tooltip } from 'components/uiKit/Dropdown'
import { KitSize } from 'components/uiKit/KitTypes'

import Employee, { AccountMock } from '..'
import { EmployeeType } from '../Employee'
import EmployeeAvatar from '../EmployeeAvatar'
import sAvatar from '../EmployeeAvatar/EmployeeAvatar.module.scss'
import s from './EmployeesGroup.module.scss'

export interface IEmployeesGroupProps {
  employees?: AccountMock[]
  size?: KitSize
  maxCount?: number
  styleType?: 'default' | 'shifted' | 'mentioned' | 'editorHeader'
  onSelect?: (employee: AccountMock) => void
  disabled?: boolean
  activeEmployeeId?: string | null
  type?: EmployeeType
}

const EmployeesGroup: React.FC<IEmployeesGroupProps> = ({
  employees = [],
  size = KitSize.M,
  styleType = 'default',
  maxCount = 3,
  onSelect,
  disabled = false,
  activeEmployeeId = null,
  type = 'interactive',
}) => {
  const isOverflow = employees.length > maxCount
  const isOverflowInfo = employees.length >= maxCount
  const cnRoot = cn(s[size], s[styleType])
  const cnDefaultOverflow = cn(s.overflow, sAvatar.root, sAvatar[size])
  const cnMentionOverflow = cn(s.mentionOverflow)

  const { showed, hidden } = useMemo(() => {
    if (styleType === 'mentioned') {
      return { showed: employees.slice(0, maxCount), hidden: employees.slice(maxCount) }
    }
    if (isOverflowInfo && type === 'info') {
      return { showed: employees.slice(0, maxCount - 1), hidden: employees.slice(maxCount - 1) }
    }
    if (isOverflow) {
      return { showed: employees.slice(0, maxCount - 1), hidden: employees.slice(maxCount - 1) }
    }
    if (employees.length === maxCount) {
      return { showed: employees.slice(0, maxCount), hidden: employees.slice(maxCount) }
    }
    return { showed: employees, hidden: [] }
  }, [employees, maxCount])

  const onEmployeeAvatarClick = useCallback(
    (employee: AccountMock) =>
      type === 'interactive' && employees.length === 1 ? () => onSelect?.(employee) : undefined,
    [employees.length, onSelect, type],
  )

  if (showed.length === 0) {
    return null
  }

  if (styleType === 'mentioned') {
    return (
      <div className={cnRoot}>
        {showed.map((employee) => (
          <Employee employee={employee} key={employee.id} size={size} styleType='mention' />
        ))}
        {isOverflow && (
          <Tooltip
            overlay={
              <div className={s.overlayContainer}>
                {hidden.map((employee) => (
                  <Employee employee={employee} key={employee.id} size={KitSize.S} />
                ))}
              </div>
            }
            placement='bottomRight'
            theme='light'
          >
            <span className={cnMentionOverflow}>{employees.length - maxCount}</span>
          </Tooltip>
        )}
      </div>
    )
  }
  if (styleType === 'editorHeader') {
    return (
      <div className={cnRoot}>
        {showed.map((employee) => (
          <Employee
            active={activeEmployeeId === employee.id}
            disabled={disabled}
            employee={employee}
            key={employee.id}
            onClick={onSelect}
            size={size}
            styleType='avatar'
          />
        ))}
        {isOverflow && (
          <Tooltip
            overlay={
              <div className={s.overlayContainer}>
                <div className={s.overflowList}>
                  {hidden.map((employee) => (
                    <Employee
                      active={activeEmployeeId === employee.id}
                      disabled={disabled}
                      employee={employee}
                      key={employee.id}
                      onClick={onSelect}
                    />
                  ))}
                </div>
              </div>
            }
            placement='bottomRight'
            theme='light'
          >
            <span className={cnDefaultOverflow}>{employees.length - maxCount + 1}</span>
          </Tooltip>
        )}
      </div>
    )
  }

  if (styleType === 'shifted') {
    const ShiftedShowedEmployeesComponent = (
      <div className={cnRoot}>
        {showed.map((employee) => (
          <EmployeeAvatar
            employee={employee}
            key={employee.id}
            onClick={onEmployeeAvatarClick(employee)}
            size={size}
            tooltip={
              <Employee
                disabled={disabled}
                employee={employee}
                onClick={onSelect}
                styleType='name'
                type={type}
              />
            }
            type={type}
          />
        ))}
        {isOverflowInfo && (
          <span className={cn(s.overflowShifted, s[size])}>+{employees.length - maxCount + 1}</span>
        )}
      </div>
    )
    if (employees.length === 1 || type === 'info') {
      return ShiftedShowedEmployeesComponent
    } else {
      return (
        <Dropdown
          overlay={
            <div className={cn(s.overlayShifted, s.overlayContainer)}>
              {employees.map((employee) => (
                <div key={employee.id} style={{ marginBottom: 12 }}>
                  <Employee
                    disabled={disabled}
                    employee={employee}
                    key={employee.id}
                    onClick={onSelect}
                    size={KitSize.S}
                    type={type}
                  />
                </div>
              ))}
            </div>
          }
          placement='bottomRight'
          styleType='clear'
          theme='light'
          trigger={['click']}
        >
          {ShiftedShowedEmployeesComponent}
        </Dropdown>
      )
    }
  }

  return (
    <div className={cnRoot}>
      {showed.map((employee) => (
        <EmployeeAvatar
          employee={employee}
          key={employee.id}
          size={size}
          tooltip={
            <Employee disabled={disabled} employee={employee} onClick={onSelect} styleType='name' />
          }
        />
      ))}
      {isOverflow && (
        <Tooltip
          overlay={
            <div className={s.overlayContainer}>
              {hidden.map((employee) => (
                <Employee
                  disabled={disabled}
                  employee={employee}
                  key={employee.id}
                  onClick={onSelect}
                  size={KitSize.S}
                />
              ))}
            </div>
          }
          placement='bottomRight'
          theme='light'
        >
          <span className={cnDefaultOverflow}>{employees.length - maxCount + 1}</span>
        </Tooltip>
      )}
    </div>
  )
}

export default EmployeesGroup
