import { FileImageUsage } from '@leenda/editor/lib/files'
import lodash from 'lodash'
import React from 'react'
import 'react-image-crop/dist/ReactCrop.css'

import { IControlProps } from 'components/controls/Field/Field.types'
import { IconButton } from 'components/uiKit/Button'
import { Tooltip } from 'components/uiKit/Dropdown'
import { KitSize } from 'components/uiKit/KitTypes'
import { useOpenModal } from 'components/uiKit/Modal'
import { t } from 'services/Translation'
import { getImageByUsage } from 'utils/files'

import s from './Crop.module.scss'
import CropModal from './CropModal'

const Crop: React.FC<IControlProps<{ value: Omit<FileImageUsage, 'type'> }>> = ({
  name,
  value,
  onChange,
  onFocus,
  onBlur,
}) => {
  const { id, params } = value || {}
  const { crop, round } = params || {}

  const { open } = useOpenModal(CropModal, { image: value })
  const imageFile = getImageByUsage(value)
  const canCrop = Boolean(imageFile?.originalFile?.ext.match(/(jpg|jpeg|png)$/))

  const onReset = () => onChange({ name, value: { id } })

  const openCropModal = async () => {
    onFocus?.()
    const result = await open({})
    if (result) {
      const value = { id, params: lodash.cloneDeep(lodash.omitBy(result, lodash.isUndefined)) }
      onChange({ name, value })
    }
    onBlur?.()
  }

  return (
    <div className={s.root}>
      <div className={s.reset}>
        <Tooltip overlay={t('uiKit.tooltip.reset')}>
          <IconButton
            disabled={!Boolean(crop || round)}
            icon='otherUndo'
            name={`undo-${name}`}
            onClick={onReset}
            size={KitSize.S}
            styleType='secondary'
            type='button'
          />
        </Tooltip>
      </div>
      <Tooltip
        overlay={canCrop ? t('uiKit.tooltip.crop') : t('uiKit.tooltip.cropDisabled')}
        placement='bottomRight'
      >
        <IconButton
          disabled={!canCrop}
          icon='otherCrop'
          name={`${name}.openCropModal`}
          onClick={openCropModal}
          size={KitSize.S}
          styleType='secondary'
          type='button'
        />
      </Tooltip>
    </div>
  )
}

export default Crop
