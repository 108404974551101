import cn from 'classnames'
import React, { ReactNode } from 'react'

import { testProps } from 'utils/test/qaData'

import Tooltip from '../Dropdown/Tooltip'
import s from './Typography.module.scss'

interface ITypography {
  styleType?: 'link' | 'text' | 'hint' | 'h2' | 'h3' | 'h4'
  children: ReactNode
  rows?: number
  name?: string
  tooltip?: React.ReactNode
  fluid?: boolean
  wordBreak?: React.CSSProperties['wordBreak']
}

const Typography: React.FC<ITypography> = ({
  styleType,
  children,
  rows,
  name,
  tooltip,
  fluid,
  wordBreak = 'break-all',
}) => {
  return (
    <Tooltip fluid={fluid} mouseEnterDelay={1} overlay={tooltip}>
      <span
        {...testProps({ el: 'typography', name })}
        className={cn(s.root, styleType && s[styleType])}
        style={{ ...(rows && { WebkitLineClamp: rows }), wordBreak }}
      >
        {children}
      </span>
    </Tooltip>
  )
}
export default Typography
