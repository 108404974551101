import { SlateElementType, SlateMark } from '@leenda/rich-text'
import cn from 'classnames'
import { hasActiveAiConfig, leftAiTokens } from 'entities/AiConfig/helpers'
import { useContext, useMemo, useState } from 'react'

import Button, { IconButton } from 'components/uiKit/Button'
import Checkbox from 'components/uiKit/Checkbox'
import ColorPicker from 'components/uiKit/ColorPicker'
import Dropdown, { Tooltip } from 'components/uiKit/Dropdown'
import DropdownMenu from 'components/uiKit/DropdownMenu'
import Icon from 'components/uiKit/Icon'
import { KitSize, ValueType } from 'components/uiKit/KitTypes'
import { IMenuOption } from 'components/uiKit/Menu'
import Select from 'components/uiKit/Select'
import { PROJECT_PATHS } from 'constants/paths'
import { AiTextPromptTogglesEnum } from 'gql/__generated__/graphql'
import { useMyInfo } from 'gql/actions.helpers'
import { usePathParams } from 'routes/hooks'
import { genColorOptions } from 'services/Branding/constants/fields'
import { useBrandTheme } from 'services/Branding/hooks'
import { t } from 'services/Translation'

import { RichTextContext } from '../../richText.context'
import HideControl from './HideControl'
import s from './Toolbar.module.scss'
import AiForm from './components/AiForm'
import AnnotationForm from './components/AnnotationForm'
import CheckboxTool from './components/CheckboxTool'
import CrossLinkForm from './components/CrossLinkForm'
import LinkForm from './components/LinkForm'
import OtherSettings from './components/OtherSettings/OtherSettings'
import SymbolItem from './components/SymbolItem'
import { AI_OPTIONS, marks, symbols, TEXT_TYPE_OPTIONS } from './toolbarOptions'

const MAX_AI_TEXT_LENGTH = 2000

const Toolbar: React.FC = () => {
  const { companyId } = usePathParams(PROJECT_PATHS.editor)
  const theme = useBrandTheme()
  const colorOptions = useMemo(() => genColorOptions(theme), [theme])
  const [preprompt, setPreprompt] = useState<AiTextPromptTogglesEnum>()
  const { profile } = useMyInfo(companyId)
  const company = profile?.company
  const selection = window.getSelection()
  const toMatchSelected = (selection?.getRangeAt(0).toString().length || 0) > MAX_AI_TEXT_LENGTH
  const disabledAi = leftAiTokens(company?.aiConfigs) <= 0
  const hasAi = hasActiveAiConfig(company?.aiConfigs)
  const overlayMessage = useMemo(() => {
    if (disabledAi) {
      return t('elements.richText.ai.tooltip')
    }

    return t('elements.richText.ai.toMatchSelected')
  }, [disabledAi])

  const { onUpdateFormat, value, controls, setToolbarForm, toolbarForm, styles } =
    useContext(RichTextContext)

  const textTypeValue =
    TEXT_TYPE_OPTIONS.find((item) => value[item.value])?.value || SlateElementType.elementDefault

  const onLinkClick = () => {
    setToolbarForm(SlateElementType.link)
  }

  const onCrossLinkClick = () => {
    setToolbarForm(SlateElementType.crossLink)
  }

  const onAnnotationClick = () => {
    setToolbarForm(SlateElementType.annotation)
  }

  const onAiClick = (item: IMenuOption<ValueType>) => {
    setToolbarForm('ai')
    setPreprompt(item.value as AiTextPromptTogglesEnum)
  }

  if (toolbarForm === SlateElementType.link) {
    return (
      <div className={s.root}>
        <LinkForm
          editorValue={value}
          onClose={() => setToolbarForm(null)}
          onUpdateFormat={onUpdateFormat as any}
        />
      </div>
    )
  }

  if (toolbarForm === SlateElementType.crossLink) {
    return (
      <div className={s.root}>
        <CrossLinkForm
          editorValue={value}
          onClose={() => setToolbarForm(null)}
          onUpdateFormat={onUpdateFormat as any}
        />
      </div>
    )
  }

  if (toolbarForm === SlateElementType.annotation) {
    return (
      <div className={cn(s.root, s.fluidHeight)}>
        <AnnotationForm
          editorValue={value}
          onClose={() => setToolbarForm(null)}
          onUpdateFormat={onUpdateFormat as any}
          styles={styles}
        />
      </div>
    )
  }

  if (toolbarForm === 'ai') {
    return (
      <div className={cn(s.root, s.fluidHeight, s.aiForm)}>
        <AiForm
          onClose={() => setToolbarForm(null)}
          prepropmpt={preprompt || AiTextPromptTogglesEnum.CUSTOM}
          styles={styles}
        />
      </div>
    )
  }

  return (
    <div className={s.root}>
      <HideControl enabled={controls} name='blockElement'>
        <Select
          name='textType'
          onChange={(value) => onUpdateFormat(value)}
          options={TEXT_TYPE_OPTIONS}
          size={KitSize.S}
          styleType='ghost'
          value={textTypeValue}
        />
        <div className={s.delimiter} />
      </HideControl>
      <HideControl enabled={controls} name={['color', 'backgroundColor']}>
        <div className={cn(s.groupControl, s.color)}>
          <HideControl enabled={controls} name='color'>
            <ColorPicker
              icon='otherFont'
              name={SlateMark.color}
              onChange={(value) => onUpdateFormat(SlateMark.color, value)}
              options={colorOptions}
              size={KitSize.S}
              value={value[SlateMark.color]}
            />
          </HideControl>
          <HideControl enabled={controls} name='backgroundColor'>
            <ColorPicker
              icon='otherBg'
              name={SlateMark.backgroundColor}
              onChange={(value) => onUpdateFormat(SlateMark.backgroundColor, value)}
              options={colorOptions}
              size={KitSize.S}
              value={value[SlateMark.backgroundColor]}
              transparent
            />
          </HideControl>
        </div>
        <div className={s.delimiter} />
      </HideControl>

      <HideControl enabled={controls} name={marks}>
        <div className={s.groupControl}>
          {marks.map((mark) => (
            <HideControl enabled={controls} key={mark} name={mark}>
              <CheckboxTool name={mark} />
            </HideControl>
          ))}
        </div>
        <div className={s.delimiter} />
      </HideControl>

      <HideControl enabled={controls} name={['link', 'crossLink']}>
        <div className={s.groupControl}>
          <HideControl enabled={controls} name='link'>
            <Checkbox
              name='link'
              onChange={onLinkClick}
              value={value.inline?.type == SlateElementType.link}
              hideCheckbox
            >
              <Icon name='link2' size={KitSize.S} />
            </Checkbox>
          </HideControl>
          <HideControl enabled={controls} name='crossLink'>
            <Tooltip overlay={t('uiKit.tooltip.crossLink')} trigger={['hoverWithoutContent']}>
              <Checkbox
                name='crossLink'
                onChange={onCrossLinkClick}
                value={value.inline?.type == SlateElementType.crossLink}
                hideCheckbox
              >
                <Icon name='interfaceEssentialAnchor' size={KitSize.S} />
              </Checkbox>
            </Tooltip>
          </HideControl>
        </div>
      </HideControl>
      <HideControl enabled={controls} name='code'>
        <CheckboxTool name='code' />
        <div className={s.delimiter} />
      </HideControl>
      <HideControl enabled={controls} name='annotation'>
        <Tooltip overlay={t('uiKit.tooltip.annotation')} trigger={['hoverWithoutContent']}>
          <Checkbox
            name='annotation'
            onChange={onAnnotationClick}
            value={value.inline?.type == SlateElementType.annotation}
            hideCheckbox
          >
            <Icon name='annotation' size={KitSize.S} />
          </Checkbox>
        </Tooltip>
        <div className={s.delimiter} />
      </HideControl>
      <HideControl enabled={controls} name='insertSymbol'>
        <Dropdown
          overlay={
            <div className={s.symbols}>
              {symbols.map((symbol, index) => (
                <SymbolItem key={index} name={symbol} />
              ))}
            </div>
          }
          placement='bottomLeft'
          trigger={['click']}
        >
          <Tooltip overlay={t('uiKit.tooltip.symbols')} trigger={['hoverWithoutContent']}>
            <IconButton icon='builderSymbol' name='symbol' size={KitSize.S} styleType='ghost' />
          </Tooltip>
        </Dropdown>
        <div className={s.delimiter} />
      </HideControl>
      {hasAi && (
        <Tooltip
          disabled={!(disabledAi || toMatchSelected)}
          overlay={overlayMessage}
          placement='topRight'
        >
          <DropdownMenu name='AiSelect' onClick={onAiClick} options={AI_OPTIONS} size={KitSize.S}>
            {({ open }) => (
              <Button
                active={open}
                disabled={disabledAi || toMatchSelected}
                name='aiButton'
                styleType='ghost'
                inheritColor
              >
                <Icon name='builderAi' size={KitSize.S} />
                AI
              </Button>
            )}
          </DropdownMenu>
        </Tooltip>
      )}
      <Dropdown overlay={<OtherSettings />} placement='bottomLeft'>
        <IconButton icon='iconsOtherMore' name='otherMore' size={KitSize.S} styleType='ghost' />
      </Dropdown>
    </div>
  )
}

export default Toolbar
