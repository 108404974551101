import { SCHEMA } from '@leenda/editor/lib/brand'

import { genFormSchema, FONT_SCHEMA_FIELDS } from 'services/Branding/genFormSchema'
import { t } from 'services/Translation'

export const STYLE_FORM_SCHEMA = genFormSchema(SCHEMA.tabs.style, {
  content: {
    META: { label: t('elements.tabs.tags.content') },
    backgroundColor: {},
    padding: {},
  },
  tabs__var: {
    META: { label: t('elements.tabs.tags.tabs') },
    backgroundColor: {},
    padding: {},
  },
  activeTab__var: {
    META: { label: t('elements.tabs.tags.activeTab') },
    backgroundColor: {},
    color: {},
  },
  border__var: {
    META: { label: t('elements.tabs.tags.border') },
    borderStyle: {},
    borderColor: {},
    borderWidth: { params: { max: 8 } },
    borderRadius: {},
  },
  splitter__var: {
    META: { label: t('elements.tabs.tags.splitter') },
    borderStyle: {},
    borderColor: {},
    borderWidth: { params: { max: 8 } },
  },
  iconLeft: {
    META: { label: t('elements.tabs.tags.iconLeft') },
    backgroundImage: { label: t('elements.tabs.schema.size') },
  },
  iconRight: {
    META: { label: t('elements.tabs.tags.iconRight') },
    backgroundImage: { label: t('elements.tabs.schema.size') },
  },
})

export const FONT_FORM_SCHEMA = genFormSchema(SCHEMA.tabs.font, {
  base: {
    ...FONT_SCHEMA_FIELDS.base,
  },
})
