import cn from 'classnames'
import { AnimationProps, motion } from 'framer-motion'
import React, { useCallback, useMemo } from 'react'

import { useOnClickBlock } from 'components/editor-v3/context/EditorContext/actions'
import { getRootNode } from 'components/editor-v3/context/EditorContext/selectors/block'
import NodeContainer from 'components/editor-v3/cource/layout/Node/NodeContainer'
import Icon from 'components/uiKit/Icon'
import { scrollProps } from 'services/Scroll/ScrollService'
import { setProjectNavigation, setToolbar } from 'services/Store/Project/actions'
import {
  CommentsTab,
  DeviceMode,
  EditorMode,
  SectionTypeEnum,
  TasksTab,
} from 'services/Store/Project/enums'
import { useProjectContext, useProjectDispatch } from 'services/Store/Project/hooks'
import {
  getBlockWaiting,
  getIsActiveBlock,
  getIsCommentedBlock,
  getIsSelectedBlock,
  getIsTaskedBlock,
  getSection,
  getSettings,
  getBlock,
  getBlockId,
  getSelectedBlocksCount,
} from 'services/Store/Project/selectors'
import { Block, IBlockMode } from 'services/Store/Project/types'
import { testProps } from 'utils/test/qaData'

import BlockControls from '../../controls/BlockControls/BlockControls'
import s from './EditorBlock.module.scss'

interface IEditorBlockProps {
  block: Block
  mode: IBlockMode
  index: number
}

const EditorBlock: React.FC<IEditorBlockProps> = ({ block, mode, index }) => {
  const ref = React.useRef<HTMLDivElement>(null)
  const dispatch = useProjectDispatch()
  const root = getRootNode(block)
  const id = block.uuid
  const maxWidth = root?.style?.[DeviceMode.desktop]?.maxWidth
  const settings = useProjectContext(getSettings)
  const isFill = mode.editorMode === EditorMode.fill
  const isPro = mode.editorMode === EditorMode.pro
  const isActive = useProjectContext(getIsActiveBlock, id)
  const currBlock = useProjectContext(getBlock)
  const isSelected = useProjectContext(getIsSelectedBlock, id)
  const selectedCount = useProjectContext(getSelectedBlocksCount)
  const currentBlockId = useProjectContext(getBlockId)
  const isWaiting = useProjectContext(getBlockWaiting, id)
  const section = useProjectContext(getSection)
  const isLanding = section?.type === SectionTypeEnum.landing
  const firstComment = useProjectContext(getIsCommentedBlock, id)
  const firstTask = useProjectContext(getIsTaskedBlock, id)
  const animate: AnimationProps['animate'] = useMemo(() => {
    if (isPro && !isActive && currBlock?.uuid) {
      return { opacity: 0.05 }
    }
    return { opacity: 1 }
  }, [currBlock?.uuid, isActive, isPro])

  const currentMode = useMemo(
    () => (isPro && isActive ? mode : { ...mode, editorMode: EditorMode.fill }),
    [mode, isActive, isPro],
  )

  const setBlock = useOnClickBlock()

  const handleSetBlock = useCallback(
    (e: React.MouseEvent) => {
      e.stopPropagation()
      setBlock(e, { blockId: id, previewMode: mode.previewMode })
    },
    [setBlock, id, mode.previewMode],
  )

  const handleCommentClick = useCallback(
    (e: React.MouseEvent) => {
      e.stopPropagation()
      dispatch(setToolbar({ commentTab: CommentsTab.section, taskTab: null, hidden: false }))
      dispatch(setProjectNavigation({ blockId: id, commentId: firstComment?.id }))
    },
    [dispatch, id, firstComment],
  )

  const handleTaskClick = useCallback(
    (e: React.MouseEvent) => {
      e.stopPropagation()
      dispatch(setToolbar({ taskTab: TasksTab.current, commentTab: null, hidden: false }))
      dispatch(setProjectNavigation({ blockId: id, taskId: firstTask?.id }))
    },
    [dispatch, id, firstTask],
  )

  const active = isFill && isLanding && (isSelected || isActive)

  const cnRoot = cn(s.root, {
    [s.active]: active,
    [s.waiting]: isWaiting,
  })
  const cnBlock = cn(s.block, {
    [s.divider]: !active && isFill && settings.divider,
    [s.active]: active,
  })

  return (
    <div {...scrollProps(id)} className={s.wrapper} ref={ref}>
      <motion.div
        {...testProps({ el: 'editorBlock', index, id, name: block.name, active: isActive })}
        animate={animate}
        className={cnRoot}
        onMouseDown={handleSetBlock}
        style={currentBlockId && isPro && !isActive ? { pointerEvents: 'none' } : {}}
        transition={{ duration: 0.3, ease: 'linear' }}
      >
        {Boolean(firstComment) && isFill && isLanding && (
          <div className={cn(s.icons, s.comment)} onMouseDown={handleCommentClick}>
            <Icon name='messagesChatRegular' />
          </div>
        )}
        {Boolean(firstTask) && isFill && isLanding && (
          <div className={cn(s.icons, s.task)} onMouseDown={handleTaskClick}>
            <Icon name='checkmarkCircle1' />
          </div>
        )}
        <div className={cnBlock} style={{ maxWidth }}>
          <NodeContainer block={block} id={root.id} mode={currentMode} />
          {isActive && selectedCount === 1 && isFill && <BlockControls id={id} index={index} />}
        </div>
      </motion.div>
    </div>
  )
}

export default React.memo(EditorBlock)
