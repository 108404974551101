import Button from 'components/uiKit/Button'
import Icon from 'components/uiKit/Icon'
import { KitSize } from 'components/uiKit/KitTypes'
import { t } from 'services/Translation'

type RichTextRespawnButtonProps = {
  onClick: () => void
}

const RespawnButton = ({ onClick }: RichTextRespawnButtonProps) => (
  <Button name='editor__element_respawn-button' onClick={onClick} styleType='secondary'>
    <Icon name='otherRefresh' size={KitSize.S} />
    {t('uiKit.button.reset')}
  </Button>
)

export { RespawnButton }
