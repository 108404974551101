import { CONFIG_COURSE } from '@leenda/editor/lib/elements'
import { useKeyPress } from 'ahooks'
import React, { useEffect } from 'react'

import { LayoutScroll } from 'components/LayoutPage'
import TestProgressFooter from 'components/editor-v3/cource/controls/TestProgressFooter'
import BlockRenderContainer from 'components/editor-v3/cource/layout/Block/BlockContainer'
import { useElementStyleCss } from 'services/Branding/hooks'
import ScrollService from 'services/Scroll/ScrollService'
import { ScrollContainerEnum } from 'services/Scroll/enums'
import { setBlockViewed } from 'services/Store/Project/actions'
import { BlockMode } from 'services/Store/Project/enums'
import { useProjectContext, useProjectDispatch } from 'services/Store/Project/hooks'
import { getBlock } from 'services/Store/Project/selectors'
import { IBlockMode } from 'services/Store/Project/types'

import s from './CourseTestSection.module.scss'

const CourseTestSection: React.FC<{ mode: IBlockMode }> = ({ mode }) => {
  const currentBlock = useProjectContext(getBlock)
  const firstBlock = useProjectContext(getBlock, { first: true })

  const dispatch = useProjectDispatch()

  const block = currentBlock || firstBlock

  useKeyPress(['meta.leftarrow', 'meta.rightarrow', 'alt.leftarrow', 'alt.rightarrow'], (e) => {
    e.preventDefault()
  })

  const isVisibleProgress = !(block?.mode && [BlockMode.start, BlockMode.end].includes(block?.mode))
  const styles = useElementStyleCss(CONFIG_COURSE.testSectionLayout.type, mode.deviceMode)

  useEffect(() => {
    block?.uuid && dispatch(setBlockViewed(block?.uuid))
  }, [block?.uuid])

  return (
    <div className={s.root} style={styles.indents}>
      <LayoutScroll ref={ScrollService.createContainerSetter(ScrollContainerEnum.canvas)}>
        <div className={s.content}>
          {block && <BlockRenderContainer block={block} index={0} mode={mode} />}
        </div>
      </LayoutScroll>
      {isVisibleProgress && (
        <div className={s.footer}>
          <TestProgressFooter mode={mode} />
        </div>
      )}
    </div>
  )
}

export default CourseTestSection
