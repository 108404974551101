import cn from 'classnames'
import React from 'react'

import Icon, { KeyIconsType } from 'components/uiKit/Icon'
import { KitSize } from 'components/uiKit/KitTypes'
import { testPropsEl } from 'utils/test/qaData'

import s from './ColorCircle.module.scss'

interface IProps {
  color: string
  icon?: KeyIconsType
  size?: KitSize
  active?: boolean
  name?: string
  onClick?: () => void
  onBlur?: () => void
  onFocus?: () => void
}

const ColorCircle = ({
  color,
  icon,
  size = KitSize.S,
  active,
  name,
  onClick,
  onBlur,
  onFocus,
}: IProps) => {
  if (icon) {
    return (
      <div
        {...testPropsEl('colorPicker', { name, value: color.slice(1, 7) }, { color })}
        className={cn(s.icon, s[size])}
        onBlur={onBlur}
        onClick={onClick}
        onFocus={onFocus}
        style={{ color }}
      >
        <Icon name={icon} size={KitSize.S} />
      </div>
    )
  }

  return (
    <div
      className={cn(s.root, s[size], { [s.active]: active })}
      onBlur={onBlur}
      onClick={onClick}
      onFocus={onFocus}
      {...testPropsEl('colorPicker', { name, value: color.slice(1, 7) }, { color })}
    >
      <div className={s.alphaBackground}>
        <div className={cn(s.color, s[size])} style={{ backgroundColor: `${color}` }} />
      </div>
    </div>
  )
}

export default ColorCircle
