import { RichTextValue, textToRtValue } from '@leenda/rich-text'
import cn from 'classnames'
import lodash from 'lodash'
import * as R from 'ramda'
import React, { useCallback, useEffect, useState } from 'react'

import { getPath } from 'components/editor-v3/context/EditorContext/selectors/block'
import RichText from 'components/form/RichText/RichText'
import { useGetRichTextProps } from 'components/form/RichText/useGetRichTextProps'
import Icon from 'components/uiKit/Icon'
import { KitSize } from 'components/uiKit/KitTypes'
import { EditorMode, PreviewMode } from 'services/Store/Project/enums'
import { useProjectContext } from 'services/Store/Project/hooks'
import { getIsInteractiveMode, getNodeId } from 'services/Store/Project/selectors'
import { t } from 'services/Translation'

import { useElementCompleted } from '../../hooks/useElementCompleted'
import { AccordionElementType } from './Accordion.types'
import s from './AccordionElement.module.scss'
import Content from './Content'
import PdfView from './PdfView'

export enum Position {
  left = 'left',
  right = 'right',
}

export const transformArrayToString = (arr?: number[]) => arr?.map((num) => num + 'px').join(' ')

const topBorderKeys = ['borderTopStyle', 'borderTopWidth', 'borderTopColor']

const AccordionElement: AccordionElementType = ({
  block,
  element,
  styles,
  mode,
  state,
  waiting,
  setState,
  onChange,
  font,
}) => {
  const { items, multipleExpand = true, showFirstTab = false } = element.value
  const isOpen = useCallback((value: string) => Boolean(state?.open?.[value]), [state?.open])
  const isPreview = useProjectContext(getIsInteractiveMode)
  const isPro = mode.editorMode === EditorMode.pro

  const collapseWrapperStyles = lodash.pick(styles.collapse, topBorderKeys)
  const collapseStyles = lodash.omit(styles.collapse, topBorderKeys)
  const controlledProps: { activeKey?: string } = {}
  const activeId = useProjectContext(getNodeId)

  const { isActiveElement, activeIndex, onLabelSelect, isFill } = useGetRichTextProps({
    elementId: element.id,
    mode,
    block,
  })
  const onChangeLabel = (labelValue: RichTextValue, index: number) => {
    onChange?.(R.assocPath(['items', index, 'label'], labelValue, element.value))
  }

  const [viewedTabs, setViewedTabs] = useState<Record<string, boolean>>(
    items.reduce((acc, item) => ({ ...acc, [item.value]: false }), {}),
  )
  const allViewed = lodash.every(viewedTabs)

  if (activeId) {
    const path = getPath(block!, activeId)
    controlledProps.activeKey = path.find((id) => items.some((item) => id === item.value))
  }

  const toggle = (value: string) => {
    if (multipleExpand) {
      setState?.({ open: { ...state?.open, [value]: !state?.open?.[value] } })
    } else {
      setState?.({ open: { [value]: !state?.open?.[value] } })
    }
    if (!allViewed) {
      setViewedTabs((prev) => ({ ...prev, [value]: true }))
    }
  }

  const handleLabelSelect = (value: string, index: number) => (e: React.MouseEvent) => {
    e.stopPropagation()
    onLabelSelect(index.toString(), e)
    setState?.({ active: value })
  }

  useElementCompleted(block?.uuid || '', element.id, allViewed)

  const iconMargin =
    (styles.icon.position as Position) === Position.left
      ? { marginRight: styles.indents.icon[0] }
      : { marginLeft: styles.indents.icon[1] }

  const isPdf = mode.previewMode === PreviewMode.pdf
  if (isPdf) {
    return (
      <PdfView
        block={block}
        collapseStyles={collapseStyles}
        collapseWrapperStyles={collapseWrapperStyles}
        font={font}
        iconMargin={iconMargin}
        items={items}
        mode={mode}
        styles={styles}
      />
    )
  }

  const closedIcon = styles.icon.backgroundImage ? (
    <div
      className={s.customIcon}
      style={{ backgroundImage: String(styles.icon.backgroundImage) }}
    />
  ) : (
    <Icon
      className={cn(s.icon, {
        [s.left]: (styles.icon?.position as Position) === Position.left,
      })}
      name='iconsForElementsDown'
      size={KitSize.S}
    />
  )

  const openIcon = styles.icon.backgroundOpen ? (
    <div className={s.customIcon} style={{ backgroundImage: String(styles.icon.backgroundOpen) }} />
  ) : (
    <Icon className={cn(s.icon, s.isOpen)} name='iconsForElementsDown' size={KitSize.S} />
  )

  // eslint-disable-next-line react-hooks/rules-of-hooks
  useEffect(() => {
    if (showFirstTab) {
      setState?.({ open: { ...state?.open, [items[0].value]: true } })
      setViewedTabs((prev) => ({ ...prev, [items[0].value]: true }))
    }
  }, [])

  // eslint-disable-next-line react-hooks/rules-of-hooks
  useEffect(() => {
    if (controlledProps.activeKey) {
      setState?.({ open: { ...state?.open, [controlledProps.activeKey]: true } })
    }
  }, [controlledProps.activeKey])

  return (
    <div className={s.root} style={{ gap: styles.indents.gap }}>
      {items.map(({ label, value }, index) => (
        <div
          key={value}
          onMouseDown={() => setState?.({ active: value })}
          style={styles.collapse.borderStyle === 'none' ? collapseWrapperStyles : undefined}
        >
          <div
            className={s.collapse}
            onClick={() => (isPreview || isPro) && toggle(value)}
            style={collapseStyles}
          >
            <div
              className={s.title}
              style={{ ...font.base, padding: transformArrayToString(styles.indents?.accordion) }}
            >
              <div
                className={cn(s.defaultTitle, {
                  [s.right]: (styles.icon?.position as Position) === Position.right,
                })}
              >
                <div
                  className={s.iconRoot}
                  onClick={() => !isPro && !isPreview && toggle(value)}
                  style={{ ...iconMargin }}
                >
                  {isOpen(value) ? openIcon : closedIcon}
                </div>
                <span
                  className={s.defaultTitleContent}
                  onMouseDown={handleLabelSelect(value, index)}
                >
                  <RichText
                    active={isActiveElement && activeIndex === index.toString()}
                    disabled={!isFill}
                    name={`accordion.${index}`}
                    onChange={(val) => onChangeLabel(val, index)}
                    styles={font}
                    toolbarContainerId='rich-text-toolbar'
                    value={label || textToRtValue(t('elements.accordion.form.component'))}
                    waiting={waiting}
                  />
                </span>
              </div>
            </div>
            <Content block={block} isOpen={isOpen} mode={mode} styles={styles} value={value} />
          </div>
        </div>
      ))}
    </div>
  )
}

export default AccordionElement
