import * as R from 'ramda'
import { useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react'
import { useContextSelector } from 'use-context-selector'

import { useProjectContext } from 'services/Store/Project/hooks'
import { getBlock, getDeviceMode, getEditorHighlight } from 'services/Store/Project/selectors'
import { shallowEqual } from 'utils/object'

import AbsolutePortalContext from './AbsolutePortalContext'
import { BoxType, PlacementType } from './types'

export const BOX_KEYS = ['x', 'y', 'width', 'height'] as const
export const GAP = 4

export const useElementBox = (ref: React.RefObject<HTMLElement | null>, deps: unknown[]) => {
  const block = useProjectContext(getBlock)
  const deviceMode = useProjectContext(getDeviceMode)
  const highlighted = useProjectContext(getEditorHighlight)

  const lastValueRef = useRef<BoxType | null>(null)
  const [box, setBox] = useState<BoxType>({ x: 0, y: 0, width: 0, height: 0 })

  useEffect(() => {
    const resizeObserver = new ResizeObserver((entries) => {
      for (const _ of entries) {
        const box = ref.current?.getBoundingClientRect()
        box && setBox(R.pick(BOX_KEYS, box))
      }
    })
    ref.current && resizeObserver.observe(ref.current)
    return () => {
      resizeObserver.disconnect()
    }
  }, [ref])

  useLayoutEffect(() => {
    const update = () => {
      if (ref.current) {
        const newBox = R.pick(BOX_KEYS, ref.current.getBoundingClientRect())
        setBox(newBox)
        if (!shallowEqual(newBox, lastValueRef.current)) {
          setTimeout(update, 16)
          setTimeout(update, 500)
        }
        lastValueRef.current = newBox
      }
    }
    setTimeout(update)
    window.addEventListener('resize', update)
    return () => {
      window.removeEventListener('resize', update)
    }
  }, [ref, block, deviceMode, highlighted, ...deps])
  return box
}

interface IPortalPositionOptions {
  placement: PlacementType
  gap?: boolean
}

export const usePortalBox = (
  root: BoxType,
  measure: BoxType,
  element: { width: number; height: number },
  { placement, gap }: IPortalPositionOptions,
) => {
  const left = useMemo(() => {
    if (!element) {
      return measure.x - (root.x || 0)
    }
    return calcLeft[placement](root, measure, element, gap ? 0 : GAP)
  }, [root, measure, placement])

  const top = useMemo(() => {
    if (!element) {
      return measure.y - (root.y || 0)
    }
    return calcTop[placement](root, measure, element, gap ? 0 : GAP)
  }, [root, measure, placement])

  const style = useMemo(
    () => ({
      x: left,
      y: top,
      top,
      left,
      bottom: top + (element?.height || 0),
      right: left + (element?.width || 0),
      height: element?.height || 0,
      width: element?.width || 0,
    }),
    [measure, placement, root],
  )

  return style
}

export const useCheckIntersection = (id: string, concat: boolean, box?: BoxType) => {
  const { portals } = useContextSelector(AbsolutePortalContext, (value) => value)

  return concat ? checkIntersection(R.omit([id], portals), box) : null
}

export const useSetBox = (id: string, placement: PlacementType, box?: BoxType) => {
  const { setPortals } = useContextSelector(AbsolutePortalContext, (value) => value)

  useEffect(() => {
    if (placement !== 'filled') {
      setPortals((old) => ({ ...old, [id]: box || null }))
    }
    return () => {
      setPortals((old) => R.omit([id], old))
    }
  }, [box])
}

const calcLeft: Record<
  PlacementType,
  (root: BoxType, measure: BoxType, el: { width: number; height: number }, gap: number) => number
> = {
  filled: (root, measure) => measure.x - root.x,
  top: (root, measure, el) => -root.x + measure.x + (measure.width - el.width) / 2,
  topLeft: (root, measure) => -root.x + measure.x,
  topRight: (root, measure, el) => -root.x + measure.x + measure.width - el.width,
  bottom: (root, measure, el) => -root.x + measure.x + (measure.width - el.width) / 2,
  bottomLeft: (root, measure) => -root.x + measure.x,
  bottomRight: (root, measure, el) => -root.x + measure.x + measure.width - el.width,
  left: (root, measure, el, gap) => -root.x + measure.x - el.width - gap,
  leftBottom: (root, measure, el, gap) => -root.x + measure.x - el.width - gap,
  leftTop: (root, measure, el, gap) => -root.x + measure.x - el.width - gap,
  right: (root, measure, _, gap) => -root.x + measure.x + measure.width + gap,
  rightBottom: (root, measure, _, gap) => -root.x + measure.x + measure.width + gap,
  rightTop: (root, measure, _, gap) => -root.x + measure.x + measure.width + gap,
}

const calcTop: Record<
  PlacementType,
  (root: BoxType, measure: BoxType, el: { width: number; height: number }, gap: number) => number
> = {
  filled: (root, measure) => measure.y - root.y,
  top: (root, measure, el, gap) => -root.y + measure.y - el.height - gap,
  topLeft: (root, measure, el, gap) => -root.y + measure.y - el.height - gap,
  topRight: (root, measure, el, gap) => -root.y + measure.y - el.height - gap,
  bottom: (root, measure, _, gap) => -root.y + measure.y + measure.height + gap,
  bottomLeft: (root, measure, _, gap) => -root.y + measure.y + measure.height + gap,
  bottomRight: (root, measure, _, gap) => -root.y + measure.y + measure.height + gap,
  left: (root, measure, el) => -root.y + measure.y + (measure.height - el.height) / 2,
  leftBottom: (root, measure, el) => -root.y + measure.y + (measure.height - el.height),
  leftTop: (root, measure) => -root.y + measure.y,
  right: (root, measure, el) => -root.y + measure.y + (measure.height - el.height) / 2,
  rightBottom: (root, measure, el) => -root.y + measure.y + (measure.height - el.height),
  rightTop: (root, measure) => -root.y + measure.y,
}

const checkIntersection = (rectangles: Record<string, BoxType>, rect?: BoxType) => {
  if (rect) {
    for (const key in rectangles) {
      const o = rectangles[key]
      if (
        o &&
        o.x < rect.x + rect.width &&
        o.x + o.width > rect.x &&
        o.y < rect.y + rect.height &&
        o.y + o.height > rect.y
      ) {
        return o
      }
    }
  }
  return null
}
