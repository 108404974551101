import cn from 'classnames'
import React, { useCallback, useEffect, useMemo, useRef } from 'react'
import { IconSectionsMap } from 'utils'

import { Tooltip } from 'components/uiKit/Dropdown'
import Highlighter from 'components/uiKit/Highlighter'
import Icon from 'components/uiKit/Icon'
import { Typography } from 'components/uiKit/Typography'
import { scrollProps } from 'services/Scroll/ScrollService'
import { SectionTypeEnum } from 'services/Store/Project/enums'
import { Section } from 'services/Store/Project/types'
import { t } from 'services/Translation'
import { getEnumLabel } from 'utils/enum'
import { testPropsEl } from 'utils/test/qaData'

import { MoreActionsEditorSections } from '../../MoreActionsEditorSections'
import s from './SectionTreeItemEditor.module.scss'
import { SectionInput } from './components'

export interface IDragCollectedProps {
  isDragging: boolean
}

interface ISectionTreeItemEditorProps {
  item: Section
  onSelect?: (sectionId: Section) => void
  searchValue?: string
  isEditSectionId?: string
  setIsEditSectionId: (activeId: string) => void
  hideActions?: boolean
  sectionId?: string
  disabledByTypes?: SectionTypeEnum[]
}

const SectionTreeItemEditor: React.FC<ISectionTreeItemEditorProps> = ({
  item,
  onSelect,
  searchValue,
  isEditSectionId,
  setIsEditSectionId,
  hideActions,
  sectionId,
  disabledByTypes,
}) => {
  const inputRef = useRef<HTMLInputElement | null>(null)
  const { type, isDone, isChapter, test, publicLink, isHide, blocks } = item
  const cover = type === SectionTypeEnum.cover
  const iconName = IconSectionsMap[type as SectionTypeEnum]
  const active = item.id === sectionId
  const edit = isEditSectionId === item.id
  const disableItem = disabledByTypes?.includes(item.type)

  const testCount = test?.randomCount || 0
  const questionCount = useMemo(
    () => blocks.filter((block) => block.mode === 'questions' && !block.isHide).length,
    [blocks],
  )

  const handleClick = useCallback(
    () => !disableItem && onSelect?.(item),
    [onSelect, item, disableItem],
  )

  const handleSectionEdit = useCallback(
    (item: Section) => {
      if (disableItem) {
        return
      }
      setIsEditSectionId(item.id)
      onSelect?.(item)
      inputRef.current?.focus()
    },
    [setIsEditSectionId, onSelect, disableItem],
  )

  useEffect(() => {
    if (edit && active && !disableItem) {
      handleSectionEdit(item)
    }
  }, [active, isEditSectionId, handleSectionEdit, item.id, edit, item, disableItem])

  const rootCn = cn(s.root, { [s.activeItem]: active })
  const iconCn = cn(s.icon, active && !cover && s.activeIcon)
  const itemIconCn = cn(s.itemIcon, cover && s.itemIconCover, isChapter && s.itemIconChapter)
  const itemCn = cn(s.item, s[type], { [s.disabled]: disableItem })
  const itemContentCn = cn(s.itemContent, active && !cover && s.reset)
  const textCn = cn(s.text, !item.name && s.placeholder, (isChapter || cover) && s.parent)

  return (
    <div className={rootCn} onClick={handleClick} {...scrollProps(item.id)}>
      <div
        {...testPropsEl(
          'sectionItem',
          { label: item.name, active: Boolean(active) },
          { id: item.id },
        )}
        className={itemCn}
      >
        <div className={cn(s.dragControl)}>
          <div className={itemIconCn}>{iconName?.icon}</div>
        </div>
        <div className={itemContentCn}>
          {edit ? (
            <SectionInput
              active={active}
              cover={cover}
              item={item}
              ref={inputRef}
              setIsEditSectionId={setIsEditSectionId}
            />
          ) : (
            <div className={textCn}>
              <Typography fluid>
                <Highlighter
                  search={searchValue}
                  text={item.name || getEnumLabel('SectionTypeEnumPlaceholder', type)}
                  breakWord
                />
              </Typography>
            </div>
          )}
          {!isChapter && (
            <>
              {isDone && (
                <Tooltip overlay={t('page.sections.section.closeSectionIcon')}>
                  <div className={iconCn}>
                    <Icon name='statusLock20Px' />
                  </div>
                </Tooltip>
              )}
              {publicLink.isOpen && !edit && (
                <Tooltip overlay={t('page.sections.section.publicLinkIcon')}>
                  <div className={iconCn}>
                    <Icon name='statusLink20Px' />
                  </div>
                </Tooltip>
              )}
              {isHide && !edit && (
                <Tooltip overlay={t('page.sections.section.hideIcon')}>
                  <div className={iconCn}>
                    <Icon name='statusHide20Px' />
                  </div>
                </Tooltip>
              )}
              {!isDone && test?.isShuffleQuestions && (test.randomCount || 0) > 0 && !edit && (
                <Tooltip
                  overlay={t('page.sections.section.randomLimitIcon', {
                    testCount,
                    questionCount,
                  })}
                >
                  <div className={iconCn}>
                    <Icon name='statusSumm20Px' />
                  </div>
                </Tooltip>
              )}
            </>
          )}
        </div>
      </div>
      {!edit && (
        <div className={s.wrapActions}>
          <div className={cn(s.actions, { [s.selected]: active })}>
            <MoreActionsEditorSections
              handleSectionClick={onSelect}
              handleSectionEdit={handleSectionEdit}
              hidden={edit || !!searchValue || !!hideActions}
              item={item}
            />
          </div>
        </div>
      )}
    </div>
  )
}

export default SectionTreeItemEditor
