import { FileImageUsage } from '@leenda/editor/lib/files'
import { useSize } from 'ahooks'
import lodash from 'lodash'
import React, { useEffect, useState } from 'react'
import ReactCrop, { centerCrop, makeAspectCrop, PixelCrop } from 'react-image-crop'
import { Crop as CropType } from 'react-image-crop/dist/types'

import Button from 'components/uiKit/Button'
import Divider from 'components/uiKit/Divider/Divider'
import Icon from 'components/uiKit/Icon'
import { KitSize } from 'components/uiKit/KitTypes'
import Modal, { ModalBody, ModalFooter, ModalHeader } from 'components/uiKit/Modal'
import { MCWithParams } from 'components/uiKit/Modal/ModalContext'
import Radio from 'components/uiKit/Radio'
import Switcher from 'components/uiKit/Segmented'
import { t } from 'services/Translation'
import { getImageByUsage } from 'utils/files'

import s from './CropModal.module.scss'

interface ICropValue {
  crop?: CropType
  round?: boolean
  aspect?: number
}

const ROUND_OPTIONS = [
  { value: true, label: <Icon name='otherCircleCrop' size={KitSize.S} /> },
  { value: false, label: <Icon name='otherSquare' size={KitSize.S} /> },
]

const ASPECT_OPTIONS = [
  { value: 1, label: '1:1' },
  { value: 4 / 3, label: '4:3' },
  { value: 16 / 9, label: '16:9' },
]

const scaleCrop = (crop?: CropType, scale = 1) => {
  return (
    crop && {
      ...crop,
      x: crop.x * scale,
      y: crop.y * scale,
      width: crop.width * scale,
      height: crop.height * scale,
    }
  )
}

const makeCrop = (
  image: HTMLImageElement,
  crop?: CropType,
  aspect?: number,
): CropType | undefined => {
  const { naturalHeight: height, naturalWidth: width } = image
  const min = Math.min(height, width)

  if (aspect) {
    // TODO: create our CropType or use BE type
    //@ts-ignore
    return makeAspectCrop(crop || { height: min, width: min }, aspect, width, height)
  }

  if (crop) {
    return crop
  }

  // TODO: create our CropType or use BE type
  //@ts-ignore
  return centerCrop({ height: min, width: min }, width, height)
}

const isDisabledApply = (
  value: ICropValue,
  localValue: ICropValue,
  ref: HTMLImageElement | null,
) => {
  if (lodash.isEqual(localValue, value)) {
    return true
  }

  if (localValue.crop && !localValue.round) {
    const { width, height } = localValue.crop
    const { naturalWidth: imgWidth, naturalHeight: imgHeight } = ref || {}

    if (width < 8 && height < 8) {
      return true
    }

    if (imgWidth === width && imgHeight === height) {
      return true
    }
  }

  return false
}

interface ICropModalProps extends ICropValue {
  image: Omit<FileImageUsage, 'type'>
}

const CropModal: MCWithParams<ICropModalProps, ICropValue> = ({ params: modalParams, onClose }) => {
  const { params } = modalParams.image
  const { crop, round, aspect } = params || {}
  const imageFile = getImageByUsage(modalParams.image)
  const path = imageFile ? imageFile.originalFile?.path : ''
  const [imgElement, setImageElement] = useState<HTMLImageElement | null>(null)
  const d = useSize(imgElement)
  const scale = d?.width && imgElement?.naturalWidth ? imgElement?.naturalWidth / d.width : 1
  const normalizedCrop = (crop: CropType | undefined) => {
    if (!imgElement || !crop) {
      return crop
    }
    const iw = imageFile?.originalFile?.width || imgElement.naturalWidth
    const ih = imageFile?.originalFile?.height || imgElement.naturalHeight
    let { width, height, x, y } = crop
    x = lodash.clamp(Math.round(x), 0, iw)
    y = lodash.clamp(Math.round(y), 0, ih)
    width = lodash.clamp(Math.round(width), 0, iw - x)
    height = lodash.clamp(Math.round(height), 0, ih - y)
    return { ...crop, width, height, x, y }
  }

  const [localValue, setLocalValue] = useState<ICropValue>({ crop, round, aspect })
  useEffect(() => {
    setLocalValue({ crop, round, aspect })
  }, [crop, round, aspect])

  const disabledApply = isDisabledApply(
    { crop, round: round || false, aspect },
    localValue,
    imgElement,
  )
  const maxImageHeight = '80vh'

  const onApply = () => {
    onClose(localValue)
  }
  const onCancel = () => {
    onClose()
  }

  const onChangeRound = (round: boolean) =>
    setLocalValue(({ crop, aspect }) =>
      imgElement
        ? { aspect, round, crop: normalizedCrop(makeCrop(imgElement, crop, aspect)) }
        : { aspect, round },
    )

  const onChangeAspect = (aspect: number) =>
    setLocalValue(({ crop, round }) =>
      imgElement
        ? { aspect, round, crop: normalizedCrop(makeCrop(imgElement, crop, aspect)) }
        : { aspect, round },
    )

  const onChangeCrop = (crop: PixelCrop) =>
    setLocalValue((value) => ({ ...value, crop: normalizedCrop(scaleCrop(crop, scale)) }))

  return (
    <Modal name='crop' styleType='crop'>
      <ModalHeader>
        <div className={s.cropActionsWrapper}>
          <div className={s.descriptionCropActions}>{t('modal.editorCrop.title')}</div>
          <div className={s.croppingShapeSwitcherWrapper}>
            <span className={s.croppingShapeSwitcherLabel}>{t('modal.editorCrop.form')}</span>
            <Switcher
              name='croppingRound'
              onChange={onChangeRound}
              options={ROUND_OPTIONS}
              size={KitSize.S}
              value={localValue.round ?? false}
            />
            <Divider />
            <Radio
              direction='horizontal'
              name='croppingAspect'
              onChange={onChangeAspect}
              options={ASPECT_OPTIONS}
              value={localValue.aspect}
              hideRadio
            />
          </div>
        </div>
      </ModalHeader>
      <ModalBody sizeAutoCapable>
        <div className={s.reactCropWrapper}>
          <ReactCrop
            aspect={localValue.aspect}
            circularCrop={localValue.round}
            crop={scaleCrop(localValue.crop, 1 / scale)}
            onChange={onChangeCrop}
          >
            <img ref={setImageElement} src={path} style={{ maxHeight: maxImageHeight }} />
          </ReactCrop>
        </div>
      </ModalBody>
      <ModalFooter>
        <Button name='cancelBtn' onClick={onCancel} styleType='ghost'>
          {t('uiKit.button.cancel')}
        </Button>
        <Button disabled={disabledApply} name='applyBtn' onClick={onApply} styleType='primary'>
          {t('uiKit.button.apply')}
        </Button>
      </ModalFooter>
    </Modal>
  )
}

export default CropModal
