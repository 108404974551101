import { SCHEMA } from '@leenda/editor/lib/brand'

import { genFormSchema } from 'services/Branding/genFormSchema'
import { t } from 'services/Translation'

export const STYLE_FORM_SCHEMA = genFormSchema(SCHEMA.image.style, {
  image: {
    META: { label: t('elements.image.tags.image') },
    borderRadius: {},
    borderStyle: {},
    borderColor: {},
    borderWidth: {},
    objectFit: {},
  },
})
