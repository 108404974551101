import { SCHEMA } from '@leenda/editor/lib/brand'

import { genFormSchema, FONT_SCHEMA_FIELDS } from 'services/Branding/genFormSchema'
import { t } from 'services/Translation'

export const STYLE_FORM_SCHEMA = genFormSchema(SCHEMA.testResult.style, {
  chartCorrectAnswerEmpty: {
    META: { label: t('elements.testResult.tags.correctEmpty') },
    color: { label: t('elements.testResult.schema.correctEmpty') },
  },
  chartCorrectAnswerFill: {
    META: { label: t('elements.testResult.tags.correctFill') },
    color: { label: t('elements.testResult.schema.correctFill') },
  },
  chartFailAnswerEmpty: {
    META: { label: t('elements.testResult.tags.failEmpty') },
    color: { label: t('elements.testResult.schema.failEmpty') },
  },
  chartFailAnswerFill: {
    META: { label: t('elements.testResult.tags.failFill') },
    color: { label: t('elements.testResult.schema.failFill') },
  },
})

export const FONT_FORM_SCHEMA = genFormSchema(SCHEMA.testResult.font, {
  h2: {
    ...FONT_SCHEMA_FIELDS.h2,
    META: { label: t('elements.testResult.tags.percents') },
  },
  h3: {
    ...FONT_SCHEMA_FIELDS.h3,
    META: { label: t('elements.testResult.tags.result') },
  },
  h4: {
    ...FONT_SCHEMA_FIELDS.h4,
    META: { label: t('elements.testResult.tags.feedback') },
  },
})
