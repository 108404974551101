import lodash from 'lodash'
import { Editor } from 'slate'

import { SlateInternal } from '../richText.constants'
import { CustomEditor } from '../richText.types'

export const switchToPseudoSelection = (editor: CustomEditor) => {
  const selection = editor.selection
  const hasSelection = !lodash.isEqual(selection?.anchor?.offset, selection?.focus?.offset)
  if (hasSelection) {
    Editor.addMark(editor, SlateInternal.pseudoSelection, true)
    editor.prevSelection = selection
  }
}

export const removeSavedSelection = (editor: CustomEditor) => {
  const prevSelection = editor.prevSelection
  if (prevSelection) {
    editor.prevSelection = undefined
    Editor.removeMark(editor, SlateInternal.pseudoSelection)
  }
}
