import { ImageElementValue } from '@leenda/editor/lib/elements'
import { chainName, getParent, urlValidation } from 'utils'

import { genDataField } from 'components/controls/Field'
import RealtimeField from 'components/controls/RealtimeField'
import Crop from 'components/controls/customComponents/Crop'
import Padding from 'components/editor-v3/Editor/Toolbar/Padding'
import Divider from 'components/uiKit/Divider'
import Hint from 'components/uiKit/Hint'
import { KitSize } from 'components/uiKit/KitTypes'
import { Block } from 'services/Store/Project/types'
import { t } from 'services/Translation'

import { ImageValueFormType } from './ImageEl.types'
import s from './ImageElForm.module.scss'

const genField = genDataField<Block>()

export const sourceType = genField({
  label: t('input.label.source'),
  name: 'sourceType',
  type: 'select',
  layout: 'horizontal',
  params: {
    options: [
      { value: 'file', label: t('input.option.file') },
      { value: 'url', label: t('input.option.link') },
    ],
    size: KitSize.S,
    fluid: true,
  },
})

export const url = genField({
  name: 'url',
  type: 'text',
  layout: 'horizontal',
  rules: [
    { required: true, message: t('input.placeholder.pasteLink') },
    { validator: urlValidation },
  ],
  params: (block, { name }) => {
    const { parent } = getParent<ImageElementValue>(name, block)

    return {
      hidden: parent?.sourceType !== 'url',
      placeholder: t('input.placeholder.pasteLink'),
      size: KitSize.S,
    }
  },
})

const image = genField({
  name: 'image',
  type: 'file',
  layout: 'horizontal',
  params: (block, { name }) => {
    const { parent } = getParent<ImageElementValue>(name, block)
    return {
      hidden: parent?.sourceType !== 'file',
      fileType: 'image',
      nullable: true,
    }
  },
})

const crop = genField({
  name: 'image',
  type: 'custom',
  component: Crop,
  params: (block, { name }) => {
    const { parent } = getParent<ImageElementValue>(name, block)
    return {
      hidden: !parent?.image?.id || parent?.sourceType !== 'file',
    }
  },
})

const zoomable = genField({
  name: 'zoomable',
  label: t('elements.image.form.zoomable'),
  type: 'segmented',
  layout: 'horizontal',
  defaultValue: false,
})

const ImageElForm: ImageValueFormType = ({ name, element }) => {
  const hasCrop = element?.value?.sourceType === 'file'
  return (
    <Padding sides>
      <RealtimeField config={chainName(name, sourceType)} />
      <RealtimeField config={chainName(name, url)} />
      <RealtimeField config={chainName(name, image)} />
      {hasCrop && (
        <div className={s.cropWrapper}>
          <div className={s.cropDescription}>{t('elements.image.form.cropHint')}</div>
          <div className={s.cropActions}>
            <span>{t('elements.image.form.actions')}</span>
            <RealtimeField config={chainName(name, crop)} />
          </div>
        </div>
      )}
      <Divider />
      <Hint text={t('elements.image.form.zoomHint')} />
      <RealtimeField config={chainName(name, zoomable)} />
    </Padding>
  )
}

export default ImageElForm
