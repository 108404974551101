import { RichTextFontSchemaType } from '@leenda/editor/lib/brand'
import { leftAiTokens } from 'entities/AiConfig/helpers'
import React, { useCallback, useEffect, useState } from 'react'
import { Editor } from 'slate'
import { ReactEditor, useSlate } from 'slate-react'

import { LayoutScroll } from 'components/LayoutPage'
import { RichTextViewer } from 'components/form/RichText/RichTextViewer'
import { removeSavedSelection } from 'components/form/RichText/formatOperations/internal'
import { collapseSelectionToEnd } from 'components/form/RichText/utils/selection'
import Button, { IconButton } from 'components/uiKit/Button'
import { Tooltip } from 'components/uiKit/Dropdown'
import Icon from 'components/uiKit/Icon'
import Input from 'components/uiKit/Input'
import { KitSize } from 'components/uiKit/KitTypes'
import Loader from 'components/uiKit/Loader'
import { PROJECT_PATHS } from 'constants/paths'
import { AiTextPromptTogglesEnum } from 'gql/__generated__/graphql'
import { useMyInfo } from 'gql/actions.helpers'
import { usePathParams } from 'routes/hooks'
import { ElementFontCss } from 'services/Branding/types'
import { t } from 'services/Translation'
import { getEnumLabel } from 'utils/enum'

import { useAi } from '../../useAi'
import s from './AiForm.module.scss'

type Command = {
  key: AiTextPromptTogglesEnum
  instruction: string
}

interface IAiFormProps {
  onClose: () => void
  prepropmpt: AiTextPromptTogglesEnum
  styles: ElementFontCss<RichTextFontSchemaType>
}

const AiForm: React.FC<IAiFormProps> = ({ onClose, prepropmpt, styles }) => {
  const { companyId } = usePathParams(PROJECT_PATHS.editor)
  const [value, setValue] = useState(
    prepropmpt !== AiTextPromptTogglesEnum.CUSTOM
      ? getEnumLabel('AiTextPromptTogglesEnum', prepropmpt)
      : '',
  )
  const {
    sendPrompt,
    acceptAiUsage,
    slate,
    response: { data, loading, error, called },
  } = useAi()
  const { profile } = useMyInfo(companyId)
  const company = profile?.company
  const disableAi = leftAiTokens(company?.aiConfigs) <= 0

  const editor = useSlate()
  const onRun = useCallback(
    async (command: Command) => {
      try {
        const prompt = editor.selection && Editor.string(editor, editor.selection)

        if (!prompt) {
          return
        }
        sendPrompt(command.key, command.instruction)
      } catch (error) {
        console.error(error)
      }
    },
    [editor, sendPrompt],
  )

  const onCustomEdit = useCallback(() => {
    onRun({
      key: prepropmpt,
      instruction: prepropmpt === AiTextPromptTogglesEnum.CUSTOM ? value : '',
    })
  }, [onRun, prepropmpt, value])

  const handleClose = useCallback(() => {
    onClose()
  }, [onClose])

  const handleConfirm = useCallback(() => {
    if (data && slate) {
      acceptAiUsage({ variables: { id: data.id } })
      ReactEditor.focus(editor)
      removeSavedSelection(editor)
      Editor.insertFragment(editor, slate)
      onClose()
    }
  }, [data, editor, onClose, slate])

  const handleDecline = useCallback(() => {
    removeSavedSelection(editor)
    collapseSelectionToEnd(editor)
    onClose()
  }, [onClose, editor])

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter' && !e.ctrlKey) {
      onCustomEdit()
    }
  }

  const handleRewrite = useCallback(() => {
    if (data) {
      acceptAiUsage({ variables: { id: data.id } })
      onCustomEdit()
    }
  }, [data?.id, onCustomEdit, acceptAiUsage])

  useEffect(() => {
    if (prepropmpt !== AiTextPromptTogglesEnum.CUSTOM && !called) {
      sendPrompt(prepropmpt)
    }
  }, [prepropmpt, called, sendPrompt])

  if (error) {
    onClose()
  }

  if (data) {
    return (
      <div className={s.root}>
        <div className={s.resultTitle}>
          <Icon name='builderAi' size={KitSize.S} /> {t('elements.richText.ai.result')}
        </div>
        <div className={s.result}>
          <LayoutScroll sizeAutoCapable>
            <div className={s.resultText}>
              <RichTextViewer styles={styles} value={slate} />
            </div>
          </LayoutScroll>
          <div className={s.shadow} />
        </div>
        <div className={s.bottomControls}>
          <Tooltip disabled={!disableAi} overlay={t('elements.richText.ai.tooltip')}>
            <Button
              disabled={disableAi}
              name='rewrite'
              onClick={handleRewrite}
              size={KitSize.S}
              styleType='secondary'
            >
              {t('uiKit.button.retry')}
            </Button>
          </Tooltip>
          <div className={s.buttonsRight}>
            <Button name='decline' onClick={handleDecline} size={KitSize.S} styleType='ghost'>
              {t('uiKit.button.decline')}
            </Button>
            <Button name='confirm' onClick={handleConfirm} size={KitSize.S} styleType='primary'>
              {t('uiKit.button.confirm')}
            </Button>
          </div>
        </div>
      </div>
    )
  }

  return (
    <div className={s.root}>
      <div className={s.prompt}>
        <div className={s.input}>
          <Input
            name='richTextLink'
            onChange={setValue}
            onKeyDown={handleKeyDown}
            placeholder={t('elements.richText.ai.placeholder')}
            prefix={<Icon name='builderAi' size={KitSize.S} />}
            styleType='ghost'
            value={value}
            autoFocus
          />
        </div>
        <div className={s.controls}>
          {loading ? (
            <Loader name='aiLoading' />
          ) : (
            <IconButton
              disabled={!value}
              icon='send'
              name='runAi'
              onClick={onCustomEdit}
              styleType='ghost'
            />
          )}
          <IconButton
            icon='otherClose'
            name='closeCustomPanel'
            onClick={handleClose}
            styleType='ghost'
          />
        </div>
      </div>
    </div>
  )
}

export default AiForm
