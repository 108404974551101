import cn from 'classnames'

import { IconButton } from 'components/uiKit/Button'
import Divider from 'components/uiKit/Divider'
import { Tooltip } from 'components/uiKit/Dropdown'
import Icon from 'components/uiKit/Icon'
import { IOption, KitSize, ValueType } from 'components/uiKit/KitTypes'
import Link from 'components/uiKit/Link'
import {
  useMenuItemIsHovered,
  useMenuItemIsSelected,
  useSetHovered,
} from 'components/uiKit/Menu/MenuContext'
import { LinkType } from 'routes/routes.types'
import { scrollProps } from 'services/Scroll/ScrollService'
import { testPropsEl } from 'utils/test/qaData'

import { OptionLabel } from '../../KitUtils'
import s from './MenuItem.module.scss'

export interface IMenuOption<V extends ValueType> extends IOption<V> {
  options?: IMenuOption<V>[]
  separated?: boolean
  link?: LinkType | string
  preventClose?: boolean
}

interface IMenuItem<T extends ValueType> {
  item: IMenuOption<T>
  onClick?: (value: IMenuOption<T>) => void
  level?: number
  size?: KitSize
  resettable?: boolean
  horizontal?: boolean
}

export const MenuItem = <T extends ValueType>({
  item,
  onClick,
  level = 1,
  size,
  resettable,
  horizontal,
}: IMenuItem<T>) => {
  const { disabled, hidden, options, tooltip, separated, testData, value, icon, link } = item
  const isHovered = useMenuItemIsHovered(value)
  const isSelected = useMenuItemIsSelected(value)
  const setHovered = useSetHovered()

  const handleClick = () => !disabled && value !== undefined && onClick?.(item)
  const onMouseMove = () => setHovered?.(item)

  const label = (label: JSX.Element) =>
    tooltip ? <Tooltip overlay={tooltip}>{label}</Tooltip> : label

  const cnRoot = cn(size && s[size], {
    [s.root]: !options,
    [s.disabled]: disabled,
    [s.hovered]: isHovered,
    [s.selected]: isSelected,
    [s.horizontal]: horizontal,
  })

  if (hidden) {
    return null
  }

  if (item.options) {
    return (
      item.options.length > 0 && (
        <div className={cn(s.group)}>
          {label(
            <div
              className={s.groupLabel}
              style={{ ...(!horizontal && { paddingLeft: 6 * level || 6 }) }}
            >
              <OptionLabel option={item} size={size} type='list' />{' '}
            </div>,
          )}
          {item.options.map((item) => (
            <MenuItem
              item={item}
              key={String(item.value)}
              level={level + 1}
              onClick={onClick}
              size={size}
            />
          ))}
        </div>
      )
    )
  }

  const menuItem = (
    <>
      {separated && !horizontal && <Divider free />}
      <div
        {...testPropsEl(
          `menuItem`,
          { value: item.value },
          { label: item.textLabel || item.label, testData },
        )}
        {...scrollProps(String(item.value))}
        className={cnRoot}
        onClick={handleClick}
        onMouseMove={onMouseMove}
        style={{ ...(!horizontal && { paddingLeft: 6 * (level - 1) || 6 }) }}
        tabIndex={-1}
      >
        {label(
          <span className={s.content}>
            {icon && <Icon name={icon} size={size || KitSize.S} />}
            <span className={s.label}>
              <OptionLabel option={item} size={size} type='list' />
            </span>
          </span>,
        )}
        {!horizontal && '\u00A0'} {/* fix ellipsis for last symbol in auto width */}
        {resettable && (
          <span className={s.remove}>
            {isSelected && isHovered && (
              <IconButton
                icon='otherClose'
                name='remove'
                size={KitSize.XS}
                styleType='ghost'
                tabIndex={-1}
              />
            )}
          </span>
        )}
      </div>
    </>
  )

  return link ? (
    <Link disabled={disabled} name={String(value)} styleType='free' to={link}>
      {menuItem}
    </Link>
  ) : (
    menuItem
  )
}

export default MenuItem
