import { SCHEMA } from '@leenda/editor/lib/brand'

import { genField } from 'components/controls/Field'
import { genFormSchema, FONT_SCHEMA_FIELDS } from 'services/Branding/genFormSchema'
import { t } from 'services/Translation'

export const STYLE_FORM_SCHEMA = genFormSchema(SCHEMA.divider.style, {
  line: {
    META: { label: t('elements.divider.tags.line') },
    borderColor: {},
    borderWidth: { params: { min: 1, max: 10 } },
  },
  double: {
    META: { label: t('elements.divider.tags.double') },
    marginTop: genField({
      label: t('input.label.interval'),
      type: 'slider',
      params: { showInput: true, min: 1, max: 20 },
    }),
  },
  numbered: {
    META: { label: t('input.label.numbered') },
    backgroundColor: {},
    borderStyle: {},
    borderColor: {},
    borderWidth: { params: { min: 1, max: 10 } },
    borderRadius: { params: { min: 0, max: 100 } },
    justifyContent: {},
    height: {},
    width: {},
  },
})

export const FONT_FORM_SCHEMA = genFormSchema(SCHEMA.divider.font, {
  base: {
    ...FONT_SCHEMA_FIELDS.base,
    META: { label: t('input.label.numberedText') },
  },
})
