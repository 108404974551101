import { ThemeKeys, ThemeType, SchemaKeys, KEYS } from '@leenda/editor/lib/brand'
import { setFontValue, setThemeVariable } from '@leenda/editor/lib/brand/utils'
import cn from 'classnames'

import { genField } from 'components/controls/Field'
import { IFieldConfig } from 'components/controls/Field/Field.types'
import { IArrayOption } from 'components/controls/inputComponents/FieldNumber/FieldNumber'
import { useBrandForm } from 'components/pages/BrandPage/hooks'
import Badge from 'components/uiKit/Badge'
import { ColorOption } from 'components/uiKit/ColorPicker'
import { Tooltip } from 'components/uiKit/Dropdown'
import Icon from 'components/uiKit/Icon'
import { KitSize } from 'components/uiKit/KitTypes'
import { ISelectOption } from 'components/uiKit/Select'
import { FontGroupFullSchemaFragment } from 'gql/__generated__/graphql'
import { DeviceMode } from 'services/Store/Project/enums'
import { t } from 'services/Translation'
import { validateFontFamilyCss } from 'utils/font'
import { getParent } from 'utils/form'

import { fontsDeviceComparator } from '../comparators'
import s from './Fields.module.scss'
import { BRAND_THEME_LABELS } from './labels'

const DesktopIcon = ({ name }: { name: SchemaKeys }) => {
  const defaultValue = useBrandForm((state) => state.defaultValues?.schema?.[name]?.font?.desktop)
  const value = useBrandForm((state) => state.values?.schema?.[name]?.font?.desktop)
  const isEqual = fontsDeviceComparator(defaultValue, value)

  return (
    <Tooltip overlay={t('uiKit.tooltip.webVersion')}>
      <Badge count={isEqual ? 1 : undefined} styleType='dot'>
        <Icon name='builderDesktop' size={KitSize.S} />
      </Badge>
    </Tooltip>
  )
}

const TabletIcon = ({ name }: { name: SchemaKeys }) => {
  const defaultValue = useBrandForm((state) => state?.defaultValues?.schema?.[name]?.font?.tablet)
  const value = useBrandForm((state) => state.values?.schema?.[name]?.font?.tablet)
  const isEqual = fontsDeviceComparator(defaultValue, value)

  return (
    <Tooltip overlay={t('uiKit.tooltip.tabletVersion')}>
      <Badge count={isEqual ? 1 : undefined} styleType='dot'>
        <Icon name='builderTablet' size={KitSize.S} />
      </Badge>
    </Tooltip>
  )
}

const MobileIcon = ({ name }: { name: SchemaKeys }) => {
  const defaultValue = useBrandForm((state) => state.defaultValues?.schema?.[name]?.font?.mobile)
  const value = useBrandForm((state) => state.values?.schema?.[name]?.font?.mobile)
  const isEqual = fontsDeviceComparator(defaultValue, value)

  return (
    <Tooltip overlay={t('uiKit.tooltip.mobileVersion')}>
      <Badge count={isEqual ? 1 : undefined} styleType='dot'>
        <Icon name='builderMobile' size={KitSize.S} />
      </Badge>
    </Tooltip>
  )
}

export const DEVICE_MODE_OPTIONS = (name: SchemaKeys) => [
  { value: DeviceMode.desktop, label: <DesktopIcon name={name} /> },
  { value: DeviceMode.tablet, label: <TabletIcon name={name} /> },
  { value: DeviceMode.mobile, label: <MobileIcon name={name} /> },
]

export const HORIZONTAL_ALIGN_OPTIONS = [
  { value: 'flex-start', label: <Icon name='alignmentAlignLeft' size={KitSize.S} /> },
  { value: 'center', label: <Icon name='alignmentAlignMid' size={KitSize.S} /> },
  { value: 'flex-end', label: <Icon name='alignmentAlignRight' size={KitSize.S} /> },
]

export const VERTICAL_ALIGN_OPTIONS = [
  { value: 'flex-start', label: <Icon name='alignmentAlignTop' size={KitSize.S} /> },
  { value: 'center', label: <Icon name='alignmentAlignCenter' size={KitSize.S} /> },
  { value: 'flex-end', label: <Icon name='alignmentAlignBottom' size={KitSize.S} /> },
]

export const OBJECT_FIT_OPTIONS = [
  { label: t('input.option.cover'), value: 'cover' },
  { label: t('input.option.contain'), value: 'contain' },
  { label: t('input.option.fill'), value: 'fill' },
]

const genFontOption = (name: string) => ({
  value: setFontValue({ name }),
  label: <div style={{ fontFamily: validateFontFamilyCss(setFontValue({ name })) }}>{name}</div>,
  textLabel: name,
})

export const FONT_FAMILY_OPTIONS = [
  genFontOption('Inter'),
  genFontOption('Arial'),
  genFontOption('Verdana'),
  genFontOption('Helvetica'),
  genFontOption('Tahoma'),
  genFontOption('Trebuchet MS'),
  genFontOption('Times New Roman'),
  genFontOption('Georgia'),
  genFontOption('Garamond'),
  genFontOption('Courier New'),
  genFontOption('Montserrat'),
  genFontOption('Brush Script MT'),
]

export const FONT_WEIGHT_OPTIONS = [
  { label: 'Thin', value: 100 },
  { label: 'Extra Light', value: 200 },
  { label: 'Light', value: 300 },
  { label: 'Regular', value: 400 },
  { label: 'Medium', value: 500 },
  { label: 'Semi Bold', value: 600 },
  { label: 'Bold', value: 700 },
  { label: 'Extra Bold', value: 800 },
  { label: 'Black', value: 900 },
]

export const createFontFamilyOption = (
  fontGroup: FontGroupFullSchemaFragment,
): ISelectOption<string> => ({
  value: setFontValue(fontGroup),
  label: (
    <div
      className={s.fontFamily}
      style={{ fontFamily: validateFontFamilyCss(setFontValue(fontGroup)) }}
    >
      {fontGroup.isArchived && (
        <Tooltip overlay={t('uiKit.tooltip.fontArchived')} placement='topLeft'>
          <Icon name='missingFont' size={KitSize.S} />
        </Tooltip>
      )}
      {fontGroup.name}
    </div>
  ),
})

export const createFontWeightOptions = (
  font: { name: string },
  fontGroup?: FontGroupFullSchemaFragment,
  fontStyle?: string,
) =>
  FONT_WEIGHT_OPTIONS.map((option) => {
    const isFont = fontGroup
      ? fontGroup.fonts.some((font) => font.weight === option.value || !font.weight)
      : true
    return {
      ...option,
      label: (
        <div
          style={{
            fontFamily: validateFontFamilyCss(setFontValue(fontGroup || font)),
            fontWeight: option.value,
            fontStyle,
          }}
        >
          <Tooltip overlay={!isFont ? t('uiKit.tooltip.syntheticFont') : null} placement='left'>
            {option?.label}
            {!isFont && '*'}
          </Tooltip>
        </div>
      ),
    }
  })

export const FONT_SIZE_OPTIONS = [
  { value: 8, label: '8' },
  { value: 9, label: '9' },
  { value: 10, label: '10' },
  { value: 11, label: '11' },
  { value: 12, label: '12' },
  { value: 13, label: '13' },
  { value: 14, label: '14' },
  { value: 15, label: '15' },
  { value: 16, label: '16' },
  { value: 18, label: '18' },
  { value: 20, label: '20' },
  { value: 22, label: '22' },
  { value: 24, label: '24' },
  { value: 28, label: '28' },
  { value: 32, label: '32' },
  { value: 36, label: '36' },
  { value: 42, label: '42' },
  { value: 56, label: '56' },
  { value: 72, label: '72' },
]

export const BORDER_OPTIONS = [
  {
    value: 'none',
    label: t('common.no'),
  },
  {
    value: 'solid',
    label: t('common.yes'),
  },
]

export const LINE_HEIGHT_OPTIONS = [
  { label: '1', value: 1 },
  { label: '1.5', value: 1.5 },
  { label: '2', value: 2 },
  { label: 'auto', value: 'normal' },
]

export const OFFSET_ARRAY: IArrayOption[] = [
  { icon: <Icon name='builderInputUp' size={KitSize.XS} /> },
  { icon: <Icon name='builderInputForward' size={KitSize.XS} /> },
  { icon: <Icon name='builderInputDown' size={KitSize.XS} /> },
  { icon: <Icon name='builderInputBack' size={KitSize.XS} /> },
]

export const FONT_ITALIC_PARAMS = {
  style: { padding: 0 },
  hideCheckbox: true,
  option: {
    label: ({ disabled }: { disabled?: boolean }) => (
      <div className={cn(s.italic, { [s.disabled]: disabled })}>
        <Tooltip disabled={disabled} overlay={t('uiKit.tooltip.italic')} placement='bottomRight'>
          <Icon name='builderItalic' size={KitSize.S} />
        </Tooltip>
      </div>
    ),
    valueTrue: 'italic',
    valueFalse: 'normal',
  },
}

const FLEX_DIRECTION_OPTIONS = [
  {
    label: (
      <Tooltip overlay={t('elements.layoutList.tooltip.row')}>
        <Icon name='builderArrowRight' size={KitSize.S} />
      </Tooltip>
    ),
    value: 'row',
  },
  {
    label: (
      <Tooltip overlay={t('elements.layoutList.tooltip.column')}>
        <Icon name='builderArrowDown' size={KitSize.S} />
      </Tooltip>
    ),
    value: 'column',
  },
]

const COLORS_THEME = [
  KEYS.primaryColor,
  KEYS.secondaryColor,
  KEYS.focusedColor,
  KEYS.hoverColor,
  KEYS.inactiveColor,
  KEYS.neutralColor,
  KEYS.borderColor,
]

const TEXT_COLORS = [
  KEYS.primaryTextColor,
  KEYS.secondaryTextColor,
  KEYS.lightTextColor,
  KEYS.accentTextColor,
]

const BACKGROUND_COLORS = [
  KEYS.primaryBackgroundColor,
  KEYS.darkBackgroundColor,
  KEYS.lightBackgroundColor,
  KEYS.accentBackgroundColor,
]

const FEEDBACK_COLORS = [KEYS.infoColor, KEYS.warningColor, KEYS.successColor, KEYS.errorColor]

export const genColorOptions = (theme: ThemeType) => [
  {
    value: 'brand',
    label: t('input.option.brandColors'),
    options: COLORS_THEME.map((key) => ({
      value: setThemeVariable(key),
      label: genColorLabel(key, theme[key] as string),
      color: String(theme[key]),
    })),
  },
  {
    value: 'text',
    label: t('input.option.textColors'),
    options: TEXT_COLORS.map((key) => ({
      value: setThemeVariable(key),
      label: genColorLabel(key, theme[key] as string),
      color: String(theme[key]),
    })),
  },
  {
    value: 'background',
    label: t('input.option.backgroundColors'),
    options: BACKGROUND_COLORS.map((key) => ({
      value: setThemeVariable(key),
      label: genColorLabel(key, theme[key] as string),
      color: String(theme[key]),
    })),
  },
  {
    value: 'feedback',
    label: t('input.option.additionalColors'),
    options: FEEDBACK_COLORS.map((key) => ({
      value: setThemeVariable(key),
      label: genColorLabel(key, theme[key] as string),
      color: String(theme[key]),
    })),
  },
]
export const genColorLabel = (name: ThemeKeys, color: string) => (
  <ColorOption color={color} name={BRAND_THEME_LABELS[name]} />
)

export const DEFAULT_BRAND_FIELDS: Partial<Record<string, IFieldConfig>> = {
  backgroundColor: genField({
    type: 'color',
    label: t('input.label.backgroundColor'),
    layout: ['horizontal', 'solid'],
    params: {
      transparent: true,
    },
  }),
  backgroundImage: genField({
    type: 'fileId',
    layout: 'vertical',
    params: { fileType: 'image' },
  }),
  color: genField({
    type: 'color',
    label: t('input.label.color'),
    layout: ['horizontal', 'solid'],
  }),

  borderStyle: genField({
    type: 'segmented',
    label: t('input.label.border'),
    layout: 'horizontal',
    params: { options: BORDER_OPTIONS },
  }),
  borderColor: genField({
    type: 'color',
    label: t('input.label.borderColor'),
    layout: ['horizontal', 'solid'],
    params: (block, { name }) => {
      const { parent } = getParent<{ borderStyle?: 'none' }>(name, block)
      return { hidden: parent?.borderStyle === 'none' }
    },
  }),
  borderWidth: genField({
    type: 'slider',
    label: t('input.label.borderWidth'),
    layout: 'horizontal',
    params: (block, { name }) => {
      const { parent } = getParent<{ borderStyle?: 'none' }>(name, block)
      return { min: 1, max: 8, hidden: parent?.borderStyle === 'none', showInput: true }
    },
  }),
  borderRadius: genField({
    type: 'slider',
    label: t('input.label.borderRadius'),
    layout: 'horizontal',
    params: { min: 0, max: 50, showInput: true },
  }),
  borderTopStyle: genField({
    type: 'segmented',
    label: t('input.label.border'),
    layout: 'horizontal',
    params: { options: BORDER_OPTIONS },
  }),
  borderTopColor: genField({
    type: 'color',
    label: t('input.label.borderColor'),
    layout: ['horizontal', 'solid'],
    params: (block, { name }) => {
      const { parent } = getParent<{ borderTopStyle?: 'none'; borderStyle?: 'none' }>(name, block)
      return { hidden: parent?.borderTopStyle === 'none' || parent?.borderStyle !== 'none' }
    },
  }),
  borderTopWidth: genField({
    type: 'slider',
    label: t('input.label.borderWidth'),
    layout: 'horizontal',
    params: (block, { name }) => {
      const { parent } = getParent<{ borderTopStyle?: 'none'; borderStyle?: 'none' }>(name, block)
      return {
        min: 1,
        max: 8,
        hidden: parent?.borderTopStyle === 'none' || parent?.borderStyle !== 'none',
        showInput: true,
      }
    },
  }),

  height: genField({
    type: 'number',
    label: t('input.label.height'),
    layout: ['horizontal', 'solid'],
    params: { width: 64 },
  }),
  width: genField({
    type: 'number',
    label: t('input.label.width'),
    layout: ['horizontal', 'solid'],
    params: { width: 64 },
  }),
  marginTop: genField({
    type: 'number',
    label: t('input.label.marginTop'),
    layout: ['horizontal', 'solid'],
    params: { width: 64 },
  }),
  marginRight: genField({
    type: 'number',
    label: t('input.label.marginSide'),
    layout: ['horizontal', 'solid'],
    params: { width: 64 },
  }),
  marginLeft: genField({
    type: 'number',
    label: t('input.label.marginSide'),
    layout: ['horizontal', 'solid'],
    params: { width: 64 },
  }),
  marginBottom: genField({
    type: 'number',
    label: t(t('input.label.paddingBottom')),
    layout: ['horizontal', 'solid'],
    params: { width: 64 },
  }),
  paddingTop: genField({
    type: 'number',
    label: t('input.label.paddingTop'),
    layout: ['horizontal', 'solid'],
    params: { width: 64 },
  }),
  paddingLeft: genField({
    type: 'number',
    label: t('input.label.paddingLeft'),
    layout: ['horizontal', 'solid'],
    params: { width: 64 },
  }),
  paddingRight: genField({
    type: 'number',
    label: t('input.label.paddingRight'),
    layout: ['horizontal', 'solid'],
    params: { width: 64 },
  }),
  paddingBottom: genField({
    type: 'number',
    label: t('input.label.paddingBottom'),
    layout: ['horizontal', 'solid'],
    params: { width: 64 },
  }),
  padding: genField({
    type: 'number',
    label: t('input.label.padding'),
    layout: 'vertical',
    params: { array: OFFSET_ARRAY },
  }),
  margin: genField({
    type: 'number',
    label: t('input.label.padding'),
    layout: 'vertical',
    params: { array: OFFSET_ARRAY },
  }),

  alignItems: genField({
    type: 'radio',
    label: t('input.label.alignItems'),
    layout: 'horizontal',
    params: { options: VERTICAL_ALIGN_OPTIONS, hideRadio: true, direction: 'horizontal' },
  }),
  justifyContent: genField({
    type: 'radio',
    label: t('input.label.justifyContent'),
    layout: 'horizontal',
    params: { options: HORIZONTAL_ALIGN_OPTIONS, hideRadio: true, direction: 'horizontal' },
  }),
  textAlign: genField({
    type: 'radio',
    label: t('input.label.justifyContent'),
    layout: 'horizontal',
    params: { options: HORIZONTAL_ALIGN_OPTIONS, hideRadio: true, direction: 'horizontal' },
  }),
  objectFit: genField({
    label: t('input.label.objectFit'),
    type: 'select',
    layout: 'horizontal',
    params: {
      options: OBJECT_FIT_OPTIONS,
      fluid: true,
    },
  }),
  flexDirection: genField({
    label: t('input.label.view'),
    type: 'segmented',
    layout: 'horizontal',
    params: { options: FLEX_DIRECTION_OPTIONS },
  }),
  gap: genField({
    type: 'number',
    label: t('input.label.gap'),
    layout: ['horizontal', 'solid'],
    params: { width: 64 },
  }),

  fontFamily: genField({
    type: 'select',
    label: t('input.label.font'),
    layout: 'horizontal',
    params: {
      fluid: true,
      options: FONT_FAMILY_OPTIONS,
    },
  }),
  fontSize: genField({
    type: 'select',
    label: t('input.label.fontSize'),
    layout: 'horizontal',
    params: {
      options: FONT_SIZE_OPTIONS,
      inputable: true,
      hideEmptyList: true,
      inputType: 'number',
      minWidth: 64,
    },
  }),
  lineHeight: genField({
    label: t('input.label.lineHeight'),
    layout: ['horizontal', 'solid'],
    type: 'select',
    params: {
      options: LINE_HEIGHT_OPTIONS,
      inputable: true,
      inputType: 'number',
      hideEmptyList: true,
      minWidth: 64,
    },
  }),
  fontWeight: genField({
    type: 'select',
    label: t('input.label.fontWeight'),
    layout: ['horizontal', 'solid'],
    params: {
      options: FONT_WEIGHT_OPTIONS,
      fluid: true,
    },
  }),
  fontStyle: genField({
    type: 'checkbox',
    label: t('input.label.italic'),
    layout: ['horizontal', 'solid'],
    params: FONT_ITALIC_PARAMS,
  }),
  textDecoration: genField({
    type: 'segmented',
    label: t('input.label.textDecoration'),
    layout: ['horizontal', 'solid'],
    params: {
      options: [
        { value: 'none', label: t('common.no') },
        { value: 'underline', label: t('common.yes') },
      ],
    },
  }),

  borderLeftColor: genField({
    type: 'color',
    label: t('input.label.color'),
    layout: ['horizontal', 'solid'],
  }),

  spacing: genField({
    type: 'number',
    label: t('input.label.spacing'),
    layout: ['horizontal', 'solid'],
    params: { width: 64 },
  }),
}
