import { ILabelProps, IOption } from 'components/uiKit/KitTypes'
import { ISelectLabelProps } from 'components/uiKit/Select'

interface IOptionLabelProps<V> extends ILabelProps, ISelectLabelProps {
  option?: IOption<V>
}

export const flatOptions = <V extends { options?: V[] }>(options: V[] = []): V[] =>
  options.reduce<V[]>((acc, cur) => {
    if (cur.options?.length) {
      acc.push(...flatOptions(cur.options))
    } else {
      acc.push(cur)
    }
    return acc
  }, [])

export const OptionLabel = <V,>({ option, ...rest }: IOptionLabelProps<V>) => {
  if (typeof option?.label === 'function') {
    const Label = option.label
    return <Label {...option} {...rest} />
  }
  return option?.label ?? option?.value
}
