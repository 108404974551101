import { createSelector } from '@reduxjs/toolkit'
import * as R from 'ramda'

import { CommentMock } from 'components/uiKit/Comment'
import { IProjectCommentsFilterValues, IProjectContext } from 'services/Store/Project/types'

import { getSection } from './section'

export const getActiveComment = ({ data, urlParams: url }: IProjectContext) =>
  data.comments.find((comment) => comment.id === url.commentId) || null

export const getActiveThread = ({ data, urlParams: url }: IProjectContext) =>
  data.comments.find((comment) => comment.id === url.threadId) || null

export const getComments = createSelector(
  (state: IProjectContext) => state,
  (_: IProjectContext, params?: { sectionId?: string; blockId?: string }) => params,
  (state, params) => {
    if (params?.blockId) {
      const blockComments = state.data.comments.filter((item) => item.blockUuid === params.blockId)
      return R.filter(filterComment(state.state.commentsFilter), blockComments)
    }
    if (params?.sectionId) {
      const section = getSection(state, { id: params.sectionId })
      const orderMap = (section?.blocksOrder || []).reduce(
        (acc, item, i) => ({ ...acc, [item]: i }),
        {} as Record<string, number>,
      )

      const sectionComments = R.sort(
        (a, b) => orderMap[a.blockUuid!] - orderMap[b.blockUuid!],
        state.data.comments.filter((item) => item.sectionId === params.sectionId),
      )

      return R.filter(filterComment(state.state.commentsFilter), sectionComments)
    }
    return R.filter(filterComment(state.state.commentsFilter), state.data.comments)
  },
)

export const getSectionComments = createSelector(
  (state: IProjectContext) => state.data.comments,
  (state: IProjectContext) => state.state.commentsFilter,
  (comments, filter) => R.filter(filterComment(filter), comments),
)

export const getNewCommentsCount = ({ data }: IProjectContext) =>
  R.filter(filterComment({ read: false }), data.comments).length

export const getBlockComments = createSelector(
  (state: IProjectContext) => state.data.comments,
  (state: IProjectContext) => state.state.commentsFilter,
  (_: IProjectContext, blockUuid?: string) => blockUuid,
  (comments, filter, blockUuid) =>
    R.compose(R.filter(filterComment({ ...filter, blockUuid })))(comments),
)

export const getIsCommentedBlock = ({ data, state }: IProjectContext, blockUuid?: string) => {
  const comments = R.filter(filterComment({ ...state.commentsFilter, blockUuid }), data.comments)
  return comments[0] || null
}

export const getIsCommentedElement = ({ data, state }: IProjectContext, elementId?: string) => {
  const comments = R.filter(filterComment({ ...state.commentsFilter, elementId }))(data.comments)
  return comments[0] || null
}

const filterComment = (values: Partial<IProjectCommentsFilterValues>) => (comment: CommentMock) => {
  const { createdById, resolved, read, blockUuid, elementId, parentId } = values
  if (!R.isNil(parentId) && parentId !== comment.parentId) {
    return false
  }
  if (!R.isNil(blockUuid) && blockUuid !== comment.blockUuid) {
    return false
  }
  if (!R.isNil(elementId) && elementId !== comment.elementId) {
    return false
  }
  if (!R.isNil(createdById) && createdById !== comment.createdBy?.id) {
    return false
  }
  if (!R.isNil(resolved) && comment.status && resolved !== 'all' && resolved !== comment.status) {
    return false
  }
  if (!R.isNil(read) && read !== 'all' && read !== comment.isRead) {
    return false
  }

  return true
}
