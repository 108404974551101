import React, { Dispatch, SetStateAction } from 'react'

import { ScrollRef } from 'components/LayoutPage/LayoutScroll/LayoutScroll'
import Empty, { IEmptyProps } from 'components/uiKit/Empty'
import InfiniteLoadGrid from 'components/uiKit/InfiniteLoadGrid'
import { ExternalImage } from 'gql/__generated__/graphql'

import UnsplashGridItem from './ExternalImagesGridItem'

interface IExternalImagesProps {
  data: ExternalImage[]
  onChangeSelection: Dispatch<SetStateAction<ExternalImage[]>>
  onClickItem: (item: ExternalImage) => void
  total: number
  selectedItems: ExternalImage[]
  loading: boolean
  empty?: IEmptyProps
  clearQuery: () => void
  fetchMore?: () => Promise<unknown>
  chunkLoading?: boolean
  error?: boolean
}

const ExternalImages = React.forwardRef<ScrollRef, IExternalImagesProps>(
  (
    {
      data,
      onChangeSelection,
      onClickItem,
      total,
      selectedItems,
      loading,
      clearQuery,
      fetchMore,
      empty,
      chunkLoading,
      error,
    },
    ref,
  ) => {
    if ((error || !data.length) && !loading) {
      return <Empty {...empty} grow />
    }

    return (
      <InfiniteLoadGrid
        chunkLoading={chunkLoading || loading}
        fetchMore={fetchMore}
        grid={{ width: '308px' }}
        hasMore={total > data.length}
        loading={loading}
        name='filePicker'
        padding='5px 0 20px'
        ref={ref}
      >
        {data.map((item, index) => (
          <UnsplashGridItem
            clearQuery={clearQuery}
            item={item}
            key={`${item.id}${index}`}
            onChangeSelection={onChangeSelection}
            onClick={onClickItem}
            selectedItems={selectedItems}
          />
        ))}
      </InfiniteLoadGrid>
    )
  },
)

ExternalImages.displayName = 'ExternalImages'

export default ExternalImages
