import { MenuPositionType } from '@leenda/editor/lib/brand'
import { CONFIG_COURSE } from '@leenda/editor/lib/elements'
import cn from 'classnames'
import React, { useMemo } from 'react'

import { COURSE_TOOLTIP } from 'constants/portals'
import { ContractType } from 'gql/__generated__/graphql'
import { useElementStyleCss, useFontCss } from 'services/Branding/hooks'
import { useProjectContext } from 'services/Store/Project/hooks'
import {
  getCourseState,
  getPreviewMode,
  getDeviceMode,
  getEditorMode,
  getProject,
} from 'services/Store/Project/selectors'

import { PortalTarget } from '../../uiKit/Portal'
import TrialLogo from '../cource/components/TrialLogo'
import { CourseHeaderContainer } from '../cource/controls/CourseHeader'
import { CourseSidebar } from '../cource/controls/CourseSidebar'
import FinishModal from '../cource/controls/FinishModal'
import CourseSection from '../cource/renderEntry/CourseSection'
import s from './Course.module.scss'

interface ICourseProps {
  isPartView?: boolean
}

const Course: React.FC<ICourseProps> = ({ isPartView }) => {
  const deviceMode = useProjectContext(getDeviceMode)
  const previewMode = useProjectContext(getPreviewMode)
  const editorMode = useProjectContext(getEditorMode)
  const courseState = useProjectContext(getCourseState)
  const contractType = useProjectContext(getProject)?.company?.contractType
  const showTrialLogo = contractType === ContractType.TRIAL
  const mode = useMemo(
    () => ({ deviceMode, previewMode, editorMode }),
    [deviceMode, previewMode, editorMode],
  )
  const styles = useElementStyleCss(CONFIG_COURSE.sidebar.type, mode.deviceMode)
  const menuPosition = styles.value.position as MenuPositionType

  const font = useFontCss(deviceMode)

  return (
    <div className={s.root} style={font.base}>
      {/* eslint-disable-next-line react/forbid-component-props */}
      <PortalTarget className={s.tooltipPortal} id={COURSE_TOOLTIP} />
      <div className={s.header}>
        <CourseHeaderContainer isPartView={isPartView} mode={mode} />
      </div>
      <div
        className={cn(s.body, {
          [s.positionsRight]: menuPosition === MenuPositionType.right,
        })}
      >
        <CourseSidebar mode={mode} />
        <div className={s.contentWrapper}>
          {showTrialLogo && (
            <div className={s.trial}>
              <TrialLogo />
            </div>
          )}
          <CourseSection mode={mode} />
        </div>
      </div>
      {courseState.status === 'finished' && <FinishModal mode={mode} />}
    </div>
  )
}

export default Course
