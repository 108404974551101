import { useKeyPress } from 'ahooks'
import React from 'react'

import Form from 'components/controls/Form'
import SubmitButton from 'components/controls/SubmitButton/SubmitButton'
import Button from 'components/uiKit/Button'
import Modal, { MCWithParams, ModalHeader, ModalFooter, ModalBody } from 'components/uiKit/Modal'

import './ConfirmModal.scss'

interface IConfirmModalParams {
  title: string | JSX.Element
  info?: string | JSX.Element
  okButtonTitle: string | JSX.Element
  cancelButtonTitle: string | JSX.Element
}

const ConfirmModal: MCWithParams<IConfirmModalParams, boolean> = ({
  onClose,
  params: { title, info, okButtonTitle, cancelButtonTitle },
}) => {
  useKeyPress(['enter'], () => onClose(true))
  return (
    <Form layout='vertical' name='confirm' onSubmit={() => onClose(true)}>
      <Modal name='confirm'>
        <ModalHeader description={info} title={title} />
        <ModalBody sizeAutoCapable>
          <div />
        </ModalBody>
        <ModalFooter>
          <Button name='cancel-confirm-remove' onClick={() => onClose(false)} styleType='ghost'>
            {cancelButtonTitle}
          </Button>
          <SubmitButton label={okButtonTitle} name='confirm-remove' />
        </ModalFooter>
      </Modal>
    </Form>
  )
}

export { ConfirmModal }
