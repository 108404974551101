import { gql } from './__generated__'

export const fileObjFragment = gql(`
  fragment FileSchema on File {
    id
    metaId
    origin
    path
    ext
    size
    width
    height
    type
    key
    fileMeta {
      name
    }
  }
`)

export const fileFragment = gql(`
  fragment FileMetaSchema on FileMeta {
    id
    origin
    files {
      ...FileSchema
    }
    status
    name
    type
    size
    isArchived
    isProcessed
    createdAt
    updatedAt
    createdBy {
      id
    }
  }
`)

export const filesGroupsFragment = gql(`
  fragment FilesGroups on FileMetaGroup {
    id
    name
    color
    parentId
    lvl
    isArchived
    companyId
    createdAt
    updatedAt
    createdFor
    size
    createdBy {
      id
    }
  }
`)

export const fileMetaPreview = gql(`
  query FileMetaPreview(
    $companyId: ID!
    $parentId: ID!
    $fileId: ID!
    $search: FileMetaGroupsSearch!
  ) {
    data: fileMetaPreview(
      companyId: $companyId
      parentId: $parentId
      fileId: $fileId
      search: $search
    ) {
      fileMeta {
        ...FileMetaSchema
      }
      previousFileMetaId
      nextFileMetaId
      fileMetaIndex
      count
    }
  }
`)

export const getFileUrlInfoQuery = gql(`
  query FileUrlInfo($url: String!) {
    data: fileUrlInfo(url: $url) {
      payload {
        url
        contentType
        mediaType
        title
        siteName
        description
        images
        favicons
      }
      errors {
        url
        message
      }
    }
  }
`)

export const ExternalImageFragment = gql(`
  fragment ExternalImage on ExternalImage {
   id
    width
    height
    urls {
      raw
    }
    slug
    user {
      name
    }
  }
`)

export const UnsplashExternalImagesFragment = gql(`
  fragment UnsplashExternalImages on UnsplashExternalImages {
   total
    limit
    offset
    externalImages {
      ...ExternalImage
    }
    }
`)

export const upsertFileMeta = gql(`
  mutation UpsertFileMeta($companyId: String, $data: FileMetaUpsert!) {
    data: upsertFileMeta(companyId: $companyId, data: $data) {
      ...FileMetaSchema
    }
  }
`)

export const fileMetaGroupAllQuery = gql(`
  query FileMetaGroupAll($companyId: String!, $search: FileMetaGroupsSearch, $parentId: String) {
    breadcrumb: fileMetaGroupGetById(companyId: $companyId, id: $parentId) {
      # ...FilesGroups
      id
      name
      breadcrumbs {
        # ...FilesGroups
        id
        name
      }
    }
    data: fileMetaGroupAll(companyId: $companyId, parentId: $parentId, search: $search) {
      groups {
        ...FilesGroups
      }
      fileMetas {
        ...FileMetaSchema
      }
      total
      totalGroup
      limit
      offset
    }
  }
`)

export const fileMetaAllQuery = gql(`
  query FileMetaAll($companyId: String!, $search: FileMetaGroupsSearch, $parentId: String) {
    breadcrumb: fileMetaGroupGetById(companyId: $companyId, id: $parentId) {
      # ...FilesGroups
      id
      name
      breadcrumbs {
        # ...FilesGroups
        id
        name
      }
    }
    data: fileMetaGroupAll(companyId: $companyId, parentId: $parentId, search: $search) {
      fileMetas {
        ...FileMetaSchema
      }
      total
      totalGroup
      limit
      offset
    }
  }
`)

export const fileGroupAllQuery = gql(`
  query FileGroupAll($companyId: String!, $search: FileMetaGroupsSearch, $parentId: String) {
    breadcrumb: fileMetaGroupGetById(companyId: $companyId, id: $parentId) {
      # ...FilesGroups
      id
      name
      breadcrumbs {
        # ...FilesGroups
        id
        name
      }
    }
    data: fileMetaGroupAll(companyId: $companyId, parentId: $parentId, search: $search) {
      groups {
        ...FilesGroups
      }
      total
      totalGroup
      limit
      offset
    }
  }
`)

export const unsplashExternalImages = gql(`
  query UnsplashExternalImages($payload: UnsplashExternalImagesPayload!) {
    data: unsplashExternalImages(payload: $payload) {
      ...UnsplashExternalImages
    }
  }
`)

export const fileMetaGroupCreateMutation = gql(`
  mutation FileMetaGroupCreate($data: FileMetaGroupInput!, $companyId: String!) {
    data: fileMetaGroupCreate(data: $data, companyId: $companyId) {
      data {
        ...FilesGroups
      }
      errors {
        ...ErrorSchema
      }
    }
  }
`)

export const fileMetasUpdateById = gql(`
  mutation FileMetasUpdateById($id: String!, $data: FileMetaInput!) {
    data: fileMetasUpdateById(id: $id, data: $data) {
      ...FileMetaSchema
    }
  }
`)

export const fileMetaGroupUpdateByIdMutation = gql(`
  mutation FileMetaGroupUpdateById(
    $id: String!
    $companyId: String!
    $data: FileMetaGroupUpdateInput!
  ) {
    data: fileMetaGroupUpdateById(id: $id, companyId: $companyId, data: $data) {
      data {
        ...FilesGroups
      }
      errors {
        ...ErrorSchema
      }
    }
  }
`)

export const fileMetaAndGroupMove = gql(`
  mutation FileMetaAndGroupMove(
    $data: FileMetaAndGroupMoveInput!
    $companyId: String!
    $projectId: String
  ) {
    data: fileMetaAndGroupMove(data: $data, companyId: $companyId, projectId: $projectId)
  }
`)

export const finderForceDelete = gql(`
  mutation FinderForceDelete($data: MultiDeleteForceInput!, $companyId: String!) {
    data: fileMetaGroupMultiForceDeleteByIds(data: $data, companyId: $companyId) {
      groupIds
      elementIds
    }
  }
`)

export const unsplashCreateFileMeta = gql(`
  mutation UnsplashCreateFileMeta($payload: UnsplashCreateFileMetaPayload!) {
    data: unsplashCreateFileMeta(payload: $payload) {
      ...FileMetaSchema
    }
  }
`)

export const fileMetaGroupSubscription = gql(`
  subscription FilesGroupsSubscription(
    $companyId: String!
    $projectId: String
    $parentId: String!
  ) {
    data: fileMetaGroup(companyId: $companyId, projectId: $projectId, parentId: $parentId) {
      groups {
        ...FilesGroups
      }
      fileMetas {
        ...FileMetaSchema
      }
      type
    }
  }
`)

export const filesSubscription = gql(`
  # same like in schema
  subscription SubcribeOnCompanyFilesUpdate(
    $companyId: String!
    $projectId: String
    $parentId: String
  ) {
    data: SubcribeOnCompanyFilesUpdate(
      companyId: $companyId
      projectId: $projectId
      parentId: $parentId
    ) {
      type
      counts {
        type
        count
      }
      companyId
      projectId
      files {
        ...FileMetaSchema
      }
    }
  }
`)

export const projectFilesSubscription = gql(`
  subscription SubscribeOnProjectFilesUpdate($projectId: ID!) {
    data: fileMetaUsageSubscription(projectId: $projectId) {
      file {
        ...FileMetaSchema
      }
      projectId
    }
  }
`)
