import { SlateMark } from '@leenda/rich-text'
import { Editor } from 'slate'

import { CustomEditor } from '../richText.types'

export const getTextMark = (editor: CustomEditor) => {
  return Editor.marks(editor) || {}
}

export const setMark = (
  editor: Editor,
  format: SlateMark,
  value: string | number | boolean | undefined,
): void => {
  if (value) {
    Editor.addMark(editor, format, value)
  } else {
    Editor.removeMark(editor, format)
  }
}
