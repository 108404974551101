import { SCHEMA } from '@leenda/editor/lib/brand'

import { genFormSchema, FONT_SCHEMA_FIELDS } from 'services/Branding/genFormSchema'
import { t } from 'services/Translation'

export const STYLE_FORM_SCHEMA = genFormSchema(SCHEMA.button.style, {
  icon: {
    META: { label: t('elements.button.tags.icon') },
    backgroundImage: { label: t('input.label.icon') },
  },
  button: {
    META: { label: t('elements.button.tags.button') },
    borderStyle: {},
    borderColor: {},
    borderWidth: {},
    borderRadius: {},
  },
  button__var: {
    META: { label: t('elements.button.tags.background') },
    backgroundColor: { label: t('input.label.color') },
    backgroundImage: { label: t('input.label.image') },
  },
  disabled__var: {
    META: { label: t('elements.button.tags.inactive') },
    backgroundColor: { label: t('elements.button.schema.disabledBg') },
    backgroundImage: { label: t('input.label.image') },
  },
  padding__var: {
    META: { label: t('elements.button.tags.indents') },
    paddingTop: {},
    paddingLeft: {},
  },
})

export const FONT_FORM_SCHEMA = genFormSchema(SCHEMA.button.font, {
  base: {
    ...FONT_SCHEMA_FIELDS.base,
    META: { label: t('elements.button.tags.button') },
  },
})
