import { DragDropContext, Draggable, DropResult, Droppable } from '@hello-pangea/dnd'
import { MultipleChoiceItem } from '@leenda/editor/lib/elements'
import { textToRtValue } from '@leenda/rich-text'
import { RichTextValue } from '@leenda/rich-text'
import cn from 'classnames'
import * as R from 'ramda'
import React from 'react'

import { useElementRootStyles } from 'components/editor-v2/EditorElements/hooks/useElementRootStyles'
import AbsolutePortal from 'components/editor-v3/components/AbsolutePortal/AbsolutePortal'
import RichText from 'components/form/RichText/RichText'
import { useGetRichTextProps } from 'components/form/RichText/useGetRichTextProps'
import { IconButton } from 'components/uiKit/Button'
import { Tooltip } from 'components/uiKit/Dropdown'
import Icon from 'components/uiKit/Icon'
import { KitSize } from 'components/uiKit/KitTypes'
import { t } from 'services/Translation'
import { extendTestData } from 'utils/test/qaData'

import { TestMultipleChoiceElementType } from '../TestMultipleChoice.types'
import Checkbox from './Checkbox'
import s from './Checkbox/Checkbox.module.scss'

const TestMultipleChoiceElementEditor: TestMultipleChoiceElementType = ({
  element,
  mode,
  styles,
  block,
  waiting,
  onChange,
  font,
}) => {
  const { id: elementId, value } = element
  const { items } = value
  const rootStyles = useElementRootStyles(styles.root, {
    marginBottom: `-${styles.offset__var.marginTop || 0}`,
  })

  const { isActiveElement, activeIndex, onLabelSelect, isFill } = useGetRichTextProps({
    block,
    elementId,
    mode,
  })

  const onChangeLabel = (labelValue: RichTextValue, index: number) =>
    onChange?.(R.assocPath(['items', index, 'label'], labelValue, value))

  const handleDelete = (item: MultipleChoiceItem) => () =>
    onChange?.(
      R.assoc(
        'items',
        items.filter(({ value: id }) => id !== item.value),
        value,
      ),
    )

  const handleMove = ({ destination, source }: DropResult) =>
    destination &&
    onChange?.(R.assoc('items', R.move(source.index, destination.index, items), value))

  const handleSetValid = (index: number, isValid: boolean) => () =>
    onChange?.(R.assocPath(['items', index, 'isValid'], isValid, value))

  return (
    <div style={rootStyles}>
      <DragDropContext onDragEnd={handleMove}>
        <Droppable droppableId={elementId}>
          {(provided, { isDraggingOver }) => (
            <div ref={provided.innerRef} {...provided.droppableProps}>
              {items.map((item, index) => (
                <Draggable draggableId={item.value} index={index} key={item.value}>
                  {(provided) => (
                    <div
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      className={cn(s.wrapItem, { [s.active]: activeIndex === index.toString() })}
                      key={item.value}
                    >
                      <Checkbox
                        brandStyle={styles.text}
                        checked={item.isValid}
                        setIsValid={handleSetValid(index, !item.isValid)}
                        testData={extendTestData({
                          label: item?.label ? (item?.label[0] as any).children[0].text : '',
                          index,
                        })}
                        value={item.value}
                        isEditor
                      >
                        <span onMouseDown={(e) => onLabelSelect(index.toString(), e)}>
                          <RichText
                            active={isActiveElement && activeIndex === index.toString()}
                            disabled={!isFill}
                            name={`checkboxItem.${index}`}
                            onChange={(val) => onChangeLabel(val, index)}
                            styles={font}
                            toolbarContainerId='rich-text-toolbar'
                            value={item?.label || textToRtValue('')}
                            waiting={waiting}
                          />
                        </span>
                      </Checkbox>
                      <div
                        {...provided.dragHandleProps}
                        className={cn(s.dnd, isFill && isActiveElement && s.show)}
                      >
                        <Icon name='builderDrugAndDrop' size={KitSize.XS} />
                      </div>
                      {isFill && isActiveElement && !isDraggingOver && (
                        <AbsolutePortal
                          name='deleteMultipleChoiceItem'
                          placement='right'
                          translateX='3px'
                          zIndex={1}
                        >
                          <Tooltip overlay={t('uiKit.tooltip.deleteAnswer')}>
                            <IconButton
                              disabled={items.length < 3}
                              icon='otherClose'
                              name='deleteMultipleChoiceItem'
                              onClick={handleDelete(item)}
                              size={KitSize.XS}
                              styleType='secondary'
                            />
                          </Tooltip>
                        </AbsolutePortal>
                      )}
                    </div>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </div>
  )
}

export default TestMultipleChoiceElementEditor
