/* eslint-disable @typescript-eslint/ban-ts-comment */
import React from 'react'
import { CustomPicker, InjectedColorProps } from 'react-color'
import { Alpha, Hue, Saturation } from 'react-color/lib/components/common'
import { ColorWrapChangeHandler } from 'react-color/lib/components/common/ColorWrap'

import { preventDefault } from 'constants/commonConstans'

import HexFields from './HexFields'
import s from './ReactColorPicker.module.scss'

type Props = InjectedColorProps & {
  onChange: ColorWrapChangeHandler
}

class ReactColorPicker extends React.Component<Props> {
  render() {
    const props = { ...this.props, radius: 6, pointer: () => <div className={s.sliderPointer} /> }
    const { onChange, hex, rgb } = this.props

    return (
      <div className={s.root}>
        <div className={s.saturation} onMouseDown={preventDefault}>
          <Saturation
            {...this.props}
            pointer={() => <div className={s.saturationPointer} style={{ backgroundColor: hex }} />}
          />
        </div>
        <div className={s.body}>
          <div className={s.hue} onMouseDown={preventDefault}>
            <Hue {...props} />
          </div>
          <div className={s.alpha} onMouseDown={preventDefault}>
            <Alpha {...props} />
          </div>
          <HexFields alpha={rgb?.a} hex={hex} onChange={onChange} />
        </div>
      </div>
    )
  }
}

export default CustomPicker(ReactColorPicker as any)
