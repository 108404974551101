import { SCHEMA } from '@leenda/editor/lib/brand'
import { getParent } from 'utils'

import { genField } from 'components/controls/Field'
import { IFieldConfig } from 'components/controls/Field/Field.types'
import { genFormSchema, FONT_SCHEMA_FIELDS } from 'services/Branding/genFormSchema'
import { t } from 'services/Translation'

export const STYLE_FORM_SCHEMA = genFormSchema(SCHEMA.splitter.style, {
  base: {
    META: { label: t('elements.splitter.tags.base') },
    borderStyle: {},
    borderWidth: {
      label: t('input.label.width'),
      params: { min: 1, max: 50 },
    },
    borderColor: {},
    borderRadius: {
      params: { min: 0, max: 50 },
    },
  },
  icon: {
    META: { label: t('elements.splitter.tags.icon'), hidden: true },
    inversion: genField({
      layout: 'horizontal',
      label: t('elements.splitter.tags.icon'),
      type: 'segmented',
    }),
    backgroundImage: {
      label: t('elements.splitter.form.iconLabel'),
      layout: 'vertical',
      params: (block: unknown, config: IFieldConfig) => {
        const { parent } = getParent<{ inversion?: boolean }>(config.name, block)
        return { hidden: parent?.inversion === false }
      },
    },
  },
  splitter: {
    META: { label: t('elements.splitter.tags.splitter') },
    width: genField({
      type: 'slider',
      layout: 'horizontal',
      params: { showInput: true, min: 1, max: 50 },
    }),
    borderColor: {},
  },
  textLeft: {
    META: { label: t('elements.splitter.tags.textLeft') },
    alignItems: {},
  },
  textRight: {
    META: { label: t('elements.splitter.tags.textRight') },
    alignItems: {},
  },
  indents: {
    META: { label: t('elements.splitter.tags.indents'), hidden: true },
    padding: {
      label: t('elements.splitter.schema.innerSlideIndents'),
      layout: 'vertical',
      params: { min: 0 },
    },
  },
})

export const FONT_FORM_SCHEMA = genFormSchema(SCHEMA.splitter.font, FONT_SCHEMA_FIELDS)
