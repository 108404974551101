import React from 'react'

import Padding from 'components/editor-v3/Editor/Toolbar/Padding'
import Hint from 'components/uiKit/Hint'
import { t } from 'services/Translation'

import { TestRankingValueFormType } from './TestRankingElement.types'

const TestRankingForm: TestRankingValueFormType = () => (
  <Padding sides>
    <Hint text={t('elements.testRanking.form.randomHint')} />
  </Padding>
)
export default TestRankingForm
