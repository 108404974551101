import { useContext, createContext } from 'react'

import { Locale } from '../../gql/__generated__/graphql'
import { getCurrentLocale } from '../Translation/i18n'

const DEFAULT_LABELS = {
  [Locale.EN]: require('@leenda/editor/lib/brand/labels/en.json'),
  [Locale.RU]: require('@leenda/editor/lib/brand/labels/ru.json'),
  [Locale.DE]: require('@leenda/editor/lib/brand/labels/de.json'),
  [Locale.FR]: require('@leenda/editor/lib/brand/labels/fr.json'),
  [Locale.IT]: require('@leenda/editor/lib/brand/labels/it.json'),
  [Locale.ES]: require('@leenda/editor/lib/brand/labels/es.json'),
  [Locale.PT]: require('@leenda/editor/lib/brand/labels/pt.json'),
  [Locale.JA]: require('@leenda/editor/lib/brand/labels/ja.json'),
  [Locale.TR]: require('@leenda/editor/lib/brand/labels/tr.json'),
  [Locale.PL]: require('@leenda/editor/lib/brand/labels/pl.json'),
  [Locale.SK]: require('@leenda/editor/lib/brand/labels/sk.json'),
  [Locale.RO]: require('@leenda/editor/lib/brand/labels/ro.json'),
  [Locale.CN]: require('@leenda/editor/lib/brand/labels/cn.json'),
  [Locale.UA]: require('@leenda/editor/lib/brand/labels/ua.json'),
  [Locale.CUSTOM]: {},
}
type ProjectLabels = (typeof DEFAULT_LABELS)[Locale.EN]

const ProjectLabelsContext = createContext(DEFAULT_LABELS[Locale.EN])

export const getDefaultLabels = () => {
  const locale = getCurrentLocale()
  return DEFAULT_LABELS[locale]
}

export const ProjectLabelsProvider = ProjectLabelsContext.Provider

export const useProjectT = () => {
  const labels = useContext(ProjectLabelsContext)
  return (key: keyof ProjectLabels) => labels[key]
}
