import moment, { Moment } from 'moment'

import Icon from 'components/uiKit/Icon'
import { EditorSectionInput } from 'gql/__generated__/graphql'
import { SectionTypeEnum } from 'services/Store/Project/enums'

import { getEnumLabel } from './enum'

export * from './pSBC'

const DEFAULT_TEST_VARIABLE = {
  questionOrder: 'default',
  isNextOnSubmit: true,
  isResultValuePercent: true,
  isValidationVisible: true,
  testThreshold: 80,
  testRetryCount: 1,
  testLimit: false,
  isShuffleQuestions: false,
  isProgressShown: true,
  time: null,
  isUnableToExit: false,
  randomCount: null,
}

const BYTES_IN_MB = 1024 * 1024

export const reorder = <T,>(list: T[], startIndex: number, endIndex: number) => {
  const result = Array.from(list)
  const [removed] = result.splice(startIndex, 1)
  result.splice(endIndex, 0, removed)

  return result
}

export const swapItems = <T,>(list: T[], startIndex: number, endIndex: number) => {
  const result = [...list]

  result[startIndex] = list[endIndex]
  result[endIndex] = list[startIndex]

  return result
}

export const IconSectionsMap = {
  [SectionTypeEnum.landing]: {
    icon: <Icon name='contentsLanding' />,
    name: getEnumLabel('SectionTypeEnum', SectionTypeEnum.landing),
  },
  [SectionTypeEnum.test]: {
    icon: <Icon name='contentsTest' />,
    name: getEnumLabel('SectionTypeEnum', SectionTypeEnum.test),
  },
  [SectionTypeEnum.cover]: {
    icon: <Icon name='contentsSlides' />,
    name: getEnumLabel('SectionTypeEnum', SectionTypeEnum.cover),
  },
  [SectionTypeEnum.chapter]: {
    icon: <Icon name='contentsChapter' />,
    name: getEnumLabel('SectionTypeEnum', SectionTypeEnum.chapter),
  },
}

export function videoFormat(seconds?: number) {
  if (typeof seconds !== 'number') {
    return '--:--'
  }
  const date = new Date(seconds * 1000)
  const hh = date.getUTCHours()
  const mm = date.getUTCMinutes()
  const ss = pad(date.getUTCSeconds())

  if (isNaN(hh) || isNaN(mm)) {
    return '--:--'
  }

  if (hh) {
    return `${hh}:${pad(mm)}:${ss}`
  }
  return `${mm}:${ss}`
}

export const generateSection = (
  name: string,
  type: SectionTypeEnum,
  lvl: number,
): EditorSectionInput => {
  const isChapter = type === SectionTypeEnum.chapter
  const isTest = type === SectionTypeEnum.test
  return {
    formats: [],
    isChapter,
    tags: [],
    totalCost: 0,
    description: '',
    name,
    type,
    blocksOrder: [],
    deadline: isChapter ? getRandomInt(0, 5) : undefined,
    lvl,
    test: isTest ? DEFAULT_TEST_VARIABLE : null,
  }
}

export const filePortal = document.createElement('div')
filePortal.className = 'file-portal'

document.body.appendChild(filePortal)

export const formatTimePickerValue = (value?: number | null) => moment.utc((value || 0) * 1000)

export const timePickerGetSeconds = (
  time: Moment | number | null,
  timeFormat?: string,
): number | null => {
  let seconds
  if (time === null) {
    return null
  }
  if (typeof time !== 'number') {
    const sec = moment(time, timeFormat).seconds()
    const min = moment(time, timeFormat).minutes()
    const hour = moment(time, timeFormat).hours()
    seconds = hour * 3600 + min * 60 + sec
  } else {
    seconds = Number(time) / 1000
  }

  return seconds
}

function pad(string: number) {
  return ('0' + string).slice(-2)
}

function getRandomInt(min: number, max: number) {
  const minInt = Math.ceil(min)
  const maxInt = Math.floor(max)
  return Math.floor(Math.random() * (maxInt - minInt + 1)) + minInt
}

export const convertMbToBytes = (size: number) => {
  return size * BYTES_IN_MB
}

export const timeGetSeconds = (value: string, format?: 'HH:mm:ss' | 'mm:ss' | 'HH:mm') => {
  const [seconds, minutes, hours] = value
    .split(':')
    .reverse()
    .map((part) => (part ? Number(part) : 0))

  switch (format) {
    case 'HH:mm:ss':
      return hours * 3600 + minutes * 60 + seconds
    case 'mm:ss':
      return minutes * 60 + seconds
    case 'HH:mm':
      return hours * 3600 + minutes * 60
    default:
      throw new Error('Invalid format')
  }
}

export const secondsToTime = (seconds: number, format: 'HH:mm:ss' | 'mm:ss' | 'HH:mm') => {
  const hoursNum = Math.floor(seconds / 3600)
  const minutesNum = Math.floor((seconds - hoursNum * 3600) / 60)
  const secsNum = Math.trunc(seconds - hoursNum * 3600 - minutesNum * 60)

  const padStartZero = (num: number) => (num < 10 ? `0${num}` : num)

  const hours = padStartZero(hoursNum)
  const minutes = padStartZero(minutesNum)
  const secs = padStartZero(secsNum)

  switch (format) {
    case 'HH:mm:ss':
      return `${hours}:${minutes}:${secs}`
    case 'mm:ss':
      return `${minutes}:${secs}`
    case 'HH:mm':
      return `${hours}:${minutes}`
    default:
      throw new Error('Invalid format')
  }
}

export const determineTimeFormat = (seconds: number): 'HH:mm:ss' | 'mm:ss' => {
  return seconds < 3600 ? 'mm:ss' : 'HH:mm:ss'
}
