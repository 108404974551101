import { Middleware } from '@reduxjs/toolkit'
import lodash from 'lodash'

import { ScormStatusMode, SuspendDataType } from 'gql/__generated__/graphql'
import { SaveType, SCORM_API_Wrapper } from 'scormWrapper/vendors/SCORM_API_Wrapper'
import { ScormSettings } from 'services/Store/Project/types'

import { isAction } from '../actionTypeGuard'
import { setCourseStatus } from '../actions'
import { ProjectActionsTypes } from '../reducer'
import {
  getBlockId,
  getBlockState,
  getCourseState,
  getSection,
  getProgress,
  getScormSettings,
  getSectionId,
  getTestScore,
} from '../selectors'
import { stateToSuspendData } from '../suspendData'

export const NAVIGATE_ACTIONS: ProjectActionsTypes[keyof ProjectActionsTypes][] = [
  'project/setCourseNavigation',
  'project/courseStart',
  'project/courseContinue',
  'project/testStart',
  'project/testRestart',
  'project/testEnd',
]

SCORM_API_Wrapper.init(true)

export const scorm: Middleware = (store) => (next) => (actionPlain) => {
  const action = actionPlain as { type: ProjectActionsTypes[keyof ProjectActionsTypes] }
  const result = next(action)
  const state = store.getState().project
  const scormSettings = getScormSettings(state)

  if (SCORM_API_Wrapper.isConnected() && scormSettings) {
    const flushActions: SaveType[] = []
    let needCommit = false
    const { sectionId: finalTestId, debug, data } = scormSettings || {}
    const sectionId = getSectionId(state)
    const blockId = getBlockId(state)

    const courseState = getCourseState(state)
    const finished = courseState.status === 'finished'
    SCORM_API_Wrapper.setSuspendData(stateToSuspendData(state))

    const isViewMode = !finalTestId

    if (!finished) {
      if (finalTestId) {
        if (sectionId === finalTestId) {
          const progress = getProgress(state, finalTestId)
          const progressMeasure = progress / 100
          SCORM_API_Wrapper.setValue('cmi.progress_measure', progressMeasure.toString())
          if (blockId && isAction(action, 'questionValidate')) {
            const section = getSection(state)
            const questionsOrder = section?.blocksOrder.filter(
              (blockId) =>
                section.blocks.find((block) => block.uuid === blockId)?.mode === 'questions',
            )
            const testBlock = getBlockState(state)

            const result = getQuestionResult(testBlock?.result?.isValid)
            const weighting = '1'
            const index = questionsOrder?.findIndex((item) => item === blockId) || 0
            SCORM_API_Wrapper.setInteraction(index, { id: blockId, result, weighting })
            flushActions.push('suspend')
          }
          if (isAction(action, 'testEnd')) {
            const score = getTestScore(state, finalTestId)
            SCORM_API_Wrapper.setValue('cmi.score.min', '0')
            SCORM_API_Wrapper.setValue(
              'cmi.score.max',
              scormSettings.data?.scoreType === 'percent' ? '100' : score.total.toString(),
            )
            SCORM_API_Wrapper.setValue(
              'cmi.score.raw',
              scormSettings.data?.scoreType === 'percent'
                ? score.percent.toString()
                : score.success.toString(),
            )
            SCORM_API_Wrapper.setValue('cmi.score.scaled', score.scaled.toString())
            const statuses = getTestStatuses(score, scormSettings)
            SCORM_API_Wrapper.setValue('cmi.success_status', statuses.successStatus)
            SCORM_API_Wrapper.setValue('cmi.completion_status', statuses.completionStatus)
            flushActions.push('test')
            flushActions.push('suspend')
            store.dispatch(setCourseStatus(statuses.completionStatus))
          }
          if (isAction(action, 'testRestart')) {
            SCORM_API_Wrapper.setValue('cmi.score.raw', '0')
            SCORM_API_Wrapper.setValue('cmi.score.scaled', '0')
            SCORM_API_Wrapper.setValue('cmi.progress_measure', '0')
            SCORM_API_Wrapper.setValue('cmi.success_status', 'unknown')
            SCORM_API_Wrapper.setValue('cmi.completion_status', 'incomplete')
            flushActions.push('test')
            flushActions.push('suspend')
            store.dispatch(setCourseStatus('incomplete'))
          }
        }
      } else if (isViewMode) {
        const progress = getProgress(state)
        const progressMeasure = progress / 100
        const progressChanged = SCORM_API_Wrapper.setValue(
          'cmi.progress_measure',
          progressMeasure.toString(),
        )
        if (scormSettings.data?.usedStatues === ScormStatusMode.passedIncomplete) {
          const updated = SCORM_API_Wrapper.setValue(
            'cmi.score.raw',
            Math.round(progress).toString(),
          )
          SCORM_API_Wrapper.setValue('cmi.score.scaled', progressMeasure.toString())
          updated && flushActions.push('test')
        }
        const completed = progress >= (scormSettings.courseThresholdFinish || 0)
        if (progressChanged && completed) {
          if (scormSettings.data?.usedStatues === ScormStatusMode.passedIncomplete) {
            SCORM_API_Wrapper.setValue('cmi.success_status', 'passed')
            flushActions.push('test')
          }
          const completionChanged = SCORM_API_Wrapper.setValue('cmi.completion_status', 'completed')
          if (
            completionChanged &&
            courseState.status !== 'completed'
            // courseState.status !== 'finished'
          ) {
            store.dispatch(setCourseStatus('completed'))
          }
        }
      }
    }

    if (NAVIGATE_ACTIONS.includes(action.type)) {
      SCORM_API_Wrapper.setValue('cmi.location', location.hash.slice(1))
      if (data?.suspendDataType.includes(SuspendDataType.navigation)) {
        flushActions.push('suspend')
      }
      needCommit = true
    }

    if (isAction(action, 'courseEnd')) {
      if (debug) {
        SCORM_API_Wrapper.saveDebugData()
      }
      SCORM_API_Wrapper.exit()
      window.close()
    }
    if (isAction(action, 'courseStart')) {
      SCORM_API_Wrapper.setValue('cmi.completion_status', 'incomplete', true)
      if (isViewMode && scormSettings.data?.usedStatues === ScormStatusMode.passedIncomplete) {
        SCORM_API_Wrapper.setValue('cmi.score.min', '0')
        SCORM_API_Wrapper.setValue('cmi.score.max', '100')
        flushActions.push('test')
      }
    }
    lodash.uniq(flushActions).forEach((saveType) => SCORM_API_Wrapper.flushType(saveType))
    if (needCommit) {
      SCORM_API_Wrapper.commit()
    }
  }
  return result
}

const getTestStatuses = (score: { percent: number }, scormSettings: ScormSettings) => {
  const isPassed = score.percent >= (scormSettings.testThreshold || 0)
  let successStatus: 'unknown' | 'failed' | 'passed' = 'unknown'
  let completionStatus: 'incomplete' | 'completed' = 'incomplete'
  if (scormSettings.data?.usedStatues === ScormStatusMode.passedFailed) {
    successStatus = isPassed ? 'passed' : 'failed'
    completionStatus = 'completed'
  } else if (scormSettings.data?.usedStatues === ScormStatusMode.completeFailed) {
    if (isPassed) {
      completionStatus = 'completed'
    } else {
      successStatus = 'failed'
    }
  } else if (scormSettings.data?.usedStatues === ScormStatusMode.completeIncomplete) {
    completionStatus = isPassed ? 'completed' : 'incomplete'
  } else if (scormSettings.data?.usedStatues === ScormStatusMode.passedIncomplete) {
    if (isPassed) {
      successStatus = 'passed'
      completionStatus = 'completed'
    }
  }
  return {
    successStatus,
    completionStatus,
  }
}

const getQuestionResult = (isValid?: boolean) => {
  if (isValid) {
    return 'correct'
  }
  if (isValid === false) {
    return 'incorrect'
  }
  return 'unanticipated'
}
