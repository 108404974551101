import { SCHEMA } from '@leenda/editor/lib/brand'

import { genFormSchema, FONT_SCHEMA_FIELDS } from 'services/Branding/genFormSchema'
import { t } from 'services/Translation'

export const STYLE_FORM_SCHEMA = genFormSchema(SCHEMA.courseStructure.style, {
  icon: {
    META: { label: t('elements.courseStructure.tags.icon') },
    color: { label: t('input.label.icon') },
  },
  progress: {
    META: { label: t('elements.courseStructure.tags.progress') },
    backgroundColor: {},
    color: { label: t('elements.courseStructure.tags.progress') },
    borderColor: {},
    borderStyle: {},
    borderWidth: {},
  },
  offsetFromCollapse__var: {
    META: {
      label: t('elements.courseStructure.tags.collapseIndents'),
      hidden: true,
      hiddenDivider: true,
    },
    marginRight: { label: t('elements.courseStructure.tags.collapseIndents') },
  },
  offsetFromIcon__var: {
    META: {
      label: t('elements.courseStructure.tags.iconIndents'),
      hidden: true,
      hiddenDivider: true,
    },
    marginRight: { label: t('elements.courseStructure.tags.iconIndents') },
  },
  offsetFromProgress__var: {
    META: {
      label: t('elements.courseStructure.tags.progressIndents'),
      hidden: true,
      hiddenDivider: true,
    },
    marginRight: { label: t('elements.courseStructure.tags.progressIndents') },
  },
  offsetFromSection__var: {
    META: {
      label: t('elements.courseStructure.tags.sectionIndents'),
      hidden: true,
      hiddenDivider: true,
    },
    marginTop: { label: t('elements.courseStructure.tags.sectionIndents') },
  },
})

export const FONT_FORM_SCHEMA = genFormSchema(SCHEMA.courseStructure.font, {
  chapter: {
    ...FONT_SCHEMA_FIELDS.base,
    META: { label: t('elements.courseStructure.tags.chapter') },
    marginTop: {},
  },
  description: {
    ...FONT_SCHEMA_FIELDS.base,
    META: { label: t('elements.courseStructure.tags.description') },
  },
  section: {
    ...FONT_SCHEMA_FIELDS.base,
    META: { label: t('elements.courseStructure.tags.section') },
    marginTop: {},
  },
})
