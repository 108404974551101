import { SlateElementType, SlateMark } from '@leenda/rich-text'
import * as R from 'ramda'
import React, { useCallback, useContext, useMemo } from 'react'

import { RichTextContext } from 'components/RichText/context'
import { IconButton } from 'components/uiKit/Button'
import Checkbox from 'components/uiKit/Checkbox'
import Dropdown, { Tooltip } from 'components/uiKit/Dropdown'
import Icon from 'components/uiKit/Icon'
import { KitSize } from 'components/uiKit/KitTypes'
import Select from 'components/uiKit/Select'
import { preventDefault } from 'constants/commonConstans'
import { t } from 'services/Translation'

import CheckboxTool from '../components/CheckboxTool'
import OtherSettings from '../components/OtherSettings/OtherSettings'
import SymbolItem from '../components/SymbolItem'
import { TEXT_TYPE_OPTIONS } from '../toolbarOptions'
import { MARKS, symbols } from '../toolbarOptions'
import s from './DefaultForm.module.scss'

const calcTop = (
  rootRef: React.RefObject<HTMLDivElement>,
  formRef: { width: number; height: number },
  _anchor: DOMRect,
  focus: DOMRect,
) => {
  const rootRect = rootRef.current?.getBoundingClientRect()
  const focusTop = focus.top - (rootRect?.top || 0)
  const toolbarHeight = formRef?.height || 0
  const top = focusTop - 8 - toolbarHeight
  return top
}

const calcLeft = (
  rootRef: React.RefObject<HTMLDivElement>,
  formRef: { width: number; height: number },
  anchor: DOMRect,
  focus: DOMRect,
) => {
  const rootRect = rootRef.current?.getBoundingClientRect()
  const focusLeft = focus.left - (rootRect?.left || 0)
  const focusTop = focus.top - (rootRect?.top || 0)
  const anchorLeft = anchor.left - (rootRect?.left || 0)
  const anchorTop = anchor.top - (rootRect?.top || 0)
  const toolbarWidth = formRef?.width || 0

  const left = R.clamp(
    0,
    R.max(1, (rootRect?.width || 0) - toolbarWidth),
    focusTop < anchorTop
      ? focusLeft
      : focusTop > anchorTop
        ? focusLeft - toolbarWidth
        : focusLeft < anchorLeft || focusTop < anchorTop
          ? focusLeft
          : focusLeft - toolbarWidth,
  )
  return left
}

interface IDefaultFormProps {
  anchor?: DOMRect
  focus?: DOMRect
  size?: { width: number; height: number }
  full?: boolean
}

const DefaultForm = React.forwardRef<HTMLDivElement, IDefaultFormProps>(
  ({ anchor, focus, size, full }, ref) => {
    const { format, rootRef, mouseDown, setForm, onUpdateFormat } = useContext(RichTextContext)
    const value = TEXT_TYPE_OPTIONS.find((item) => format[item.value])?.value

    const onLinkClick = useCallback(() => setForm('link'), [setForm])

    const top = anchor && focus && size && calcTop(rootRef, size, anchor, focus)
    const left = anchor && focus && size && calcLeft(rootRef, size, anchor, focus)

    const marks = useMemo(() => (full ? MARKS : [SlateMark.bold, SlateMark.italic]), [full])

    return (
      <div
        className={s.root}
        ref={ref}
        style={{
          opacity: 1,
          transform: `translate(${left}px, ${top}px)`,
          transition: 'transform 0.3s ease, opacity 0.1s ease',
          ...(mouseDown && { pointerEvents: 'none', opacity: 0.5 }),
        }}
      >
        {full && (
          <>
            <Select
              name='textType'
              onChange={(value) => onUpdateFormat(value)}
              options={TEXT_TYPE_OPTIONS}
              size={KitSize.S}
              styleType='ghost'
              value={value || SlateElementType.elementDefault}
              fluid
            />
            <div className={s.delimiter} />
          </>
        )}
        <div className={s.group}>
          {marks.map((mark) => (
            <CheckboxTool key={mark} name={mark} />
          ))}
        </div>
        <div className={s.delimiter} />
        <Checkbox
          name='link'
          onChange={onLinkClick}
          onMouseDown={preventDefault}
          value={!!format.inline}
          hideCheckbox
        >
          <Icon name='link2' size={KitSize.S} />
        </Checkbox>
        {full && (
          <>
            <div className={s.delimiter} />
            <Dropdown
              overlay={
                <div className={s.symbols}>
                  {symbols.map((symbol, index) => (
                    <SymbolItem key={index} name={symbol} />
                  ))}
                </div>
              }
              placement='bottomLeft'
              trigger={['click']}
            >
              <Tooltip
                overlay={t('uiKit.tooltip.symbols')}
                placement='top'
                trigger={['hoverWithoutContent']}
              >
                <IconButton icon='builderSymbol' name='symbol' size={KitSize.S} styleType='ghost' />
              </Tooltip>
            </Dropdown>
            <div className={s.delimiter} />

            <Dropdown overlay={<OtherSettings />} placement='bottomLeft'>
              <IconButton
                icon='iconsOtherMore'
                name='otherMore'
                size={KitSize.S}
                styleType='ghost'
              />
            </Dropdown>
          </>
        )}
      </div>
    )
  },
)
DefaultForm.displayName = 'DefaultForm'

export default DefaultForm
