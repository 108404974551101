import { TestHotspotElementValue } from '@leenda/editor/lib/elements'

import { genDataField } from 'components/controls/Field'
import RealtimeField from 'components/controls/RealtimeField'
import Crop from 'components/controls/customComponents/Crop'
import Padding from 'components/editor-v3/Editor/Toolbar/Padding'
import Collapse from 'components/uiKit/Collapse'
import { FULL_COLLAPSE_PADDING } from 'constants/styles'
import { Block } from 'services/Store/Project/types'
import { t } from 'services/Translation'
import { chainName, getParent } from 'utils/form'

import s from './TestHotspotElement.module.scss'
import { TestHotspotValueFormType } from './TestHotspotElement.types'

const genField = genDataField<Block>()

const responseOptions = [
  { value: 'single', label: t('elements.testHotspot.form.response.any') },
  { value: 'all', label: t('elements.testHotspot.form.response.all') },
]

const typeOptions = [
  { value: 'icon', label: t('input.option.icons') },
  { value: 'withoutIcon', label: t('input.option.withoutIcon') },
]

const response = genField({
  name: 'response',
  label: t('elements.testHotspot.form.response.label'),
  type: 'segmented',
  layout: ['horizontal', 'solid'],
  defaultValue: 'all',
  params: { options: responseOptions },
})

const image = genField({
  name: 'image',
  type: 'file',
  layout: 'horizontal',
  params: { fileType: 'image', nullable: true },
})

const crop = genField({
  name: 'image',
  type: 'custom',
  label: t('elements.labeledGraphic.form.actions'),
  layout: 'horizontal',
  component: Crop,
  params: (block, { name }) => {
    const { parent } = getParent<TestHotspotElementValue>(name, block, 1)
    return {
      hidden: !parent?.image?.id,
    }
  },
})

const type = genField({
  name: 'type',
  type: 'select',
  label: t('input.label.type'),
  layout: 'horizontal',
  params: { options: typeOptions, fluid: true },
  defaultValue: 'icon',
})

const TestHotspotForm: TestHotspotValueFormType = ({ name }) => {
  return (
    <>
      <Padding bottom sides top>
        <RealtimeField config={chainName(name, response)} />
        {/* TODO: fix after hint refactoring */}
        <div className={s.hint}>{t('elements.testHotspot.form.hint')}</div>
      </Padding>
      <Collapse
        header={t('elements.labeledGraphic.form.image')}
        iconPosition='right'
        name='image'
        paddings={FULL_COLLAPSE_PADDING}
        initialOpen
      >
        <RealtimeField config={chainName(name, image)} />
        <RealtimeField config={chainName(name, crop)} />
      </Collapse>
      <Collapse
        header={t('elements.testHotspot.tags.spot')}
        iconPosition='right'
        name='spot'
        paddings={FULL_COLLAPSE_PADDING}
        initialOpen
      >
        <RealtimeField config={chainName(name, type)} />
      </Collapse>
    </>
  )
}

export default TestHotspotForm
