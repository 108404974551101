import React from 'react'
import { createContext } from 'use-context-selector'

import { NOOP } from 'constants/commonConstans'

import { BoxType } from './types'

interface IAbsolutePortalContextContext {
  containerRef: React.RefObject<HTMLDivElement>
  portals: Record<string, BoxType | null>
  setPortals: React.Dispatch<React.SetStateAction<Record<string, BoxType | null>>>
}

const AbsolutePortalContext = createContext<IAbsolutePortalContextContext>({
  containerRef: { current: null },
  portals: {},
  setPortals: NOOP,
})

AbsolutePortalContext.Provider.displayName = 'AbsolutePortalContext.Provider'

export default AbsolutePortalContext
