import { RichTextFontSchemaType } from '@leenda/editor/lib/brand'
import React from 'react'
import { Text } from 'slate'

import { ElementFontCss } from 'services/Branding/types'

import ElementResolver from './nodes/Elements'
import TextComponent from './nodes/Text'
import s from './styles/RichText.module.scss'
import { useFontToVars } from './useFontToVars'

const serializeLeaves: any = (
  node: any,
  index: number,
  styles: ElementFontCss<RichTextFontSchemaType>,
) => {
  if (Text.isText(node)) {
    const nodeText = (node.text as string) || '\uFEFF'

    return (
      <TextComponent key={`${index}_${nodeText?.length}_${nodeText[0]}`} leaf={node}>
        {nodeText}
      </TextComponent>
    )
  }

  if (Array.isArray(node)) {
    return node.map((subNode, i) => serializeLeaves(subNode, i, styles))
  }
  return serializeElements(node, index, styles)
}

const serializeElements = (
  node: any,
  index: any,
  styles: ElementFontCss<RichTextFontSchemaType>,
) => {
  const children = node.children?.map((n: any, i: number) => serializeLeaves(n, i, styles))
  return (
    <ElementResolver
      element={node}
      key={`${node.type}_${index}_${node.children?.length}`}
      styles={styles}
    >
      {children}
    </ElementResolver>
  )
}

interface IProps {
  styles: ElementFontCss<RichTextFontSchemaType>
  value: any
  verticalAlign?: 'start' | 'center' | 'end'
  onClick?: (event: React.MouseEvent<HTMLDivElement>) => void
}

export const RichTextViewer: React.FC<IProps> = ({ value, verticalAlign, styles, onClick }) => {
  const style = useFontToVars(styles)
  return (
    <div className={s.container} style={{ ...style, justifyContent: verticalAlign }}>
      {value?.map((item: any, i: number) => serializeLeaves(item, i, styles, onClick))}
    </div>
  )
}
