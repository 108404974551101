export const LEVEL_INDENT = 42
export const CHAPTER_WITH_CHILDREN_OFFSET = 12
export const LINE_CHAPTER_OFFSET = 23
export const MAX_NESTED_LEVEL = 6
export const CIRCLE_DRAG_LINE_WIDTH = 6
export const MAX_LENGTH_SECTION_TITLE = 248
export const HOVERED_CHAPTER_OPEN_DELAY = 500
export const SECTION_HEIGHT = 43

export enum IconDirection {
  left = 'left',
  right = 'right',
}
