import { Block } from '@leenda/editor'
import { gqlClient } from 'gql'
import lodash from 'lodash'
import { useCallback } from 'react'

import { notify } from 'components/uiKit/Notification'
import { NotificationType } from 'components/uiKit/Notification/types'
import { useBlocksCopy } from 'gql/blocks/apollo'
import { blocksGetById } from 'gql/blocks/gql/queries'
import { ScrollType } from 'services/Scroll/ScrollService'
import { ScrollContainerEnum } from 'services/Scroll/enums'
import { setProjectNavigation, setNavbar, updateBlock } from 'services/Store/Project/actions'
import { BlockMode, NavigationEnum, PreviewMode } from 'services/Store/Project/enums'
import { useProjectContext, useProjectDispatch } from 'services/Store/Project/hooks'
import {
  getBlock,
  getNavBar,
  getNodeId,
  getSection,
  getSectionId,
  getSelectedBlocksIds,
} from 'services/Store/Project/selectors'
import store from 'services/Store/store'
import { t } from 'services/Translation'
import { isCtrl } from 'utils/events'

import { ICopyData } from './EditorHotkeys'
import { pasteNode } from './mutators/block'

export const useOnClickBlock = (mode?: BlockMode) => {
  const dispatch = useProjectDispatch()
  const { tab: navbar } = useProjectContext(getNavBar)

  return useCallback(
    (
      event: React.MouseEvent | undefined,
      {
        blockId,
        nodeId,
        previewMode,
      }: { blockId: string | null; nodeId?: string | null; previewMode: PreviewMode },
      scroll?: ScrollType,
    ) => {
      if (previewMode === PreviewMode.editor) {
        dispatch(
          setProjectNavigation({
            blockId: blockId || mode,
            nodeId: nodeId || null,
            ctrl: isCtrl(event),
            shift: event?.shiftKey,
            scroll,
          }),
        )
      }
      if (navbar === NavigationEnum.project) {
        dispatch(setNavbar({ tab: null }))
      }
    },
    [dispatch, mode, navbar],
  )
}

export const useBlocksDuplicate = (paramsSectionId?: string) => {
  const dispatch = useProjectDispatch()
  const secId = useProjectContext(getSectionId)
  const sectionId = paramsSectionId || secId
  const [copyBlocks] = useBlocksCopy(sectionId)

  return useCallback(
    async (copedBlockIds?: string[]) => {
      const projectState = store.getState().project
      const selectedBlockIds = getSelectedBlocksIds(projectState)
      const { blocksOrder } = getSection(projectState) || { blocksOrder: [] as string[] }
      const orderedSelectedBlocks = blocksOrder.filter((id) => selectedBlockIds.includes(id))
      const lastSelected = orderedSelectedBlocks[orderedSelectedBlocks.length - 1]
      const lastSelectedIndex = blocksOrder.findIndex((id) => id === lastSelected)
      const uuids = lodash.sortBy(copedBlockIds || selectedBlockIds, (id) =>
        blocksOrder.indexOf(id),
      )
      const res = await copyBlocks({
        variables: {
          payload: {
            uuids,
            activeBlockUUID: lastSelected || null,
            sectionId: sectionId || '',
          },
        },
      })
      const newBlocksOrder = res.data?.data.blocksOrder
      if (newBlocksOrder) {
        notify({
          message: t('notify.pasteBlockToAnotherSection.success', {
            count: selectedBlockIds.length,
          }),
          type: NotificationType.success,
        })
        const newSelectedBlocks = newBlocksOrder.slice(
          lastSelectedIndex + 1,
          lastSelectedIndex + selectedBlockIds.length + 1,
        )
        if (res.data?.data.blocks.length) {
          const blocksIds = [...res?.data?.data.blocks].map((b) => b.uuid)
          dispatch(
            setProjectNavigation({
              blockId: newSelectedBlocks[0],
              blockIds: blocksIds,
              nodeId: null,
              sectionId,
              scroll: [
                {
                  container: ScrollContainerEnum.canvas,
                  id: newSelectedBlocks[0],
                  block: 'begin',
                  scroll: ['never', 'always'],
                },
                {
                  container: ScrollContainerEnum.blocksNav,
                  id: newSelectedBlocks[0],
                  block: 'begin',
                  scroll: 'always',
                },
              ],
            }),
          )
          dispatch(setNavbar({ tab: NavigationEnum.section }))
        }
      }
    },
    [copyBlocks, dispatch, sectionId],
  )
}

export const useNodeDuplicate = () => {
  const dispatch = useProjectDispatch()
  const block = useProjectContext(getBlock)
  const nodeId = useProjectContext(getNodeId)

  const onChange = useCallback(
    async (value: unknown) => block?.uuid && dispatch(updateBlock({ id: block.uuid, value })),
    [dispatch, block],
  )

  return useCallback(
    async (data: ICopyData) => {
      const blockData = await gqlClient.core.query<{ data: Block }>({
        query: blocksGetById,
        variables: {
          uuid: data.blockId || '',
          sectionId: data.sectionId || '',
        },
      })

      const fromBlock = blockData.data.data
      const fromNodeId = data.nodeId

      if (fromNodeId && fromBlock && nodeId && block) {
        const update = pasteNode(fromBlock, block, fromNodeId, nodeId, false)
        if (update) {
          onChange(update.block)
        } else {
          notify({
            message: 'Paste failed',
            type: NotificationType.info,
          })
        }
      }
    },
    [block, nodeId, onChange],
  )
}
