import numeral from 'numeral'
import React from 'react'

import { CreatedBy } from 'components/pages/FinderPage/FinderTable/config'
import { FileComponent, FolderComponent } from 'components/uiKit/Table'
import { ColumnsType } from 'components/uiKit/Table/types'
import { t } from 'services/Translation'
import { FinderItem } from 'store/models/entry.model'
import { testProps } from 'utils/test/qaData'
import { isFinderFile } from 'utils/useFinder'

export const columnsConfig = (): ColumnsType<FinderItem> => [
  {
    title: t('input.label.name'),
    dataIndex: 'name',
    width: 330,
    render: (_text, record) => <NameComponent record={record} />,
  },
  {
    title: t('input.label.size'),
    dataIndex: 'size',
    align: 'right',
    width: 100,
    render: (size) => Number.isInteger(size) && numeral(size).format('0.0 b'),
  },
  {
    title: t('common.createdBy'),
    dataIndex: 'createdBy',
    width: 200,
    render: (createdBy) => <CreatedBy createdBy={createdBy} />,
  },
]

interface IProps {
  record: FinderItem
}

const NameComponent: React.FC<IProps> = ({ record }) => {
  if (isFinderFile(record)) {
    return <FileComponent file={record} />
  }

  if (!record.isArchived) {
    return (
      <div
        style={record.isArchived ? { filter: 'grayscale(100%)' } : {}}
        {...testProps({
          el: 'folder',
          name: record.name,
        })}
      >
        <FolderComponent folder={record} />
      </div>
    )
  }

  return null
}
