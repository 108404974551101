import cn from 'classnames'
import React, { useEffect, useRef, useState } from 'react'

import { testProps } from 'utils/test/qaData'

import { KitSize } from '../KitTypes'
import s from './InputNumber.module.scss'

type InputDefaultType = Omit<
  React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>,
  'onChange' | 'type' | 'prefix' | 'size'
>

export interface IInputNumberProps extends InputDefaultType {
  value?: number
  onChange?: (value: number) => void
  onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void
  // TODO: add XS
  size?: KitSize
  name?: string
  min?: number
  max?: number
  isInteger?: boolean
  defaultValue?: number
  placeholder?: string
  disabled?: boolean
  emptyValue?: any
  prefix?: React.ReactNode
  postfix?: React.ReactNode
  width?: number
  error?: boolean
}

const parse = (str: string) => parseFloat(str)

const format = (value: number | undefined) =>
  value === undefined || value === null ? '' : String(value)

const validate = (
  value: number,
  min: number | undefined,
  max: number | undefined,
  isInteger: boolean | undefined,
) => {
  let res = true
  if (min !== undefined && value < min) {
    res = false
  }
  if (max !== undefined && value > max) {
    res = false
  }
  if (!Number.isInteger(value) && isInteger) {
    res = false
  }
  return res
}

const InputNumber: React.FC<IInputNumberProps> = ({
  name,
  max,
  min,
  isInteger,
  value,
  onChange,
  onBlur,
  onFocus,
  placeholder,
  disabled,
  emptyValue,
  prefix,
  postfix,
  width,
  size = 'medium',
  error,
  ...rest
}) => {
  const [textValue, setTextValue] = useState<string>(format(value))
  const [isFocus, setIsFocus] = useState(false)
  const inputRef = useRef<HTMLInputElement>(null)

  useEffect(() => {
    const formatted = format(value)
    if (!isFocus && formatted !== textValue) {
      setTextValue(formatted)
    }
  }, [isFocus, value])

  const onInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value
    setTextValue(value)
    let parsedValue: number /* | undefined  */ = parse(value)
    parsedValue = max === undefined ? parsedValue : Math.min(max, parsedValue)
    parsedValue = min === undefined ? parsedValue : Math.max(min, parsedValue)
    parsedValue = Number.isNaN(parsedValue) ? emptyValue : parsedValue
    onChange?.(parsedValue)
  }

  const onFocusHandle = (event: React.FocusEvent<HTMLInputElement>) => {
    onFocus && onFocus(event)
    setIsFocus(true)
  }

  const onBlurHandle = (event: React.FocusEvent<HTMLInputElement>) => {
    onBlur && onBlur(event)
    setIsFocus(false)
  }

  const validateError = isFocus
    ? textValue !== format(parse(textValue)) || !validate(parse(textValue), min, max, isInteger)
    : value && !validate(value, min, max, isInteger)

  const rootCn = cn(s.root, s[size], 'ui-kit-input-number', {
    [s.error]: validateError, // || error,
    [s.focus]: isFocus,
    [s.disabled]: disabled,
  })

  // console.log({ value, textValue })

  return (
    <div className={rootCn} style={{ width }}>
      {prefix && prefix}
      <input
        {...rest}
        disabled={disabled}
        onBlur={onBlurHandle}
        onChange={onInputChange}
        onFocus={onFocusHandle}
        placeholder={placeholder}
        ref={inputRef}
        value={textValue}
        {...testProps({ el: 'inputNumber', value, name, disabled, focus: isFocus })}
      />
      {postfix && postfix}
    </div>
  )
}

export default InputNumber
