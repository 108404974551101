import { ListFontSchemaType } from '@leenda/editor/lib/brand'
import { ListItem } from '@leenda/editor/lib/elements'
import { generateId } from '@leenda/editor/lib/utils/id'
import { RichTextValue } from '@leenda/rich-text'
import { useInViewport } from 'ahooks'
import * as R from 'ramda'
import { useCallback, useEffect, useRef } from 'react'

import { EMPTY_RICH_TEXT } from 'components/form/RichText/richText.constants'
import { ElementFontCss } from 'services/Branding/types'

import { DEFAULT_MARGIN_TOP, DEFAULT_SIZE, POINT_SIZE } from './ListConstants'
import s from './ListElement.module.scss'
import { ListElementType } from './ListElement.types'
import ListMarker from './elements/ListMarker'

type IListItemProps = {
  item: ListItem
  i: number
  font?: ElementFontCss<ListFontSchemaType>
  onViewIndex: (i: number) => void
  rtProps: {
    onLabelSelect: (index: string, e?: React.MouseEvent) => void
    isActiveElement: boolean
    isFill: boolean
    activeIndex: string
    cursorPosition: 'start' | 'end'
  }
} & Parameters<ListElementType>[0]

const ListItemComponent: React.FC<IListItemProps> = ({
  element,
  styles,
  onChange,
  i,
  onViewIndex,
  item,
  rtProps,
  waiting,
  font,
}) => {
  const name = `listItem.${i}`
  const ref = useRef<HTMLLIElement>(null)
  const { value } = element
  const width = value.mode === 'point' ? POINT_SIZE : DEFAULT_SIZE
  const paddingLeft = width + parseInt(styles.indent.marginRight as string)

  const onChangeLabel = useCallback(
    async (labelValue: RichTextValue | typeof EMPTY_RICH_TEXT, index: number) => {
      if (labelValue === EMPTY_RICH_TEXT) {
        if (value.items.length > 1) {
          const newItems = value.items.filter((_, i) => i !== index)
          await onChange?.(R.assoc('items', newItems, value))
          rtProps.onLabelSelect(`listItem.${index - 1}`)
        }
        return
      } else if (labelValue.length > 1) {
        const filteredItems = value.items.filter((_, i) => i !== index)
        const newItems = labelValue.map((item, i) => ({
          label: [item],
          value: generateId(),
          number: index + i + 1,
          icon: null,
        }))

        await onChange?.(R.assoc('items', R.insertAll(index, newItems, filteredItems), value))
        rtProps.onLabelSelect(`listItem.${index + newItems.length - 1}`)
      } else {
        onChange?.(R.assocPath(['items', index, 'label'], labelValue, value))
      }
    },
    [onChange, value, rtProps.onLabelSelect],
  )

  const [inViewPort] = useInViewport(ref)
  useEffect(() => {
    if (inViewPort) {
      onViewIndex(i)
    }
  }, [inViewPort])

  return (
    <li
      className={s.list__items}
      key={item.value}
      onMouseDown={(e) => rtProps.onLabelSelect(name, e)}
      ref={ref}
      style={{
        paddingLeft,
        marginTop: i === 0 ? DEFAULT_MARGIN_TOP : styles.indent.marginTop,
      }}
    >
      <ListMarker
        active={rtProps.isActiveElement && rtProps.activeIndex === name}
        cursorPosition={rtProps.cursorPosition}
        font={font}
        index={i}
        isFill={rtProps.isFill}
        item={item}
        mode={value.mode}
        name={name}
        onChange={onChangeLabel}
        onDown={
          i < value.items.length - 1 ? () => rtProps.onLabelSelect(`listItem.${i + 1}`) : undefined
        }
        onUp={i > 0 ? () => rtProps.onLabelSelect(`listItem.${i - 1}`) : undefined}
        styles={styles}
        waiting={waiting}
      />
    </li>
  )
}

export default ListItemComponent
