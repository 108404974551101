import cn from 'classnames'
import React, { useMemo } from 'react'

import { testPropsEl } from 'utils/test/qaData'

import {
  useSelectSize,
  useSelectStyleType,
  useSelectContext,
  useSelectRootRef,
} from '../SelectContext'
import SelectIcon from '../SelectIcon'
import SelectInput from '../SelectInput'
import SelectRootItem from '../SelectRootItem'
import { isEmptyValue, mapValue } from '../SelectUtils'
import s from './SelectRoot.module.scss'

const SelectRoot = () => {
  const rootRef = useSelectRootRef()
  const size = useSelectSize()
  const minWidth = useSelectContext((c) => c.minWidth)
  const styleType = useSelectStyleType()
  const fluid = useSelectContext((c) => c.fluid)
  const value = useSelectContext((c) => c.value)
  const disabled = useSelectContext((c) => c.disabled)
  const readOnly = useSelectContext((c) => c.readOnly)
  const name = useSelectContext((c) => c.name)
  const inputable = useSelectContext((c) => c.inputable)
  const map = useSelectContext((c) => c.map)
  const error = useSelectContext((c) => c.error)
  const inputValue = useSelectContext((c) => c.inputValue)
  const focus = useSelectContext((c) => c.focus)
  const placeholder = useSelectContext((c) => c.placeholder)

  const items = useMemo(() => mapValue(map, value), [value, map])

  const cnRoot = cn(s.root, s[size], s[styleType], {
    [s.disabled]: disabled,
    [s.inputable]: inputable,
    [s.fluid]: fluid,
    [s.focus]: focus && !readOnly,
    [s.error]: error,
  })
  const cnPlaceholder = cn(s.placeholder, s[styleType], s[size])
  const cnValue = cn(s.value, s[size], s[styleType])

  return (
    <div
      {...testPropsEl(
        'select',
        { name, value },
        { disabled, label: String(items.map((item) => item.textLabel || item.label)) },
      )}
      className={cnRoot}
      ref={rootRef}
      style={{ minWidth }}
    >
      <div className={cnValue}>
        {items.map((item) => (
          <SelectRootItem item={item} key={String(item.value)} />
        ))}
        <SelectInput />
        {isEmptyValue(value) && !inputValue && <div className={cnPlaceholder}>{placeholder}</div>}
      </div>
      <SelectIcon name={name} />
    </div>
  )
}

export default React.memo(SelectRoot)
