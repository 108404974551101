import { useLayoutEffect, useRef, useState } from 'react'
import { Editor } from 'slate'
import { ReactEditor, useSlate, useSlateSelection } from 'slate-react'

export const useSelectionRect = () => {
  const editor = useSlate()
  const [focusRect, setFocusRect] = useState<DOMRect>()
  const lastFocusRect = useRef<DOMRect>()
  const [anchorRect, setAnchorRect] = useState<DOMRect>()
  const lastAnchorRect = useRef<DOMRect>()
  const { anchor, focus } = useSlateSelection() || {}

  useLayoutEffect(() => {
    setTimeout(() => {
      try {
        const focusRange = focus && Editor.range(editor, focus, focus)
        const focusDomRange = focusRange && ReactEditor.toDOMRange(editor, focusRange)
        const focusRect = focusDomRange?.getBoundingClientRect()
        lastFocusRect.current = focusRect
        setFocusRect(focusRect)
      } catch (error) {
        setFocusRect(lastFocusRect.current)
      }
    })
  }, [editor, focus, editor.children])

  useLayoutEffect(() => {
    setTimeout(() => {
      try {
        const anchorRange = anchor && Editor.range(editor, anchor, anchor)
        const anchorDomRange = anchorRange && ReactEditor.toDOMRange(editor, anchorRange)
        const anchorRect = anchorDomRange?.getBoundingClientRect()
        lastAnchorRect.current = anchorRect
        setAnchorRect(anchorRect)
      } catch (error) {
        setAnchorRect(lastAnchorRect.current)
      }
    })
  }, [editor, anchor, editor.children])

  return { focus: focusRect, anchor: anchorRect }
}
