import { SlateElementMark, SlateMark } from '@leenda/rich-text'
import { useContext } from 'react'

import { SlateCommand } from 'components/form/RichText/richText.constants'
import { RichTextContext } from 'components/form/RichText/richText.context'
import Radio from 'components/uiKit/Radio'
import { t } from 'services/Translation'

import HideControl from '../../HideControl'
import { ALIGN_OPTIONS, REGISTER_OPTIONS, TRANSFORM_OPTIONS } from '../../toolbarOptions'
import RadioButtonTool from '../RadioButtonTool'
import s from './OtherSettings.module.scss'

const OtherSettings = () => {
  const { onUpdateFormat, value, controls } = useContext(RichTextContext)
  return (
    <div className={s.other}>
      <HideControl enabled={controls} name='textAlign'>
        <div className={s.list}>
          <div className={s.titleList}>{t('input.label.align')}</div>
          <Radio
            direction='horizontal'
            name='textAlign'
            onChange={(value) => onUpdateFormat(SlateElementMark.textAlign, value)}
            options={ALIGN_OPTIONS}
            value={value[SlateElementMark.textAlign]}
            hideRadio
          />
        </div>
      </HideControl>

      <HideControl enabled={controls} name='textTransform'>
        <div className={s.list}>
          <div className={s.titleList}>{t('elements.richText.settings.register')}</div>
          <Radio
            direction='horizontal'
            fluid={false}
            name='textTransform'
            onChange={(value) => onUpdateFormat(SlateMark.textTransform, value)}
            options={TRANSFORM_OPTIONS}
            value={value[SlateMark.textTransform]}
            hideRadio
          />
        </div>
      </HideControl>

      <HideControl enabled={controls} name='insertSymbol'>
        <div className={s.list}>
          <div className={s.titleList}>{t('uiKit.tooltip.insertNpbs')}</div>
          <RadioButtonTool name={SlateCommand.insertNpbs} />
        </div>
      </HideControl>

      <HideControl enabled={controls} name='register'>
        <div className={s.list}>
          <div className={s.titleList}>{t('input.label.view')}</div>
          <Radio
            direction='horizontal'
            fluid={false}
            name='register'
            onChange={(value) => onUpdateFormat(SlateMark.register, value)}
            options={REGISTER_OPTIONS}
            value={value[SlateMark.register]}
            hideRadio
          />
        </div>
      </HideControl>
      <div className={s.delimiter} />
      <div className={s.list}>
        <div className={s.titleList}>{t('elements.richText.settings.reset')}</div>
        <RadioButtonTool name={SlateCommand.clear} />
      </div>
    </div>
  )
}

export default OtherSettings
