import cn from 'classnames'
import React, { useCallback } from 'react'

import EditorElementComponent from 'components/editor-v2/EditorElements/EditorElementComponent'
import { NodeType } from 'components/editor-v3/types/date.enums'
import { setElement } from 'services/Store/Project/actions'
import { useProjectContext, useProjectDispatch } from 'services/Store/Project/hooks'
import { getElementState } from 'services/Store/Project/selectors'
import { ElementState } from 'services/Store/Project/types'

import { FCNode } from '../../Node/types'
import s from './PreviewShell.module.scss'

const PreviewShell: FCNode<NodeType.shell> = React.forwardRef(
  ({ mode, block, style, wrapperStyle, node }, ref) => {
    const { type, elementId } = node
    const dispatch = useProjectDispatch()
    const shellCn = cn(s.shell, s[type])
    const elementState = useProjectContext(getElementState, elementId || '')

    const handleSetState = useCallback(
      (value: ElementState) => elementId && dispatch(setElement({ id: elementId, value })),
      [elementId],
    )
    const elementNode = elementId && block.elements[elementId] && (
      <EditorElementComponent
        block={block}
        element={block.elements[elementId]}
        elementState={elementState}
        mode={mode}
        setState={handleSetState}
        shellStyles={style}
      />
    )

    const cnRoot = cn(s.wrapper, {
      [s.zeroLineHeight]: elementId && block.elements[elementId]?.type === 'image',
    })

    return (
      <div className={cnRoot} ref={ref} style={wrapperStyle}>
        <div className={shellCn} style={style}>
          {elementNode}
        </div>
      </div>
    )
  },
)

PreviewShell.displayName = 'PreviewShell'

export default PreviewShell
