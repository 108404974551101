import Padding from 'components/editor-v3/Editor/Toolbar/Padding'
import Hint from 'components/uiKit/Hint'
import { t } from 'services/Translation'

import { TestMatchingValueFormType } from './TestMatchingElement.types'

const TestMatchingForm: TestMatchingValueFormType = () => (
  <Padding sides>
    <Hint text={t('elements.testMatching.form.randomHint')} />
  </Padding>
)
export default TestMatchingForm
