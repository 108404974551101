import { SCHEMA } from '@leenda/editor/lib/brand'

import { genFormSchema, FONT_SCHEMA_FIELDS } from 'services/Branding/genFormSchema'
import { t } from 'services/Translation'

export const STYLE_FORM_SCHEMA = genFormSchema(SCHEMA.audio.style, {
  border: {
    META: { label: t('elements.audio.tags.border') },
    borderRadius: {},
    borderStyle: {},
    borderColor: {},
    borderWidth: {},
  },
  icons: {
    META: { hidden: true },
    color: { label: t('input.label.iconColor') },
  },
  player: {
    META: { hidden: true },
    backgroundColor: { label: t('elements.video.tags.player') },
  },
  slider: {
    META: { hidden: true },
    backgroundColor: { label: t('elements.video.tags.slider') },
  },
  sliderFilled: {
    META: { hidden: true },
    backgroundColor: { label: t('elements.video.tags.sliderFilled') },
  },
})

export const FONT_FORM_SCHEMA = genFormSchema(SCHEMA.audio.font, {
  META: {},
  player: {
    ...FONT_SCHEMA_FIELDS.base,
    META: { label: t('elements.audio.tags.player') },
  },
  tooltip: {
    ...FONT_SCHEMA_FIELDS.base,
    META: { label: t('elements.audio.tags.tooltip') },
  },
})
