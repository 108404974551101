import { useSize } from 'ahooks'
import cn from 'classnames'
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react'
import { useEffect, useMemo, useRef } from 'react'
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'

import { LayoutScroll } from 'components/LayoutPage'
import AbsolutePortal from 'components/editor-v3/components/AbsolutePortal'
import { stopPropagation } from 'constants/commonConstans'
import { PreviewMode } from 'services/Store/Project/enums'

import { useTableContext } from '../../TableContext'
import { isElementInViewport } from '../../utils'
import OptionsMenu from '../OptionsMenu'
import Row from '../Row'
import TableDragLayer from '../TableDragLayer'
import s from './Table.module.scss'

const Table = () => {
  const { cellNodes, tableRef, tableValue, setDndControlsCoordinates, selectedCells, mode } =
    useTableContext()
  const containerRef = useRef<HTMLDivElement>(null)
  const scrollRef = useRef<OverlayScrollbarsComponent>(null)
  const selectedAreaRef = useRef<HTMLDivElement>(null)
  const { height: tableHeight } = useSize(tableRef) || {}
  const portalDeps = useMemo(() => [tableRef.current?.clientWidth], [tableRef.current?.clientWidth])

  const containerWidth = containerRef.current?.parentElement?.parentElement?.clientWidth
  const tableWidth = tableRef.current?.clientWidth
  const selectedZoneRectStyle = useMemo(() => {
    if (!selectedCells.length) {
      return undefined
    }

    const { row: startRow, col: startCol } = selectedCells[0]
    const { row: endRow, col: endCol } = selectedCells[selectedCells.length - 1]

    if (!cellNodes?.[startRow]?.[startCol] || !cellNodes?.[endRow]?.[endCol]) {
      return undefined
    }

    const startCell = cellNodes?.[startRow]?.[startCol]
    const endCell = cellNodes?.[endRow]?.[endCol]

    const startCellRect = startCell.getBoundingClientRect()
    const endCellRect = endCell.getBoundingClientRect()

    const tableRect = tableRef.current?.getBoundingClientRect()

    if (!tableRect) {
      return undefined
    }

    const top = startCellRect.top - tableRect.top
    const left = startCellRect.left - tableRect.left
    const width = endCellRect.right - startCellRect.left
    const height = endCellRect.bottom - startCellRect.top

    return { top, left, width, height }
  }, [selectedCells, tableHeight])

  useEffect(() => {
    const instance = scrollRef.current?.osInstance()
    const scrollContainer = scrollRef.current?.osTarget()
    if (instance && selectedAreaRef.current && scrollContainer) {
      if (!isElementInViewport(selectedAreaRef.current, scrollContainer)) {
        instance.scroll({ el: selectedAreaRef.current, block: 'nearest', scroll: 'ifneeded' }, 100)
      }
    }
  }, [selectedCells, tableRef.current?.clientHeight])

  const isPdf = mode.previewMode === PreviewMode.pdf

  if (isPdf && containerWidth && tableWidth) {
    return (
      <DndProvider backend={HTML5Backend}>
        <div className={cn(s.tableContainer, s.pdf)} ref={containerRef}>
          <table
            className={s.table}
            ref={tableRef}
            style={{ transform: `scale(${containerWidth / tableWidth})` }}
          >
            <tbody>
              {tableValue.cells.map((_, i) => (
                <Row index={i} key={i} />
              ))}
            </tbody>
          </table>
          <TableDragLayer />
        </div>
      </DndProvider>
    )
  }

  return (
    <DndProvider backend={HTML5Backend}>
      <LayoutScroll ref={scrollRef} sizeAutoCapable>
        <div
          className={cn(s.tableContainer, {
            [s.center]: mode.previewMode === PreviewMode.demo,
          })}
          onWheelCapture={stopPropagation}
          ref={containerRef}
        >
          <table
            className={s.table}
            onMouseLeave={() => setDndControlsCoordinates({ x: null, y: null })}
            ref={tableRef}
          >
            <tbody>
              {tableValue.cells.map((_, i) => (
                <Row index={i} key={i} />
              ))}
            </tbody>
          </table>

          <TableDragLayer />

          {!!selectedCells.length && (
            <div className={s.selectedArea} ref={selectedAreaRef} style={selectedZoneRectStyle} />
          )}
        </div>
      </LayoutScroll>
      {!!selectedCells.length && (
        <AbsolutePortal deps={portalDeps} name='tableOptions' placement='topRight' zIndex={5}>
          <OptionsMenu containerWidth={containerWidth} />
        </AbsolutePortal>
      )}
    </DndProvider>
  )
}

export default Table
