import { SCHEMA } from '@leenda/editor/lib/brand'

import { genField } from 'components/controls/Field'
import { IArrayOption } from 'components/controls/inputComponents/FieldNumber/FieldNumber'
import { Tooltip } from 'components/uiKit/Dropdown'
import Icon from 'components/uiKit/Icon'
import { genFormSchema, FONT_SCHEMA_FIELDS } from 'services/Branding/genFormSchema'
import { t } from 'services/Translation'

import { ArrowPosition } from './Slider.types'
import indentsHint from './assets/indentsHint.svg'

const OFFSET_ARRAY: IArrayOption[] = [
  {
    icon: (
      <Tooltip overlay={t('input.tooltip.leftMargin')}>
        <Icon name='builderInputBack' />
      </Tooltip>
    ),
  },
  {
    icon: (
      <Tooltip overlay={t('input.tooltip.rightMargin')}>
        <Icon name='builderInputForward' />
      </Tooltip>
    ),
  },
  {
    icon: (
      <Tooltip overlay={t('input.tooltip.bottomMargin')}>
        <Icon name='builderInputDown' />
      </Tooltip>
    ),
  },
]

export const STYLE_FORM_SCHEMA = genFormSchema(SCHEMA.slider.style, {
  activeColor__var: {
    META: {},
    backgroundColor: { label: t('elements.slider.schema.activePoint') },
  },
  inactiveColor__var: {
    META: {},
    backgroundColor: { label: t('elements.slider.schema.inactivePoint') },
  },
  progress: {
    META: { label: t('elements.slider.tags.progress') },
    marginLeft: {
      label: t('elements.slider.schema.spacing'),
      params: { placeholder: '8px', min: 1, max: 48 },
    },
    height: {
      params: { placeholder: '6px', min: 1, max: 48 },
    },
    width: {
      params: { placeholder: '6px', min: 1, max: 48 },
    },
  },
  icons: {
    META: { label: t('elements.slider.tags.icons') },
    sliderPosition: genField({
      type: 'select',
      label: t('elements.slider.schema.position'),
      layout: 'horizontal',
      params: {
        fluid: true,
        options: [
          { label: t('input.option.slide'), value: ArrowPosition.slide },
          { label: t('input.option.progress'), value: ArrowPosition.progress },
        ],
      },
    }),
  },
  leftArrow__var: {
    META: { label: t('elements.slider.tags.leftArrow') },
    backgroundImage: { label: t('input.label.image') },
  },
  rightArrow__var: {
    META: {
      label: t('elements.slider.tags.rightArrow'),
    },
    backgroundImage: { label: t('input.label.image') },
  },
  indents: {
    META: { label: t('elements.slider.tags.indents'), hidden: true },
    margin: {
      label: t('input.label.innerIndents'),
      layout: 'vertical',
      info: <img alt='image' src={indentsHint} />,
      params: { array: OFFSET_ARRAY },
    },
  },
})

export const FONT_FORM_SCHEMA = genFormSchema(SCHEMA.slider.font, {
  base: {
    ...FONT_SCHEMA_FIELDS.base,
  },
})
