import { TabsFontSchemaType, TabsSchemaType } from '@leenda/editor/lib/brand'
import { useSize } from 'ahooks'
import cn from 'classnames'
import React, {
  useState,
  useEffect,
  ReactNode,
  useRef,
  CSSProperties,
  useCallback,
  useMemo,
} from 'react'

import { ElementFontCss, ElementStyleCss } from 'services/Branding/types'
import { testPropsEl } from 'utils/test/qaData'

import TabContent from './TabContent'
import TabItem from './TabItem'
import { ITabPanelProps } from './TabPanel/TabPanel'
import s from './Tabs.module.scss'
import iconLeftDefault from './icon_left.svg'
import iconRightDefault from './icon_right.svg'
import { useMobileEvents } from './useMobileEvents'

export interface ITabsProps {
  children: ReactNode
  name: string
  defaultActiveKey?: string
  onChange: (tabKey: string) => void
  styles: ElementStyleCss<TabsSchemaType>
  activeKey?: string
  styleContent: CSSProperties
  mobile: boolean
  font?: ElementFontCss<TabsFontSchemaType>
}

const OFFSET_TAB_OF_CONTAINER = 70
const PADDING_TAB_X = 40

const Tabs: React.FC<ITabsProps> = ({
  children,
  name,
  onChange,
  styles,
  defaultActiveKey,
  activeKey: activeKeyProps,
  styleContent,
  mobile,
  font,
}) => {
  const ref = useRef<HTMLDivElement>(null)
  const iconLeft = styles?.iconLeft.backgroundImage
  const iconRight = styles?.iconRight.backgroundImage
  const borderWidth = styles?.border__var.borderWidth
  const [activeKeyState, setActiveKeyState] = useState(defaultActiveKey || '')
  const [drawArrowIconRight, setDrawArrowIconRight] = useState(false)
  const [drawArrowIconLeft, setDrawArrowIconLeft] = useState(false)
  const scrollableTabsContainer = useRef<HTMLDivElement>(null)
  const activeTabItemRef = useRef<HTMLDivElement>(null)
  const size = useSize(ref)

  const tabList: string[] = []
  React.Children.forEach(children, (child: React.ReactNode) => {
    if (!React.isValidElement<ITabPanelProps>(child)) {
      return
    }
    const element: React.ReactElement<ITabPanelProps> = child

    tabList.push(element.props.tabKey)
  })

  const activeKey = tabList.includes(activeKeyState)
    ? activeKeyState
    : defaultActiveKey && tabList.includes(defaultActiveKey)
      ? defaultActiveKey
      : tabList[0]

  const handleNext = (isRight: boolean) => {
    const directionScroll = isRight ? 1 : -1

    const indexItem = tabList.indexOf(activeKey)

    if (tabList[indexItem + directionScroll]) {
      setActiveKeyState(tabList[indexItem + directionScroll])
    }
  }

  const onChangeTab = (tabKey: string) => {
    setActiveKeyState(tabKey)
    onChange && onChange(tabKey)
  }

  const checkShowArrows = useCallback(
    (scrollLeft: number, scrollWidth: number, clientWidth: number) => {
      if (scrollLeft > 0) {
        setDrawArrowIconLeft(true)
      }
      if (scrollLeft === 0) {
        setDrawArrowIconLeft(false)
      }

      if (scrollWidth > Math.ceil(scrollLeft + clientWidth)) {
        setDrawArrowIconRight(true)
      }
      if (Math.ceil(scrollLeft + clientWidth) >= scrollWidth) {
        setDrawArrowIconRight(false)
      }
    },
    [],
  )

  const onScroll = (e: React.UIEvent<HTMLElement>) => {
    const target = e.target as HTMLElement
    const scrollLeft = target.scrollLeft || 0
    const scrollWidth = target.scrollWidth || 0
    const clientWidth = target.clientWidth || 0
    checkShowArrows(scrollLeft, scrollWidth, clientWidth)
  }

  const { onTouchEnd, onTouchMove, onTouchStart } = useMobileEvents((isRight: boolean) =>
    handleNext(isRight),
  )

  const maxWidthTabItem = useMemo(
    () =>
      (scrollableTabsContainer.current?.clientWidth || 0) -
      OFFSET_TAB_OF_CONTAINER * 2 -
      PADDING_TAB_X -
      parseInt(borderWidth?.toLocaleString() || '1') * 2,
    [borderWidth],
  )

  useEffect(() => {
    if (ref.current && activeTabItemRef.current && scrollableTabsContainer.current) {
      const { left: scrollContainerLeft, width: scrollContainerWidth } =
        ref.current.getBoundingClientRect()
      const { left: activeTabLeft, width: activeTabWidth } =
        activeTabItemRef.current.getBoundingClientRect()

      const shiftScroll =
        activeTabLeft -
        scrollContainerLeft -
        scrollContainerWidth +
        activeTabWidth +
        OFFSET_TAB_OF_CONTAINER
      // auto-scroll on change active tab
      scrollableTabsContainer.current.scrollLeft += shiftScroll
    }
  }, [activeKey])

  useEffect(() => {
    if (activeKeyProps !== undefined) {
      setActiveKeyState(activeKeyProps)
    }
  }, [activeKeyProps])

  useEffect(() => {
    const clientWidth = scrollableTabsContainer.current?.clientWidth || 0
    const scrollWidth = scrollableTabsContainer.current?.scrollWidth || 0
    const scrollLeft = scrollableTabsContainer.current?.scrollLeft || 0
    checkShowArrows(scrollLeft, scrollWidth, clientWidth)
  }, [size, activeKey, children, checkShowArrows])

  return (
    <div className={s.root} {...testPropsEl('tabs', { name })} ref={ref} style={styles.root}>
      <div className={s.controls}>
        {drawArrowIconLeft && (
          <span className={cn(s.arrowLeft, mobile && s.mobile)} onClick={() => handleNext(false)}>
            {iconLeft ? (
              <div className={s.leftIcon} style={{ backgroundImage: iconLeft }} />
            ) : (
              <img src={iconLeftDefault} />
            )}
          </span>
        )}
        <div className={s.tabListWrap} onScroll={onScroll} ref={scrollableTabsContainer}>
          <div className={s.tabList}>
            {React.Children.map(children, (child: React.ReactNode) => {
              if (!React.isValidElement<ITabPanelProps>(child)) {
                return null
              }
              const element: React.ReactElement<ITabPanelProps> = child
              const { title, tabKey, disabled } = element.props

              const active = activeKey === tabKey

              return (
                <TabItem
                  active={active}
                  disabled={disabled}
                  font={font}
                  key={tabKey}
                  maxWidth={maxWidthTabItem}
                  mobile={mobile}
                  onChangeTab={onChangeTab}
                  ref={(active && activeTabItemRef) || null}
                  tabKey={tabKey}
                  title={title}
                />
              )
            })}
          </div>
        </div>
        {drawArrowIconRight && (
          <span className={cn(s.arrowRight, mobile && s.mobile)} onClick={() => handleNext(true)}>
            {iconRight ? (
              <div className={s.rightIcon} style={{ backgroundImage: iconRight }} />
            ) : (
              <img src={iconRightDefault} />
            )}
          </span>
        )}
      </div>
      <div
        className={s.content}
        onTouchEnd={onTouchEnd}
        onTouchMove={onTouchMove}
        onTouchStart={onTouchStart}
      >
        {React.Children.map(children, (child: React.ReactNode) => {
          if (!React.isValidElement<ITabPanelProps>(child)) {
            return null
          }
          const element: React.ReactElement<ITabPanelProps> = child
          const { children, tabKey } = element.props
          if (tabKey !== activeKey) {
            return null
          }
          return (
            <TabContent hidden={tabKey !== activeKey} key={tabKey} styles={styleContent}>
              {children}
            </TabContent>
          )
        })}
      </div>
    </div>
  )
}

export default Tabs
