import { SCHEMA } from '@leenda/editor/lib/brand'

import { genFormSchema, FONT_SCHEMA_FIELDS } from 'services/Branding/genFormSchema'
import { t } from 'services/Translation'

export const STYLE_FORM_SCHEMA = genFormSchema(SCHEMA.testMultipleChoice.style, {
  text: {
    META: { label: t('elements.testMultipleChoice.tags.text') },
    borderStyle: {},
    borderColor: {},
    borderWidth: {},
    padding: {},
  },
  offset__var: {
    META: { label: t('elements.testMultipleChoice.tags.indents') },
    marginTop: {},
  },
  checkbox__var: {
    META: { label: t('elements.testMultipleChoice.tags.checkbox') },
    color: { label: t('input.label.accent') },
  },
})

export const FONT_FORM_SCHEMA = genFormSchema(SCHEMA.testMultipleChoice.font, FONT_SCHEMA_FIELDS)
