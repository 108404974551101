import { SectionTypeEnum } from '@leenda/editor/lib/elements'
import { useCallback, useMemo, useState } from 'react'

import SubmitButton from 'components/controls/SubmitButton/SubmitButton'
import {
  disableCloneBlock,
  getBlocksError,
  getPreparedNameBlocksError,
} from 'components/editor-v3/context/EditorContext/EditorHotkeys'
import { useBlocksDuplicate } from 'components/editor-v3/context/EditorContext/actions'
import { listToTree } from 'components/sections/ProjectStructure/utils'
import Button from 'components/uiKit/Button'
import { KitSize } from 'components/uiKit/KitTypes'
import Modal, { MCWithParams, ModalBody, ModalFooter, ModalHeader } from 'components/uiKit/Modal'
import { notify } from 'components/uiKit/Notification'
import { NotificationType } from 'components/uiKit/Notification/types'
import Search from 'components/uiKit/Search'
import { NOOP } from 'constants/commonConstans'
import { useProjectContext } from 'services/Store/Project/hooks'
import { getSections, getSelectedBlocksIds } from 'services/Store/Project/selectors'
import { Block, Section } from 'services/Store/Project/types'
import { t } from 'services/Translation'

import SectionPlaneSearch from '../../Navigation/NavigationProject/components/SectionPlaneSearch/SectionPlaneSearch'
import SectionsTreeEditor from '../../Navigation/NavigationProject/components/SectionsTreeEditor'
import s from './MoveBlockToAnotherSectionModal.module.scss'

const DISABLED_SELECTED_TYPES = [SectionTypeEnum.chapter, SectionTypeEnum.cover]

const canSectionSelect = (section: Section) =>
  [SectionTypeEnum.landing, SectionTypeEnum.test].includes(section.type) && !section.isDone

const MoveBlockToAnotherSectionModal: MCWithParams<{ selectedBlocks: Block[] }> = ({
  params,
  onClose,
}) => {
  const { selectedBlocks } = params
  const selectedBlockIds = useProjectContext(getSelectedBlocksIds)
  const [selectedSection, setSelectedSection] = useState<Section>()
  const [searchValue, setSearch] = useState<string>()
  const cloneBlocks = useBlocksDuplicate(selectedSection?.id)
  const disabledMoveBlocks = selectedBlocks.find((block) => disableCloneBlock(block))
  const errorBlocks = useMemo(
    () => getBlocksError(selectedBlocks, selectedSection),
    [selectedBlocks, selectedSection],
  )
  const blockType = getPreparedNameBlocksError(errorBlocks)

  const sections = useProjectContext(getSections)
  const nodes = useMemo(() => listToTree(sections), [sections])

  const onSectionPick = useCallback(async (section: Section) => {
    if (canSectionSelect(section)) {
      setSelectedSection(section)
    }
  }, [])

  const onSubmit = useCallback(async () => {
    if (selectedSection?.id) {
      await cloneBlocks()
      onClose()
    }
  }, [onClose, selectedBlockIds, selectedBlocks, selectedSection, cloneBlocks])

  if (disabledMoveBlocks) {
    notify({
      type: NotificationType.error,
      message: t('notify.moveBlockToAnotherSection.error', {
        count: errorBlocks.length,
        blockType,
      }),
    })
    onClose()
    return null
  }

  const resultSections = sections.filter(
    (e) =>
      !e.isChapter && e.name?.toLocaleLowerCase().includes((searchValue || '').toLocaleLowerCase()),
  )

  return (
    <Modal maxWidth={864} minHeight={650} name='blockMoveToAnotherSectionModal'>
      <ModalHeader
        description={t('modal.moveBlockToAnotherSection.description')}
        title={t('modal.moveBlockToAnotherSection.title')}
      >
        <div className={s.search}>
          <Search
            name='blockMoveToAnotherSectionModalSearch'
            onChange={setSearch}
            size={KitSize.S}
            value={searchValue}
            wait={500}
            fluid
          />
        </div>
      </ModalHeader>
      <ModalBody sizeAutoCapable>
        <div className={s.content}>
          {searchValue ? (
            <SectionPlaneSearch
              disabledByTypes={DISABLED_SELECTED_TYPES}
              onSelect={onSectionPick}
              resultSections={resultSections}
              searchValue={searchValue}
              sectionId={selectedSection?.id}
              setIsEditSectionId={NOOP}
            />
          ) : (
            <SectionsTreeEditor
              disabledByTypes={DISABLED_SELECTED_TYPES}
              nodes={nodes}
              onSectionPick={onSectionPick}
              sectionId={selectedSection?.id}
              setIsEditSectionId={NOOP}
              hideActions
              initialOpenCollapse
            />
          )}
        </div>
      </ModalBody>
      <ModalFooter>
        <Button name='cancel' onClick={() => onClose()} styleType='ghost'>
          {t('uiKit.button.cancel')}
        </Button>
        <SubmitButton
          disabled={!selectedSection}
          label={t('uiKit.button.copy')}
          name='move'
          onClick={onSubmit}
        />
      </ModalFooter>
    </Modal>
  )
}

export default MoveBlockToAnotherSectionModal
