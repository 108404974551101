import { PermissionsKey } from '@leenda/crud'
import { t } from 'i18next'
import { useMemo } from 'react'
import { FileTypeEnum } from 'utils'

import { usePreferencesTab } from 'components/editor-v2/EditorElements/elements/TabsElement/Tabs/hooks'
import { FileMetaFor } from 'gql/__generated__/graphql'
import { usePermissions } from 'services/Permission/PermissionContext'

import { FinderTabsEnum } from './types'

interface IUseGetTabsOptions {
  projectId?: string
  contentType?: FileTypeEnum
  showExternalImages?: boolean | null
  tabs?: FinderTabsEnum[]
}

export const ACTIVE_TAB_KEY = 'modalPickFile-activeTab'

const COMPANY_TABS = [FinderTabsEnum.companies, FinderTabsEnum.unsplash]
const PROJECT_TABS = [FinderTabsEnum.projects, ...COMPANY_TABS]

const getTabConfig = (finderHidden: boolean, externalImageHidden: boolean) => ({
  [FinderTabsEnum.companies]: {
    tabKey: FinderTabsEnum.companies,
    title: t('page.finder.tabs.company'),
    value: FileMetaFor.companies,
    hidden: finderHidden,
  },
  [FinderTabsEnum.projects]: {
    tabKey: FinderTabsEnum.projects,
    title: t('page.finder.tabs.project'),
    value: FileMetaFor.projects,
    hidden: finderHidden,
  },
  [FinderTabsEnum.unsplash]: {
    tabKey: FinderTabsEnum.unsplash,
    title: t('page.finder.tabs.unsplash'),
    value: FinderTabsEnum.unsplash,
    hidden: externalImageHidden,
  },
})

export const useGetTabsOptions = ({
  projectId,
  contentType,
  showExternalImages,
  tabs,
}: IUseGetTabsOptions) => {
  const permissions = usePermissions()
  const hiddenFinderTabs = !permissions.has(
    projectId ? PermissionsKey.project_resource_c : PermissionsKey.company_resource_c,
  )

  const hiddenImageTabs =
    hiddenFinderTabs || contentType !== FileTypeEnum.IMAGE || !showExternalImages

  const tabsConfig = getTabConfig(hiddenFinderTabs, hiddenImageTabs)
  const defaultTabs = tabs || (projectId ? PROJECT_TABS : COMPANY_TABS)

  const tabsOptions = useMemo(
    () => defaultTabs.map((tab) => tabsConfig[tab]).filter(Boolean),
    [defaultTabs, tabsConfig],
  )

  const { setActiveTab, activeTab } = usePreferencesTab(
    ACTIVE_TAB_KEY,
    tabsOptions[0].tabKey,
    tabsOptions,
  )

  return { tabsOptions, setActiveTab, activeTab }
}
