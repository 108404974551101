export enum ElementTab {
  layout = 'layout',
  element = 'element',
  fill = 'fill',
}

export enum NavigationEnum {
  project = 'project',
  section = 'section',
  block = 'block',
  templates = 'templates',
}

export enum CommentsTab {
  all = 'all',
  section = 'section',
  block = 'block',
}

export enum TasksTab {
  current = 'current',
  done = 'done',
}

export enum PreviewMode {
  editor = 'editor',
  preview = 'preview',
  publicLink = 'publicLink',
  skeleton = 'skeleton',
  scorm = 'scorm',
  demo = 'demo',
  pdf = 'pdf',
}

export enum DeviceMode {
  desktop = 'desktop',
  tablet = 'tablet',
  mobile = 'mobile',
}

export enum EditorMode {
  fill = 'fill',
  pro = 'pro',
}

export enum SectionTypeEnum {
  landing = 'landing',
  test = 'test',
  chapter = 'chapter',
  cover = 'cover',
}

export enum BlockMode {
  questions = 'questions',
  start = 'start',
  end = 'end',
  view = 'view',
  cover = 'cover',
}
