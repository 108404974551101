import { PermissionsKey } from '@leenda/crud'
import cn from 'classnames'
import React from 'react'
import { useHistory } from 'react-router'

import { PreviewBlockActionUiEnum } from 'components/editor-v3/Editor/types'
import { getRootNode } from 'components/editor-v3/context/EditorContext/selectors/block'
import Icon from 'components/uiKit/Icon'
import { IMenuOption } from 'components/uiKit/Menu'
import { NOOP } from 'constants/commonConstans'
import { EDITOR_PATHS } from 'constants/paths'
import generateLink from 'routes/generateLink'
import { usePathParams } from 'routes/hooks'
import { usePermissions } from 'services/Permission/PermissionContext'
import { scrollProps } from 'services/Scroll/ScrollService'
import { setProjectNavigation, setToolbar } from 'services/Store/Project/actions'
import { CommentsTab, DeviceMode, PreviewMode } from 'services/Store/Project/enums'
import { useProjectContext, useProjectDispatch, useProjectPath } from 'services/Store/Project/hooks'
import { getIsCommentedBlock, getSection } from 'services/Store/Project/selectors'
import { Block, IBlockMode } from 'services/Store/Project/types'
import { getEnumOption } from 'utils/enum'
import { testProps } from 'utils/test/qaData'

import NodeContainer from '../../Node/NodeContainer'
import ControlItem from '../../controls/ControlItem'
import ControlsGroup from '../../controls/ControlsGroup/ControlsGroup'
import s from './PreviewBlock.module.scss'

interface IPreviewBlockProps {
  block: Block
  mode: IBlockMode
  index: number
}

const BLOCK_ACTIONS: { [key in PreviewBlockActionUiEnum]: IMenuOption<PreviewBlockActionUiEnum> } =
  {
    [PreviewBlockActionUiEnum.EDIT]: getEnumOption(
      'PreviewBlockActionUiEnum',
      PreviewBlockActionUiEnum.EDIT,
      {
        icon: 'penEdit',
      },
    ),
    [PreviewBlockActionUiEnum.ADD_COMMENT]: getEnumOption(
      'PreviewBlockActionUiEnum',
      PreviewBlockActionUiEnum.ADD_COMMENT,
      {
        icon: 'messagesChatRegular',
      },
    ),
  }

const PreviewBlock = React.forwardRef<HTMLDivElement, IPreviewBlockProps>(
  ({ block, index, mode }, ref) => {
    const permissions = usePermissions()
    const path = useProjectPath()
    const history = useHistory()
    const root = getRootNode(block)
    const section = useProjectContext(getSection)
    const isDone = (section?.isDone || block?.isDone) && mode.previewMode === PreviewMode.skeleton
    const maxWidth = root?.style?.[DeviceMode.desktop]?.maxWidth
    const isPreview = mode.previewMode === PreviewMode.preview
    const params = usePathParams(path)
    const dispatch = useProjectDispatch()
    const firstComment = useProjectContext(getIsCommentedBlock, block.uuid)

    const handleEditClick = (e: React.MouseEvent) => {
      e.stopPropagation()
      const link = generateLink(EDITOR_PATHS.main, { ...params, blockId: block.uuid })
      history.push(link)
    }

    const handleWriteCommentClick = () => {
      dispatch(setProjectNavigation({ blockId: block.uuid }))
      dispatch(setToolbar({ commentTab: CommentsTab.section }))
    }

    const handleCommentClick = (e: React.MouseEvent) => {
      e.stopPropagation()
      dispatch(setToolbar({ commentTab: CommentsTab.section }))
      dispatch(setProjectNavigation({ blockId: block.uuid, commentId: firstComment?.id }))
    }

    const canUpdateSection = permissions.has(PermissionsKey.project_sections_u)
    const canComment = permissions.has(PermissionsKey.project_comments_c)

    return (
      <div
        {...scrollProps(block.uuid)}
        {...testProps({ el: 'block', name: block.name, id: block.uuid, index })}
        className={cn(s.canvas, {
          [s.isDone]: isDone,
          [s.hover]: isPreview,
        })}
        ref={ref}
        style={{ maxWidth }}
      >
        <NodeContainer block={block} id={root.id} mode={mode} />
        {isPreview && (canUpdateSection || canComment) && (
          <div className={s.blockActions}>
            <ControlsGroup>
              {canComment && (
                <ControlItem
                  handler={handleWriteCommentClick}
                  option={BLOCK_ACTIONS[PreviewBlockActionUiEnum.ADD_COMMENT]}
                />
              )}
              {canUpdateSection && (
                <div onClick={handleEditClick}>
                  <ControlItem
                    handler={NOOP}
                    option={BLOCK_ACTIONS[PreviewBlockActionUiEnum.EDIT]}
                  />
                </div>
              )}
            </ControlsGroup>
          </div>
        )}
        {Boolean(firstComment) && (
          <div className={s.isComment} onClick={handleCommentClick}>
            <Icon name='messagesChatRegular' />
          </div>
        )}
      </div>
    )
  },
)

PreviewBlock.displayName = 'PreviewBlock'

export default PreviewBlock
