import { SCHEMA } from '@leenda/editor/lib/brand'

import { genFormSchema, FONT_SCHEMA_FIELDS } from 'services/Branding/genFormSchema'
import { t } from 'services/Translation'

export const STYLE_FORM_SCHEMA = genFormSchema(SCHEMA.testFeedback.style, {
  common: {
    META: { label: t('elements.testFeedback.tags.common') },
    borderRadius: {},
  },
  infoPanel: {
    META: { label: t('elements.testFeedback.tags.infoPanel') },
    backgroundColor: {},
    borderStyle: {},
    borderColor: {},
    borderWidth: {},
  },
  infoIcon: {
    META: { label: t('elements.testFeedback.tags.infoIcon') },
    backgroundImage: { label: t('input.label.image') },
  },
  successPanel: {
    META: { label: t('elements.testFeedback.tags.successPanel') },
    backgroundColor: {},
    borderStyle: {},
    borderColor: {},
    borderWidth: {},
  },
  successIcon: {
    META: { label: t('elements.testFeedback.tags.successIcon') },
    backgroundImage: { label: t('input.label.image') },
  },
  failPanel: {
    META: { label: t('elements.testFeedback.tags.failPanel') },
    backgroundColor: {},
    borderStyle: {},
    borderColor: {},
    borderWidth: {},
  },
  failIcon: {
    META: { label: t('elements.testFeedback.tags.failIcon') },
    backgroundImage: { label: t('input.label.image') },
  },

  // failDescription: {
  //   META: { label: t('elements.testFeedback.tags.failDescription') },
  // },
  // failTitle: { META: { label: t('elements.testFeedback.tags.failTitle') } },
  // infoDescription: {
  //   META: { label: t('elements.testFeedback.tags.infoDescription') },
  // },
  // infoTitle: { META: { label: t('elements.testFeedback.tags.infoTitle') } },
  // successDescription: {
  //   META: { label: t('elements.testFeedback.tags.successDescription') },
  // },
  // successTitle: {
  //   META: { label: t('elements.testFeedback.tags.successTitle') },
  // },
})

export const FONT_FORM_SCHEMA = genFormSchema(SCHEMA.testFeedback.font, FONT_SCHEMA_FIELDS)
