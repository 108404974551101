import React from 'react'

import { useOnClickBlock } from 'components/editor-v3/context/EditorContext/actions'
import { getShellByElementId } from 'components/editor-v3/context/EditorContext/selectors/block'
import RichText from 'components/form/RichText'
import { PreviewMode, EditorMode } from 'services/Store/Project/enums'
import { useProjectContext } from 'services/Store/Project/hooks'
import { getIsActiveElement } from 'services/Store/Project/selectors'

import './RichTextElement.scss'
import { RichTextElementType } from './RichTextElement.types'

const RichTextElement: RichTextElementType = ({
  block,
  element,
  mode,
  onChange,
  waiting,
  font,
}) => {
  const { id, error, value } = element
  const isFill = mode.previewMode === PreviewMode.editor && mode.editorMode === EditorMode.fill
  const onClick = useOnClickBlock()
  const isActive = useProjectContext(getIsActiveElement, id)

  const onMouseDown = (e: React.MouseEvent) => {
    const shellId = getShellByElementId(block!, id)
    onClick(e, { blockId: block?.uuid || '', nodeId: shellId, previewMode: mode.previewMode })
    e.stopPropagation()
  }

  return (
    <div onMouseDown={onMouseDown}>
      <RichText
        active={isActive}
        disabled={!isFill}
        error={error}
        key={id}
        name='richTextElement'
        onChange={onChange}
        styles={font}
        toolbarContainerId='rich-text-toolbar'
        value={value}
        waiting={waiting}
      />
    </div>
  )
}

export default React.memo(RichTextElement)
