import { useCallback } from 'react'

import Form from 'components/controls/Form'
import SubmitButton from 'components/controls/SubmitButton/SubmitButton'
import Button from 'components/uiKit/Button'
import Modal, { MCWithParams, ModalBody, ModalFooter, ModalHeader } from 'components/uiKit/Modal'
import { notify } from 'components/uiKit/Notification'
import { NotificationType } from 'components/uiKit/Notification/types'
import { getDataGrouping } from 'components/uiKit/Table/utils'
import { useFinderForceDeleteGroupAll } from 'gql/files.apollo'
import { t } from 'services/Translation'
import { FinderItem } from 'store/models'

import FileTable from './FileTable'

export interface IHistory {
  items: FinderItem[]
  name: string
}

type Params = {
  companyId: string
  removingItems: FinderItem[]
}

const FileRemove: MCWithParams<Params> = ({ onClose, params }) => {
  const { companyId, removingItems } = params

  const [forceDeleteById] = useFinderForceDeleteGroupAll()

  const onRemoveFiles = useCallback(async () => {
    const data = getDataGrouping(removingItems)
    try {
      const result = await forceDeleteById({
        variables: {
          data,
          companyId,
        },
      })

      if (result.data) {
        onClose()
        notify({
          type: NotificationType.success,
          message: t('notify.delete', { count: removingItems.length }),
        })
      }
    } catch (err) {
      onClose()
      notify({ type: NotificationType.warning, message: t('notify.cantDelete') })
      console.error(err)
    }
  }, [removingItems, forceDeleteById, companyId, onClose])

  return (
    <Form layout='vertical' name='fileRemove' onSubmit={onRemoveFiles}>
      <Modal maxWidth={720} name='fileRemove'>
        <ModalHeader
          description={t('modal.remove.subtitle', { count: removingItems.length })}
          title={t('modal.remove.title', { count: removingItems.length })}
        />
        <ModalBody sizeAutoCapable>
          <FileTable files={removingItems} />
        </ModalBody>
        <ModalFooter>
          <Button name='cancel' onClick={onClose} styleType='ghost'>
            {t('uiKit.button.cancel')}
          </Button>
          <SubmitButton label={t('uiKit.button.delete')} name='delete' />
        </ModalFooter>
      </Modal>
    </Form>
  )
}

export default FileRemove
