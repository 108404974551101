import { useContext } from 'react'

import { SYMBOLS_CODES, SlateFormats } from 'components/RichText/constants'
import { RichTextContext } from 'components/RichText/context'
import Button from 'components/uiKit/Button'
import { Tooltip } from 'components/uiKit/Dropdown'
import { t } from 'services/Translation'

import s from './SymbolItem.module.scss'

const SymbolItem = ({ name }: { name: SlateFormats }) => {
  const { onUpdateFormat, format } = useContext(RichTextContext)

  return (
    <Tooltip overlay={t(`uiKit.tooltip.${name}` as const)} trigger={['hoverWithoutContent']}>
      <div className={s.root}>
        <Button name={name} onClick={() => onUpdateFormat(name, format)} styleType='ghost'>
          {String.fromCodePoint(SYMBOLS_CODES[name])}
        </Button>
      </div>
    </Tooltip>
  )
}

export default SymbolItem
