import { AccordionFontSchemaType, AccordionSchemaType } from '@leenda/editor/lib/brand'
import { RichTextPanelItem } from '@leenda/editor/lib/elements'
import { textToRtValue } from '@leenda/rich-text'
import cn from 'classnames'

import NodeContainer from 'components/editor-v3/cource/layout/Node/NodeContainer'
import RichText from 'components/form/RichText/RichText'
import Icon from 'components/uiKit/Icon'
import { KitSize } from 'components/uiKit/KitTypes'
import { NOOP } from 'constants/commonConstans'
import { ElementFontCss, ElementStyleCss } from 'services/Branding/types'
import { Block, IBlockMode } from 'services/Store/Project/types'
import { t } from 'services/Translation'

import { Position, transformArrayToString } from '../AccordionElement'
import s from './PdfView.module.scss'

interface IPdfViewProps {
  items: RichTextPanelItem[]
  styles: ElementStyleCss<AccordionSchemaType>
  block?: Block | null
  mode: IBlockMode
  collapseWrapperStyles: React.CSSProperties
  collapseStyles: React.CSSProperties
  iconMargin: Record<string, number | undefined>
  font: ElementFontCss<AccordionFontSchemaType>
}

const PdfView = ({
  items,
  styles,
  block,
  mode,
  collapseWrapperStyles,
  collapseStyles,
  iconMargin,
  font,
}: IPdfViewProps) => {
  return (
    <div className={s.root} style={{ gap: styles.indents.gap }}>
      {items.map(({ label, value }, index) => (
        <div
          key={value}
          style={styles.collapse.borderStyle === 'none' ? collapseWrapperStyles : undefined}
        >
          <div className={s.collapse} style={collapseStyles}>
            <div
              className={s.title}
              style={{ padding: transformArrayToString(styles.indents?.accordion) }}
            >
              <div
                className={cn(s.defaultTitle, {
                  [s.right]: (styles.icon?.position as Position) === Position.right,
                })}
              >
                <div style={{ ...iconMargin }}>
                  {styles.icon.backgroundOpen ? (
                    <div
                      className={s.customIcon}
                      style={{ backgroundImage: String(styles.icon.backgroundOpen) }}
                    />
                  ) : (
                    <Icon
                      className={cn(s.icon, s.isOpen)}
                      name='iconsForElementsDown'
                      size={KitSize.S}
                    />
                  )}
                </div>
                <RichText
                  active={false}
                  name={`accordion.${index}`}
                  onChange={NOOP}
                  styles={font}
                  toolbarContainerId='rich-text-toolbar'
                  value={label || textToRtValue(t('elements.accordion.form.component'))}
                  disabled
                />
              </div>
            </div>
            <div style={{ padding: transformArrayToString(styles.indents?.content as any) }}>
              <NodeContainer block={block!} id={value} level={0} mode={mode} />
            </div>
          </div>
        </div>
      ))}
    </div>
  )
}

export default PdfView
