import React from 'react'

import { PreviewMode } from 'services/Store/Project/enums'
import { Block, IBlockMode } from 'services/Store/Project/types'

import EditorBlock from './EditorBlock'
import PreviewBlock from './PreviewBlock'
import SkeletonBlock from './SkeletonBlock'

const BLOCK_MAP: Partial<Record<PreviewMode, typeof PreviewBlock>> = {
  editor: EditorBlock,
  skeleton: SkeletonBlock,
}

interface IProps {
  mode: IBlockMode
  block?: Block | null
  index?: number
}

const BlockContainer: React.FC<IProps> = ({ block, mode, index = 0 }) => {
  const Component = BLOCK_MAP[mode.previewMode] || PreviewBlock

  if (Array.isArray(block?.schema)) {
    return <div>Old block</div>
  }

  if (!block?.schema?.rootId) {
    return null
  }

  return <Component block={block} index={index} mode={mode} />
}

export default React.memo(BlockContainer)
