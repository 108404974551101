import { createContext } from 'react'
import { BaseRange } from 'slate'

import { AccountMock } from 'components/uiKit/Employee'
import { NOOP } from 'constants/commonConstans'

import { SlateFormats } from './constants'

export type FormType = 'default' | 'link' | 'mention' | null

export type RichTextContextType = {
  format: any
  readOnly?: boolean
  disabled?: boolean
  form: FormType
  setForm: (from: FormType) => void
  employees: AccountMock[]
  mentionsMap: Record<string, AccountMock>
  onUpdateFormat: (format: SlateFormats, value?: any) => void
  mentionSearch: string | null
  mentionTarget: BaseRange | null
  mentionIndex: number | null
  rootRef: React.RefObject<HTMLDivElement>
  mouseDown: boolean
}

const RichTextContext = createContext<RichTextContextType>({
  format: {},
  onUpdateFormat: (_format, _value: any) => null,
  form: 'default',
  setForm: NOOP,
  employees: [],
  mentionSearch: null,
  mentionTarget: null,
  mentionIndex: null,
  rootRef: { current: null },
  mouseDown: false,
  mentionsMap: {},
})

export { RichTextContext }
