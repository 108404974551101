import { ButtonNavigationAction } from '@leenda/editor/lib/elements'
import { createSelector } from '@reduxjs/toolkit'
import lodash from 'lodash'
import * as R from 'ramda'

import { BrandType } from 'services/Branding/types'

import {
  courseContinue,
  courseEnd,
  courseStart,
  setCourseNavigation,
  questionReset,
  questionValidate,
  testEnd,
  testRestart,
  testStart,
  answerReset,
} from './actions'
import { BlockMode, SectionTypeEnum } from './enums'
import { useProjectContext, useProjectDispatch } from './hooks'
import { checkPassedTestSection } from './mutators/test'
import type { ProjectActionsTypes } from './reducer'
import {
  getBlockElementsState,
  getBlock,
  getBlocks,
  getSection,
  isDisabledNavigation,
  getEndCourseDisabled,
  getContinueTestQuestionId,
  getSectionState,
  getBlockState,
  getCurrentTry,
  getBlockId,
  getSectionId,
  isSectionDisabled,
} from './selectors'
import { getContext } from './selectors/context'
import { IProjectContext, Section } from './types'

const NOOP_TRIGGER = {
  action: 'noop',
  disabled: true,
  actionCreator: () => undefined,
} as const

const HIDE_TRIGGER = {
  action: 'noop',
  hide: true,
  actionCreator: () => undefined,
} as const

interface ISubmitTrigger {
  action:
    | ProjectActionsTypes['courseStart']
    | ProjectActionsTypes['courseContinue']
    | ProjectActionsTypes['questionValidate']
    | ProjectActionsTypes['questionValidate']
    | ProjectActionsTypes['setCourseNavigation']
    | ProjectActionsTypes['answerReset']
    | ProjectActionsTypes['questionReset']
    | ProjectActionsTypes['testStart']
    | ProjectActionsTypes['testEnd']
    | ProjectActionsTypes['courseEnd']
    | 'noop'
  disabled?: boolean
  actionCreator: () => any
  hide?: boolean
  tooltip?: string
}

export const getStartContinueTrigger = createSelector(
  (state: IProjectContext) => state.state.course,
  (courseState): ISubmitTrigger => {
    if (courseState.status === 'not attempted') {
      return {
        action: 'project/courseStart',
        actionCreator: () => courseStart(),
      }
    } else {
      return {
        action: 'project/courseContinue',
        actionCreator: () => courseContinue(),
      }
    }
  },
)

export const getNavigationTrigger = (
  state: IProjectContext,
  params: {
    sectionId: string
    blockId?: string
    currentSection?: Section | null
    type?: ButtonNavigationAction
  },
): ISubmitTrigger => {
  const { sectionId, blockId, currentSection, type } = params
  const section = getSection(state, { id: sectionId })
  const block = getBlock(state, { id: blockId })
  const nextTest = sectionId && section?.type === SectionTypeEnum.test
  const passed = checkPassedTestSection(state, currentSection?.id || '', currentSection?.test)
  const disabled = currentSection?.type === SectionTypeEnum.test ? !passed : false

  if (isDisabledNavigation(state) || isSectionDisabled(state, sectionId)) {
    // disable navigation to the next section if it is a final test
    return NOOP_TRIGGER
  }
  if (nextTest) {
    const testState = getSectionState(state, { id: sectionId })
    let blockId: string | undefined = ''
    const blocks = getBlocks(state, sectionId)
    if (testState?.status === 'notStarted') {
      blockId = blocks.find((block) => block.mode === 'start')?.uuid || R.head(blocks)?.uuid
    } else if (testState?.status === 'finished') {
      blockId = blocks.find((block) => block.mode === 'end')?.uuid || R.last(blocks)?.uuid
    } else {
      blockId = getContinueTestQuestionId(state, sectionId)
    }
    if (!blockId) {
      // throw new Error('blockId is undefined')  //case for no start block
      return NOOP_TRIGGER
    }

    return {
      action: 'project/setCourseNavigation',
      disabled,
      actionCreator: () => setCourseNavigation({ sectionId, blockId }),
    }
  }

  const navigateBlockId = block?.uuid || section?.blocksOrder[0]

  if (!navigateBlockId || !section) {
    return NOOP_TRIGGER
  }

  return {
    action: 'project/setCourseNavigation',
    disabled,
    actionCreator: () =>
      setCourseNavigation({
        sectionId,
        blockId: navigateBlockId,
        ...(type === ButtonNavigationAction.custom && { scrollBlockId: navigateBlockId }),
      }),
  }
}

export const useNavigationAction = () => {
  const dispatch = useProjectDispatch()
  const state = useProjectContext(getContext)
  return (params: { sectionId: string; blockId?: string }) => {
    const trigger = getNavigationTrigger(state, params)
    if (!trigger.disabled) {
      dispatch(trigger.actionCreator())
    }
  }
}

export const getNextSectionTrigger = createSelector(
  (state: IProjectContext) => state,
  (state: IProjectContext) => getSection(state, { next: true })?.id,
  (state, sectionId) => {
    if (!sectionId) {
      return getEndCourseTrigger(state)
    }
    return getNavigationTrigger(state, { sectionId, currentSection: getSection(state) })
  },
)

export const getPrevSectionTrigger = createSelector(
  (state: IProjectContext) => state,
  (state: IProjectContext) => getSection(state, { prev: true })?.id,
  (state, sectionId): ISubmitTrigger => {
    if (!sectionId) {
      return NOOP_TRIGGER
    }
    return getNavigationTrigger(state, { sectionId: sectionId })
  },
)

export const getNavigationLinkTrigger = (
  state: IProjectContext,
  params: { type: ButtonNavigationAction; sectionId: string; blockId?: string },
) => {
  const { type, sectionId, blockId } = params
  const currentSection = getSection(state)
  if (type === ButtonNavigationAction.nextSection) {
    return getNextSectionTrigger(state)
  } else if (type === ButtonNavigationAction.prevSection) {
    return getPrevSectionTrigger(state)
  } else if (type === ButtonNavigationAction.custom) {
    return getNavigationTrigger(state, { sectionId, blockId, currentSection, type })
  }
  throw new Error(`Unknown navigation action type: ${type}`)
}

export const getTestStartTrigger = createSelector(
  (state: IProjectContext) => state,
  (state): ISubmitTrigger => {
    const block = getBlock(state)
    if (block && block.mode !== 'start') {
      return NOOP_TRIGGER
    }
    return {
      action: 'project/testStart',
      disabled: false,
      actionCreator: () => testStart(),
    }
  },
)

export const getTestNextTrigger = (
  state: IProjectContext,
  params?: { sectionId?: string },
): ISubmitTrigger => {
  const { sectionId = getSectionId(state) } = params || {}
  const blockId = getBlockId(state)
  if (!blockId || !sectionId) {
    throw new Error('Cannot get next block without sectionId and blockId')
  }
  const nextBlock = getBlock(state, { next: true })
  if (nextBlock?.mode === 'end' || !nextBlock) {
    return getTestEndTrigger(state)
  } else {
    return {
      action: 'project/setCourseNavigation',
      disabled: false,
      actionCreator: () => setCourseNavigation({ sectionId, blockId: nextBlock.uuid }),
    }
  }
}

export const getTestSubmitTrigger = createSelector(
  (state: IProjectContext) => state,
  (
    _: IProjectContext,
    params: {
      sectionId?: string
      blockId?: string
      pt: (key: keyof BrandType['labels']) => string
    },
  ) => params,
  (
    state: IProjectContext,
    params: {
      sectionId?: string
      blockId?: string
      pt: (key: keyof BrandType['labels']) => string
    },
  ): ISubmitTrigger => {
    const { sectionId = getSectionId(state), blockId = getBlockId(state), pt } = params
    const blockState = getBlockState(state, blockId)
    const block = getBlock(state, { id: blockId })

    const section = sectionId ? getSection(state, { id: sectionId }) : null
    if (!blockState || !section || !sectionId || !blockId) {
      return NOOP_TRIGGER
    }
    if (!blockState.result) {
      return {
        action: 'project/questionValidate',
        disabled: !blockState.isReady,
        actionCreator: () => questionValidate({ blockId }),
      }
    }
    if (section?.type === SectionTypeEnum.test) {
      return getTestNextTrigger(state, { sectionId })
    } else if (section?.type === SectionTypeEnum.landing) {
      const attemptsLeft = (block?.test?.attempts || 1) - (blockState?.currentTry || 0)
      const tooltip = `${pt('button.attempts')} ${attemptsLeft}`
      const resultValid = blockState.result?.isValid
      if (block?.test?.repeat && block.test?.limit) {
        return {
          action: 'project/questionReset',
          disabled: !!attemptsLeft ? false : true,
          actionCreator: () => questionReset(blockId),
          tooltip,
        }
      }
      if (!block?.test?.repeat && block?.test?.limit) {
        return {
          action: 'project/questionReset',
          disabled: !resultValid && !!attemptsLeft ? false : true,
          actionCreator: () => questionReset(blockId),
          tooltip: !resultValid ? tooltip : undefined,
          hide: resultValid,
        }
      }
      if (!block?.test?.limit && !block?.test?.repeat) {
        return resultValid
          ? HIDE_TRIGGER
          : {
              action: 'project/questionReset',
              disabled: false,
              actionCreator: () => questionReset(blockId),
            }
      }
      return {
        action: 'project/questionReset',
        disabled: false,
        actionCreator: () => questionReset(blockId),
      }
    }
    throw new Error('Invalid block state')
  },
)

export const getTestEndTrigger = (state: IProjectContext): ISubmitTrigger => {
  const block = getBlock(state)
  const section = getSection(state)
  if (section?.type === SectionTypeEnum.landing) {
    return NOOP_TRIGGER
  } else if (section?.type === SectionTypeEnum.test) {
    if (block?.mode === BlockMode.end) {
      return getNextSectionTrigger(state)
    } else if (block?.mode === BlockMode.questions) {
      return {
        action: 'project/testEnd',
        disabled: false,
        actionCreator: () => testEnd(),
      }
    }
  }
  return NOOP_TRIGGER
}
export const getTestRetryTrigger = createSelector(
  (state: IProjectContext) => state,
  (state) => {
    const testRetryCount = getSection(state)?.test?.testRetryCount || 0
    const isLimit = getSection(state)?.test?.testLimit || false
    const currentTry = getCurrentTry(state) || 0
    const isTryCountEnded = currentTry >= testRetryCount
    return {
      action: 'project/testStart',
      disabled: isLimit ? testRetryCount <= 1 || isTryCountEnded : false,
      actionCreator: () => testRestart(),
    }
  },
)

export const getEndCourseTrigger = (state: IProjectContext): ISubmitTrigger => {
  return {
    action: 'project/courseEnd',
    disabled: getEndCourseDisabled(state),
    actionCreator: () => courseEnd(),
  }
}

export const getAnswerResetTrigger = (
  state: IProjectContext,
  params: { blockId?: string },
): ISubmitTrigger => {
  const { blockId = getBlockId(state) } = params
  const blockState = getBlockState(state, blockId)
  const elements = getBlockElementsState(state, blockId)
  const elementsHasValues = Object.values(elements).some((state) => state.value)

  if (!blockId) {
    return NOOP_TRIGGER
  }
  return {
    action: 'project/answerReset',
    disabled: !elementsHasValues || lodash.has(blockState, 'result'),
    actionCreator: () => answerReset(blockId),
  }
}
