import { SlateElementType } from '@leenda/rich-text'
import { isInlineElement } from '@leenda/rich-text/lib/types/guards'
import { Editor, Element, Transforms } from 'slate'

import { LIST_ELEMENTS } from '../richText.constants'
import { RichTextControl } from '../richText.types'

export const withFeatureControl = (controls: RichTextControl[]) => (editor: Editor) => {
  const { insertFragment, normalizeNode } = editor

  editor.insertFragment = (data) => {
    insertFragment(data)
    Transforms.unwrapNodes(editor, {
      at: [],
      split: true,
      mode: 'all',
      match: (n) => {
        return (
          !Editor.isEditor(n) &&
          Element.isElement(n) &&
          isInlineElement(n) &&
          !controls.includes(n.type as RichTextControl)
        )
      },
    })
  }

  if (!controls.includes('blockElement')) {
    editor.normalizeNode = (entry) => {
      normalizeNode(entry)
      const [node, path] = entry
      if (Element.isElement(node) && LIST_ELEMENTS.includes(node.type)) {
        Transforms.unwrapNodes(editor, {
          at: path,
        })
      }
      if (Element.isElement(node) && node.type === SlateElementType.listItem) {
        Transforms.setNodes(editor, { type: SlateElementType.elementDefault }, { at: path })
      }
    }
  }
  return editor
}
