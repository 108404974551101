import cn from 'classnames'
import { motion } from 'framer-motion'
import React, { useCallback } from 'react'

import { RenderFunction } from 'components/uiKit/KitTypes'
import { NOOP } from 'constants/commonConstans'
import { testProps } from 'utils/test/qaData'

import DefaultHeader from '../DefaultHeader'
import s from './CollapseControlled.module.scss'

interface IHeaderProps {
  open: boolean
  toggle?: () => void
}

const EXIT = { height: 0, opacity: 0 }
const INITIAL = { height: 0, opacity: 0, overflow: 'visible' }

export interface IControlledCollapseProps {
  children: React.ReactNode
  header: RenderFunction<IHeaderProps> | React.ReactElement | string
  iconPosition?: 'left' | 'right' | 'none'
  styleType?: 'default' | 'ghost'
  onChangeOpen?: (val: boolean) => void
  open: boolean
  name: string
  noBorder?: boolean
  paddings?: {
    title?: string
    content?: string
    iconGap?: number
  }
  collapsible?: 'full' | 'icon'
}

const CollapseControlled: React.FC<IControlledCollapseProps> = ({
  iconPosition = 'left',
  name,
  header,
  children,
  styleType = 'default',
  open,
  onChangeOpen,
  noBorder = false,
  paddings,
  collapsible = 'full',
}) => {
  const toggle = (val?: boolean) => {
    const newOpen = val ?? !open
    onChangeOpen && onChangeOpen(newOpen)
  }

  const animate = {
    transition: { type: 'tween' },
    height: open ? 'auto' : 0,
    opacity: open ? 1 : 0.5,
    overflow: 'hidden',
  }

  const titleElement =
    typeof header === 'function' ? (
      header({ open, toggle })
    ) : (
      <DefaultHeader gap={paddings?.iconGap} iconPosition={iconPosition} open={open}>
        {header as any}
      </DefaultHeader>
    )

  const handleLabelClick = useCallback(
    () => (collapsible === 'full' ? toggle() : NOOP),
    [toggle, collapsible],
  )

  return (
    <div className={cn(s.root, s[styleType], { [s.border]: !noBorder })}>
      <div
        {...testProps({ el: 'collapse', name, label: header, open })}
        className={cn(s.title, { [s.isOpen]: open })}
        onClick={handleLabelClick}
        style={{ padding: paddings?.title }}
      >
        {titleElement}
      </div>
      <motion.div
        animate={animate}
        aria-expanded={open}
        exit={EXIT}
        initial={INITIAL}
        style={{ overflow: 'hidden' }}
      >
        <div className={s.contentWrapper} style={{ padding: paddings?.content }}>
          {children}
        </div>
      </motion.div>
    </div>
  )
}

export default CollapseControlled
