import { useEffect } from 'react'

import { setUserPreferencesItems } from 'services/Store/Users/reducer'
import { selectUserPreferences } from 'services/Store/Users/selectors'
import { useAppSelector, useAppDispatch } from 'services/Store/hooks'

export const usePreferencesTab = <
  T extends string,
  V extends string,
  D extends { tabKey: V; hidden?: boolean; disabled?: boolean },
>(
  name: T,
  defaultActiveTab: V,
  availableTabs?: D[],
) => {
  const dispatch = useAppDispatch()
  const userPreferences = useAppSelector(selectUserPreferences)
  const tabKey = `${name}-tab`

  const activeTab = (userPreferences[tabKey] as V) || defaultActiveTab

  const setActiveTab = (value: V) => dispatch(setUserPreferencesItems({ [tabKey]: value }))

  useEffect(() => {
    if (!availableTabs?.find((t) => t.tabKey === activeTab && !t.hidden && !t.disabled)) {
      setActiveTab(defaultActiveTab)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return { setActiveTab, activeTab }
}
