import { useCallback, useEffect, useState } from 'react'

import { useOnClickBlock } from 'components/editor-v3/context/EditorContext/actions'
import { getShellByElementId } from 'components/editor-v3/context/EditorContext/selectors/block'
import { EditorMode, PreviewMode } from 'services/Store/Project/enums'
import { useProjectContext } from 'services/Store/Project/hooks'
import { getIsActiveElement } from 'services/Store/Project/selectors'
import { Block, IBlockMode } from 'services/Store/Project/types'

export const useGetRichTextProps = ({
  elementId,
  mode,
  block,
}: {
  block: Block | null | undefined
  elementId: string
  mode: IBlockMode
}) => {
  const isActiveElement = useProjectContext(getIsActiveElement, elementId)
  const [cursorPosition, setCursorPosition] = useState<'start' | 'end'>('start')
  const [activeIndex, setActiveIndex] = useState<string>('')
  const isFill = mode.previewMode === PreviewMode.editor && mode.editorMode === EditorMode.fill
  const onClick = useOnClickBlock()

  const onLabelSelect = useCallback(
    (newIndex: string, e?: React.MouseEvent) => {
      e?.stopPropagation()
      setActiveIndex(newIndex)
      if (newIndex < activeIndex) {
        setCursorPosition('end')
      } else {
        setCursorPosition('start')
      }
      const shellId = getShellByElementId(block!, elementId)
      onClick(e, { blockId: block?.uuid || '', nodeId: shellId, previewMode: mode.previewMode })
    },
    [activeIndex, block, elementId, onClick, mode],
  )

  const handlePropagationClick = (e: React.MouseEvent) => {
    e?.stopPropagation()
    const shellId = getShellByElementId(block!, elementId)
    onClick(e, { blockId: block?.uuid || '', nodeId: shellId, previewMode: mode.previewMode })
  }

  useEffect(() => {
    if (!isActiveElement) {
      setActiveIndex('')
    }
  }, [isActiveElement])

  return {
    isActiveElement,
    activeIndex,
    onLabelSelect,
    isFill,
    handlePropagationClick,
    cursorPosition,
  }
}
