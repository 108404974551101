import React from 'react'

import Icon from 'components/uiKit/Icon'
import { KitSize } from 'components/uiKit/KitTypes'

import s from './Hint.module.scss'

interface IPropsHint {
  text?: string
  children?: React.ReactNode
}

const Hint: React.FC<IPropsHint> = ({ text, children }) => {
  return (
    <span className={s.info}>
      <span className={s.icon}>
        <Icon name='builderTooltip' size={KitSize.S} />
      </span>
      <span className={s.text}>{text || children}</span>
    </span>
  )
}

export default Hint
