import React from 'react'

import { RichTextErrorContent } from './RIchTextErrorContent'
import { RICH_TEXT_INTERNAL_ERROR_MESSAGE } from './richText.constants'

type ErrBoundProps = {
  onError: () => void
  onRespawn: () => void
  children: React.ReactElement
}

type ErrBoundState = {
  hasError: boolean
}

class RichTextErrorBoundary extends React.Component<ErrBoundProps, ErrBoundState> {
  constructor(props: ErrBoundProps) {
    super(props)
    this.state = { hasError: false }
  }

  static getDerivedStateFromError(): Record<string, boolean> {
    return { hasError: true }
  }

  componentDidCatch(error: Error, errorInfo: React.ErrorInfo): void {
    const { onError } = this.props
    console.error(error, errorInfo)
    onError()
  }

  render(): React.ReactElement {
    const { hasError } = this.state
    const { onRespawn, children } = this.props

    return hasError ? (
      <RichTextErrorContent message={RICH_TEXT_INTERNAL_ERROR_MESSAGE} onClick={onRespawn} />
    ) : (
      children
    )
  }
}

export { RichTextErrorBoundary }
