import * as R from 'ramda'

import { Block, IBlockState, IProjectContext, MapType } from 'services/Store/Project/types'

import { getBlocks } from '..'
import { getSectionId } from '..'
import { BlockMode } from '../../enums'
import { getBlocksState } from './block'
import { getSectionState } from './section'

export const getStartTime = (state: IProjectContext, id?: string) =>
  getSectionState(state, { id: id || getSectionId(state) })?.startTime || 0

export const getEndTime = (state: IProjectContext, id?: string) =>
  getSectionState(state, { id: id || getSectionId(state) })?.endTime || 0

export const getTotalQuestions = (state: IProjectContext, id?: string) =>
  R.compose<[Block[]], Block[], number>(
    R.length,
    R.filter(R.propSatisfies((mode) => mode === BlockMode.questions, 'mode')),
  )(getBlocks(state, id || getSectionId(state)))

export const getDoneQuestions = (state: IProjectContext, id?: string) =>
  R.compose<[MapType<IBlockState>], IBlockState[], IBlockState[], number>(
    R.length,
    R.filter<IBlockState>(R.has('result')),
    R.values,
  )(getBlocksState(state, { sectionId: id || getSectionId(state) }))

export const getSuccessQuestions = (state: IProjectContext, id?: string) =>
  R.compose<[MapType<IBlockState>], IBlockState[], number>(
    R.reduce((acc, cur: IBlockState) => (cur.result?.isValid ? R.inc(acc) : acc), 0),
    R.values,
  )(getBlocksState(state, { sectionId: id || getSectionId(state) }))

export const getTestPercent = (state: IProjectContext, id?: string) =>
  getTestScore(state, id).percent

export const getTestScore = (state: IProjectContext, id?: string) => {
  const total = getTotalQuestions(state, id || getSectionId(state))
  const success = getSuccessQuestions(state, id || getSectionId(state)) || 0
  const scaled = success / total || 0
  const percent = Math.floor(scaled * 100)
  return {
    total,
    success,
    scaled,
    percent,
  }
}

export const getCurrentTry = (state: IProjectContext, id?: string) =>
  getSectionState(state, { id: id || getSectionId(state) })?.currentTry

export const getTestStartBlock = (state: IProjectContext, id?: string): Block | undefined =>
  R.find(R.propSatisfies((mode) => mode === BlockMode.start, 'mode'))(
    getBlocks(state, id || getSectionId(state)),
  )

export const getTestEndBlock = (state: IProjectContext, id?: string): Block | undefined =>
  R.find(R.propSatisfies((mode) => mode === BlockMode.end, 'mode'))(
    getBlocks(state, id || getSectionId(state)),
  )
