import { CONFIG_ELEMENTS } from '@leenda/editor/lib/elements'
import React, { useCallback } from 'react'

import { t } from 'services/Translation'

import { RichTextErrorContent } from './RIchTextErrorContent'
import RichTextCore from './RichTextCore'
import { RichTextViewer } from './RichTextViewer'
import { RICH_TEXT_INTERNAL_ERROR_MESSAGE } from './richText.constants'
import { IRichTextProps } from './richText.types'

const RichText: React.FC<IRichTextProps> = ({
  value,
  defaultValue = CONFIG_ELEMENTS.richText.defaultValue,
  disabled,
  placeholder = t('elements.richText.placeholder'),
  error,
  errorMessage = RICH_TEXT_INTERNAL_ERROR_MESSAGE,
  styles,
  verticalAlign,
  ...rest
}) => {
  const handleEditorRespawn = useCallback(
    () => rest.onChange?.(defaultValue, []),
    [rest.onChange, defaultValue],
  )

  const renderEditorInstance = disabled ? (
    <RichTextViewer styles={styles} value={value} verticalAlign={verticalAlign} />
  ) : (
    <RichTextCore
      handleEditorRespawn={handleEditorRespawn}
      initialValue={value}
      placeholder={placeholder}
      styles={styles}
      verticalAlign={verticalAlign}
      {...rest}
    />
  )

  return error?.length ? (
    <RichTextErrorContent message={errorMessage} onClick={handleEditorRespawn} />
  ) : (
    renderEditorInstance
  )
}

export default React.memo(RichText)
