import cn from 'classnames'
import React, { useCallback } from 'react'

import { testPropsEl } from 'utils/test/qaData'

import s from './Checkbox.module.scss'

interface ICheckboxProps {
  disabled?: boolean
  checked: boolean
  onChange?: (value: boolean) => void
  setIsValid?: (value: boolean) => void
  brandStyle?: React.CSSProperties
  value: string
  testData: string
  isEditor?: boolean
  children: React.ReactNode
}

const Checkbox: React.FC<ICheckboxProps> = ({
  disabled,
  checked,
  onChange,
  setIsValid,
  children,
  brandStyle,
  value,
  testData,
  isEditor,
}) => {
  const handleOnChange = useCallback(() => onChange?.(!checked), [checked, onChange])
  const handleSetIsValid = useCallback(
    (e: React.MouseEvent) => {
      e.stopPropagation()
      setIsValid?.(!checked)
    },
    [checked, setIsValid],
  )

  return (
    <label
      className={cn(s.root, { [s.disabled]: disabled, [s.checked]: checked })}
      onClick={isEditor ? (e) => e.preventDefault() : undefined}
      style={brandStyle}
      {...testPropsEl('courseCheckbox', { value }, { checked, testData })}
    >
      <input checked={checked} disabled={disabled} onChange={handleOnChange} type='checkbox' />
      <div
        className={s.checkmark}
        onClick={isEditor ? handleSetIsValid : undefined}
        {...testPropsEl('checkmarkCheckbox', { value }, { checked, testData })}
      />
      <div className={s.label}>{children}</div>
    </label>
  )
}

export default React.memo(Checkbox)
