import { PermissionsKey } from '@leenda/crud'
import { config } from 'config'
import { saveAs } from 'file-saver'
import { FileTypeEnum } from 'utils'

import FileFinderEdit from 'components/modals/FileFinderEdit'
import FileMove from 'components/modals/FileMove'
import FileRemove from 'components/modals/FileRemove'
import AddFolderModal from 'components/modals/FolderAddModal'
import FolderFinderEdit from 'components/modals/FolderFinderEdit'
import { IMenuOption } from 'components/uiKit/Menu'
import { useOpenModal } from 'components/uiKit/Modal'
import { FileMetaFor } from 'gql/__generated__/graphql'
import { usePermissions } from 'services/Permission/PermissionContext'
import { FinderItem } from 'store/models'
import { getEnumOption } from 'utils/enum'
import { IParams, isFinderFile, isFinderFolder } from 'utils/useFinder'

import { useFilePickerModal } from './FinderModalPage'
import { FinderTabsEnum } from './FinderModalPage/types'
import { SelectedLabel } from './SelectedLabel'
import { FinderPageActionUiEnum, FinderTableActionUiEnum } from './types'

const getPageActions = (disableUpload?: boolean, isProject?: boolean, library?: boolean | null) => [
  getEnumOption('FinderPageActionUiEnum', FinderPageActionUiEnum.ADD_FOLDER, {
    permission: {
      hidden: isProject ? PermissionsKey.project_resource_c : PermissionsKey.company_resource_c,
    },
  }),
  getEnumOption('FinderPageActionUiEnum', FinderPageActionUiEnum.ADD_FILES, {
    disabled: disableUpload,
    permission: {
      hidden: isProject ? PermissionsKey.project_resource_c : PermissionsKey.company_resource_c,
    },
  }),
  getEnumOption('FinderPageActionUiEnum', FinderPageActionUiEnum.ADD_FROM_LIBRARY, {
    hidden: Boolean(library),
    permission: {
      hidden: isProject ? PermissionsKey.project_resource_c : PermissionsKey.company_resource_c,
    },
  }),
]

export const usePageMenu = (
  params: IParams,
  setShowUploader: () => void,
  disableUpload?: boolean,
  createdFor: FileMetaFor.companies | FileMetaFor.projects = FileMetaFor.companies,
  hideExternalImages?: boolean | null,
) => {
  const addFolderModal = useOpenModal(AddFolderModal, {
    createdFor,
    fileMetaGroupId: params.fileMetaGroupId,
    companyId: params.companyId,
    projectId: params.projectId,
  })

  const filePickModal = useFilePickerModal({
    defaultContentType: FileTypeEnum.IMAGE,
    tabs: [FinderTabsEnum.unsplash],
  })

  const pageActions = usePermissions(
    getPageActions(disableUpload, createdFor === FileMetaFor.projects, hideExternalImages),
  )

  const onClickPageMenu = ({ value }: IMenuOption<FinderPageActionUiEnum>) => {
    if (value === FinderPageActionUiEnum.ADD_FILES) {
      return setShowUploader()
    }
    if (value === FinderPageActionUiEnum.ADD_FOLDER) {
      addFolderModal.open()
    }
    if (value === FinderPageActionUiEnum.ADD_FROM_LIBRARY) {
      filePickModal.open({})
    }
  }

  return { pageActions, onClickPageMenu }
}

const getTableActions = (selected: FinderItem[], isProject: boolean) => [
  getEnumOption('FinderTableActionUiEnum', FinderTableActionUiEnum.DOWNLOAD, {
    hidden: selected.length !== 1 || !isFinderFile(selected[0]),
    permission: {
      hidden: isProject ? PermissionsKey.project_resource_r : PermissionsKey.company_resource_r,
    },
  }),
  getEnumOption('FinderTableActionUiEnum', FinderTableActionUiEnum.UPDATE_FILE, {
    hidden: selected.length !== 1 || !isFinderFile(selected[0]),
    permission: {
      hidden: isProject ? PermissionsKey.project_resource_u : PermissionsKey.company_resource_u,
    },
  }),
  getEnumOption('FinderTableActionUiEnum', FinderTableActionUiEnum.UPDATE_FOLDER, {
    hidden: selected.length !== 1 || !isFinderFolder(selected[0]),
    permission: {
      hidden: isProject ? PermissionsKey.project_resource_u : PermissionsKey.company_resource_u,
    },
  }),
  getEnumOption('FinderTableActionUiEnum', FinderTableActionUiEnum.MOVE, {
    hidden: selected.length === 0,
    permission: {
      hidden: isProject ? PermissionsKey.project_resource_u : PermissionsKey.company_resource_u,
    },
  }),
  getEnumOption('FinderTableActionUiEnum', FinderTableActionUiEnum.DELETE, {
    hidden: selected.length === 0,
    permission: {
      hidden: isProject ? PermissionsKey.project_resource_d : PermissionsKey.company_resource_d,
    },
  }),
  // TODO: think about refactoring this or simplify
  getEnumOption('FinderTableActionUiEnum', FinderTableActionUiEnum.RESET, {
    label: <SelectedLabel selected={selected} />,
  }),
]

export const useTableMenu = (
  selected: FinderItem[],
  params: IParams,
  createdFor: FileMetaFor,
  setSelected: (selected: FinderItem[]) => void,
) => {
  const folderFinderEdit = useOpenModal(FolderFinderEdit, { companyId: params.companyId })
  const fileFinderEdit = useOpenModal(FileFinderEdit, {})
  const deleteModal = useOpenModal(FileRemove, {
    companyId: params.companyId,
    removingItems: selected,
  })
  const moveModal = useOpenModal(FileMove, {
    companyId: params.companyId,
    projectId: params?.projectId || '',
    fileMetaGroupId: params.fileMetaGroupId || '',
    createdFor,
    movingItems: selected,
  })

  const tableActions = usePermissions(
    getTableActions(selected, createdFor === FileMetaFor.projects),
  )

  const onClickTableMenu = ({ value }: IMenuOption<FinderTableActionUiEnum>) => {
    if (value === FinderTableActionUiEnum.UPDATE_FILE) {
      fileFinderEdit.open({
        editableFile: selected[0].id,
        name: selected[0].name,
        type: selected[0].type,
      })
    }
    if (value === FinderTableActionUiEnum.UPDATE_FOLDER) {
      if (isFinderFolder(selected[0])) {
        folderFinderEdit.open({
          editableCategory: selected[0].id,
          companyId: params.companyId,
          color: selected[0].color,
          name: selected[0].name,
        })
      }
    }
    if (value === FinderTableActionUiEnum.MOVE) {
      moveModal.open()
    }
    if (value === FinderTableActionUiEnum.DOWNLOAD) {
      if (isFinderFile(selected[0])) {
        const { files, name } = selected[0]
        saveAs(`${config.io.fileEndpoint}/download/${files?.[0]?.id}`, name?.toString())
      }
    }
    if (value === FinderTableActionUiEnum.DELETE) {
      deleteModal.open()
    }
    if (value === FinderTableActionUiEnum.RESET) {
      setSelected([])
    }
  }

  return { tableActions, onClickTableMenu }
}
