import { AudioSchemaType, VideoSchemaType } from '@leenda/editor/lib/brand'
import {
  CONFIG_ELEMENTS,
  EditorElement,
  AudioElementValue,
  VideoElementValue,
} from '@leenda/editor/lib/elements'
import { urlValidation, getParent } from 'utils'

import { PLAYBACK_RATE_OPTIONS } from 'components/MediaPlayer/constants'
import { genDataField } from 'components/controls/Field'
import { Tooltip } from 'components/uiKit/Dropdown'
import Icon from 'components/uiKit/Icon'
import { KitSize } from 'components/uiKit/KitTypes'
import { Block } from 'services/Store/Project/types'
import { t } from 'services/Translation'

type MediaSchemaType = AudioSchemaType | VideoSchemaType
type MediaValue = AudioElementValue | VideoElementValue

type MediaElement = EditorElement<MediaValue, MediaSchemaType>

const genField = genDataField<Block>()

export const sourceType = genField({
  label: t('input.label.source'),
  name: 'sourceType',
  type: 'select',
  layout: 'horizontal',
  params: (block, { name }) => {
    const { parent } = getParent<MediaElement>(name, block, 2)

    return {
      hidden: parent?.type === 'audio',
      options: [
        { value: 'file', label: t('input.option.file') },
        { value: 'url', label: t('input.option.link') },
      ],
      size: KitSize.S,
      fluid: true,
    }
  },
  effect: (_, changeData) => {
    const { value: sourceType, name } = changeData
    const { parentName } = getParent(name)

    return {
      ...changeData,
      name: parentName,
      value: { ...CONFIG_ELEMENTS.audio.defaultValue, sourceType },
    }
  },
})

export const url = genField({
  name: 'url',
  type: 'text',
  layout: 'horizontal',
  rules: [
    { required: true, message: t('input.placeholder.pasteLink') },
    { validator: urlValidation },
  ],
  params: (block, { name }) => {
    const { parent } = getParent<MediaValue>(name, block)

    return {
      hidden: parent?.sourceType === 'file',
      placeholder: t('input.placeholder.pasteLink'),
      size: KitSize.S,
    }
  },
  effect: async (block, changeData) => {
    const { value, name } = changeData
    const { parentName } = getParent<MediaElement>(name, block, 2)

    const url = String(value).includes('youtube.com')
      ? String(value).split('&list=')[0]
      : String(value)

    // const websiteValidated = validateUrl(String(value))
    // const checkedFileRequestResult = websiteValidated ? await checkUrlRequest(url) : {}
    // const checkedFileType =
    //   checkedFileRequestResult?.payload && websiteValidated && parent
    //     ? checkedFileRequestResult.payload.mediaType.includes(parent.type)
    //     : undefined
    //
    // if (checkedFileRequestResult?.payload && !checkedFileType) {
    //   notify({ type: NotificationType.error, message: t('notify.wrongFileType') })
    //   throw new Error('Error with File')
    // }
    //
    // if (checkedFileRequestResult?.errors) {
    //   notify({ type: NotificationType.error, message: t('notify.fileNotFound') })
    //   throw new Error('Error with File')
    // }

    return {
      ...changeData,
      name: `${parentName}.value`,
      value: { ...CONFIG_ELEMENTS.audio.defaultValue, sourceType: 'url', url },
    }
  },
})

export const file = genField({
  name: 'file',
  type: 'file',
  layout: 'horizontal',
  params: (block, { name }) => {
    const { parent } = getParent<MediaElement>(name, block, 2)

    return {
      hidden: parent?.value.sourceType === 'url',
      fileType: parent?.type as 'audio' | 'video',
      nullable: true,
    }
  },
  effect: (_, changeData) => {
    const { value: file, name } = changeData
    const { parentName } = getParent(name)

    return {
      ...changeData,
      name: parentName,
      value: { ...CONFIG_ELEMENTS.audio.defaultValue, sourceType: 'file', file },
    }
  },
})
export const coverImage = genField({
  name: 'coverImage',
  type: 'file',
  layout: 'horizontal',
  params: (block, { name }) => {
    const { parent } = getParent<MediaElement>(name, block, 2)
    return {
      hidden: parent?.type !== 'video',
      fileType: 'image',
      nullable: true,
    }
  },
})

export const autoPlay = genField({
  name: 'autoPlay',
  type: 'segmented',
  label: t('elements.media.form.autoPlay'),
  layout: 'horizontal',
})

export const repeat = genField({
  name: 'repeat',
  type: 'segmented',
  label: t('elements.media.form.repeat'),
  layout: 'horizontal',
})

export const controls = genField({
  name: 'controls',
  type: 'segmented',
  label: (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      {t('elements.media.form.controls')}
      <Tooltip overlay={t('input.tooltip.mediaPlayerControls')}>
        <Icon name='builderTooltip' size={KitSize.S} />
      </Tooltip>
    </div>
  ),
  layout: 'horizontal',
  params: (block, { name }) => {
    const { parent } = getParent<MediaElement>(name, block, 2)

    return {
      hidden: parent?.type !== 'video',
    }
  },
})

export const volume = genField({
  name: 'volume',
  type: 'slider',
  label: t('elements.media.form.volume'),
  layout: 'horizontal',
  params: { showInput: true, min: 0, max: 100 },
})

export const playbackRate = genField({
  name: 'playbackRate',
  type: 'select',
  label: t('elements.media.form.playbackRate'),
  layout: 'horizontal',
  params: {
    size: KitSize.S,
    options: PLAYBACK_RATE_OPTIONS,
    fluid: true,
  },
})

export const breakpoints = genField({
  name: 'file.params.breakpoints',
  type: 'timeRange',
  label: t('elements.media.form.slice'),
  layout: 'horizontal',
  params: (block, { name }) => {
    const { parent } = getParent<MediaValue>(name, block, 3)
    const { duration } = parent || {}

    return {
      maxTime: duration,
      disabled: !duration,
      format: duration && duration > 3600 ? 'HH:mm:ss' : 'mm:ss',
      placeholder: duration && duration > 3600 ? '00:00:00' : '00:00',
      suffixIcon: null,
      hideDisabledOptions: true,
    }
  },
})

export const blackout = genField({
  name: 'blackout',
  type: 'color',
  label: t('input.label.blackout'),
  layout: 'horizontal',
  params: (block, { name }) => {
    const { parent } = getParent<MediaElement>(name, block, 2)
    return {
      hidden: parent?.type !== 'video',
    }
  },
})
