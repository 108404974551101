import React from 'react'
import { Text } from 'slate'

import s from '../RichText.module.scss'
import { SlateInternal } from '../constants'

export const withPseudoSelection = (
  leaf: Text,
  children: React.ReactElement,
): React.ReactElement => {
  if (leaf[SlateInternal.pseudoSelection]) {
    return <span className={s.pseudoSelection}>{children}</span>
  } else {
    return children
  }
}

export const withRegister = (leaf: Text, children: React.ReactElement): React.ReactElement => {
  if (leaf.register) {
    const Tag = leaf.register as keyof JSX.IntrinsicElements
    return <Tag>{children}</Tag>
  } else {
    return children
  }
}
