import { MutableRefObject, useEffect, useMemo, useState } from 'react'

import { IPlacement, IType } from './types'

const OFFSET = 4
const isBottom = (placement: IPlacement) => placement.toLowerCase().startsWith('bottom')
const isTop = (placement: IPlacement) => placement.toLowerCase().startsWith('top')
const isLeft = (placement: IPlacement) => placement.toLowerCase().startsWith('left')
const isRight = (placement: IPlacement) => placement.toLowerCase().startsWith('right')

interface IPlacementType {
  refPortal: MutableRefObject<HTMLDivElement | null>
  refRoot: HTMLDivElement | null
  popupEle: HTMLDivElement | null
  placement: IPlacement
  type: IType
  offset: [number, number]
  size?: { width?: number; height?: number }
}

type IVisibilityType = 'visible' | 'hidden' | 'collapse' | 'initial' | 'inherit' | undefined

interface IPositionType {
  top: number
  left: number
  visibility: IVisibilityType
  width: string | number
  maxWidth: string | number
}

const DEFAULT_POSITIONS = {
  top: 0,
  left: 0,
  visibility: 'hidden' as IVisibilityType,
  width: 'auto',
  maxWidth: '100%',
}

// need refactoring
export const useGetPosition = ({
  refPortal,
  refRoot,
  popupEle,
  placement,
  type,
  offset,
  size,
}: IPlacementType) => {
  // Resize hotfix
  const [forceUpdater, forceUpdate] = useState<Record<string, unknown>>()
  useEffect(() => {
    const resizeObserver = new ResizeObserver((entries) => {
      for (const _ of entries) {
        refRoot?.getBoundingClientRect().height && forceUpdate({})
      }
    })
    refRoot && resizeObserver.observe(refRoot)
    return () => {
      resizeObserver.disconnect()
    }
  }, [refRoot])
  // end Resize hotfix

  const positions: IPositionType = useMemo(() => {
    if (refRoot && popupEle && refPortal.current) {
      const { top, left, right, width, height } = refRoot?.getBoundingClientRect()
      const { height: containerHeight, width: containerWidth } =
        refPortal.current.getBoundingClientRect()
      const { height: contentHeight, width: contentWidth } = popupEle?.getBoundingClientRect()

      let maxX = left + contentWidth
      let maxY = top + height + contentHeight
      let x =
        maxX > containerWidth
          ? left - contentWidth + (isLeft(placement) ? -OFFSET : isRight(placement) ? OFFSET : 0)
          : left + width + (isLeft(placement) ? -OFFSET : isRight(placement) ? OFFSET : 0)
      let y =
        maxY > containerHeight
          ? top - contentHeight + (isBottom(placement) ? -OFFSET : isTop(placement) ? OFFSET : 0)
          : top + height + (isBottom(placement) ? OFFSET : isTop(placement) ? -OFFSET : 0)
      const isLeftSide = left < containerWidth / 2
      const isTopSide = top < containerHeight / 2
      const offsetX = left - (contentWidth / 2 - width / 2)

      if (type === 'dropdown') {
        return {
          ...DEFAULT_POSITIONS,
          top: y,
          left,
          visibility: 'visible',
          width,
        }
      }

      switch (placement) {
        case 'bottom':
          if (!isLeftSide) {
            maxX = offsetX + contentWidth
            x = maxX > containerWidth ? right - contentWidth : offsetX
          }
          if (isLeftSide) {
            maxX = offsetX - contentWidth
            x = 0 > maxX ? left : offsetX
          }
          break
        case 'bottomLeft':
          x = maxX > containerWidth ? right - contentWidth : right - width
          break
        case 'bottomRight':
          x = contentWidth > right ? left : left + width - contentWidth
          break
        case 'top':
          y = top > contentHeight ? top - contentHeight : top + height
          if (!isLeftSide) {
            maxX = offsetX + contentWidth
            x = maxX > containerWidth ? right - contentWidth : offsetX
          }
          if (isLeftSide) {
            maxX = offsetX - contentWidth
            x = 0 > maxX ? left : offsetX
          }
          break
        case 'topRight':
          y = top > contentHeight ? top - contentHeight : top + height
          x = contentWidth > right ? left : left + width - contentWidth
          break
        case 'topLeft':
          y = top > contentHeight ? top - contentHeight - OFFSET : top + height + OFFSET
          x = maxX > containerWidth ? right - contentWidth : right - width
          break
        case 'right':
          if (isTopSide) {
            maxY = contentHeight / 2 - height / 2
            y = maxY > top ? top : top - contentHeight / 2 + height / 2
          }
          if (!isTopSide) {
            y =
              maxY > containerHeight
                ? top - contentHeight / 2 - height
                : top - contentHeight / 2 + height / 2
          }
          x = maxX > containerWidth ? right - contentWidth - width + OFFSET : right + OFFSET
          break
        case 'rightBottom':
          if (isTopSide) {
            y = contentHeight > top ? top : top - contentHeight + height
          }
          if (!isTopSide) {
            y = top - contentHeight + height
          }
          x = maxX > containerWidth ? right - contentWidth - width + OFFSET : right + OFFSET
          break
        case 'rightTop':
          if (isTopSide) {
            y = top
          }
          if (!isTopSide) {
            maxY = contentHeight + top
            y = Math.max(0, maxY > containerHeight ? top - contentHeight + height : top)
          }
          x = maxX > containerWidth ? right - contentWidth - width + OFFSET : right + OFFSET
          break
        case 'left':
          if (isTopSide) {
            maxY = contentHeight / 2 - height / 2
            y = maxY > top ? top : top - contentHeight / 2 + height / 2
          }
          if (!isTopSide) {
            y =
              maxY > containerHeight
                ? top - contentHeight + height
                : top - contentHeight / 2 + height / 2
          }
          maxX = contentWidth
          x = maxX > left ? left + width - OFFSET : left - contentWidth - OFFSET
          break
        case 'leftTop':
          if (isTopSide) {
            y = top
          }
          if (!isTopSide) {
            maxY = contentHeight + top
            y = maxY > containerHeight ? top - contentHeight + height : top
          }
          maxX = contentWidth
          x = maxX > left ? left + width - OFFSET : left - contentWidth - OFFSET
          break

        case 'leftBottom':
          if (isTopSide) {
            maxY = top + height
            y = maxY > contentHeight ? top - contentHeight + height : top
          }
          if (!isTopSide) {
            y = top - contentHeight + height
          }
          maxX = contentWidth
          x = maxX > left ? left + width - OFFSET : left - contentWidth - OFFSET
          break
      }

      const newCalculatedPosition = {
        ...DEFAULT_POSITIONS,
        top: y + offset[0],
        left: x + offset[1],
        visibility: 'visible' as IVisibilityType,
      }

      if (type === 'tooltip') {
        return {
          ...newCalculatedPosition,
          maxWidth: '300px',
        }
      }

      return {
        ...newCalculatedPosition,
        maxWidth:
          containerWidth < contentWidth + left ? containerWidth - x : DEFAULT_POSITIONS.maxWidth,
        minWidth: width,
      }
    }
    return DEFAULT_POSITIONS
  }, [placement, popupEle, refRoot, refPortal.current, size, forceUpdater])

  useEffect(() => {
    if (popupEle) {
      const onScroll = () => forceUpdate({})
      document.addEventListener('scroll', onScroll, true)
      return () => {
        document.removeEventListener('scroll', onScroll, true)
      }
    }
    return () => undefined
  }, [popupEle])

  return positions
}
