import { ElementSchemaType } from '@leenda/editor/lib/brand'
import { ElementsTypes, EditorElement, ElementValue } from '@leenda/editor/lib/elements'

import { IFieldConfig } from 'components/controls/Field/Field.types'
import { BrandFormSchemaType } from 'services/Branding/genFormSchema'
import { ElementFontCss, ElementStyleCss, ElementStyleValue } from 'services/Branding/types'
import { ElementState, Block, IBlockMode } from 'services/Store/Project/types'

export interface IElementConfig<
  Type extends ElementsTypes = ElementsTypes,
  Value extends ElementValue = ElementValue,
  Schema extends ElementSchemaType = ElementSchemaType,
  Font extends ElementSchemaType = ElementSchemaType,
  State extends ElementState = ElementState,
> {
  type: Type
  icon: string
  group: ElementGroup
  label: string
  Element: FCElementType<Value, Schema, Font, State>
  Demo: React.FC<IElementDemoProps<Schema, Font>> | React.FC<IElementDemoProps<Schema, Font>>[]
  defaultValue: DEPRECATED_ElementDefaultValue<Value>
  STYLE_FORM_SCHEMA: BrandFormSchemaType<Schema>
  FONT_FORM_SCHEMA: BrandFormSchemaType<Font>
  ValueForm?: FCValueFormType<Value, Schema, Font, State>
  EditorActions?: FCEditorActions<Value, Schema, Font, State>
}

export type FCElementType<
  Value extends ElementValue = ElementValue,
  Schema extends ElementSchemaType = ElementSchemaType,
  Font extends ElementSchemaType = ElementSchemaType,
  State extends ElementState = ElementState,
> = React.FC<{
  element: EditorElement<Value, Schema, Font>
  mode: IBlockMode
  styles: ElementStyleCss<Schema>
  block?: Block | null
  state?: ElementState<State>
  setState?: (value: ElementState<State>) => void
  onChange?: (value: Value) => void
  waiting?: boolean
  font: ElementFontCss<Font>
}>

export type FCValueFormType<
  Value extends ElementValue = ElementValue,
  Schema extends ElementSchemaType = ElementSchemaType,
  Font extends ElementSchemaType = ElementSchemaType,
  State extends ElementState = ElementState,
> = React.FC<{
  name: string
  element: EditorElement<Value, Schema, Font>
  mode: IBlockMode
  styles: ElementStyleValue<Schema>
  state?: ElementState<State>
  setState: (value: ElementState<State>) => void
}>

export type FCEditorActions<
  Value extends ElementValue = ElementValue,
  Schema extends ElementSchemaType = ElementSchemaType,
  Font extends ElementSchemaType = ElementSchemaType,
  State extends ElementState = ElementState,
> = React.FC<{
  element: EditorElement<Value, Schema, Font>
  customMode: IBlockMode | null
  mode: IBlockMode
  setCustomMode: React.Dispatch<React.SetStateAction<IBlockMode | null>>
  block: Block
  state?: ElementState<State>
  setState: (value: ElementState<State>) => void
  onChange: (value: Value) => void
}>

export type FCStyleFormType<
  Value extends ElementValue = ElementValue,
  Schema extends ElementSchemaType = ElementSchemaType,
  Font extends ElementSchemaType = ElementSchemaType,
  State extends ElementState = ElementState,
> = React.FC<{
  name: string
  element: EditorElement<Value, Schema, Font>
  mode: 'brand' | 'edit' | 'fill'
  Field: React.FC<{ config: IFieldConfig }>
  state?: ElementState<State>
  setState: (value: ElementState<State>) => void
}>

export interface IElementDemoProps<
  Schema extends ElementSchemaType = ElementSchemaType,
  Font extends ElementSchemaType = ElementSchemaType,
> {
  styles: ElementStyleCss<Schema>
  fonts: ElementFontCss<Font>
}

export type Properties<T> = T extends React.FC<infer P> ? P : never

export enum ElementGroup {
  notUsed = 'notUsed',
  view = 'view',
  interactive = 'interactive',
  test = 'test',
  cover = 'cover',
}

type DEPRECATED_ElementDefaultValue<Value extends ElementValue = ElementValue> = Value

export type DEPRECATED_Position = {
  x?: number
  y?: number
  width?: number
  height?: number
  autoHeight?: boolean
  autoWidth?: boolean
}
