import { CardFontSchemaType, CardSchemaType } from '@leenda/editor/lib/brand'
import { CardElementValue } from '@leenda/editor/lib/elements'
import { RichTextValue } from '@leenda/rich-text'
import cn from 'classnames'
import lodash from 'lodash'
import * as R from 'ramda'

import RichText from 'components/form/RichText/RichText'
import { useGetRichTextProps } from 'components/form/RichText/useGetRichTextProps'
import Icon from 'components/uiKit/Icon'
import { KitSize } from 'components/uiKit/KitTypes'
import { ElementFontCss, ElementStyleCss } from 'services/Branding/types'
import { Block, IBlockMode } from 'services/Store/Project/types'
import { t } from 'services/Translation'
import { useImageWithCrop } from 'utils/files'
import { testProps } from 'utils/test/qaData'

import s from './CardElement.module.scss'

const stylesKeys = {
  front: 'sideA',
  back: 'sideB',
} as const

const DEFAULT_TEXT = t('input.placeholder.addText')

interface ICardSideProps {
  side: 'front' | 'back'
  styles: ElementStyleCss<CardSchemaType>
  value: CardElementValue
  active: boolean
  id: string
  mode: IBlockMode
  block?: Block | null
  onChange?: (value: CardElementValue) => void
  onClick?: () => void
  waiting?: boolean
  font: ElementFontCss<CardFontSchemaType>
}

const CardSide = ({
  side,
  styles,
  value,
  active,
  id,
  mode,
  onChange,
  onClick,
  block,
  waiting,
  font,
}: ICardSideProps) => {
  const sideValues = value[side]
  const hasImg = Boolean(sideValues.image?.id)
  const stylesKey = stylesKeys[side]

  const img = useImageWithCrop(sideValues.image)
  const text = sideValues.label || (img?.path ? '' : DEFAULT_TEXT)

  const borderRadius = parseInt(String(styles.card.borderRadius))
  const borderWidth = parseInt(String(styles.border.borderWidth))
  const borderRadiusContent = borderRadius > borderWidth ? borderRadius - borderWidth : 0

  const backIconStyles = {
    ...styles.icon,
    backgroundColor: styles.icon.inversion ? styles.icon.color : styles.icon.backgroundColor,
    color: styles.icon.inversion ? styles.icon.backgroundColor : styles.icon.color,
  }

  const { isActiveElement, isFill, onLabelSelect, activeIndex } = useGetRichTextProps({
    elementId: id,
    mode,
    block,
  })

  const onChangeLabel = (labelValue: RichTextValue) => {
    onChange?.(R.assocPath([side, 'label'], labelValue, value))
  }

  const iconStyles = side === 'back' ? backIconStyles : styles.icon
  const cardName = `card.${side}`

  return (
    <div
      className={cn(s.cardContent, s[side], { [s.active]: active })}
      style={{
        backgroundImage: img?.path && hasImg ? `url(${img.path})` : undefined,
        ...lodash.omit(styles[stylesKey], ['alignItems', 'justifyContent']),
        borderRadius: isNaN(borderRadius) ? styles.card.borderRadius : borderRadiusContent,
      }}
    >
      <div
        className={s.mask}
        style={{
          backgroundColor: hasImg ? sideValues.fillColor : undefined,
          borderRadius: borderRadiusContent || styles.card.borderRadius,
        }}
      />
      <div className={s.textContainer} style={{ justifyContent: styles[stylesKey].alignItems }}>
        <div className={s.textInner} onMouseDown={(e) => onLabelSelect(cardName, e)}>
          <RichText
            active={isActiveElement && activeIndex === cardName && active}
            disabled={!isFill}
            key={cardName}
            name={cardName}
            onChange={(val) => onChangeLabel(val)}
            styles={font}
            toolbarContainerId='rich-text-toolbar'
            value={text}
            waiting={waiting}
          />
        </div>
      </div>
      <div className={cn({ [s.bottomFix]: styles[stylesKey].alignItems === 'center' })} />
      <div
        className={s.icon}
        onClick={() => isFill && onClick && onClick()}
        style={iconStyles}
        {...testProps({
          el: 'cardIcon',
          side,
        })}
      >
        <Icon name='iconsForElementsRotateRight' size={KitSize.M} />
      </div>
    </div>
  )
}

export default CardSide
