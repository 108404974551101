import cn from 'classnames'
import numeral from 'numeral'
import { FileTypeEnum } from 'utils'

import { IControlProps } from 'components/controls/Field/Field.types'
import { useFilePickerModal } from 'components/pages/FinderPage'
import { Tooltip } from 'components/uiKit/Dropdown'
import Icon from 'components/uiKit/Icon'
import { KitSize } from 'components/uiKit/KitTypes'
import { getFileMetaByIdFromCache } from 'gql/files.apollo'
import { t } from 'services/Translation'
import { testProps } from 'utils/test/qaData'

import s from './FieldFile.module.scss'

export type FileTypes = 'image' | 'audio' | 'video' | 'document'

interface IFieldFile {
  value: { id: string } | null
  fileType?: FileTypes
  placeholder?: string
  nullable?: boolean
}

const FieldFile: React.FC<IControlProps<IFieldFile>> = ({
  value,
  onChange,
  name,
  fileType,
  placeholder,
  defaultValue,
  onFocus,
  onBlur,
  ...rest
}) => {
  const { nullable } = rest
  const hasValue = value || defaultValue
  const file = getFileMetaByIdFromCache(hasValue?.id)
  const size = numeral(file?.size).format('0.0 b')
  const { name: fileName } = file || {}

  const [nameWithoutExt, ext] = (fileName || '').split('.')

  const filePick = useFilePickerModal({
    defaultContentType: (fileType === 'document' ? undefined : fileType) as FileTypeEnum,
  })

  const pickFile = async () => {
    onFocus?.()
    const fileId = await filePick.open({})
    fileId && onChange?.({ value: { id: fileId }, name })
    onBlur?.()
  }

  const resetFile = (e: React.MouseEvent) => {
    e.stopPropagation()
    value && onChange?.({ value: null, name })
  }

  return (
    <div
      className={cn(s.root, { [s.disabled]: !value })}
      onClick={pickFile}
      {...testProps({ el: 'fileField', name })}
    >
      <Icon className={s.icon} name='otherDownload' size={KitSize.S} />
      <div className={s.content}>
        <div
          className={cn(s.label, { [s.disabled]: !value })}
          {...testProps({
            el: 'fileFieldLabel',
            name,
            label: hasValue ? `${nameWithoutExt}.${ext}` : t('uiKit.button.addFile'),
          })}
        >
          <div className={s.fullName}>
            <div className={s.name}>{hasValue ? nameWithoutExt : t('uiKit.button.addFile')}</div>
            {hasValue && <div className={s.ext}>.{ext}</div>}
          </div>
          {hasValue && <div className={s.size}>{size}</div>}
        </div>
        {value && nullable && (
          <div
            className={s.iconReset}
            onClick={resetFile}
            {...testProps({ el: 'fileFieldReset', name })}
          >
            <Tooltip overlay={t('uiKit.tooltip.reset')}>
              <div className={cn(s.closeIcon, { [s.disabled]: !value })}>
                <Icon name='otherClose' size={KitSize.S} />
              </div>
            </Tooltip>
          </div>
        )}
      </div>
    </div>
  )
}

export default FieldFile
