import { useMemo } from 'react'

import RealtimeField from 'components/controls/RealtimeField'
import Collapse from 'components/uiKit/Collapse'
import Icon from 'components/uiKit/Icon'
import { KitSize } from 'components/uiKit/KitTypes'
import { FULL_COLLAPSE_PADDING } from 'constants/styles'
import { genColorOptions } from 'services/Branding/constants/fields'
import { useBrandTheme } from 'services/Branding/hooks'
import { t } from 'services/Translation'
import { chainName } from 'utils/form'

import s from './ChartForm.module.scss'
import { ChartValueFormType } from './ChartTypes'
import { description, type, x, y, items } from './chartFormConfig'

const ChartForm: ChartValueFormType = ({ name }) => {
  const theme = useBrandTheme()
  const colorOptions = useMemo(() => genColorOptions(theme), [theme])
  return (
    <>
      <Collapse
        header={t('elements.chart.form.settings')}
        iconPosition='right'
        name='settings'
        paddings={FULL_COLLAPSE_PADDING}
        initialOpen
      >
        <RealtimeField config={chainName(name, type)} />
        <RealtimeField config={chainName(name, description)} />
        <RealtimeField config={chainName(name, x)} />
        <RealtimeField config={chainName(name, y)} />
      </Collapse>
      <Collapse
        header={t('elements.chart.form.points')}
        iconPosition='right'
        name='label'
        paddings={FULL_COLLAPSE_PADDING}
        initialOpen
      >
        <div className={s.info}>
          <Icon name='builderTooltip' size={KitSize.S} />
          <div>{t('elements.chart.form.axisHint')}</div>
        </div>
        <RealtimeField config={chainName(name, items(colorOptions))} />
      </Collapse>
    </>
  )
}

export default ChartForm
