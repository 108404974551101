import { useCallback } from 'react'

import { ConfirmModal } from 'components/custom-forms/ConfirmModal/ConfirmModal'
import { useOpenModal } from 'components/uiKit/Modal'
import { notify } from 'components/uiKit/Notification'
import { NotificationType } from 'components/uiKit/Notification/types'
import { useBlocksDeleteByIds } from 'gql/blocks/apollo'
import { ScrollContainerEnum } from 'services/Scroll/enums'
import { setProjectNavigation } from 'services/Store/Project/actions'
import { useProjectContext, useProjectDispatch } from 'services/Store/Project/hooks'
import {
  getSection,
  getSelectedBlocksIds,
  getSelectedBlocksCount,
} from 'services/Store/Project/selectors'
import store from 'services/Store/store'
import { t } from 'services/Translation'

const confirmModalTitles = (count: number) => ({
  cancelButtonTitle: t('common.cancel'),
  info: t('entity.block.delete.info', { count }),
  okButtonTitle: t('common.delete'),
  title: t('entity.block.delete.title', { count }),
})

export const useDeleteBlock = () => {
  const dispatch = useProjectDispatch()
  const selectedBlocks = useProjectContext(getSelectedBlocksCount)
  const confirmModal = useOpenModal(ConfirmModal, confirmModalTitles(selectedBlocks))
  const [deleteBlock] = useBlocksDeleteByIds()

  const remove = useCallback(async () => {
    const projectState = store.getState().project
    const selectedBlocks = getSelectedBlocksIds(projectState)
    const section = getSection(projectState)
    const successMessage = t('notify.deleteBlock', { count: selectedBlocks.length })
    const confirm = await confirmModal.open()
    if (!confirm) {
      return
    }
    const blocksOrder = section?.blocksOrder || []
    let index = -1
    const newBlocksOrder = blocksOrder.filter((block, i) => {
      if (selectedBlocks.includes(block)) {
        index = i
        return false
      }
      return true
    })

    let activeUUID: undefined | string = undefined
    if (index !== -1) {
      if (index === blocksOrder.length - 1) {
        activeUUID = newBlocksOrder[index - selectedBlocks.length]
      } else {
        activeUUID = newBlocksOrder[index - selectedBlocks.length + 1]
      }
    }

    dispatch(
      setProjectNavigation({
        blockId: activeUUID,
        nodeId: null,
        scroll: [
          {
            container: ScrollContainerEnum.canvas,
            id: activeUUID,
            block: 'begin',
            scroll: ['never', 'always'],
          },
          {
            container: ScrollContainerEnum.blocksNav,
            id: activeUUID,
            block: 'begin',
            scroll: 'always',
          },
        ],
      }),
    )
    const res = await deleteBlock({
      variables: {
        uuid: selectedBlocks,
      },
      optimisticResponse: {
        editorBlocksDeleteByIds: {
          __typename: 'BlocksRemoveResult',
          blocksOrder: newBlocksOrder,
          uuid: selectedBlocks,
        },
      },
    })
    if (res.data?.editorBlocksDeleteByIds) {
      notify({
        type: NotificationType.success,
        message: successMessage,
      })
    }
  }, [selectedBlocks])
  return remove
}
